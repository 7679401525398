import {
    FETCH_USUARIO_LOGADO
} from '../actions/types';
import { initialState as initialFilialState } from './filiaisReducer';

const initialState = {
    dados: {
        nome: '',
        email: '',
        senha: '',
        foto: '',
        emailResumo: 'D',
        filial: initialFilialState
    }
};
  
export default function usuarioLogadoReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_USUARIO_LOGADO:
            return {
                ...state,
                dados: action.payload.usuario
            };

        default:
            return state;
    }
}