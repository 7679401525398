/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from "react-table"
import "react-table/react-table.css"

/* Métodos / Ações */
import { fetchDepartamentosList } from '../../actions/departamentos'
import { setModalOpen, setModalId } from '../../actions/modal'
import { clearFuncionarioId } from '../../actions/scroll'

/* Componentes */
import Error from '../Error'
import RightPanel from '../Sidepanel/RightPanel'
import DepartamentoFormUsuarios from './DepartamentoFormUsuarios'
import DepartamentoFormDocumentos from './DepartamentoFormDocumentos'

/* Botões */

class Departamentos extends Component {
    constructor() {
        super()
        
        this._isMounted = false
        this.state = {
            nome: '',
            rows: [],
            pages: null,
            loading: true,
            pageSize: null,
            page: null,
            sorted: null,
            filtered: [],
            statusFilter: null,
        }

        this.fetchData = this.fetchData.bind(this)
        this.renderDoubleClickable = this.renderDoubleClickable.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.requestData = this.requestData.bind(this)
        this.handleStatusFilter = this.handleStatusFilter.bind(this)
    }

    requestData() {
        let reqData = {
            pageSize: this.state.pageSize,
            page: this.state.page,
            sort: this.state.sorted,
            filter: this.state.filtered
        }
        return new Promise((resolve, reject) => {
            fetchDepartamentosList(reqData, resolve, reject, this.props.dispatch)
        }).then(res => {
            if (this._isMounted) {
                this.setState({
                    rows: res.rows,
                    pages: res.pages,
                    loading: false
                })
            }
        }).catch(err => {
            if (this._isMounted) {
                this.setState({
                    rows: [],
                    pages: 0,
                    loading: false
                })
            }
        })
    }

    fetchData(state, instance) {
        this.setState({ 
            loading: true,
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
            //filtered: state.filtered,
        },()=>{
            this.requestData()
        })
        
    } 

    handleStatusFilter(st = null){
        let filtered = this.state.filtered
        if(filtered){
            let existsFilter = false
            for(let i = 0; i < filtered?.length; i ++){
                if(filtered[i].id === 'status'){
                    existsFilter = true
                    if(st !== null)
                        filtered[i].value = st
                    else
                        filtered.splice(i, 1)
                }
            }

            if(!existsFilter)
                filtered.push({
                    id: 'status',
                    value: st
                })
        } else if(st !== null) {
            filtered = [{
                id: 'status',
                value: st
            }]
        }
        this.setState({
            statusFilter: st,
            loading: true,
            filtered: filtered
        },()=>{
            this.requestData()
        })
    }

    componentDidMount() {
        this._isMounted = true

        this.props.dispatch(clearFuncionarioId())
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    handleOpen(id, nome, modal){
        this.setState({
            nome: nome
        },()=>{
            this.props.dispatch(setModalOpen(modal))
            this.props.dispatch(setModalId(id))
        })
    }

    renderDoubleClickable(cellInfo) {
        return (
            <div onDoubleClick={()=>this.handleOpen(cellInfo.original.idDepartamento)}>{cellInfo.value}</div>
        )
    }

    render() {
        if (this.props.error && this.props.modal === '') {
            return <Error erro={this.props.error}></Error>
        }

        return(
            <div className="pb-3">
                <div className="row justify-content-center mx-0">

                    <div className="col-12 px-0">
                        <div className="card tertium-card">
                            <div className="card-header py-3 px-2 px-lg-5">
                                Departamentos
                            </div>
                            <div className="card-body px-2 px-lg-5">
                                <div className="row mx-0 mb-4 pt-4 row-table-filters">
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === null ? ' active' : '')} onClick={()=>{this.handleStatusFilter(null)}}>
                                        Todos
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === 1 ? ' active' : '')} onClick={()=>{this.handleStatusFilter(1)}}>
                                        Ativos
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === 0 ? ' active' : '')} onClick={()=>{this.handleStatusFilter(0)}}>
                                        Aguardando aprovação
                                    </button>
                                </div>
                                <ReactTable
                                    defaultSorted={[{
                                        id   : 'nome',
                                        desc : false,
                                    }]}
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "Nome",
                                            accessor: "nome",
                                            headerClassName: 'nome-placeholder',
                                            className: "font-weight-600 text-uppercase"
                                        },
                                        {
                                            Header: "Qt. Usuários",
                                            accessor: "qtUsuarios",
                                            filterable:false,
                                            maxWidth: 200,
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpen(props.original.idDepartamento, props.original.nome, 'edit_usuarios')}>
                                                        <button className="a mr-4" onClick={()=>this.handleOpen(props.original.idDepartamento, props.original.nome, 'edit_usuarios')} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                                        {props.value}
                                                        <span className="d-inline-block d-md-none pl-2">Usuário(s)</span>
                                                    </div>
                                            }
                                        },
                                        {
                                            Header: "Qt. Documentos",
                                            accessor: "qtDocumentos",
                                            filterable:false,
                                            maxWidth: 200,
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpen(props.original.idDepartamento, props.original.nome, 'edit_documentos')}>
                                                        <button className="a mr-4" onClick={()=>this.handleOpen(props.original.idDepartamento, props.original.nome, 'edit_documentos')} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                                        {props.value}
                                                        <span className="d-inline-block d-md-none pl-2">Documento(s)</span>
                                                    </div>
                                            }
                                        },
                                    ]}
                                    manual
                                    multiSort={false}
                                    data={this.state.rows}
                                    pages={this.state.pages}
                                    loading={this.state.loading}
                                    onFetchData={(state, instance)=>this.fetchData(state, instance)}
                                    filterable
                                    filtered={this.state.filtered}
                                    onFilteredChange={filtered => {
                                        this.setState({ filtered })
                                    }}
                                    previousText={<FontAwesomeIcon icon="chevron-left" />}
                                    nextText={<FontAwesomeIcon icon="chevron-right" />}
                                    resizable={false}
                                    loadingText="Carregando..."
                                    noDataText="Nenhum departamento encontrado"
                                    pageText="Página"
                                    ofText="de"
                                    rowsText="linhas"
                                    pageJumpText="ir para a página"
                                    rowsSelectorText="linhas por página"
                                    pageSizeOptions={[5, 10, 20, 50]}
                                    defaultPageSize={10}
                                    minRows={0}
                                    className="-highlight"
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                <RightPanel isPaneOpen={(this.props.modal === 'edit_documentos' && this.props.id !== null)} title="Documentos do Departamento" path="edit" onClose={()=>{
                    this.requestData()
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <DepartamentoFormDocumentos nome={this.state.nome} id={this.props.id}></DepartamentoFormDocumentos>
                </RightPanel>
                <RightPanel isPaneOpen={(this.props.modal === 'edit_usuarios' && this.props.id !== null)} title="Usuários do Departamento" path="edit" onClose={()=>{
                    this.requestData()
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <DepartamentoFormUsuarios nome={this.state.nome} id={this.props.id}></DepartamentoFormUsuarios>
                </RightPanel>
            </div>
        )
    }
}
Departamentos.propTypes = {
    modal: PropTypes.string.isRequired,
    id: PropTypes.any,
}

const mapStateToProps = (state) => ({
    modal: state.modal.modalOpen,
    id: state.modal.id,
    error: state.errors.message,
})

export default connect(mapStateToProps)(withRouter(Departamentos));
