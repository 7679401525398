import { SITUACAO } from "../types/SituacaoDocumento";

export const isAuditoria = (funcionario, status) => (
  !!funcionario?.dataDemissao &&
  ![
    SITUACAO.RECUSADO,
    SITUACAO.AGUARDANDO_APROVACAO,
    SITUACAO.UPLOAD_PENDENTE,
  ].includes(status)
)
