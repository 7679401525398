import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  FETCH_FUNCIONARIO_BEGIN,
  FETCH_FUNCIONARIO_SUCCESS,
  FETCH_FUNCIONARIO_FAILURE,
  GET_ERRORS,
} from "./types";
import { setModalOpen } from "./modal";
import { URL_API } from "./urls";
import { initialFuncionarioState } from "../reducers/funcionariosReducer";
import { getCancelToken } from "../helpers/axiosCancel";

const MySwal = withReactContent(Swal);

export function fetchFuncionarios(reqData, resolve, reject, dispatch) {
  axios
    .post(URL_API + "funcionarios", reqData, {
      cancelToken: getCancelToken("funcionarios/fetchFuncionarios"),
    })
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      if (axios.isCancel(err)) return;

      let erro = {
        message: "Não foi possível obter os dados dos funcionários",
      };
      if (err.response) {
        erro = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: { message: erro },
      });
      reject(erro.message);
    });
}

export const clearFuncionario = () => ({
  type: FETCH_FUNCIONARIO_SUCCESS,
  payload: { funcionario: initialFuncionarioState },
});

export const fetchFuncionarioBegin = () => ({
  type: FETCH_FUNCIONARIO_BEGIN,
});

export const fetchFuncionarioSuccess = (funcionario) => ({
  type: FETCH_FUNCIONARIO_SUCCESS,
  payload: { funcionario },
});

export const fetchFuncionarioFailure = (error) => ({
  type: FETCH_FUNCIONARIO_FAILURE,
  payload: { error },
});

export function fetchFuncionario(id = "") {
  return (dispatch) => {
    dispatch(fetchFuncionarioBegin());

    axios
      .get(URL_API + "funcionario/" + id)
      .then((res) => {
        const { data } = res.data;
        data.dataNascimento = new Date(data.dataNascimento.date);
        data.dataAdmissao = new Date(data.dataAdmissao.date);
        if (data.dataDemissao !== null)
          data.dataDemissao = new Date(data.dataDemissao.date);
        dispatch(fetchFuncionarioSuccess(data));
        return data;
      })
      .catch((err) => {
        let erro = {
          message: "Não foi possível obter os dados do funcionário",
        };
        if (err.response) {
          erro = err.response.data;
        }
        dispatch(fetchFuncionarioFailure(erro));
      });
  };
}

export const saveFuncionario =
  (funcionario, actions, afterSave) => (dispatch) => {
    let tmpFuncionario = Object.assign({}, funcionario);
    tmpFuncionario.filial = funcionario.filial.idFilial;

    axios
      .post(URL_API + "funcionario", tmpFuncionario)
      .then((res) => {
        MySwal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: "Funcionário salvo com sucesso",
        });
        actions.setSubmitting(false);

        dispatch(setModalOpen(""));
        if (afterSave) afterSave();
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: { message: err.response.data },
          });
          actions.setErrors(err.response.data.errors);
        }
        actions.setSubmitting(false);
      });
  };

export const deleteFuncionario =
  (idFuncionario, afterDelete, afterError) => (dispatch) => {
    axios
      .delete(URL_API + "funcionario/" + idFuncionario)
      .then((res) => {
        MySwal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: "Funcionário excluído com sucesso",
        });
        dispatch(setModalOpen(""));
        if (afterDelete) afterDelete();
      })
      .catch((err) => {
        if (err.response) {
          MySwal.fire({
            type: "error",
            title: err.response.data,
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          if (afterError) afterError();
        }
      });
  };

export const changeStatusFuncionario =
  (idFuncionario, status = 0, afterChange, afterError) =>
  (dispatch) => {
    axios
      .put(URL_API + "funcionario/" + idFuncionario + "/" + status)
      .then((res) => {
        MySwal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title:
            "Funcionário " +
            (status === 0 ? "bloqueado" : "ativado") +
            " com sucesso",
        });
        dispatch(setModalOpen(""));
        if (afterChange) afterChange();
      })
      .catch((err) => {
        if (err.response) {
          MySwal.fire({
            type: "error",
            title: err.response.data,
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 3000,
          });
          if (afterError) afterError();
        }
      });
  };

export function fetchCargos() {
  return new Promise((resolve, reject) => {
    axios
      .get(URL_API + "funcionario-cargos", {
        cancelToken: getCancelToken("funcionarios/fetchCargos"),
      })
      .then((res) => resolve(res.data.data))
      .catch((err) => {
        let erro = { message: "Não foi possível obter os cargos" };
        if (err.response) {
          erro = err.response.data;
        }
        reject(erro.message);
      });
  });
}

export const fetchGestaoFuncionarios = (reqData = {}) => {
  return async (fullfill, reject) => {
    try {
      const { data } = await axios.post(
        URL_API + "gestao-funcionarios",
        reqData
      );

      let list = data.rows;
      fullfill({ rows: list });


      const maxPages = Math.max(0, data.pages);
      for (let page = 1; page <= maxPages; page++) {
        const { data: result } = await axios.post(URL_API + "gestao-funcionarios", {
          ...reqData,
          page,
        });
        list = [...list, ...result.rows];

        fullfill({ rows: list });
      }

      return { rows: list };
    } catch (err) {
      console.error(err)
      if (err.response) {
        return reject(err.response.data);
      }

      return reject({ message: "Não foi possível obter funcionários" });
    }
  };
};
