import React, { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReactTable from "react-table";
import Accordion from "react-bootstrap/Accordion";

import {
  COLOR_GRAY2,
  COLOR_WARNING,
} from "../../Colors";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import { SITUACAO } from "../../types/SituacaoDocumento";
import UploadColumn from "../Table/CellDef/UploadColumn";
import SituacaoCell from "../Table/Cells/SituacaoCell";
import { isAuditoria } from "../../helpers/isAuditoria";

export default function DocumentsTable({
  employee,
  documents,
  handleNameDbClick,
  handleInitialDateDbClick,
  handleFinalDateDbClick,
  handleStatusDbClick,
  handleEyeClick,
  onUploadOpen,
  showingAllDocumentsDefault = false,
  onDocumentsExpand,
  handleEmployeeDocumentDismissal,
  onShowContracts,
}) {
  const [showAllDocuments, setShowAllDocuments] = useState(
    showingAllDocumentsDefault
  );

  const dismissedDocumentsLength = useMemo(
    () => documents.filter((document) => document.dispensado).length,
    [documents]
  );

  const handleShowAllDocuments = useCallback(() => {
    if (onDocumentsExpand) onDocumentsExpand(true, employee);
    setShowAllDocuments(true);
  }, [onDocumentsExpand, employee]);

  const handleCollapseDocuments = useCallback(() => {
    if (onDocumentsExpand) onDocumentsExpand(false, employee);
    setShowAllDocuments(false);
  }, [onDocumentsExpand, employee]);

  return (
    <Accordion.Collapse eventKey="1" className="row">
      <div className="col pb-3">
        <ReactTable
          columns={[
            {
              Header: "Nome",
              accessor: "nome",
              headerClassName: "",
              filterable: false,
              className: "font-weight-600",
              Cell: (props) => {
                return (
                  <div
                    className="d-flex align-items-center gap-1"
                    style={{
                      color: props.original.dispensado
                        ? COLOR_GRAY2
                        : "initial",
                    }}
                    onDoubleClick={() =>
                      handleNameDbClick?.(props.original, employee)
                    }
                    title={
                      props.original.dispensado ? "Documento dispensado" : null
                    }
                  >
                    {!!props.original.dispensado && (
                      <img
                        src="/images/documento_dispensado.png"
                        alt="Documento dispensado"
                        style={{
                          height: "1.8em",
                          opacity: 0.75,
                          mixBlendMode: "multiply",
                          userSelect: "none",
                          float: "left",
                        }}
                      />
                    )}
                    {props.value}
                  </div>
                );
              },
            },
            {
              Header: "Data Inicial",
              accessor: "dataInicio.date",
              filterable: false,
              maxWidth: 120,
              Cell: (props) => {
                if (props.value) {
                  return (
                    <div
                      onDoubleClick={() =>
                        handleInitialDateDbClick?.(
                          props.original,
                          employee,
                          showAllDocuments
                        )
                      }
                    >
                      {moment(props.value).format("DD/MM/YYYY")}
                    </div>
                  );
                } else {
                  return (
                    <div
                      onDoubleClick={() =>
                        handleInitialDateDbClick?.(
                          props.original,
                          employee,
                          showAllDocuments
                        )
                      }
                    >
                      --/--/---
                    </div>
                  );
                }
              },
            },
            {
              Header: "Data Final",
              accessor: "dataFim.date",
              filterable: false,
              maxWidth: 120,
              Cell: (props) => {
                if (props.value) {
                  return (
                    <div
                      onDoubleClick={() =>
                        handleFinalDateDbClick?.(
                          props.original,
                          employee,
                          showAllDocuments
                        )
                      }
                    >
                      {moment(props.value).format("DD/MM/YYYY")}
                    </div>
                  );
                } else {
                  return (
                    <div
                      onDoubleClick={() =>
                        handleFinalDateDbClick?.(
                          props.original,
                          employee,
                          showAllDocuments
                        )
                      }
                    >
                      --/--/---
                    </div>
                  );
                }
              },
            },
            {
              Header: "Situação",
              accessor: "status",
              filterable: false,
              maxWidth: 160,
              Cell: (props) => (
                <SituacaoCell
                  funcionario={employee}
                  handleOpen={() => (
                    handleStatusDbClick?.(
                      props.original,
                      employee,
                      showAllDocuments
                    )
                  )}
                  handleOpenJustificativa={() => (
                    handleStatusDbClick?.(
                      props.original,
                      employee,
                      showAllDocuments
                    )
                  )}
                  showContractsButton
                  onShowContracts={onShowContracts}
                  props={props}
                />
              )
            },
            {
              Header: "",
              filterable: false,
              sortable: false,
              className: "option-column-5",
              headerClassName: "option-column-5",
              Cell: (props) => {
                return (
                  <button
                    className="a"
                    onClick={() => {
                      window.history.pushState(
                        {
                          idFuncionario: employee.funcionario,
                        },
                        ""
                      );
                      handleEyeClick(
                        props.original,
                        employee,
                        showAllDocuments
                      );
                    }}
                    title="Ver"
                  >
                    <FontAwesomeIcon icon="eye" />
                  </button>
                );
              },
            },
            {
              Header: "",
              filterable: false,
              sortable: false,
              className: "option-column-5",
              headerClassName: "option-column-5",
              Cell: (row) =>
                [
                  SITUACAO.EXPIRADO,
                  SITUACAO.EXPIRANDO,
                  SITUACAO.RECUSADO,
                  SITUACAO.UPLOAD_PENDENTE,
                ].includes(row.original.status) &&
                !row.original.dispensado &&
                !isAuditoria(employee, row.original.status) && (
                  <button
                    className="a"
                    onClick={() =>
                      handleEmployeeDocumentDismissal({
                        ...row,
                        employee,
                      })
                    }
                    title="Dispensar"
                  >
                    <FontAwesomeIcon icon="file-excel" />
                  </button>
                ),
            },
            UploadColumn({
              hidden: row => isAuditoria(employee, row.original.status),
              onClick: (ev, props) =>
                onUploadOpen(props.original, employee, showAllDocuments),
            }),
          ]}
          defaultPageSize={documents.length - dismissedDocumentsLength}
          pageSize={showAllDocuments ? documents.length : documents.length - dismissedDocumentsLength}
          multiSort={false}
          data={documents}
          filterable={false}
          resizable={false}
          noDataText="Nenhum documento encontrado"
          minRows={0}
          showPagination={false}
          showPaginationBottom={false}
          showPageSizeOptions={false}
          className="mb-3"
        />
        {dismissedDocumentsLength > 0 && !showAllDocuments && (
          <div className="w-100">
            <ButtonPrimary
              title="Ver documentos dispensados"
              onClick={handleShowAllDocuments}
            >
              VER DISPENSADOS
            </ButtonPrimary>
            <span
              className="ml-2 font-weight-500"
              style={{ color: COLOR_WARNING, fontSize: "14px" }}
            >
              <FontAwesomeIcon icon="exclamation-triangle" /> +{" "}
              {dismissedDocumentsLength} documento{dismissedDocumentsLength > 1 ? 's' : ''}
            </span>
          </div>
        )}
        {dismissedDocumentsLength > 0 && showAllDocuments && (
          <ButtonPrimary
            title="Esconder documentos dispensados"
            onClick={handleCollapseDocuments}
          >
            ESCONDER DISPENSADOS
          </ButtonPrimary>
        )}
      </div>
    </Accordion.Collapse>
  );
}
