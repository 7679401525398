export const GET_ERRORS = 'GET_ERRORS'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER'
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN'
export const SET_MODAL_ID = 'SET_MODAL_ID'
export const SET_MODAL_EXTRA = 'SET_MODAL_EXTRA'
export const SET_APP_DATA = 'SET_APP_DATA'

// Notificações
export const SET_NOTIFICACOES = 'SET_NOTIFICACOES'

// Central de Exportação
export const SET_EXPORTACAO = 'SET_EXPORTACAO'

// Quero me cadastrar
export const SET_KEY = 'SET_KEY'
export const CHECK_KEY_ENTERPRISE = 'CHECK_KEY_ENTERPRISE'

// Permissões
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const GET_PERMISSIONS = 'GET_PERMISSIONS'

// Planos
export const SET_PLANOS_PENDENCIAS = 'SET_PLANOS_PENDENCIAS'
export const GET_PLANOS_PENDENCIAS = 'GET_PLANOS_PENDENCIAS'
export const SET_PLANOS_COTAS = 'SET_PLANOS_COTAS'
export const GET_PLANOS_COTAS = 'GET_PLANOS_COTAS'

// Empresas
export const FETCH_EMPRESA_BEGIN = 'FETCH_EMPRESA_BEGIN'
export const FETCH_EMPRESA_SUCCESS = 'FETCH_EMPRESA_SUCCESS'
export const FETCH_EMPRESA_FAILURE = 'FETCH_EMPRESA_FAILURE'

// Filiais
export const FETCH_FILIAL_BEGIN = 'FETCH_FILIAL_BEGIN'
export const FETCH_FILIAL_SUCCESS = 'FETCH_FILIAL_SUCCESS'
export const FETCH_FILIAL_FAILURE = 'FETCH_FILIAL_FAILURE'

// Funcionários
export const FETCH_FUNCIONARIO_BEGIN = 'FETCH_FUNCIONARIO_BEGIN'
export const FETCH_FUNCIONARIO_SUCCESS = 'FETCH_FUNCIONARIO_SUCCESS'
export const FETCH_FUNCIONARIO_FAILURE = 'FETCH_FUNCIONARIO_FAILURE'

// Modelos
export const FETCH_MODELOS_BEGIN = 'FETCH_MODELOS_BEGIN'
export const FETCH_MODELOS_SUCCESS = 'FETCH_MODELOS_SUCCESS'
export const FETCH_MODELOS_FAILURE = 'FETCH_MODELOS_FAILURE'

// Gestão de Documentos
export const FETCH_GESTAO_DOCUMENTOS_BEGIN = 'FETCH_GESTAO_DOCUMENTOS_BEGIN'
export const FETCH_GESTAO_DOCUMENTOS_SUCCESS = 'FETCH_GESTAO_DOCUMENTOS_SUCCESS'
export const FETCH_GESTAO_DOCUMENTOS_UPDATE = 'FETCH_GESTAO_DOCUMENTOS_UPDATE'
export const FETCH_GESTAO_DOCUMENTOS_FAILURE = 'FETCH_GESTAO_DOCUMENTOS_FAILURE'

// Contratos
export const FETCH_CONTRATO_BEGIN = 'FETCH_CONTRATO_BEGIN'
export const FETCH_CONTRATO_SUCCESS = 'FETCH_CONTRATO_SUCCESS'
export const FETCH_CONTRATO_FAILURE = 'FETCH_CONTRATO_FAILURE'
export const FETCH_CONTRATO_DOCUMENTOS_BEGIN = 'FETCH_CONTRATO_DOCUMENTOS_BEGIN'
export const FETCH_CONTRATO_DOCUMENTOS_SUCCESS = 'FETCH_CONTRATO_DOCUMENTOS_SUCCESS'
export const FETCH_CONTRATO_DOCUMENTOS_FAILURE = 'FETCH_CONTRATO_DOCUMENTOS_FAILURE'
export const FETCH_FUNCIONARIOS_CONTRATO_BEGIN = 'FETCH_FUNCIONARIOS_CONTRATO_BEGIN'
export const FETCH_FUNCIONARIOS_CONTRATO_SUCCESS = 'FETCH_FUNCIONARIOS_CONTRATO_SUCCESS'
export const FETCH_FUNCIONARIOS_CONTRATO_FAILURE = 'FETCH_FUNCIONARIOS_CONTRATO_FAILURE'

// Usuários
export const FETCH_USUARIO_BEGIN = 'FETCH_USUARIO_BEGIN'
export const FETCH_USUARIO_SUCCESS = 'FETCH_USUARIO_SUCCESS'
export const FETCH_USUARIO_FAILURE = 'FETCH_USUARIO_FAILURE'
export const FETCH_USUARIO_LOGADO = 'FETCH_USUARIO_LOGADO'

// CNAES
export const FETCH_CNAES_BEGIN = 'FETCH_CNAES_BEGIN'
export const FETCH_CNAES_SUCCESS = 'FETCH_CNAES_SUCCESS'
export const FETCH_CNAES_FAILURE = 'FETCH_CNAES_FAILURE'

// Departamentos
export const FETCH_DEPARTAMENTOS_BEGIN = 'FETCH_DEPARTAMENTOS_BEGIN'
export const FETCH_DEPARTAMENTOS_SUCCESS = 'FETCH_DEPARTAMENTOS_SUCCESS'
export const FETCH_DEPARTAMENTOS_FAILURE = 'FETCH_DEPARTAMENTOS_FAILURE'

// Documentos
export const FETCH_DOCUMENTOS_BEGIN = 'FETCH_DOCUMENTOS_BEGIN'
export const FETCH_DOCUMENTOS_SUCCESS = 'FETCH_DOCUMENTOS_SUCCESS'
export const FETCH_DOCUMENTOS_FAILURE = 'FETCH_DOCUMENTOS_FAILURE'
export const FETCH_DOCUMENTOS_FUNCIONARIOS_BEGIN = 'FETCH_DOCUMENTOS_FUNCIONARIOS_BEGIN'
export const FETCH_DOCUMENTOS_FUNCIONARIOS_SUCCESS = 'FETCH_DOCUMENTOS_FUNCIONARIOS_SUCCESS'
export const FETCH_DOCUMENTOS_FUNCIONARIOS_UPDATE = 'FETCH_DOCUMENTOS_FUNCIONARIOS_UPDATE'
export const FETCH_DOCUMENTOS_FUNCIONARIOS_FAILURE = 'FETCH_DOCUMENTOS_FUNCIONARIOS_FAILURE'
export const FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_BEGIN = 'FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_BEGIN'
export const FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_SUCCESS = 'FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_SUCCESS'
export const FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_UPDATE = 'FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_UPDATE'
export const FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_FAILURE = 'FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_FAILURE'
export const FETCH_DOCUMENTOS_CONTRATO_BEGIN = 'FETCH_DOCUMENTOS_CONTRATO_BEGIN'
export const FETCH_DOCUMENTOS_CONTRATO_SUCCESS = 'FETCH_DOCUMENTOS_CONTRATO_SUCCESS'
export const FETCH_DOCUMENTOS_CONTRATO_FAILURE = 'FETCH_DOCUMENTOS_CONTRATO_FAILURE'
export const FETCH_DOCUMENTO_BEGIN = 'FETCH_DOCUMENTO_BEGIN'
export const FETCH_DOCUMENTO_SUCCESS = 'FETCH_DOCUMENTO_SUCCESS'
export const FETCH_DOCUMENTO_FAILURE = 'FETCH_DOCUMENTO_FAILURE'

export const FETCH_ENVIO_BEGIN = 'FETCH_ENVIO_BEGIN'
export const FETCH_ENVIO_SUCCESS = 'FETCH_ENVIO_SUCCESS'
export const FETCH_ENVIO_FAILURE = 'FETCH_ENVIO_FAILURE'

// Pesquisa
export const FETCH_PESQUISA_BEGIN = 'FETCH_PESQUISA_BEGIN'
export const FETCH_PESQUISA_SUCCESS = 'FETCH_PESQUISA_SUCCESS'
export const FETCH_PESQUISA_FAILURE = 'FETCH_PESQUISA_FAILURE'

// Comentários
export const FETCH_COMENTARIO_BEGIN = 'FETCH_COMENTARIO_BEGIN'
export const FETCH_COMENTARIO_SUCCESS = 'FETCH_COMENTARIO_SUCCESS'
export const FETCH_COMENTARIO_FAILURE = 'FETCH_COMENTARIO_FAILURE'
export const FETCH_COMENTARIO_CLEAR = 'FETCH_COMENTARIO_CLEAR'

// Scroll
export const CLEAR_FUNCIONARIO_ID = 'CLEAR_FUNCIONARIO_ID'
export const SET_FUNCIONARIO_ID = 'SET_FUNCIONARIO_ID'

// Filial Funcionario
export const CLEAR_FILIAL_FUNCIONARIO_ID = 'CLEAR_FILIAL_FUNCIONARIO_ID';
export const SET_FILIAL_FUNCIONARIO_ID = 'SET_FILIAL_FUNCIONARIO_ID';

// Refresh
export const CLEAR_REFRESH = 'CLEAR_REFRESH';
export const SET_REFRESH = 'SET_REFRESH';
