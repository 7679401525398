import { SET_APP_DATA } from "./types";

export const setAppData = (data) => (dispatch) => {
  let previousData = JSON.parse(localStorage.getItem("tertium_app_data"));
  if (previousData === null) previousData = {};

  if (!data.filtroDocumentos) {
    delete previousData.filtroDocumentos;
  }
  if (data !== null) {
    previousData = Object.assign(previousData, data);
  }
  localStorage.setItem("tertium_app_data", JSON.stringify(previousData));
  dispatch({
    type: SET_APP_DATA,
    payload: previousData,
  });
};
