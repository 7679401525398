import {
    FETCH_CNAES_BEGIN,
    FETCH_CNAES_SUCCESS,
    FETCH_CNAES_FAILURE
} from '../actions/types';

const initialCnaesState = []

const initialState = {
    dados: initialCnaesState,
    loading: false,
    error: null
};
  
export default function cnaesReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_CNAES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_CNAES_SUCCESS:
            return {
                ...state,
                loading: false,
                dados: action.payload.cnaes
            };

        case FETCH_CNAES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                dados: initialCnaesState
            };

        default:
            return state;
    }
}