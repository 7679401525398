import {
    FETCH_FILIAL_BEGIN as BEGIN,
    FETCH_FILIAL_SUCCESS as SUCCESS,
    FETCH_FILIAL_FAILURE as FAILURE
} from '../actions/types'
import defaultReducer from './defaultReducer'

const model = 'filial'

export const initialState = {
    idFilial: '',
    cnpj: '',
    nome: '',
    telefone: '',
    email: '',
    inscricaoEstadual: '',
    inscricaoMunicipal: '',
    endereco: '',
    numero: '',
    bairro: '',
    cep: '',
    pontoReferencia: '',
    cidade: '',
    matriz: 0,
    status: 1
}

export default function filiaisReducer(state, action){ return defaultReducer(model, BEGIN, SUCCESS, FAILURE, initialState, state, action) }