import { SET_PLANOS_PENDENCIAS, GET_PLANOS_PENDENCIAS, SET_PLANOS_COTAS, GET_PLANOS_COTAS } from '../actions/types';

const initialState = {
    pendencias: [],
    cotas: {
        usuarios: {
            usado: 1,
            permitido: 0,
            upgrade: false
        }
    }
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_PLANOS_PENDENCIAS:
            return {
                ...state,
                pendencias: action.payload
            }
        case GET_PLANOS_PENDENCIAS:
            return action.payload;

        case SET_PLANOS_COTAS:
            return {
                ...state,
                cotas: action.payload
            }
        case GET_PLANOS_COTAS:
            return action.payload;

        default: 
            return state;
    }
};