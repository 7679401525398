import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FETCH_USUARIO_BEGIN as BEGIN, FETCH_USUARIO_SUCCESS as SUCCESS, FETCH_USUARIO_FAILURE as FAILURE, FETCH_USUARIO_LOGADO, GET_ERRORS } from './types'
import { setModalOpen } from './modal'
import { loginUser } from './authentication'
import { URL_API } from './urls'
import { initialState } from '../reducers/usuariosReducer'
import { defaultClearData, defaultFetchList, defaultFetchBegin, defaultFetchSuccess, defaultFetchFailure, defaultFetch, defaultDelete, defaultChangeStatus } from './default'

const MySwal = withReactContent(Swal)

const model_list = 'usuarios'
const model = 'usuario'

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model_list, reqData, resolve, reject, dispatch)
export const clearData = () => defaultClearData(SUCCESS, model, initialState)
export const fetchBegin = () => defaultFetchBegin(BEGIN)
export const fetchSuccess = data => defaultFetchSuccess(SUCCESS, model, data)
export const fetchFailure = error => defaultFetchFailure(FAILURE, error)
export const fetch = (id = '') => defaultFetch(model, fetchBegin, fetchSuccess, fetchFailure, id)
export const deleteData = (id, afterDelete, afterError) => defaultDelete(model, id, 'Usuário excluído com sucesso', afterDelete, afterError)
const msgStatus = (status)=> 'Usuário '+(status === 0 ? 'bloqueado' : 'ativado')+' com sucesso'
export const changeStatus = (id, status = 0, afterChange, afterError) => defaultChangeStatus(model, id, status, msgStatus, afterChange, afterError)

export function fetchUsuarioLogado() {
    return dispatch => {
        axios.get(URL_API + 'usuario')
        .then(res => {
            const { data } = res.data
            dispatch({
                type: FETCH_USUARIO_LOGADO,
                payload: { usuario: data }
            })
            return data
        })
        .catch(err => {
            let erro = {message: "Não foi possível obter os dados do usuário"}
            if(err.response){
                erro = err.response.data
            }
            dispatch({
                type: GET_ERRORS,
                payload: erro
            })
        });
    };
}

export const newUsuario = (user) => dispatch => {
    axios.post(URL_API + 'usuario/new', user)
        .then(res => {
            dispatch(loginUser(user, ()=>{
                window.location.href='/';
            }))
        })
        .catch(err => {
            let erro = {message: "Não foi possível realizar está operação! Por favor entre em contato com o suporte."}
            if(err.response.data.errors){
                erro = err.response.data.errors
            }
            dispatch({  
                type: GET_ERRORS,
                payload: erro
            }); 
        });
}

export const saveUsuario = (usuario, actions, afterSave) => dispatch => {
    let tmpUsuario = Object.assign({}, usuario)
    tmpUsuario.filial = usuario.filial.idFilial

    const departamentos = []
    for(let i=0; i<usuario.departamentos?.length; i++){
        departamentos.push(usuario.departamentos[i].idDepartamento)
    }

    tmpUsuario.departamentos = departamentos

    axios.post(URL_API + 'usuario', tmpUsuario)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Usuário salvo com sucesso'
            })
            actions.setSubmitting(false)

            dispatch(setModalOpen(''))
            if(afterSave)
                afterSave()
        })
        .catch(err => {
            if(err.response){
                if(err.response.data && err.response.data.message){
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
                } else {
                    dispatch({
                        type: GET_ERRORS,
                        payload: {message: err.response.data}
                    })
                }
                actions.setErrors(err.response.data.errors)
            }
            actions.setSubmitting(false)
        })
}

export const saveConfiguracoes = (usuario, actions) => dispatch => {
    let tmpUsuario = Object.assign({}, usuario)
    tmpUsuario.emailResumo = tmpUsuario.emailResumo.value
    var formData = new FormData()
    Object.keys(usuario).forEach(function(key) {
        formData.append(key, usuario[key])
    })

    axios.post(URL_API + 'config', formData, {headers: {
            'Content-Type': 'multipart/form-data'
        }})
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Configurações alteradas com sucesso'
            })
            actions.setSubmitting(false)
            dispatch(fetchUsuarioLogado())
        })
        .catch(err => {
            if(err.response){
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                })
                actions.setErrors(err.response.data.errors)
            }
            actions.setSubmitting(false)
        })
}

export const fetchUsuariosByFilialOptions = (input) => {
    const filter = input ? [{ id: 'nome', value: input }] : [];

    return new Promise((resolve, reject) => {
        axios.post(`${URL_API}usuarios`, { filter })
            .then(({ data: { rows } }) => resolve(rows))
            .catch((err) => reject(err.response || "Não foi possível obter os usuários"))
    });
}
