import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SET_EXPORTACAO } from "./types";
import { initialState } from "../reducers/exportacaoReducer";
import { COLOR_WARNING, COLOR_GREEN } from "../Colors";

export const setExportacao = (payload) => ({ type: SET_EXPORTACAO, payload });

export const montaCentralExportacao = (data = initialState) => {
  return (
    <Dropdown.Menu
      className="dropdown-menu-right dropdown-notification mw-100"
      style={{ maxWidth: "500px", minWidth: "400px" }}
    >
      {data?.length === 0 && (
        <Dropdown.Item as="button">
          <div className="text-center w-100">Nenhuma exportação encontrada</div>
        </Dropdown.Item>
      )}
      {data?.map((item) => {
        if (item.concluidoEm) {
          return (
            <Dropdown.Item
              as="a"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              key={item.uuid}
            >
              <FontAwesomeIcon
                size="2x"
                icon="check-circle"
                style={{ color: COLOR_GREEN }}
              />
              <div className="pl-2">
                <b>{item.titulo.toUpperCase()}</b>, concluido em{" "}
                <b>
                  {moment(item.concluidoEm.date).format("DD/MM/YYYY, HH:mm")}
                </b>{" "}
                - <b>clique para baixar.</b>
              </div>
            </Dropdown.Item>
          );
        }

        return (
          <Dropdown.Item as="button" key={item.uuid}>
            <FontAwesomeIcon
              size="2x"
              icon="info-circle"
              style={{ color: COLOR_WARNING }}
            />
            <div className="pl-2">
              <b>{item.titulo.toUpperCase()}</b>, iniciado em{" "}
              <b>
                {moment(item.solicitadoEm.date).format("DD/MM/YYYY, HH:mm")}
              </b>
              , situação{" "}
              <b>
                {item.situacao.toUpperCase()}
                {!!item.progresso && " (" + parseInt(item.progresso) + "%)"}
              </b>
            </div>
          </Dropdown.Item>
        );
      })}
    </Dropdown.Menu>
  );
};
