import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react';
import { dateField, objectField } from '../../validation/formValidation';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import Calendar from 'react-datepicker';
import MaskedTextInput from "react-text-mask";
import pt from 'date-fns/locale/pt';
import Select from 'react-select';

export const TIPOS_AFASTAMENTO = {
  DEMISSAO: 1,
};

const FuncionarioAfastamentoModalForm = ({ name, onSubmit, defaultValue, tiposAfastamento, onCancel }) => {
  const tiposAfastamentoOptions = tiposAfastamento.map(tipo => ({ label: tipo.nome, value: tipo.id }));

  return (
    <div className="d-flex flex-column">
      <span className="mb-3">Afastamento de <b>{name?.toUpperCase()}</b></span>
      <Formik
        initialValues={defaultValue}
        onSubmit={onSubmit}
        render={({ isSubmitting, handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <div className="form-group d-flex flex-column justify-content-center align-items-start gap-1">
              <label htmlFor="tipoAfastamento">Tipo de Afastamento*</label>
              <Field
                name="tipoAfastamento"
                validate={objectField}
              >
                {({ field }) =>
                  <Select
                    {...field}
                    name="tipoAfastamento"
                    id='tipoAfastamento'
                    className="w-100"
                    placeholder="Selecione o tipo de afastamento"
                    options={tiposAfastamentoOptions}
                    defaultValue={values.tipoAfastamento}
                    value={values.tipoAfastamento}
                    onChange={(value) => setFieldValue("tipoAfastamento", value)}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#ebebeb',
                        primary25: '#ebebeb',
                        primary: '#ff5818',
                      },
                    })}
                  />
                }
              </Field>
              <ErrorMessage name="tipoAfastamento" component="small" className="form-text text-danger" />
            </div>
            <div className="d-flex gap-1 align-items-start">
                <div className="form-group d-flex flex-column justify-content-center align-items-start gap-1">
                  <label htmlFor="inicioAfastamento">Início Afastamento*</label>
                  <Field
                    name="inicioAfastamento"
                    validate={dateField}
                  >
                    {() =>
                      <Calendar
                        name="inicioAfastamento"
                        id='inicioAfastamento'
                        locale={pt}
                        className="form-control"
                        showLeadingZeros={true}
                        selected={values.inicioAfastamento}
                        placeholderText="-- / -- / ----"
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => setFieldValue("inicioAfastamento", date)}
                        maxDate={values.fimAfastamento}
                        customInput={
                          <MaskedTextInput
                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                          />
                        }
                      />
                    }
                  </Field>
                  <ErrorMessage name="inicioAfastamento" component="small" className="form-text text-danger" />
                </div>
              {values.tipoAfastamento?.value !== TIPOS_AFASTAMENTO.DEMISSAO && (
                <div className="form-group d-flex flex-column justify-content-center align-items-start gap-1">
                  <label htmlFor="fimAfastamento">Fim Afastamento*</label>
                  <Field
                    name="fimAfastamento"
                    validate={dateField}
                  >
                    {() =>
                      <Calendar
                        name="fimAfastamento"
                        id='fimAfastamento'
                        locale={pt}
                        className="form-control"
                        showLeadingZeros={true}
                        selected={values.fimAfastamento}
                        placeholderText="-- / -- / ----"
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) => setFieldValue("fimAfastamento", date)}
                        minDate={values.inicioAfastamento}
                        customInput={
                          <MaskedTextInput
                            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                          />
                        }
                      />
                    }
                  </Field>
                  <ErrorMessage name="fimAfastamento" component="small" className="form-text text-danger" />
                </div>
              )}
            </div>
            <div className='d-flex justify-content-center gap-1 w-full mt-4'>
              <ButtonPrimary
                className="btn-cancel swal2-styled"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancelar
              </ButtonPrimary>
              <ButtonPrimary 
                className="btn-confirm swal2-styled"
                type="submit"
                disabled={isSubmitting}
              >
                Confirmar
              </ButtonPrimary>
            </div>
          </Form>
        )}
      />
    </div>
  )
}

export default FuncionarioAfastamentoModalForm
