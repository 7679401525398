/* Geral / Bibliotecas */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsyncSelect from "react-select/lib/Async";
import Calendar, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt"; // the locale you want
import isPast from "date-fns/isPast";
import "react-datepicker/dist/react-datepicker.css";
import MaskedTextInput from "react-text-mask";
import moment from "moment";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import Alert from 'react-bootstrap/Alert';

/* Cores */

/* Métodos / Ações */
import {
    fetchFuncionario,
    saveFuncionario,
    deleteFuncionario,
    changeStatusFuncionario,
    fetchCargos,
} from "../../actions/funcionarios";
import { loadFiliaisOptions } from "../../actions/filiais";
import { clearErrors } from "../../actions/errors";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";
import FuncionarioAfastamentoModalForm from "./FuncionarioAfastamentoModalForm";

/* Botões */
import ButtonSuccess from "../Buttons/ButtonSuccess";
import ButtonGray from "../Buttons/ButtonGray";
import ButtonWarning from "../Buttons/ButtonWarning";

/* Campos */
import Cpf from "../CustomFields/Cpf";
import Telefone from "../CustomFields/Telefone";

/* Validação */
import {
    requiredField,
    optionalEmailField,
    cpfField,
    objectField,
    dateField,
    optionalPhoneField,
    optionalDateField,
} from "../../validation/formValidation";
import FuncionarioModalForm from "./FuncionarioModalForm";
import {
    fetchTiposAfastamento,
    afastarFuncionarioContrato,
} from "../../actions/contratos";
import { setModalOpen } from "../../actions/modal";

const MySwal = withReactContent(Swal);

registerLocale("pt", pt);

class FuncionarioForm extends Component {
    constructor() {
        super();

        this._isMounted = false;
        this.state = {
            lockedFields: false,
            cargoSuggestions: [],
            tiposAfastamento: [],
        };
    }

    handleSubmit(values, actions) {
        this.props.dispatch(
            saveFuncionario(values, actions, this.props.onAfterSave)
        );
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.id) this.props.dispatch(fetchFuncionario(this.props.id));

        if (!this.state.tiposAfastamento.length)
            fetchTiposAfastamento().then((tiposAfastamento) =>
                this.setState({ tiposAfastamento: tiposAfastamento })
            );

        fetchCargos().then((cargos) =>
            this.setState({ cargoSuggestions: cargos })
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            let show = false;
            if (Object.keys(nextProps.errors)?.length) {
                show = true;
            }
            if (show) {
                MySwal.fire({
                    type: "error",
                    title: nextProps.errors.message,
                    toast: true,
                    position: "center",
                    showConfirmButton: false,
                    timer: 3000,
                });
                this.props.dispatch(clearErrors());
            }
        }
        if (nextProps.funcionario.idFuncionario) {
            this.setState({ lockedFields: true });
        }
    }

    handleDelete() {
        MySwal.fire({
            title: "",
            type: "warning",
            html:
                "Deseja realmente excluir o funcionário <b>" +
                this.props.funcionario.nome.toUpperCase() +
                "</b>, essa ação não pode ser revertida?",
            showCancelButton: true,
            confirmButtonText: "Sim, excluir",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.setState({
                    loading: true,
                });
                this.props.dispatch(
                    deleteFuncionario(
                        this.props.id,
                        this.props.onAfterSave,
                        () => {
                            this.setState({ loading: false });
                        }
                    )
                );
            }
        });
    }

    handleChangeStatus() {
        var st = this.props.funcionario.status === 1 ? 0 : 1;
        this.setState({
            loading: true,
        });
        this.props.dispatch(
            changeStatusFuncionario(
                this.props.id,
                st,
                this.props.onAfterSave,
                () => {
                    this.setState({ loading: false });
                }
            )
        );
    }

    handleFireEmployee = (date, actions) => {
        const values = { ...this.props.funcionario, ...date };
        this.props.dispatch(
            saveFuncionario(values, actions, this.props.onAfterSave)
        );
        Swal.clickConfirm();
    };

    handleDismissalDate() {
        MySwal.fire({
            title: "",
            type: "warning",
            html: (
                <FuncionarioModalForm
                    name={this.props.funcionario.nome.toUpperCase()}
                    onSubmit={this.handleFireEmployee}
                    onCancel={Swal.clickCancel}
                    defaultValue={{
                        dataDemissao: this.props.funcionario.dataDemissao,
                    }}
                />
            ),
            showCancelButton: false,
            showConfirmButton: false,
        });
    }

    handleAfastarFuncionario = (values) => {
        this.props.dispatch(
            afastarFuncionarioContrato({
                funcionario: this.props.funcionario.idFuncionario,
                filial: this.props.funcionario.filial.idFilial,
                afastamento: values?.tipoAfastamento?.value,
                inicio: values?.inicioAfastamento,
                termino: values?.fimAfastamento,
            }, this.props.onAfterSave)
        );
        this.props.dispatch(setModalOpen(""));
        Swal.clickConfirm();
    };

    handleAfastarFuncionarioModal() {
        const defaultValue = {
            tipoAfastamento: undefined,
            inicioAfastamento: undefined,
            fimAfastamento: undefined,
        };

        MySwal.fire({
            type: "warning",
            html: (
                <FuncionarioAfastamentoModalForm
                    name={this.props.funcionario.nome.toUpperCase()}
                    onSubmit={this.handleAfastarFuncionario}
                    onCancel={Swal.clickCancel}
                    defaultValue={defaultValue}
                    tiposAfastamento={this.state.tiposAfastamento}
                />
            ),
            showCancelButton: false,
            showConfirmButton: false,
        });
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>;
        }

        if (this.props.loading) {
            return <LoadingImage></LoadingImage>;
        }

        return (
            <div className="pb-5">
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-12 px-4">
                        <div className="card">
                            <div className="card-body px-2">
                                <Formik
                                    initialValues={this.props.funcionario}
                                    onSubmit={this.handleSubmit.bind(this)}
                                    render={({
                                        isSubmitting,
                                        setFieldValue,
                                        handleSubmit,
                                        values,
                                        validateField,
                                    }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="cpf">
                                                            CPF *
                                                        </label>
                                                        <Field
                                                            name="cpf"
                                                            validate={cpfField}
                                                        >
                                                            {({ field }) => {
                                                                return (
                                                                    <Cpf
                                                                        disabled={
                                                                            this
                                                                                .state
                                                                                .lockedFields
                                                                        }
                                                                        mounted={
                                                                            this
                                                                                ._isMounted
                                                                        }
                                                                        callback={(
                                                                            data
                                                                        ) => {
                                                                            if (
                                                                                typeof data.idFuncionario ===
                                                                                "undefined"
                                                                            ) {
                                                                                this.setState(
                                                                                    {
                                                                                        lockedFields: false,
                                                                                    }
                                                                                );
                                                                                return;
                                                                            }
                                                                            setFieldValue(
                                                                                "nome",
                                                                                data.nome
                                                                            );
                                                                            setFieldValue(
                                                                                "dataNascimento",
                                                                                new Date(
                                                                                    data.dataNascimento.date
                                                                                )
                                                                            );
                                                                            setFieldValue(
                                                                                "idFuncionario",
                                                                                data.idFuncionario
                                                                            );
                                                                            this.setState(
                                                                                {
                                                                                    lockedFields: true,
                                                                                }
                                                                            );
                                                                        }}
                                                                        {...field}
                                                                        id="cpf"
                                                                    ></Cpf>
                                                                );
                                                            }}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="cpf"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="nome">
                                                            Nome *
                                                        </label>
                                                        <Field
                                                            type="text"
                                                            id="nome"
                                                            name="nome"
                                                            className="form-control"
                                                            validate={
                                                                requiredField
                                                            }
                                                            disabled={
                                                                this.state
                                                                    .lockedFields
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="nome"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label
                                                            className="w-100"
                                                            htmlFor="dataNascimento"
                                                        >
                                                            Data de Nascimento *
                                                        </label>
                                                        <Field
                                                            name="dataNascimento"
                                                            validate={dateField}
                                                        >
                                                            {({ field }) => {
                                                                return (
                                                                    <Calendar
                                                                        name="dataNascimento"
                                                                        id="dataNascimento"
                                                                        className="form-control"
                                                                        showLeadingZeros={
                                                                            true
                                                                        }
                                                                        maxDate={moment()
                                                                            .subtract(
                                                                                14,
                                                                                "years"
                                                                            )
                                                                            .toDate()}
                                                                        selected={
                                                                            values.dataNascimento
                                                                        }
                                                                        locale={
                                                                            pt
                                                                        }
                                                                        disabled={
                                                                            this
                                                                                .state
                                                                                .lockedFields
                                                                        }
                                                                        placeholderText="-- / -- / ----"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        onChange={(
                                                                            date
                                                                        ) => {
                                                                            setFieldValue(
                                                                                "dataNascimento",
                                                                                date
                                                                            );
                                                                        }}
                                                                        customInput={
                                                                            <MaskedTextInput
                                                                                mask={[
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    "/",
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    "/",
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                ]}
                                                                            />
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="dataNascimento"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="email">
                                                            E-mail
                                                        </label>
                                                        <Field
                                                            type="email"
                                                            autoComplete="email"
                                                            id="email"
                                                            name="email"
                                                            className="form-control"
                                                            validate={
                                                                optionalEmailField
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="email"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="telefone">
                                                            Telefone
                                                        </label>
                                                        <Field
                                                            name="telefone"
                                                            validate={
                                                                optionalPhoneField
                                                            }
                                                        >
                                                            {({ field }) => {
                                                                return (
                                                                    <Telefone
                                                                        {...field}
                                                                        id="telefone"
                                                                    ></Telefone>
                                                                );
                                                            }}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="telefone"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="dataAdmissao">
                                                            Data de Admissão *
                                                        </label>
                                                        <Field
                                                            name="dataAdmissao"
                                                            validate={dateField}
                                                        >
                                                            {({ field }) => {
                                                                return (
                                                                    <Calendar
                                                                        name="dataAdmissao"
                                                                        id="dataAdmissao"
                                                                        className="form-control"
                                                                        showLeadingZeros={
                                                                            true
                                                                        }
                                                                        locale={
                                                                            pt
                                                                        }
                                                                        maxDate={
                                                                            values.dataDemissao
                                                                        }
                                                                        selected={
                                                                            values.dataAdmissao
                                                                        }
                                                                        placeholderText="-- / -- / ----"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        onChange={(
                                                                            date
                                                                        ) => {
                                                                            setFieldValue(
                                                                                "dataAdmissao",
                                                                                date
                                                                            );
                                                                        }}
                                                                        customInput={
                                                                            <MaskedTextInput
                                                                                mask={[
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    "/",
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    "/",
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                ]}
                                                                            />
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="dataAdmissao"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="dataDemissao">
                                                            Data de Demissão
                                                        </label>
                                                        <Field
                                                            name="dataDemissao"
                                                            validate={
                                                                optionalDateField
                                                            }
                                                        >
                                                            {({ field }) => {
                                                                return (
                                                                    <Calendar
                                                                        name="dataDemissao"
                                                                        id="dataDemissao"
                                                                        locale={
                                                                            pt
                                                                        }
                                                                        className="form-control"
                                                                        disabled
                                                                        minDate={
                                                                            values.dataAdmissao
                                                                        }
                                                                        showLeadingZeros={
                                                                            true
                                                                        }
                                                                        selected={
                                                                            values.dataDemissao
                                                                        }
                                                                        placeholderText="-- / -- / ----"
                                                                        dateFormat="dd/MM/yyyy"
                                                                        onChange={(
                                                                            date
                                                                        ) => {
                                                                            setFieldValue(
                                                                                "dataDemissao",
                                                                                date
                                                                            );
                                                                        }}
                                                                        customInput={
                                                                            <MaskedTextInput
                                                                                mask={[
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    "/",
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    "/",
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                    /\d/,
                                                                                ]}
                                                                            />
                                                                        }
                                                                    />
                                                                );
                                                            }}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="dataDemissao"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.props.permissoes
                                                .administradorEmpresa === 1 && (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="filial"
                                                                className="w-100"
                                                            >
                                                                Filial *
                                                            </label>
                                                            <Field
                                                                name="filial"
                                                                validate={
                                                                    objectField
                                                                }
                                                            >
                                                                {({
                                                                    field,
                                                                }) => {
                                                                    return (
                                                                        <AsyncSelect
                                                                            {...field}
                                                                            placeholder="Digite para localizar..."
                                                                            id="filial"
                                                                            isSearchable
                                                                            defaultValue={
                                                                                this
                                                                                    .props
                                                                                    .funcionario
                                                                                    .filial
                                                                            }
                                                                            getOptionValue={(
                                                                                option
                                                                            ) =>
                                                                                option[
                                                                                    "idFilial"
                                                                                ]
                                                                            }
                                                                            getOptionLabel={(
                                                                                option
                                                                            ) =>
                                                                                option[
                                                                                    "matriz"
                                                                                ] ===
                                                                                1
                                                                                    ? option[
                                                                                          "nome"
                                                                                      ].toUpperCase() +
                                                                                      " - MATRIZ"
                                                                                    : option[
                                                                                          "nome"
                                                                                      ].toUpperCase()
                                                                            }
                                                                            loadOptions={
                                                                                loadFiliaisOptions
                                                                            }
                                                                            defaultOptions={
                                                                                true
                                                                            }
                                                                            onChange={(
                                                                                value
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    "filial",
                                                                                    value
                                                                                );
                                                                            }}
                                                                            noOptionsMessage={(
                                                                                input
                                                                            ) => {
                                                                                return input.inputValue ===
                                                                                    ""
                                                                                    ? "Digite para localizar..."
                                                                                    : "Nenhum resultado encontrado";
                                                                            }}
                                                                            loadingMessage={() =>
                                                                                "Carregando..."
                                                                            }
                                                                            theme={(
                                                                                theme
                                                                            ) => ({
                                                                                ...theme,
                                                                                colors: {
                                                                                    ...theme.colors,
                                                                                    primary50:
                                                                                        "#ebebeb",
                                                                                    primary25:
                                                                                        "#ebebeb",
                                                                                    primary:
                                                                                        "#ff5818",
                                                                                },
                                                                            })}
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                            <ErrorMessage
                                                                name="filial"
                                                                component="small"
                                                                className="form-text text-danger"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label htmlFor="cargo">
                                                            Cargo
                                                        </label>
                                                        <Field
                                                            name="cargo"
                                                            id="cargo"
                                                            className="form-control"
                                                        >
                                                            {({ field }) => (
                                                                <Typeahead
                                                                    {...field}
                                                                    id={
                                                                        field.id ||
                                                                        "cargo"
                                                                    }
                                                                    labelKey="cargo"
                                                                    options={
                                                                        this
                                                                            .state
                                                                            .cargoSuggestions
                                                                    }
                                                                    onChange={([
                                                                        value,
                                                                    ]) =>
                                                                        setFieldValue(
                                                                            "cargo",
                                                                            typeof value ===
                                                                                "string"
                                                                                ? value
                                                                                : value?.cargo
                                                                        )
                                                                    }
                                                                    onInputChange={(
                                                                        value
                                                                    ) =>
                                                                        setFieldValue(
                                                                            "cargo",
                                                                            value
                                                                        )
                                                                    }
                                                                    defaultInputValue={
                                                                        values.cargo ||
                                                                        undefined
                                                                    }
                                                                    allowNew
                                                                    newSelectionPrefix=""
                                                                    emptyLabel="Sem Sugestões"
                                                                />
                                                            )}
                                                        </Field>
                                                        <ErrorMessage
                                                            name="cargo"
                                                            component="small"
                                                            className="form-text text-danger"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.props.funcionario.dataDemissao && (
                                                <div className="row">
                                                    <div className="col-12">
                                                        <Alert variant="warning">Crie o Funcionário novamente para readmiti-lo.</Alert>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row">
                                                <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                                                    {this.props.funcionario
                                                        .status === 1 && (
                                                        <ButtonSuccess
                                                            type="submit"
                                                            disabled={
                                                                isSubmitting || this.props.dataDemissao
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon="save" />{" "}
                                                            Salvar
                                                        </ButtonSuccess>
                                                    )}
                                                    {this.props.funcionario
                                                        .idFuncionario !== "" &&
                                                        this.props.funcionario
                                                            .status === 0 && (
                                                            <ButtonSuccess
                                                                type="button"
                                                                onClick={this.handleChangeStatus.bind(
                                                                    this
                                                                )}
                                                                disabled={
                                                                    isSubmitting || this.props.funcionario.dataDemissao
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon="check" />{" "}
                                                                Ativar
                                                            </ButtonSuccess>
                                                        )}
                                                    {this.props.funcionario
                                                        .idFuncionario !== "" &&
                                                        !this.props.funcionario
                                                            .dataDemissao && (
                                                            <ButtonWarning
                                                                type="button"
                                                                onClick={this.handleDismissalDate.bind(
                                                                    this
                                                                )}
                                                                className="ml-3"
                                                                disabled={
                                                                    isSubmitting
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon="times" />{" "}
                                                                Demitir
                                                            </ButtonWarning>
                                                        )}
                                                    {this.props.funcionario
                                                        .idFuncionario !== "" &&
                                                        !this.props.funcionario
                                                            .dataDemissao &&
                                                        (!this.props.funcionario
                                                            .afastamento ||
                                                            isPast(
                                                                this.props
                                                                    .funcionario
                                                                    .afastamento
                                                                    ?.dataFim
                                                            )) && (
                                                            <ButtonWarning
                                                                type="button"
                                                                onClick={this.handleAfastarFuncionarioModal.bind(
                                                                    this
                                                                )}
                                                                className="ml-3"
                                                                disabled={
                                                                    isSubmitting
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon="times" />{" "}
                                                                Afastar
                                                            </ButtonWarning>
                                                        )}

                                                    {this.props.funcionario
                                                        .idFuncionario !==
                                                        "" && (
                                                        <ButtonGray
                                                            type="button"
                                                            onClick={this.handleDelete.bind(
                                                                this
                                                            )}
                                                            className="ml-3"
                                                            disabled={
                                                                isSubmitting
                                                            }
                                                        >
                                                            <FontAwesomeIcon icon="trash" />{" "}
                                                            Excluir
                                                        </ButtonGray>
                                                    )}
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
FuncionarioForm.propTypes = {
    funcionario: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    id: PropTypes.any,
    onAfterSave: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    permissoes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    funcionario: state.funcionarios.funcionario,
    loading: state.funcionarios.loading,
    error: state.funcionarios.error,
    errors: state.errors,
    id: state.modal.id,
    permissoes: state.permissions.permissoes,
});

export default connect(mapStateToProps)(withRouter(FuncionarioForm));
