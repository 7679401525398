import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { COLOR_DARK2 } from '../../Colors'

import ButtonBack from '../Buttons/ButtonBack';

class NotFound extends Component {
    render() {
        return(
            <>
            <ButtonBack style={{height: '20px'}}></ButtonBack>
            <div className="py-5">
                <img src="/images/404.png" className="img-fluid d-block mx-auto" alt="404" title="404" style={{maxWidth: '200px'}}/>
                <h2 className="text-center font-weight-400 pt-3" style={{color: COLOR_DARK2}}>Oops! Não conseguimos localizar a página solicitada</h2>
            </div>
            </>
        )
    }
}
NotFound.propTypes = {
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(withRouter(NotFound));