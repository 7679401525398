/* Geral / Bibliotecas */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "react-toggle/style.css";

/* Cores */

/* Métodos / Ações */
import { fetchComentarios, saveComentario } from "../../actions/comentarios";
import { clearErrors } from "../../actions/errors";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";

/* Botões */
import ButtonWarning from "../Buttons/ButtonWarning";

/* Validação */
import { requiredField } from "../../validation/formValidation";
import { COLOR_WARNING } from "../../Colors";
import ButtonSuccess from "../Buttons/ButtonSuccess";

const MySwal = withReactContent(Swal);

class ComentarioForm extends Component {
  constructor() {
    super();

    this._isMounted = false;
    this.state = {
      lockedFields: false,
      page: 1
    };
  }

  handleSubmit(values, actions) {
    this.props.dispatch(
      saveComentario(
        values,
        actions,
        this.props.contrato,
        this.props.dados.documento
          ? this.props.dados.documento.idDocumento
          : null,
        this.props.dados.funcionario
          ? this.props.dados.funcionario.idFuncionario
          : null
      )
    );
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.dispatch(
      fetchComentarios(
        this.props.contrato,
        this.props.dados.documento
          ? this.props.dados.documento.idDocumento
          : null,
        this.props.dados.funcionario
          ? this.props.dados.funcionario.idFuncionario
          : null
      )
    );
  }

  commentsPaginate = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.props.dispatch(
        fetchComentarios(
          this.props.contrato,
          this.props.dados.documento
            ? this.props.dados.documento.idDocumento
            : null,
          this.props.dados.funcionario
            ? this.props.dados.funcionario.idFuncionario
            : null,
          this.state.page
        )
      );
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(nextProps) {
    if (nextProps.errors) {
      let show = false;
      if (Object.keys(nextProps.errors)?.length) {
        show = true;
      }
      if (show) {
        MySwal.fire({
          type: "error",
          title: nextProps.errors.message,
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000
        });
        this.props.dispatch(clearErrors());
      }
    }
    if (nextProps.contrato !== this.props.contrato) {
      this.props.dispatch(
        fetchComentarios(
          nextProps.contrato,
          this.props.dados.documento
            ? this.props.dados.documento.idDocumento
            : null
        )
      );
    }
  }

  render() {
    if (this.props.error) {
      return <Error erro={this.props.error}></Error>;
    }

    if (this.props.loading) {
      return <LoadingImage></LoadingImage>;
    }

    return (
      <div className="pb-5">
        <div className="row mx-0 d-flex align-items-center justify-content-center">
          <div className="col-12 px-4">
            <div className="card">
              <div className="card-body pt-2 pb-0">
                {this.props.loading && <LoadingImage></LoadingImage>}

                {!this.props.loading && this.props.total > 0 && (
                  <button
                    className="btn-link"
                    style={{ textDecoration: "underline", color: "#f15923" }}
                    onClick={this.commentsPaginate}
                    type="submit"
                  >
                    <u>+{this.props.total} comentários</u>
                  </button>
                )}
                {!this.props.loading && this.props.comentarios?.length ? (
                  this.props.comentarios
                    .reverse()
                    ?.map(function(comentario, index) {
                      return (
                        <div className="box-comment py-4" key={index}>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <p>
                                {comentario.interno === 1 && (
                                  <span
                                    title="Comentário interno"
                                    style={{ color: COLOR_WARNING }}
                                  >
                                    <FontAwesomeIcon icon={"lock"} />
                                    &nbsp;
                                  </span>
                                )}
                                <strong>
                                  {comentario.usuario.toUpperCase()}
                                </strong>{" "}
                                disse:
                              </p>
                            </div>
                            <div className="col-12 col-md-6 text-right">
                              <p>
                                {moment(comentario.dataHora.date).format(
                                  "DD/MM/YYYY [ás] HH:mm"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <p className="m-0">{comentario.mensagem}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                ) : (
                  <div>
                    Quer deixar um recado interno (sua empresa) ou para o{" "}
                    {this.props.tipoContrato
                      ? this.props.tipoContrato
                      : "Terceiro / Tomador"}
                    ?
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr className="mb-5 mt-0" />
        <div className="row mx-0 d-flex align-items-center justify-content-center">
          <div className="col-12 px-4">
            <div className="card">
              <div className="card-body px-2">
                <Formik
                  initialValues={{ comentarioInterno: 1, comentario: "" }}
                  onSubmit={this.handleSubmit.bind(this)}
                  render={({ isSubmitting, setFieldValue, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label
                              htmlFor="comentario"
                              className="font-weight-bold h4"
                            >
                              <FontAwesomeIcon
                                style={{ color: "#f15923", fontSize: "2rem" }}
                                icon={["far", "comment-dots"]}
                              />{" "}
                              DEIXE SUA MENSAGEM
                            </label>
                            <Field
                              component="textarea"
                              rows="8"
                              placeholder="Digite aqui..."
                              id="comentario"
                              name="comentario"
                              className="form-control"
                              validate={requiredField}
                              disabled={this.state.lockedFields}
                            />
                            <ErrorMessage
                              name="comentario"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row pt-4">
                        <label
                          className="font-weight-bold pl-2"
                          style={{ fontSize: "18px" }}
                        >
                          ENVIAR PARA:
                        </label>
                        <div className="col-12 d-flex align-items-center justify-content-center">
                          <ButtonWarning
                            onClick={() => {
                              setFieldValue("comentarioInterno", 1);
                            }}
                            type="submit"
                            disabled={isSubmitting}
                          >
                            <FontAwesomeIcon icon={"lock"} /> APENAS SUA EMPRESA
                          </ButtonWarning>
                          <ButtonSuccess
                            onClick={() => {
                              setFieldValue("comentarioInterno", 0);
                            }}
                            type="submit"
                            className="ml-3"
                            disabled={isSubmitting}
                          >
                            <FontAwesomeIcon icon={"globe-americas"} /> TODOS
                          </ButtonSuccess>
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ComentarioForm.propTypes = {
  errors: PropTypes.object.isRequired,
  dados: PropTypes.any,
  comentarios: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  comentarios: state.comentarios.comentarios,
  total: state.comentarios.total - state.comentarios.comentarios?.length,
  errors: state.errors,
  dados: state.dados.data,
  loading: state.comentarios.loading
});

export default connect(mapStateToProps)(withRouter(ComentarioForm));
