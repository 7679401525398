import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setAppData } from "../../../actions/dados";
import { fetchList as fetchGestao } from "../../../actions/gestaoDocumentos";
import {
  fetchFuncionariosList,
  fetchContratoList,
  fetchList as fetchDocumentosList,
} from "../../../actions/documentos";
import { STATUS } from "../../../types/StatusContrato";
import { setRefresh, REFRESH_KEY } from "../../../actions/refresh";

export const AbreGestaoContratoButton = ({ appData, contrato, history, dispatch }) => {
  const isAguardandoAprovacao = contrato.status === STATUS.AGUARDANDO_APROVACAO;

  if (contrato.tipo === "terceiro" && isAguardandoAprovacao) return null;

  const abreGestao = () => {
    if (isAguardandoAprovacao) return;

    if (appData?.filtros?.contrato?.idContrato !== contrato?.idContrato) {
      dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
    }

    dispatch(
      setAppData({
        filtros: {
          filial: {
            idFilial: contrato.idFilial,
            nome: contrato.filial,
          },
          empresa: {
            nome: contrato.empresa,
            idFilial: parseInt(contrato.idEmpresa),
          },
          contrato: contrato,
        },
      })
    );
    history.push("/gestao-documentos/");

    const reqData = {
      empresa: parseInt(contrato.idEmpresa),
      contrato: contrato.idContrato,
    };
    dispatch(fetchGestao(reqData));
    dispatch(fetchDocumentosList(reqData));
    dispatch(fetchFuncionariosList(reqData));
    dispatch(fetchContratoList(reqData));
  };

  return (
    <button
      className="a"
      style={{
        cursor: isAguardandoAprovacao ? 'not-allowed' : 'pointer'
      }}
      disabled={isAguardandoAprovacao}
      onClick={() => abreGestao()}
      title={isAguardandoAprovacao ? "Contrato precisa ser aprovado para visualizar os Documentos" : "Gestão de Documentos"}
    >
      <FontAwesomeIcon icon="cog" />
    </button>
  );
};
