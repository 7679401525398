/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from "react-table"
import "react-table/react-table.css"

/* Métodos / Ações */
import { fetchList, clearData } from '../../actions/filiais'
import { setModalOpen, setModalId } from '../../actions/modal'
import { clearFuncionarioId } from '../../actions/scroll'

/* Cores */
import { COLOR_LIGHT_GREEN, COLOR_LIGHT_BLUE } from '../../Colors'

/* Componentes */
import Error from '../Error'
import RightPanel from '../Sidepanel/RightPanel'
import FilialForm from './FilialForm'

/* Botões */
import ButtonPrimary from '../Buttons/ButtonPrimary'

class Filiais extends Component {
    constructor() {
        super()
        
        this._isMounted = false
        this.state = {
            rows: [],
            pages: null,
            loading: true,
            pageSize: null,
            page: null,
            sorted: null,
            filtered: null,
            statusFilter: null,
        }

        this.fetchData = this.fetchData.bind(this)
        this.renderDoubleClickable = this.renderDoubleClickable.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.requestData = this.requestData.bind(this)
        this.handleStatusFilter = this.handleStatusFilter.bind(this)
    }

    requestData() {
        let reqData = {
            pageSize: this.state.pageSize,
            page: this.state.page,
            sort: this.state.sorted,
            filter: this.state.filtered
        }
        return new Promise((resolve, reject) => {
            fetchList(reqData, resolve, reject, this.props.dispatch)
        }).then(res => {
            if (this._isMounted) {
                this.setState({
                    rows: res.rows,
                    pages: res.pages,
                    loading: false
                })
            }
        }).catch(err => {
            if (this._isMounted) {
                this.setState({
                    rows: [],
                    pages: 0,
                    loading: false
                })
            }
        })
    }

    fetchData(state, instance) {
        this.setState({ 
            loading: true,
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
            filtered: state.filtered,
        },()=>{
            this.requestData()
        })
        
    } 

    handleStatusFilter(st = null){
        let filtered = this.state.filtered
        if(filtered){
            let existsFilter = false
            for(let i = 0; i < filtered?.length; i ++){
                if(filtered[i].id === 'status'){
                    existsFilter = true
                    if(st !== null)
                        filtered[i].value = st
                    else
                        filtered.splice(i, 1)
                }
            }

            if(!existsFilter)
                filtered.push({
                    id: 'status',
                    value: st
                })
        } else if(st !== null) {
            filtered = [{
                id: 'status',
                value: st
            }]
        }
        this.setState({
            statusFilter: st,
            loading: true,
            filtered: filtered
        },()=>{
            this.requestData()
        })
    }

    componentDidMount() {
        this._isMounted = true

        this.props.dispatch(clearFuncionarioId())
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    handleOpen(id){
        this.props.dispatch(setModalOpen('edit'))
        this.props.dispatch(setModalId(id))
    }

    renderDoubleClickable(cellInfo) {
        return (
            <div onDoubleClick={()=>this.handleOpen(cellInfo.original.idFilial)}>{cellInfo.value}</div>
        )
    }

    render() {
        if (this.props.error && this.props.modal === '') {
            return <Error erro={this.props.error}></Error>
        }

        return(
            <div className="pb-3">
                <div className="row justify-content-center mx-0">

                    <div className="col-12 px-0">
                        <div className="card tertium-card">
                            <div className="card-header py-3 px-2 px-lg-5">
                                Matriz / Filiais
                                <ButtonPrimary title="Cadastrar nova filial" onClick={()=>{
                                    this.props.dispatch(clearData())
                                    this.props.dispatch(setModalOpen('new'))
                                }}
                                style={{
                                    position: 'absolute',
                                    right: '0',
                                    top: '0',
                                    height: '100%',
                                    borderRadius: '0',
                                    boxShadow: 'none',
                                    borderTopRightRadius: 'calc(.25rem - 1px)',
                                    backgroundColor: '#f15923',
                                    borderBottom: '1px solid #cd4c1e',
                                    marginBottom: '-1px'
                                }}><span className="d-none d-md-inline-block px-5 mx-3">Nova filial</span>
                                    <img src={"/images/plus.png"} alt="Cadastrar nova filial" title="Cadastrar nova filial" className="img-fluid ml-0 plus-img" style={{ 
                                        maxWidth: '25px',
                                        transform: 'translateY(-3px)',
                                        position: 'absolute',
                                        right: '20px'
                                    }}/>
                                </ButtonPrimary>
                            </div>
                            <div className="card-body px-2 px-lg-5">
                                <div className="row mx-0 mb-4 pt-4 row-table-filters">
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === null ? ' active' : '')} onClick={()=>{this.handleStatusFilter(null)}}>
                                        Todas
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === 1 ? ' active' : '')} onClick={()=>{this.handleStatusFilter(1)}}>
                                        Ativas
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === 0 ? ' active' : '')} onClick={()=>{this.handleStatusFilter(0)}}>
                                        Bloqueadas
                                    </button>
                                </div>
                                <ReactTable
                                    defaultSorted={[{
                                        id   : 'nome',
                                        desc : false,
                                    }]}
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }} onDoubleClick={()=>this.handleOpen(props.original.idFilial)}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "Nome",
                                            accessor: "nome",
                                            headerClassName: 'nome-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: this.renderDoubleClickable,
                                        },
                                        {
                                            Header: "CNPJ",
                                            accessor: "cnpj",
                                            headerClassName: 'cnpj-placeholder',
                                            maxWidth: 200,
                                            Cell: this.renderDoubleClickable
                                        },
                                        {
                                            Header: "Tipo",
                                            accessor: "matriz",
                                            maxWidth: 200,
                                            Cell: props => <span onDoubleClick={()=>this.handleOpen(props.original.idFilial)} className='table-badge' style={{backgroundColor: parseInt(props.value) === 1 ? COLOR_LIGHT_GREEN : COLOR_LIGHT_BLUE}}>{parseInt(props.value) === 1 ? 'MATRIZ' : 'FILIAL'}</span>,
                                            Filter: ({ filter, onChange }) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{ width: "100%" }}
                                                    value={filter ? filter.value : ""}
                                                >
                                                    <option value="">Todas</option>
                                                    <option value="1">Matriz</option>
                                                    <option value="0">Filiais</option>
                                                </select>
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => {
                                                return <button className="a" onClick={()=>this.handleOpen(props.original.idFilial)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                            }
                                        }
                                    ]}
                                    manual
                                    multiSort={false}
                                    data={this.state.rows}
                                    pages={this.state.pages}
                                    loading={this.state.loading}
                                    onFetchData={(state, instance)=>this.fetchData(state, instance)}
                                    filterable
                                    previousText={<FontAwesomeIcon icon="chevron-left" />}
                                    nextText={<FontAwesomeIcon icon="chevron-right" />}
                                    resizable={false}
                                    loadingText="Carregando..."
                                    noDataText="Nenhuma filial encontrada"
                                    pageText="Página"
                                    ofText="de"
                                    rowsText="linhas"
                                    pageJumpText="ir para a página"
                                    rowsSelectorText="linhas por página"
                                    pageSizeOptions={[5, 10, 20, 50]}
                                    defaultPageSize={10}
                                    minRows={0}
                                    className="-highlight"
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Formulário de Criação */}
                <RightPanel isPaneOpen={this.props.modal === 'new'} title="Cadastro de Filial" path="new" onClose={()=>{
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <FilialForm onAfterSave={this.requestData}></FilialForm>
                </RightPanel>

                
                <RightPanel isPaneOpen={(this.props.modal === 'edit' && this.props.id !== null)} title="Edição de Filial" path="edit" onClose={()=>{
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <FilialForm onAfterSave={this.requestData} id={this.props.id}></FilialForm>
                </RightPanel>
            </div>
        )
    }
}
Filiais.propTypes = {
    modal: PropTypes.string.isRequired,
    id: PropTypes.any,
}

const mapStateToProps = (state) => ({
    modal: state.modal.modalOpen,
    id: state.modal.id,
    error: state.errors.message,
})

export default connect(mapStateToProps)(withRouter(Filiais));
