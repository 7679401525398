import axios from "axios";
import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import { URL_API } from "./urls";
import setAuthToken from "../helpers/setAuthToken";
import jwt_decode from "jwt-decode";
import { refreshToken } from "../helpers/refresh";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { history } from "../helpers/navigation";

const MySwal = withReactContent(Swal);

export const registerUser = (user, history) => dispatch => {
  axios
    .post(URL_API + "/api/users/register", user)
    .then(res => history.push("/login"))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

export const postLogin = ({ jwt, fingerprint, clear }) => dispatch => {
  if (clear) {
      clearSession();
  }

  localStorage.setItem("jwtToken", jwt);
  localStorage.setItem("fingerprint", fingerprint);
  setAuthToken(jwt);
  const decoded = jwt_decode(jwt);
  refreshToken();
  dispatch(setCurrentUser(decoded));
}

const clearSession = () => {
  const fingerprint = localStorage.fingerprint || null;
  localStorage.clear();
  localStorage.setItem('fingerprint', fingerprint);
}

export const loginUser = (user, after) => dispatch => {
  clearSession();
  user.fingerprint = localStorage.fingerprint || null;

  axios
    .post(URL_API + "auth", user, { headers: { Accept: "application/json" } })
    .then(res => {
      const { data } = res.data;
      dispatch(postLogin({ jwt: data, fingerprint: localStorage.fingerprint }));
      if (after) {
        after();
      }
    })
    .catch(err => {
      const data = err?.response?.data;
      const erro = {
        message: typeof data === "string" && !!data ? data : "Não foi possível fazer o login"
      };

      if (data?.askMFA) {
        // Redirect user to MFA form
        history.push("/autorizar-dispositivo", { email: user.email });
        return;
      }

      dispatch({
        type: GET_ERRORS,
        payload: erro
      });
    });
};

export const resetPassword = (email, after) => dispatch => {
  axios
    .post(
      URL_API + "reset-password",
      { email },
      { headers: { Accept: "application/json" } }
    )
    .then(after)
    .catch(err => {
      let erro = { message: "Não foi possível enviar sua nova senha" };
      if (err.response) {
        erro = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: erro
      });
    });
};

export const sendAccessCode = async (email) => (
  axios
    .post(
      URL_API + 'recuperar-senha/enviar-codigo',
      { email },
    )
    .catch(error => {
      MySwal.fire({
        type: "error",
        title: error?.response?.data ?? 'Não foi possível enviar o código de acesso',
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    })
)

export const changePassword = async (data) => (
  axios
    .post(
      URL_API + 'recuperar-senha/alterar',
      data,
    )
    .catch(error => {
      MySwal.fire({
        type: "error",
        title: error?.response?.data ?? 'Não foi possível alterar sua senha',
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 3000,
      });
    })
)

/**
 * Authorize user device by MFA with email and code
 *
 * @param {Object} data
 * @property {string} email
 * @property {string} code
 */
export const confirmMFA = async ({ email, code }) => (
  axios
    .post(
      URL_API + 'auth/mfa',
      { email, code },
    )
)

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

export const logoutUser = _ => dispatch => {
  clearSession();
  setAuthToken(false);
  dispatch(setCurrentUser({}));
  window.location.href = "/login";
};
