import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tilt from "react-tilt";

import Loading from "../../components/Loading";
import { loginUser, resetPassword } from "../../actions/authentication";
import { clearErrors } from "../../actions/errors";

import "../Login.css";

import { FirstStep, SecondStep } from "./steps";

const STEPS = [FirstStep, SecondStep];

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const CurrentStep = useMemo(() => STEPS[currentStep] ?? null, [currentStep]);

  const nextStep = useCallback(
    () => setCurrentStep(step => Math.min(step + 1, STEPS.length - 1)),
    [],
  );

  const previousStep = useCallback(
    () => setCurrentStep(step => Math.max(step - 1, 0)),
    [],
  );

  return (
    <div className="limiter">
      <Loading active={loading}></Loading>
      <div className="container-login100">
        <div className="wrap-login100">
          <Tilt
            className="Tilt login100-pic js-tilt d-flex align-items-center"
            options={{ max: 25 }}
          >
            <img
              src="/images/logo.png"
              alt="Tertium Online"
              style={{ transform: "translateY(-50%)" }}
            />
          </Tilt>

          <CurrentStep
            email={email}
            setEmail={setEmail}
            nextStep={nextStep}
            previousStep={previousStep}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = {
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, clearErrors, resetPassword }
)(ForgotPassword);
