import React, { Component } from 'react'
import { URL_API } from '../../actions/urls'
import objToQueryString from '../../helpers/objToQueryString'

export default class PdfView extends Component {  
    render() {
        const token = localStorage.getItem('jwtToken');
       
        const reqData = {}

        if(this.props.filial){
            reqData.filial = this.props.filial
        }
        if(this.props.contrato){
            reqData.contrato = this.props.contrato
        }
        if(this.props.funcionario){
            reqData.funcionario = this.props.funcionario
        }
        if(this.props.documento){
            reqData.documento = this.props.documento
        }
        if(this.props.idDocumentoEnvio){
            reqData.idDocumentoEnvio = this.props.idDocumentoEnvio
        }
        reqData.token = token;
    
        return (<div className='embed-responsive' style={{paddingBottom: '150%'}}>
            <embed src={URL_API+'arquivo'+objToQueryString(reqData)} type="application/pdf"></embed>
        </div>)
    }
  }