import {
  FETCH_GESTAO_DOCUMENTOS_BEGIN as BEGIN,
  FETCH_GESTAO_DOCUMENTOS_SUCCESS as SUCCESS,
  FETCH_GESTAO_DOCUMENTOS_UPDATE as UPDATE,
  FETCH_GESTAO_DOCUMENTOS_FAILURE as FAILURE,
} from "../actions/types";
import defaultReducer from "./defaultReducer";

const model = "gestaoDocumentos";

export const initialState = [];
const cleanup = (models) =>
  models.map((m) => Object.assign(m, { nome: m.nome.toUpperCase() }));

export default function gestaoDocumentosReducer(state = initialState, action) {
  switch (action.type) {
    case SUCCESS:
      const selected = state[model][0];
      const hasDuplicated = action.payload[model].find(
        (item) => selected?.idFilial === item.idFilial
      );
      return {
        ...state,
        loading: false,
        [model]: cleanup(
          !hasDuplicated && selected
            ? [selected, ...action.payload[model]]
            : action.payload[model]
        ),
      };

    case UPDATE:
      return {
        ...state,
        loading: false,
        [model]: cleanup(
          state[model].length
            ? state[model].map((stateItem) => {
                const updatedItem = action.payload[model].find(
                  (newItem) => newItem.idFilial === stateItem.idFilial
                );
                return updatedItem || stateItem;
              })
            : action.payload[model]
        ),
      };

    default:
      return defaultReducer(
        model,
        BEGIN,
        SUCCESS,
        FAILURE,
        initialState,
        state,
        action
      );
  }
}
