import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  COLOR_DANGER,
  COLOR_GRAY2,
  COLOR_GREEN,
  COLOR_INFO,
  COLOR_WARNING,
} from "../../Colors";

const ListaContratos = ({
  contratos,
  contratoFilter,
  handleContratoFilterChange,
  handleOpenComments,
  handleEdit,
  handleBulkDownload,
}) => {
  if (contratos.length === 0) {
    return <div>Nenhum contrato encontrado</div>;
  }

  let today = moment().startOf("day");

  return contratos.map((val, idx) => {
    let a = moment(val.dataInicio.date);
    let b = moment(val.dataFim.date);

    let diffDuration = moment.duration(b.diff(today));

    let anosRestantes = diffDuration.years();
    let mesesRestantes = diffDuration.months();
    let diasRestantes = diffDuration.days();

    const color =
      val.porcentagem < 100
        ? val.invalidos
          ? COLOR_DANGER
          : COLOR_WARNING
        : COLOR_GREEN;
    const left =
      val.porcentagem < 5 ? 5 : val.porcentagem > 95 ? 95 : val.porcentagem;

    if (contratoFilter && val.idContrato !== contratoFilter.idContrato)
      return null;

    return (
      <div
        key={idx}
        className={
          "row cursor-pointer m-0 justify-content-center " +
          (val.invalidos === 0 && val.pendentes === 0 && val.aguardando === 0
            ? "success"
            : "warning")
        }
        onClick={() => handleContratoFilterChange(val)}
      >
        <div className="col-12 col-md-auto d-flex justify-content-center">
          {(val.invalidos > 0 || val.pendentes > 0 || val.aguardando > 0) && (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                color: val.invalidos > 0 ? COLOR_DANGER : COLOR_WARNING,
              }}
            >
              <FontAwesomeIcon icon="exclamation-triangle" />
              Irregular
              <br />
              {val.tipo === "terceiro" && (
                <span
                  style={{ color: "#35bae9", fontWeight: "bold" }}
                  className="small"
                >
                  <FontAwesomeIcon icon="id-card-alt" className="mr-2" />
                  Sou terceiro
                </span>
              )}
              {val.tipo === "tomador" && (
                <span
                  style={{ color: "#35bae9", fontWeight: "bold" }}
                  className="small"
                >
                  <FontAwesomeIcon icon="building" className="mr-2" />
                  Sou tomador
                </span>
              )}
            </div>
          )}
          {val.invalidos === 0 && val.pendentes === 0 && val.aguardando === 0 && (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ color: COLOR_GREEN }}
            >
              <FontAwesomeIcon icon="check" />
              Regular
              <br />
              {val.tipo === "terceiro" && (
                <span
                  style={{ color: "#35bae9", fontWeight: "bold" }}
                  className="small"
                >
                  <FontAwesomeIcon icon="id-card-alt" className="mr-2" />
                  Sou terceiro
                </span>
              )}
              {val.tipo === "tomador" && (
                <span
                  style={{ color: "#35bae9", fontWeight: "bold" }}
                  className="small"
                >
                  <FontAwesomeIcon icon="building" className="mr-2" />
                  Sou tomador
                </span>
              )}
            </div>
          )}
        </div>
        <div className="col-auto d-flex flex-column text-center text-md-left">
          <span style={{ color: COLOR_GRAY2, fontSize: "14px" }}>
            Nome: <b className="d-md-none">{val.nome}</b>
          </span>
          <span style={{ color: COLOR_GRAY2, fontSize: "14px" }}>
            Data inicial - final:{" "}
            <b className="d-md-none">
              {a.format("DD/MM/YYYY")} - {b.format("DD/MM/YYYY")}
            </b>
          </span>
          <span style={{ color: COLOR_GRAY2, fontSize: "14px" }}>
            Comentários:{" "}
            <b className="d-md-none">
              {val.comentarios}
              <button
                className="a ml-4"
                onClick={() => handleOpenComments(val)}
                title="Ver"
              >
                <FontAwesomeIcon icon="envelope" />
              </button>
            </b>
          </span>
          <div className="d-flex">
            <button
              type="button"
              onClick={() => handleEdit(val)}
              style={{
                textDecoration: "underline",
                fontSize: "14px",
              }}
              className="mt-2 mr-3 font-weight-600"
            >
              EDITAR CONTRATO
            </button>
            <button
              type="button"
              onClick={() => handleBulkDownload(val)}
              style={{
                textDecoration: "underline",
                fontSize: "14px",
              }}
              className="mt-2 font-weight-600"
            >
              BAIXAR DOCUMENTOS
            </button>
          </div>
        </div>
        <div className="d-none col-12 col-md-5 d-md-flex flex-column text-center text-md-left">
          <span style={{ fontSize: "14px" }}>{val.nome}</span>
          <span style={{ fontSize: "14px" }}>
            {moment(val.dataInicio.date).format("DD/MM/YYYY")}
            &nbsp;-&nbsp;
            {moment(val.dataFim.date).format("DD/MM/YYYY")}&nbsp;
            <span
              style={{
                color: "#666666",
                fontWeight: "400",
              }}
            >
              ({anosRestantes > 1 && anosRestantes + " anos, "}
              {anosRestantes === 1 && "1 ano, "}
              {mesesRestantes > 1 && mesesRestantes + " meses e "}
              {mesesRestantes === 1 && "1 mês e "}
              {diasRestantes > 1 && diasRestantes + " dias restantes"}
              {diasRestantes === 1 && "1 dia restante "}
              {diasRestantes < 1 &&
                mesesRestantes < 1 &&
                anosRestantes < 1 &&
                "VENCIDO"}
              )
            </span>
          </span>
          <span style={{ fontSize: "14px" }}>
            {val.comentarios}
            <button
              className="a ml-4"
              onClick={() => handleOpenComments(val)}
              title="Ver"
            >
              <FontAwesomeIcon icon="envelope" />
            </button>
          </span>
        </div>
        <div className="col-12 col-md">
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover
                placement="bottom"
                id="popover-basic"
                title={
                  parseInt(val.porcentagem) < 100
                    ? "Como melhorar?"
                    : "Tudo certo"
                }
              >
                {parseInt(val.porcentagem) < 100 && (
                  <div>
                    {val.pendentes > 0 && (
                      <div
                        style={{
                          color: COLOR_WARNING,
                        }}
                      >
                        <FontAwesomeIcon icon="cloud-upload-alt" />{" "}
                        <b>Documento(s) Pendente(s)</b>: Para solucionar este
                        problema devem ser enviadas versões, que irão para a
                        análise dos contratantes
                      </div>
                    )}
                    {val.aguardando > 0 && (
                      <div
                        style={{
                          color: COLOR_INFO,
                        }}
                      >
                        <FontAwesomeIcon icon="hourglass-half" />{" "}
                        <b>Documento(s) Aguardando Avaliação</b>: Para
                        solucionar este problema os documentos devem ser
                        avaliados por todos os contratantes
                      </div>
                    )}
                    {val.invalidos > 0 && (
                      <div
                        style={{
                          color: COLOR_DANGER,
                        }}
                      >
                        <FontAwesomeIcon icon="ban" />{" "}
                        <b>Documento(s) Inválido(s)</b>: Caso o documento tenha
                        sido recusado, é possível reenviá-lo ao contrato que o
                        fez, ou para todos os contratos, caso o documento tenha
                        expirado deve-se ser enviado a todos os contratos
                      </div>
                    )}
                  </div>
                )}
                {parseInt(val.porcentagem) >= 100 && (
                  <div style={{ color: COLOR_GREEN }}>
                    <FontAwesomeIcon icon="check" /> Nenhuma pendência neste
                    contrato
                  </div>
                )}
              </Popover>
            }
          >
            <div className="progress mt-4">
              <div
                className="progress-bar"
                style={{
                  background: color,
                  width: parseInt(val.porcentagem) + "%",
                }}
                role="progressbar"
                aria-valuenow={parseInt(val.porcentagem)}
                aria-valuemin="0"
                aria-valuemax="100"
                id="progressbar"
              >
                <span
                  className="progress-indicator"
                  style={{
                    backgroundColor: color,
                    borderColor: color,
                    left: left + "%",
                  }}
                >
                  {parseInt(val.porcentagem)}%
                </span>
              </div>
            </div>
          </OverlayTrigger>
          <div
            className="w-100 text-center font-weight-500 pt-2"
            style={{ color: color, fontSize: "15px" }}
          >
            {val.invalidos > 0 && (
              <span className="d-block w-100">
                HÁ <b>{val.invalidos}</b> IRREGULARIDADE(S) NO CONTRATO
              </span>
            )}
            {val.pendentes + val.aguardando > 0 && (
              <span className="d-block w-100">
                HÁ <b>{val.pendentes + val.aguardando}</b> PENDÊNCIA(S) NO
                CONTRATO
              </span>
            )}
          </div>
        </div>
      </div>
    );
  });
};

export default ListaContratos;
