import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR_GRAY, COLOR_ORANGE } from '../Colors';
import packageJson from '../../package.json';

console.info(
    '%cTertium Online - Versão %s',
    `color: ${COLOR_ORANGE}; font-weight: bold;`,
    packageJson.version,
);

const phone = process.env.REACT_APP_PHONE || '';
const phoneHref = phone.replace(/\D+/, '');

class Footer extends Component {
    render() {
        return (
            <footer className="py-4 px-5 mx-3">
                <div className="row mx-0 d-flex align-items-center justify-content-between">
                    <div className="col-12 col-lg-auto d-flex justify-content-center flex-column flex-lg-row">
                        <div
                            className="font-weight-600 mr-4"
                            style={{ color: COLOR_GRAY }}
                        >
                            PRECISA DE AJUDA?
                        </div>
                        <a
                            target="_blank"
                            className="font-weight-600 mr-4"
                            rel="noopener noreferrer"
                            href="https://tertium.online"
                            style={{ color: COLOR_ORANGE }}
                        >
                            <FontAwesomeIcon
                                icon="comment"
                                style={{ color: COLOR_GRAY }}
                            />
                            &nbsp;CONSULTE O FAQ
                        </a>
                        <a
                            target="_blank"
                            className="font-weight-600"
                            rel="noopener noreferrer"
                            href="https://tertium.online#contato"
                            style={{ color: COLOR_ORANGE }}
                        >
                            <FontAwesomeIcon
                                icon="headphones-alt"
                                style={{ color: COLOR_GRAY }}
                            />
                            &nbsp;SUPORTE
                        </a>
                    </div>
                    <div className="col-12 col-lg-auto d-flex justify-content-center flex-column flex-lg-row">
                        <a
                            target="_blank"
                            className="font-weight-600"
                            rel="noopener noreferrer"
                            href={'tel:+55' + phoneHref}
                            style={{ color: COLOR_GRAY }}
                        >
                            {phone}
                        </a>
                        <span className="font-weight-600 mx-1 d-none d-lg-flex">
                            |
                        </span>
                        <a
                            className="font-weight-400"
                            href="mailto:suporte@tertiumonline.com.br"
                            style={{ color: COLOR_GRAY }}
                        >
                            suporte@tertiumonline.com.br
                        </a>
                    </div>
                    <div className="col-12 col-lg-auto d-flex justify-content-center mt-3 mt-lg-0">
                        <a
                            target="_blank"
                            className="font-weight-600 mr-3"
                            rel="noopener noreferrer"
                            href="https://dosul.digital"
                        >
                            <img
                                src="/images/dosul.png"
                                className="img-fluid orange-on-hover"
                                alt="doSul Sistemas"
                                title="doSul Sistemas"
                            />
                        </a>
                        <a
                            target="_blank"
                            className="font-weight-600"
                            rel="noopener noreferrer"
                            href="https://www.datastream.com.br"
                        >
                            <img
                                src="/images/datastream.png"
                                className="img-fluid orange-on-hover"
                                alt="DATASTREAM"
                                title="DATASTREAM"
                            />
                        </a>
                    </div>
                </div>
            </footer>
        );
    }
}

Footer.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(withRouter(Footer));
