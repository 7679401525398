import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SITUACAO } from "../../../types/SituacaoDocumento";

export default ({ onClick, contrato, hidden = false }) => ({
  Header: "",
  filterable: false,
  sortable: false,
  className: "option-column-5",
  headerClassName: "option-column-5",
  maxWidth: 10,
  Cell: (row) =>
    !(typeof hidden === "function" ? hidden(row) : hidden) &&
    (SITUACAO.UPLOAD_PENDENTE === row.original.status ||
      contrato?.tipo !== "tomador") && (
      <button
        className="a"
        onClick={(ev) => onClick(ev, row)}
        title="Enviar Novo Documento"
      >
        <FontAwesomeIcon icon="upload" />
      </button>
    ),
});
