import {
    FETCH_USUARIO_BEGIN as BEGIN,
    FETCH_USUARIO_SUCCESS as SUCCESS,
    FETCH_USUARIO_FAILURE as FAILURE
} from '../actions/types'

import defaultReducer from './defaultReducer'

const model = 'usuario'

export const initialState = {
    idUsuario: '',
    senha: '',
    confirmaSenha: '',
    cpf: '',
    nome: '',
    sexo: '',
    email: '',
    foto: '',
    emailResumo: 'D',
    administradorEmpresa: 0,
    administradorFilial: 0,
    permissoesGerais: [],
    filial: '',
    departamentos: '',
    status: 1
}

export default function usuariosReducer(state, action){ return defaultReducer(model, BEGIN, SUCCESS, FAILURE, initialState, state, action) }