import {
    FETCH_COMENTARIO_BEGIN as BEGIN,
    FETCH_COMENTARIO_SUCCESS as SUCCESS,
    FETCH_COMENTARIO_FAILURE as FAILURE,
    FETCH_COMENTARIO_CLEAR as CLEAR
} from '../actions/types';

const model = 'comentarios'

export const initialState = []

export default function comentariosReducer(state, action) {
    if(!state){
        state = {
            [model]: initialState,
            loading: false,
            total: 0,
            error: null
        };
    }
    switch(action.type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case SUCCESS:
            return {
                ...state,
                loading: false,
                total: action.payload.total,
                [model]: [...state[model], ...action.payload[model]]
            };

        case CLEAR:
            return {
                ...state,
                loading: false,
                total: 0,
                [model]: initialState
            };

        case FAILURE:
            return {
                ...state,
                loading: false,
                total: 0,
                error: action.payload.error,
                [model]: initialState
            };

        default:
            return state;
    }
}