import {
    FETCH_EMPRESA_BEGIN as BEGIN,
    FETCH_EMPRESA_SUCCESS as SUCCESS,
    FETCH_EMPRESA_FAILURE as FAILURE
} from '../actions/types'
import defaultReducer from './defaultReducer'

const model = 'empresa'

const initialState = {
    nome: '',
    razaoSocial: '',
    telefone: '',
    email: '',
    logo: '',
    cnae: [],
}

export default function empresasReducer(state, action){ return defaultReducer(model, BEGIN, SUCCESS, FAILURE, initialState, state, action) }