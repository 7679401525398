import React from "react";
import TopNavbar from "../components/TopNavbar";
import MainLinks from "../components/MainLinks";
import MainBlock from "../components/MainBlock";
import Footer from "../components/Footer";

const Home = () => (
  <div>
    <TopNavbar />
    <MainLinks />
    <MainBlock />
    <Footer />
  </div>
);

export default Home;
