import { COLOR_WARNING, COLOR_GREEN, COLOR_INFO, COLOR_DANGER, COLOR_PRIMARY, COLOR_LIGHT_GREEN } from '../Colors'

export const montaGraficoDocumentos_Home = (documentos) => {
    let status = {
        valido: 0,
        pendente: 0,
        aguardando: 0,
        recusado: 0,
        expirando: 0,
        expirado: 0,
    }
    for(let i=0; i<documentos?.length; i++){
        switch(documentos[i].status){
            case -2: // Recusado
                status.recusado += 1
                break
            case -1: // Expirado
                status.expirado += 1
                break
            case 0: // A fazer upload
                status.pendente += 1
                break
            case 1: // Próximo da expiração
                status.expirando += 1
                break
            case 2: // Aguardando aprovação
                status.aguardando += 1
                break
            case 3: // Sem pendências
                status.valido += 1
                break
            default: 
                break
        }
    }
    let labels = []
    let colors = []
    let series = []
    if(status.valido > 0){
        labels.push('Válidos')
        colors.push(COLOR_GREEN)
        series.push(status.valido)
    }
    if(status.pendente > 0){
        labels.push('Pendentes')
        colors.push(COLOR_WARNING)
        series.push(status.pendente)
    }
    if(status.aguardando > 0){
        labels.push('Ag. Aprovação')
        colors.push(COLOR_INFO)
        series.push(status.aguardando)
    }
    if(status.recusado > 0){
        labels.push('Recusados')
        colors.push(COLOR_DANGER)
        series.push(status.recusado)
    }
    if(status.expirando > 0){
        labels.push('Expirando')
        colors.push(COLOR_LIGHT_GREEN)
        series.push(status.expirando)
    }
    if(status.expirado > 0){
        labels.push('Expirados')
        colors.push(COLOR_PRIMARY)
        series.push(status.expirado)
    }
    const graficoDocumento = {
        options: {
            legend:{
                position: 'bottom'
            },
            labels: labels,
            colors: colors,
            chart: {
                fontFamily: "'Open Sans', sans-serif"
            },
            stroke: {
                show: true,
                curve: 'smooth', // "smooth" / "straight" / "stepline"
                lineCap: 'butt', // round, butt , square
                width: 1,
                colors: colors, // array of colors
                dashArray: 0 // single value or array of values
            },
        },
        series: series,
    }
    return graficoDocumento
}

export const montaGraficoDocumentosFuncionarios_Home = (funcionarios) => {
    let status = {
        valido: 0,
        pendente: 0,
        aguardando: 0,
        recusado: 0,
        expirando: 0,
        expirado: 0,
    }
    for(let f=0; f<funcionarios?.length; f++){
        let documentos = funcionarios[f].documentos
        for(let i=0; i<documentos?.length; i++){
            switch(documentos[i].status){
                case -2: // Recusado
                    status.recusado += 1
                    break
                case -1: // Expirado
                    status.expirado += 1
                    break
                case 0: // A fazer upload
                    status.pendente += 1
                    break
                case 1: // Próximo da expiração
                    status.expirando += 1
                    break
                case 2: // Aguardando aprovação
                    status.aguardando += 1
                    break
                case 3: // Sem pendências
                    status.valido += 1
                    break
                default: 
                    break
            }
        }
    }
    let labels = []
    let colors = []
    let series = []
    if(status.valido > 0){
        labels.push('Válidos')
        colors.push(COLOR_GREEN)
        series.push(status.valido)
    }
    if(status.pendente > 0){
        labels.push('Pendentes')
        colors.push(COLOR_WARNING)
        series.push(status.pendente)
    }
    if(status.aguardando > 0){
        labels.push('Ag. Aprovação')
        colors.push(COLOR_INFO)
        series.push(status.aguardando)
    }
    if(status.recusado > 0){
        labels.push('Recusados')
        colors.push(COLOR_DANGER)
        series.push(status.recusado)
    }
    if(status.expirando > 0){
        labels.push('Expirando')
        colors.push(COLOR_LIGHT_GREEN)
        series.push(status.expirando)
    }
    if(status.expirado > 0){
        labels.push('Expirados')
        colors.push(COLOR_PRIMARY)
        series.push(status.expirado)
    }
    const graficoDocumento = {
        options: {
            legend:{
                position: 'bottom'
            },
            labels: labels,
            colors: colors,
            chart: {
                fontFamily: "'Open Sans', sans-serif"
            },
            stroke: {
                show: true,
                curve: 'smooth', // "smooth" / "straight" / "stepline"
                lineCap: 'butt', // round, butt , square
                width: 1,
                colors: colors, // array of colors
                dashArray: 0 // single value or array of values
            },
        },
        series: series,
    }
    return graficoDocumento
}