import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router-dom'
import "react-table/react-table.css"
import axios from "axios";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";

import { COLOR_DARK2 } from '../../Colors'
import { fetch } from '../../actions/empresas'
import { setModalOpen } from '../../actions/modal'
import { clearFuncionarioId } from '../../actions/scroll'
import LoadingImage from '../LoadingImage'
import Loading from '../Loading'
import Error from '../Error'
import ButtonPrimary from '../Buttons/ButtonPrimary'
import RightPanel from '../Sidepanel/RightPanel'
import EmpresaEdit from './EmpresaEdit'
import { URL_API } from "../../actions/urls";

const MySwal = withReactContent(swal);

class Empresa extends Component {
    constructor() {
        super();
        
        this._isMounted = false;
        this.state = {
            loadingExport: false,
        };

        this.handleExportarSituacaoDocumentos = this.handleExportarSituacaoDocumentos.bind(this);
    }

    componentDidMount() {
        this._isMounted = true

        this.props.dispatch(fetch())
        this.props.dispatch(clearFuncionarioId())
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    async handleExportarSituacaoDocumentos() {
        try {
            this.setState({ loadingExport: true });
            const response = await axios({
                url: URL_API + 'exportacao/situacao-documentos',
                method: 'GET',
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const date = new Date().toLocaleDateString("pt-BR", {
                timeZone: "UTC",
            }).replaceAll('/', '-');

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);

            link.download = `situacao-documentos-${date}.csv`;
            link.click();

            window.URL.revokeObjectURL(link.href);
        } catch(error) {
            const message = typeof error?.response?.data === "string"
              ? error.response.data
              : "Houve um erro ao exportar o arquivo de situação dos documentos"

            MySwal.fire({
              toast: true,
              showConfirmButton: false,
              timer: 3000,
              type: "error",
              title: message,
            });
        } finally {
            this.setState({ loadingExport: false });
        }
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>
        }

        if (this.props.loading) {
            return <LoadingImage></LoadingImage>
        }

        const cnaes = this.props.empresa.cnae !== undefined ? this.props.empresa.cnae?.map((cnae)=>{
            return (
                <p key={cnae.codigo} className="font-openSans mb-0 small text-justify">&bull;<b className="pr-2">{ cnae.codigo }</b>{ cnae.descricao }</p>
            )
        }) : null

        return(
            <Loading active={this.state.loadingExport}>
                <div className="py-5">
                    <h4 className="text-center font-weight-400" style={{color: COLOR_DARK2}}>Sua empresa</h4>
                    <div className="row px-0 px-lg-5 mx-0 justify-content-center">

                        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    Informações<span className="small">Dados da sua empresa</span>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-md-4 pr-0">
                                            <img src={ this.props.empresa.logo } alt={ this.props.empresa.nome } title={ this.props.empresa.nome } className="img-fluid rounded-circle d-block mx-auto" style={{ maxWidth: '100px'}}/>
                                        </div>
                                        <div className="col-12 col-md">
                                            <h5 className="font-openSans text-uppercase">{ this.props.empresa.nome }</h5>
                                            <p className="font-openSans mb-0 text-uppercase">{ this.props.empresa.razaoSocial }</p>
                                            {this.props.empresa.telefone &&
                                                <p className="font-openSans mb-0"><a target="_blank" rel="noopener noreferrer" href={ "tel:+55" + this.props.empresa.telefone.replace(/[^\d]/g, '') }>{ this.props.empresa.telefone }</a></p>
                                            }
                                            {this.props.empresa.email &&
                                                <p className="font-openSans mb-0"><a target="_blank" rel="noopener noreferrer" href={ "mailto:" + this.props.empresa.email }>{ this.props.empresa.email }</a></p>
                                            }
                                        </div>
                                        <div className="col-12">
                                            <div className="row mx-0">
                                                <div className="col-12 pt-2 px-2 mt-2" style={{borderTop: '1px solid #cccccc'}}>
                                                    { cnaes }
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className="col-12 line-in-sides pt-4">
                                            <ButtonPrimary
                                                title="Editar"
                                                onClick={()=> {
                                                    this.props.dispatch(setModalOpen('edit'))
                                                }}
                                            >
                                                Editar
                                            </ButtonPrimary>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                            <div className="card">
                                <div className="card-header">
                                    Ações<span className="small">Links rápidos</span>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12 col-sm-6">
                                            <Link to="/filiais/" title="Filiais" className="link-box-dark">
                                                <img src={"/images/icon_filial.png"} className="img-fluid pb-1" alt="Filiais" title="Filiais" style={{maxHeight: '50px'}}/>
                                                FILIAIS
                                            </Link>
                                        </div>
                                        <div className="col-12 col-sm-6 pt-3 pt-sm-0">
                                            <Link to="/usuarios/" title="Usuários" className="link-box-dark">
                                                <img src={"/images/icon_usuarios.png"} className="img-fluid pb-1" alt="Usuários" title="Usuários" style={{maxHeight: '50px'}}/>
                                                USUÁRIOS
                                            </Link>
                                        </div>
                                        <div className="col-12 col-sm-6 pt-3">
                                            <Link to="/departamentos/" title="Departamentos" className="link-box-dark">
                                                <img src={"/images/icon_departamentos.png"} className="img-fluid pb-1" alt="Departamentos" title="Departamentos" style={{maxHeight: '50px'}}/>
                                                DEPARTAMENTOS
                                            </Link>
                                        </div>
                                        <div className="col-12 col-sm-6 pt-3">
                                            <button onClick={this.handleExportarSituacaoDocumentos} title="Exportar Situação Documentos" className="link-box-dark w-100 h-100">
                                                <img src={"/images/icon_documentos.png"} className="img-fluid pb-1" alt="Exportar Situação Documentos" title="Exportar Situação Documentos" style={{maxHeight: '50px'}}/>
                                                EXPORTAÇÃO
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <RightPanel isPaneOpen={this.props.modal === 'edit'} title="Edição de Empresa" path="edit" onClose={()=>{
                        this.props.dispatch(setModalOpen(''))
                    }}>
                        <EmpresaEdit></EmpresaEdit>
                    </RightPanel>
                </div>
            </Loading>
        )
    }
}
Empresa.propTypes = {
    empresa: PropTypes.object.isRequired,
    modal: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    empresa: state.empresas.empresa,
    loading: state.empresas.loading,
    error: state.empresas.error,
    modal: state.modal.modalOpen
})

export default connect(mapStateToProps)(withRouter(Empresa));
