import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Tilt from 'react-tilt'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from '../components/Loading'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Redirect } from 'react-router-dom'
import './Login.css'

import { enterKey } from '../actions/cadastroEmpresa'
import { newUsuario } from '../actions/usuarios'
import { clearErrors } from '../actions/errors'

const MySwal = withReactContent(Swal)

const CHAVE_INCORRETA = 'chave incorreta ou não encontrada!'

class QueroMeCadastrar extends Component {

    constructor() {
        super();
        this.state = {
            loading: true,
            chave: '',
            nome: '',
            razaoSocial: '',
            cnpj: '',
            nomeUser: '',
            email: '',
            password: '',
            confirmPassword: '',
            formEmpresa: false,
            formUser: false
        }
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount() {
        this.props.clearErrors()
        this.setState({
            loading: false
        })

        if(this.props.match.params.hash && this.props.match.params.hash !== this.state.chave){
            this.setState({
                chave: this.props.match.params.hash,
                loading: true
            })
            this.props.enterKey(this.props.match.params.hash);
            this.setState({
                formEmpresa: true,
                loading: false
            })
        }
    }

    componentDidUpdate() {
        if (this.props.errors.message && this.props.errors.message?.toLowerCase() !== CHAVE_INCORRETA) {
            MySwal.fire({
                title: 'Oops!',
                type: 'error',
                text: this.props.errors.message,
                showCancelButton: false,
                confirmButtonText: 'OK',
            })
            this.props.clearErrors()
        }
    }

    verifyUserKey = (e) => {
        e.preventDefault()
        this.setState({
            loading: true
        })
        let chave = this.state.chave
        this.props.enterKey(chave);
        this.setState({
            formEmpresa: true,
            loading: false
        })

    }

    submitEnterprise = (e) => {
        e.preventDefault()
        this.setState({
            formEmpresa: false,
            formUser: true
        })
    }

    submitUser = (e) => {
        e.preventDefault()
        let user = {
            filial: this.props.queroMeCadastrar.filial.filial,
            nome: this.state.nomeUser,
            email: this.state.email,
            password: this.state.password,
            confirmPassword: this.state.confirmPassword
        }
        if (user.password !== user.confirmPassword) {
            MySwal.fire({
                title: 'Oops!',
                type: 'error',
                text: 'Os campos de senha devem ser iguais!',
                showCancelButton: false,
                confirmButtonText: 'OK',
            })
            return
        }
        this.props.newUsuario(user);
    }

    render() {
        const { errors } = this.props;

        if (errors.message?.toLowerCase() === CHAVE_INCORRETA) {
            return <Redirect to="/login" />
        }

        return (<div className="limiter">
            <Loading active={this.state.loading}></Loading>
            <div className="container-login100">
                <div className="wrap-login100">
                    <Tilt className="Tilt login100-pic js-tilt d-flex align-items-center" options={{ max: 25 }}>
                        <img src="/images/logo.png" alt="Tertium Online" style={
                            { transform: 'translateY(-50%)' }
                        } />
                    </Tilt>

                    {!this.props.queroMeCadastrar.filial.chaveCadastro &&
                        <div className="form-chave d-flex justify-content-center">
                            <form onSubmit={this.verifyUserKey} className="login100-form">
                                <span className="login100-form-title">
                                    Bem vindo!
                                </span>

                                <div className="wrap-input100">
                                    <input className={classnames('input100', {
                                        'is-invalid': errors.chave
                                    })} type="text" name="chave" placeholder="Digite sua chave" onChange={this.handleInputChange} value={this.state.chave} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <FontAwesomeIcon icon="key" />
                                    </span>
                                </div>
                                {errors.chave && (<div className="invalid-feedback">{errors.chave}</div>)}

                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn">
                                        Enviar
                                    </button>
                                </div>

                                <div className="text-center pt-3">
                                    <button type="button" className="txt2">
                                        <FontAwesomeIcon icon="sad-tear" />&nbsp;
                                        Ainda não recebi minha chave!
                                    </button>
                                </div>

                                <div className="text-center pt-4">
                                    <a href="https://tertium.online#contato" rel="noopener noreferrer" target="_blank" className="txt2">
                                        <FontAwesomeIcon icon="life-ring" />&nbsp;
                                        Preciso de Ajuda
                                    </a>
                                </div>
                            </form>
                        </div>
                    }

                    {this.props.queroMeCadastrar.filial.chaveCadastro && this.state.formEmpresa &&
                        <div className="form-empresa d-flex justify-content-center">
                            <form onSubmit={this.submitEnterprise} className="login100-form">
                                <span className="login100-form-title">
                                    Confirme os dados de sua empresa
                                </span>

                                <div className="wrap-input100">
                                    <input className={classnames('input100', {
                                        'is-invalid': errors.nome
                                    })} type="text" name="nome" autoComplete="nome" placeholder="Nome da empresa" onChange={(this.handleInputChange)} value={this.props.queroMeCadastrar.filial.nome} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <FontAwesomeIcon icon="industry" />
                                    </span>
                                </div>
                                {errors.nome && (<div className="invalid-feedback">{errors.nome}</div>)}

                                <div className="wrap-input100">
                                    <input className={classnames('input100', {
                                        'is-invalid': errors.razaoSocial
                                    })} type="text" name="razaoSocial" autoComplete="razaoSocial" placeholder="Razão social" onChange={this.handleInputChange} value={this.props.queroMeCadastrar.filial.razaoSocial} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <FontAwesomeIcon icon="signature" />
                                    </span>
                                </div>
                                {errors.razaoSocial && (<div className="invalid-feedback">{errors.razaoSocial}</div>)}

                                <div className="wrap-input100">
                                    <input className={classnames('input100', {
                                        'is-invalid': errors.cnpj
                                    })} type="text" name="cnpj" autoComplete="cnpj" placeholder="CNPJ" onChange={this.handleInputChange} value={this.props.queroMeCadastrar.filial.cnpj} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <FontAwesomeIcon icon="id-card" />
                                    </span>
                                </div>
                                {errors.cnpj && (<div className="invalid-feedback">{errors.cnpj}</div>)}

                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn">
                                        Enviar
                                    </button>
                                </div>
                            </form>
                        </div>
                    }

                    {!this.state.formEmpresa && this.state.formUser &&
                        <div className="form-user d-flex justify-content-center">
                            <form onSubmit={this.submitUser} className="login100-form">
                                <span className="login100-form-title py-3">
                                    Dados do usuário
                                </span>

                                <div className="wrap-input100">
                                    <input className={classnames('input100', {
                                        'is-invalid': errors.nome
                                    })} type="text" name="nomeUser" placeholder="Nome" onChange={this.handleInputChange} value={this.state.nomeUser} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <FontAwesomeIcon icon="user" />
                                    </span>
                                </div>
                                {errors.nomeUser && (<div className="invalid-feedback">{errors.nomeUser}</div>)}

                                <div className="wrap-input100">
                                    <input className={classnames('input100', {
                                        'is-invalid': errors.email
                                    })} type="text" name="email" autoComplete="username" placeholder="E-mail" onChange={this.handleInputChange} value={this.state.email} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <FontAwesomeIcon icon="envelope" />
                                    </span>
                                </div>
                                {errors.email && (<div className="invalid-feedback">{errors.email}</div>)}

                                <div className="wrap-input100">
                                    <input className={classnames('input100', {
                                        'is-invalid': errors.password
                                    })} type="password" name="password" placeholder="Senha" onChange={this.handleInputChange} value={this.state.password} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <FontAwesomeIcon icon="lock" />
                                    </span>
                                </div>
                                {errors.password && (<div className="invalid-feedback">{errors.password}</div>)}

                                <div className="wrap-input100">
                                    <input className={classnames('input100', {
                                        'is-invalid': errors.confirmPassword
                                    })} type="password" name="confirmPassword" placeholder="Confirmar senha" onChange={this.handleInputChange} value={this.state.confirmPassword} />
                                    <span className="focus-input100"></span>
                                    <span className="symbol-input100">
                                        <FontAwesomeIcon icon="lock" />
                                    </span>
                                </div>
                                {errors.confirmPassword && (<div className="invalid-feedback">{errors.confirmPassword}</div>)}

                                <div className="container-login100-form-btn">
                                    <button className="login100-form-btn">
                                        Enviar
                                    </button>
                                </div>
                            </form>
                        </div>
                    }

                </div>
            </div>
        </div>);
    }
}

QueroMeCadastrar.propTypes = {
    enterKey: PropTypes.func.isRequired,
    newUsuario: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    queroMeCadastrar: state.cadastroEmpresa,
    errors: state.errors
})

export default connect(mapStateToProps, { enterKey, newUsuario, clearErrors })(QueroMeCadastrar)