import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setModalOpen, setModalId } from "../../../actions/modal";
import { STATUS } from "../../../types/StatusContrato";

export const EditarContratoButton = ({ contrato, dispatch }) => {
    if (contrato.tipo === 'terceiro' && contrato.status === STATUS.AGUARDANDO_APROVACAO) return null;

    const openModal = (modal) => {
        dispatch(setModalOpen(modal));
        dispatch(setModalId(contrato.idContrato));
    };

    return contrato.tipo === "terceiro" ? (
        <button
            className="a"
            onClick={() => openModal("funcionarios")}
            title="Selecionar funcionários"
        >
            <FontAwesomeIcon icon="user-friends" />
        </button>
    ) : (
        <button
            className="a"
            onClick={() => openModal("documentos")}
            title="Selecionar documentos"
        >
            <FontAwesomeIcon icon="clipboard-list" />
        </button>
    );
};
