/* Geral / Bibliotecas */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Toggle from "react-toggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toggle/style.css";
import AsyncSelect from "react-select/lib/Async";

/* Cores */
import { COLOR_PRIMARY } from "../../Colors";

/* Métodos / Ações */
import {
  fetch,
  saveUsuario,
  deleteData,
  changeStatus,
} from "../../actions/usuarios";
import { loadFiliaisOptions } from "../../actions/filiais";
import {
  saveDepartamento,
  fetchDepartamentos,
} from "../../actions/departamentos";
import { clearErrors } from "../../actions/errors";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";
import CheckPermissao from "./CheckPermissao";

/* Botões */
import ButtonSuccess from "../Buttons/ButtonSuccess";
import ButtonGray from "../Buttons/ButtonGray";
import ButtonWarning from "../Buttons/ButtonWarning";

/* Campos */
import Cpf from "../CustomFields/Cpf";

/* Validação */
import {
  requiredField,
  emailField,
  cpfField,
  objectField,
} from "../../validation/formValidation";

const MySwal = withReactContent(Swal);

class UsuarioForm extends Component {
  constructor() {
    super();

    this._isMounted = false;
    this.state = {
      forcarAtivo: {
        filial: false,
        funcionarios: false,
      },
    };
  }

  handleSubmit(values, actions) {
    this.props.dispatch(saveUsuario(values, actions, this.props.onAfterSave));
  }

  componentDidMount() {
    this.props.dispatch(fetchDepartamentos());
    this._isMounted = true;
    if (this.props.id) this.props.dispatch(fetch(this.props.id));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setForcarAtivo({ filial, funcionarios }) {
    this.setState((state) => {
      filial = typeof filial == "undefined" ? state.forcarAtivo.filial : filial;
      funcionarios =
        typeof funcionarios == "undefined"
          ? state.forcarAtivo.funcionarios
          : funcionarios;

      return { ...state, forcarAtivo: { filial, funcionarios } };
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      let show = false;
      if (Object.keys(nextProps.errors)?.length) {
        show = true;
      }
      if (show) {
        MySwal.fire({
          type: "error",
          title: nextProps.errors.message,
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        this.props.dispatch(clearErrors());
      }
    }

    if (nextProps.usuario) {
      this.setForcarAtivo({
        filial: !!nextProps.usuario.administradorEmpresa,
        funcionarios: !!nextProps.usuario.administradorFilial,
        gerais: !!nextProps.usuario.responsavelFuncionarios,
      });
    }
  }

  handleDelete() {
    MySwal.fire({
      title: "",
      type: "warning",
      html:
        "Deseja realmente excluir o usuário <b>" +
        this.props.usuario.nome.toUpperCase() +
        "</b>, essa ação não pode ser revertida?",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({
          loading: true,
        });
        this.props.dispatch(
          deleteData(
            this.props.usuario.idUsuario,
            this.props.onAfterSave,
            () => {
              this.setState({ loading: false });
            }
          )
        );
      }
    });
  }

  handleChangeStatus() {
    var st = this.props.usuario.status === 1 ? 0 : 1;
    this.setState({
      loading: true,
    });
    this.props.dispatch(
      changeStatus(
        this.props.usuario.idUsuario,
        st,
        this.props.onAfterSave,
        () => {
          this.setState({ loading: false });
        }
      )
    );
  }

  handleDepartamentoCreation() {
    MySwal.fire({
      input: "text",
      confirmButtonText: "Salvar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      title: "Nome",
      text: "Digite o nome do Departamento",
      preConfirm: (value) => {
        return new Promise((resolve, reject) => {
          if (value && value?.length > 1) {
            resolve();
          } else {
            reject();
          }
        }).catch(() => {
          Swal.showValidationMessage(`Digite um Departamento válido`);
        });
      },
    }).then((result) => {
      if (result.value) {
        let departamento = {
          nome: result.value,
        };
        this.props.dispatch(saveDepartamento(departamento));
      }
    });
  }

  render() {
    if (this.props.error) {
      return <Error erro={this.props.error}></Error>;
    }

    if (this.props.loading) {
      return <LoadingImage></LoadingImage>;
    }

    const loadDepartamentosOptions = (inputValue) => {
      return new Promise((resolve) => {
        let dados = this.props.departamentos.dados;
        if (inputValue !== "") {
          dados = dados.filter((i) =>
            i.nome.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
        dados = dados.slice(0, 30);
        resolve(dados);
      });
    };
    const forcarGeral = !!(
      this.state.forcarAtivo.filial || this.state.forcarAtivo.funcionarios
    );

    return (
      <div className="pb-5">
        <div className="row mx-0 d-flex align-items-center justify-content-center">
          <div className="col-12 px-4">
            <div className="card">
              <div className="card-body px-2">
                <Formik
                  initialValues={this.props.usuario}
                  onSubmit={this.handleSubmit.bind(this)}
                  render={({
                    isSubmitting,
                    setFieldValue,
                    handleSubmit,
                    validateField,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="cpf">CPF *</label>
                            <Field name="cpf" validate={cpfField}>
                              {({ field }) => {
                                return (
                                  <Cpf
                                    mounted={this._isMounted}
                                    callback={(data) => {
                                      data.nome === "undefined" &&
                                        setFieldValue("nome", data.nome);
                                    }}
                                    {...field}
                                    id="cpf"
                                  ></Cpf>
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="cpf"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="nome">Nome *</label>
                            <Field
                              type="text"
                              id="nome"
                              name="nome"
                              className="form-control"
                              validate={requiredField}
                            />
                            <ErrorMessage
                              name="nome"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="email">E-mail *</label>
                            <Field
                              type="email"
                              autoComplete="email"
                              id="email"
                              name="email"
                              className="form-control"
                              validate={emailField}
                            />
                            <ErrorMessage
                              name="email"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="senha">
                              Senha
                              {this.props.usuario.idUsuario !== "" ? (
                                <span className="small">
                                  {" "}
                                  Digite para alterar
                                </span>
                              ) : (
                                " *"
                              )}
                            </label>
                            <Field
                              autoComplete="new-password"
                              type="password"
                              id="senha"
                              name="senha"
                              className="form-control"
                              validate={
                                this.props.usuario.idUsuario === ""
                                  ? requiredField
                                  : ""
                              }
                            />
                            <ErrorMessage
                              name="senha"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="confirmaSenha">
                              Confirme a senha
                              {this.props.usuario.idUsuario !== "" ? "" : " *"}
                            </label>
                            <Field
                              autoComplete="new-password"
                              type="password"
                              id="confirmaSenha"
                              name="confirmaSenha"
                              className="form-control"
                              validate={
                                this.props.usuario.idUsuario === ""
                                  ? requiredField
                                  : ""
                              }
                            />
                            <ErrorMessage
                              name="confirmaSenha"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      {this.props.permissoes.administradorEmpresa === 1 && (
                        <div className="row">
                          <div className="col-12">
                            <div className="form-group">
                              <label htmlFor="filial" className="w-100">
                                Filial *
                              </label>
                              <Field name="filial" validate={objectField}>
                                {({ field }) => {
                                  return (
                                    <AsyncSelect
                                      {...field}
                                      placeholder="Digite para localizar..."
                                      id="filial"
                                      isSearchable
                                      defaultValue={this.props.usuario.filial}
                                      getOptionValue={(option) =>
                                        option["idFilial"]
                                      }
                                      getOptionLabel={(option) =>
                                        option["nome"]
                                      }
                                      loadOptions={loadFiliaisOptions}
                                      defaultOptions={true}
                                      onChange={(value) => {
                                        setFieldValue("filial", value);
                                      }}
                                      noOptionsMessage={(input) => {
                                        return input.inputValue === ""
                                          ? "Digite para localizar..."
                                          : "Nenhum resultado encontrado";
                                      }}
                                      loadingMessage={() => "Carregando..."}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary50: "#ebebeb",
                                          primary25: "#ebebeb",
                                          primary: "#ff5818",
                                        },
                                      })}
                                    />
                                  );
                                }}
                              </Field>
                              <ErrorMessage
                                name="filial"
                                component="small"
                                className="form-text text-danger"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="departamentos" className="w-100">
                              Departamento(s) *
                              <button
                                type="button"
                                onClick={this.handleDepartamentoCreation.bind(
                                  this
                                )}
                                className="small position-absolute"
                                style={{
                                  color: COLOR_PRIMARY,
                                  right: "15px",
                                  top: 0,
                                }}
                              >
                                Adicionar um Departamento
                              </button>
                            </label>
                            <Field name="departamentos" validate={objectField}>
                              {({ field }) => {
                                return (
                                  <AsyncSelect
                                    {...field}
                                    isMulti
                                    placeholder="Digite para localizar..."
                                    id="departamentos"
                                    isSearchable
                                    defaultValue={
                                      this.props.usuario.departamentos
                                    }
                                    getOptionValue={(option) =>
                                      option["idDepartamento"]
                                    }
                                    getOptionLabel={(option) => option["nome"]}
                                    loadOptions={loadDepartamentosOptions}
                                    onChange={(value) => {
                                      setFieldValue("departamentos", value);
                                    }}
                                    noOptionsMessage={(input) => {
                                      return input.inputValue === ""
                                        ? "Digite para localizar..."
                                        : "Nenhum resultado encontrado, por favor adicione o departamento";
                                    }}
                                    loadingMessage={() => "Carregando..."}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary50: "#ebebeb",
                                        primary25: "#ebebeb",
                                        primary: "#ff5818",
                                      },
                                    })}
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="departamentos"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        {this.props.permissoes.administradorEmpresa === 1 && (
                          <div className="col-6">
                            <div className="form-group d-flex align-items-center">
                              <Field name="administradorEmpresa">
                                {({ field }) => {
                                  return (
                                    <Toggle
                                      name="administradorEmpresa"
                                      id="administradorEmpresa"
                                      checked={field.value === 1}
                                      onChange={(evt) => {
                                        setFieldValue(
                                          "administradorEmpresa",
                                          evt.target.checked ? 1 : 0
                                        );
                                        this.setForcarAtivo({
                                          filial: evt.target.checked,
                                        });
                                      }}
                                    />
                                  );
                                }}
                              </Field>
                              <label
                                htmlFor="administradorEmpresa"
                                className="pl-1 mb-0"
                              >
                                Administrador da Empresa
                              </label>
                              <ErrorMessage
                                name="administradorEmpresa"
                                component="small"
                                className="form-text text-danger"
                              />
                            </div>
                          </div>
                        )}
                        <div className="col-6">
                          <div className="form-group d-flex align-items-center">
                            <Field name="administradorFilial">
                              {({ field }) => {
                                return (
                                  <Toggle
                                    name="administradorFilial"
                                    id="administradorFilial"
                                    checked={field.value === 1}
                                    {...(this.state.forcarAtivo.filial && {
                                      readOnly: true,
                                      checked: true,
                                    })}
                                    onChange={(evt) => {
                                      setFieldValue(
                                        "administradorFilial",
                                        evt.target.checked ? 1 : 0
                                      );
                                      this.setForcarAtivo({
                                        funcionarios: evt.target.checked,
                                      });
                                    }}
                                  />
                                );
                              }}
                            </Field>
                            <label
                              htmlFor="administradorFilial"
                              className="pl-1 mb-0"
                              title={
                                this.state.forcarAtivo.filial
                                  ? "Se usuário for administrador, então terá permissão sobre as filiais"
                                  : undefined
                              }
                            >
                              Administrador da Filial
                            </label>
                            <ErrorMessage
                              name="administradorFilial"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group d-flex align-items-center">
                            <Field name="responsavelFuncionarios">
                              {({ field }) => {
                                return (
                                  <Toggle
                                    name="responsavelFuncionarios"
                                    id="responsavelFuncionarios"
                                    checked={field.value === 1}
                                    {...((this.state.forcarAtivo.funcionarios ||
                                      this.state.forcarAtivo.filial) && {
                                      readOnly: true,
                                      checked: true,
                                    })}
                                    onChange={(evt) => {
                                      if (
                                        this.state.forcarAtivo.funcionarios ||
                                        this.state.forcarAtivo.filial
                                      )
                                        return;

                                      setFieldValue(
                                        "responsavelFuncionarios",
                                        evt.target.checked ? 1 : 0
                                      );
                                    }}
                                  />
                                );
                              }}
                            </Field>
                            <label
                              htmlFor="responsavelFuncionarios"
                              className="pl-1 mb-0"
                              title={
                                this.state.forcarAtivo.funcionarios ||
                                this.state.forcarAtivo.filial
                                  ? "Se usuário for administrador, então terá permissão acesso a Funcionários"
                                  : undefined
                              }
                            >
                              Responsável Funcionários
                            </label>
                            <ErrorMessage
                              name="responsavelFuncionarios"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      {(this.props.permissoes.administradorEmpresa === 1 ||
                        this.props.permissoes.administradorFilial === 1) && (
                        <div className="row">
                          <div className="col-12">
                            <h6>Permissões gerais:</h6>
                          </div>

                          <CheckPermissao
                            name="permissoesGerais"
                            option="contratos"
                            label="Acesso aos Contratos"
                            forcarAtivo={forcarGeral}
                          />
                          <CheckPermissao
                            name="permissoesGerais"
                            option="funcionarios_contrato"
                            label="Funcionários dos Contratos"
                            forcarAtivo={forcarGeral}
                          />
                          <CheckPermissao
                            name="permissoesGerais"
                            option="contratos_modelo"
                            label="Modelos de Contrato (Documentos)"
                            forcarAtivo={forcarGeral}
                          />
                          <CheckPermissao
                            name="permissoesGerais"
                            option="documentos"
                            label="Documentos"
                            forcarAtivo={forcarGeral}
                          />
                          <CheckPermissao
                            name="permissoesGerais"
                            option="arquivo"
                            label="Visualização de Arquivos"
                            forcarAtivo={forcarGeral}
                          />
                          <CheckPermissao
                            name="permissoesGerais"
                            option="avaliacao"
                            label="Avaliação de Documentos"
                            forcarAtivo={forcarGeral}
                          />
                          <CheckPermissao
                            name="permissoesGerais"
                            option="documento_upload"
                            label="Envio de Documentos"
                            forcarAtivo={forcarGeral}
                          />
                          <CheckPermissao
                            name="permissoesGerais"
                            option="gestao-documentos"
                            label="Tela de Gestão de Documentos"
                            forcarAtivo={forcarGeral}
                          />
                          <CheckPermissao
                            name="permissoesGerais"
                            option="notificacoes"
                            label="Notificações"
                            forcarAtivo={forcarGeral}
                          />
                        </div>
                      )}
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                          {this.props.usuario.status === 1 && (
                            <ButtonSuccess
                              type="submit"
                              disabled={isSubmitting}
                            >
                              <FontAwesomeIcon icon="save" /> Salvar
                            </ButtonSuccess>
                          )}
                          {this.props.usuario.idUsuario !== "" &&
                            this.props.usuario.status === 0 && (
                              <ButtonSuccess
                                type="button"
                                onClick={this.handleChangeStatus.bind(this)}
                                disabled={isSubmitting}
                              >
                                <FontAwesomeIcon icon="check" /> Ativar
                              </ButtonSuccess>
                            )}
                          {this.props.usuario.idUsuario !== "" &&
                            this.props.usuario.status === 1 && (
                              <ButtonWarning
                                type="button"
                                onClick={this.handleChangeStatus.bind(this)}
                                className="ml-3"
                                disabled={isSubmitting}
                              >
                                <FontAwesomeIcon icon="times" /> Bloquear
                              </ButtonWarning>
                            )}
                          {this.props.usuario.idUsuario !== "" && (
                            <ButtonGray
                              type="button"
                              onClick={this.handleDelete.bind(this)}
                              className="ml-3"
                              disabled={isSubmitting}
                            >
                              <FontAwesomeIcon icon="trash" /> Excluir
                            </ButtonGray>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
UsuarioForm.propTypes = {
  usuario: PropTypes.object.isRequired,
  departamentos: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  id: PropTypes.any,
  onAfterSave: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  permissoes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  usuario: state.usuarios.usuario,
  loading: state.usuarios.loading,
  departamentos: state.departamentos,
  error: state.usuarios.error,
  errors: state.errors,
  id: state.modal.id,
  permissoes: state.permissions.permissoes,
});

export default connect(mapStateToProps)(withRouter(UsuarioForm));
