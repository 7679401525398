import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { URL_API } from "./urls";
import {
  FETCH_GESTAO_DOCUMENTOS_BEGIN as FETCH_BEGIN_LIST,
  FETCH_GESTAO_DOCUMENTOS_SUCCESS as FETCH_SUCCESS_LIST,
  FETCH_GESTAO_DOCUMENTOS_UPDATE as FETCH_UPDATE_LIST,
  FETCH_GESTAO_DOCUMENTOS_FAILURE as FETCH_FAILURE_LIST,
} from "./types";
import objToQueryString from "../helpers/objToQueryString";
import { getCancelToken } from "../helpers/axiosCancel";

const model_list = "gestaoDocumentos";
const MySwal = withReactContent(Swal);

export const clearList = () => ({
  type: FETCH_SUCCESS_LIST,
  payload: { [model_list]: [] },
});

export const fetchListBegin = () => ({
  type: FETCH_BEGIN_LIST,
});

export const fetchListSuccess = (data) => ({
  type: FETCH_SUCCESS_LIST,
  payload: { [model_list]: data },
});

export const fetchListUpdate = (data) => ({
  type: FETCH_UPDATE_LIST,
  payload: { [model_list]: data },
});

export const fetchListFailure = (error) => ({
  type: FETCH_FAILURE_LIST,
  payload: { error },
});

const uriList = URL_API + "contratos_gestao";
let lastQueryGestao = "";
export function fetchList(reqData = {}) {
  return (dispatch) => {
    const query = objToQueryString(reqData);
    if (query === lastQueryGestao) return;
    lastQueryGestao = query;

    dispatch(fetchListBegin());

    axios
      .get(uriList, {
        params: reqData,
        cancelToken: getCancelToken("gestaoDocumentos/fetchList"),
      })
      .then((res) => {
        const list = res.data.rows;
        dispatch(fetchListSuccess(list));
        return res.data.pages > 1
          ? nextPageList(dispatch, reqData, list, res.data.pages, 1)
          : list;
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }
        dispatch(fetchListFailure(erro));
      });
  };
}

const nextPageList = (dispatch, filter, list, totalPages, page) => {
  const ct = getCancelToken("gestaoDocumentos/fetchList");
  return axios
    .get(uriList, {
      params: {
        ...filter,
        page,
      },
    })
    .then((res) => {
      const results = res.data.rows;
      if (results && results.length) {
        list = [...list, ...results].sort((a, b) => {
          if (a.porcentagem === b.porcentagem) return a.nome < b.nome ? -1 : 1;

          return a.porcentagem < b.porcentagem ? -1 : 1;
        });

        if (ct.reason) return list;

        dispatch(fetchListSuccess(list));

        if (page === totalPages) return list;

        return nextPageList(dispatch, filter, list, totalPages, page + 1);
      }
    })
    .catch((err) => {
      if (ct.reason) return;
      console.log(err);
    });
};

export function fetchContrato(reqData = {}) {
  return (dispatch) => {
    if (!reqData.contrato) return;

    const ct = getCancelToken("gestaoDocumentos/fetchContrato");

    axios
      .get(uriList, {
        params: reqData,
        cancelToken: ct,
      })
      .then((res) => {
        const list = res.data.rows;
        dispatch(fetchListUpdate(list));
        return list;
      })
      .catch((err) => {
        if (ct.reason) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }
        dispatch(fetchListFailure(erro));
      });
  };
}

export function solicitaMudancaContrato(
  idFuncionario,
  idContrato,
  idNovoContrato,
  callback
) {
  return (dispatch) => {
    axios
      .post(URL_API + "contratos_gestao/mudanca-funcionario-contrato", {
        idFuncionario,
        idContrato,
        idNovoContrato,
      })
      .then((res) => {
        MySwal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: "Solicitação enviada",
        });
        callback();
      })
      .catch((err) => {
        let erro = { message: "Não foi possível fazer a solicitação" };
        if (err.response) {
          erro = err.response.data;
        }
        if (typeof erro === "string") erro = { message: erro };
        MySwal.fire({
          position: "center",
          type: "error",
          text: erro.message,
        });
      });
  };
}

export function aprovaMudancaContrato(
  idFuncionario,
  idContrato,
  idNovoContrato,
  callback
) {
  return (dispatch) => {
    axios
      .post(URL_API + "contratos_gestao/mudanca-funcionario-contrato/aprova", {
        idFuncionario,
        idContrato,
        idNovoContrato,
      })
      .then((res) => {
        MySwal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: "Aprovação de troca feita com sucesso",
        });
        callback();
      })
      .catch((err) => {
        let erro = { message: "Não foi possível fazer a troca" };
        if (err.response) {
          erro = err.response.data;
        }
        MySwal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          type: "error",
          title: erro.message,
        });
      });
  };
}

export function rejeitaMudancaContrato(
  idFuncionario,
  idContrato,
  idNovoContrato,
  callback
) {
  return (dispatch) => {
    axios
      .post(URL_API + "contratos_gestao/mudanca-funcionario-contrato/rejeita", {
        idFuncionario,
        idContrato,
        idNovoContrato,
      })
      .then((res) => {
        MySwal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: "Aprovação de troca rejeitada com sucesso",
        });
        callback();
      })
      .catch((err) => {
        let erro = { message: "Não foi possível rejeitar a troca" };
        if (err.response) {
          erro = err.response.data;
        }
        MySwal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          type: "error",
          title: erro.message,
        });
      });
  };
}
