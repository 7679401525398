import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FETCH_EMPRESA_BEGIN as BEGIN, FETCH_EMPRESA_SUCCESS as SUCCESS, FETCH_EMPRESA_FAILURE as FAILURE, GET_ERRORS } from './types'
import { setModalOpen } from './modal'
import { URL_API } from './urls'
import { defaultFetchBegin, defaultFetchSuccess, defaultFetchFailure, defaultFetch } from './default'

const MySwal = withReactContent(Swal)

const model = 'empresa'

export const fetchBegin = () => defaultFetchBegin(BEGIN)
export const fetchSuccess = data => defaultFetchSuccess(SUCCESS, model, data)
export const fetchFailure = error => defaultFetchFailure(FAILURE, error)
export const fetch = (id = '') => defaultFetch(model, fetchBegin, fetchSuccess, fetchFailure, id)

export const saveEmpresa = (empresa, history, actions) => dispatch => {
    empresa.cnae = empresa.cnae?.map((val)=>val.codigo)

    var formData = new FormData();
    Object.keys(empresa).forEach(function(key) {
        formData.append(key, empresa[key]);
    })

    axios.post(URL_API + 'empresa', formData, {headers: {
            'Content-Type': 'multipart/form-data'
        }})
        .then(res => {
            history.push('/empresa')
            MySwal.fire({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Empresa salva com sucesso'
            })
            actions.setSubmitting(false)
            dispatch(setModalOpen(''))
            dispatch(fetch())
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
            actions.setErrors(err.response.data.errors)
            actions.setSubmitting(false)
        })
}