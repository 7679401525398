import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SET_NOTIFICACOES } from './types'
import { initialState } from '../reducers/notificacoesReducer'
import { setModalOpen, setModalId } from './modal'
import { fetchList } from './gestaoDocumentos'
import { fetchFuncionariosList, fetchContratoList, fetchList as fetchDocumentosList } from './documentos'
import { setAppData } from './dados'
import { COLOR_DANGER, COLOR_WARNING, COLOR_INFO } from '../Colors'
import { setRefresh, REFRESH_KEY } from './refresh'

export const setNotificacoes = notificacoes => ({ type: SET_NOTIFICACOES, payload: notificacoes })

const abreContrato = (filter, dispatch, history) => {
    dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
    dispatch(setAppData({filtros: filter}))
    history.push('/gestao-documentos/')

    let reqData = {
        empresa: filter.empresa.idFilial,
        contrato: filter.contrato.idContrato,
    }
    if(filter.pesquisa){
        reqData.search = filter.pesquisa
    }
    dispatch(fetchList(reqData))
    dispatch(fetchDocumentosList(reqData))
    dispatch(fetchFuncionariosList(reqData))
    dispatch(fetchContratoList(reqData))
}

const abreDocumento = (documento, funcionario, dispatch, history) => {
    dispatch(setAppData({
        filial: null,
        funcionario: funcionario,
        documento: documento,
        contrato: null
    }))
    history.push('/documentos/envios/')
}

const abreContratoModal = (contrato, dispatch, history) => {
    history.push('/contratos/')
    dispatch(setModalOpen('edit'))
    dispatch(setModalId(contrato))
} 

export const montaNotificacoes = (notificacoes = initialState.notificacoes, dispatch, history) => {
    let idx = -1
    return (<Dropdown.Menu className="dropdown-menu-right dropdown-notification mw-100" style={{maxWidth: '500px', minWidth: '400px'}}>
        {notificacoes.quantidade === 0 &&
            <Dropdown.Item as="button" key={idx}>
                <div className="text-center w-100">
                    Nenhuma notificação encontrada
                </div>
            </Dropdown.Item>}
        {notificacoes.contratos?.map((notification)=>{
            return notification.contratos?.map((contrato)=>{
                idx++
                if(contrato.tipo === 'terceiro'){ // Sou terceiro
                    if(contrato.invalidos > 0){
                        return <Dropdown.Item as="button" key={idx} onClick={()=>{
                            abreContrato({
                                filial: null,
                                empresa: notification,
                                contrato: contrato,
                            }, dispatch, history)
                        }}>
                            <FontAwesomeIcon size="2x" icon="ban" style={{color: COLOR_DANGER}}/> 
                            <div className="pl-2">
                                Há <b>{contrato.invalidos}</b> documento(s) inválido(s) no seu contrato <b>{contrato.nome.toUpperCase()}</b> com a empresa <b>{notification.nome.toUpperCase()}</b>
                            </div>
                        </Dropdown.Item>
                    } else if(contrato.pendentes > 0){
                        return <Dropdown.Item as="button" key={idx} onClick={()=>{
                            abreContrato({
                                filial: null,
                                empresa: notification,
                                contrato: contrato,
                            }, dispatch, history)
                        }}>
                            <FontAwesomeIcon size="2x" icon="cloud-upload-alt" style={{color: COLOR_WARNING}}/> 
                            <div className="pl-2">
                                Há <b>{contrato.pendentes}</b> documento(s) pendente(s) no seu contrato <b>{contrato.nome.toUpperCase()}</b> com a empresa <b>{notification.nome.toUpperCase()}</b>
                            </div>
                        </Dropdown.Item>
                    }
                } else if (contrato.tipo === 'tomador'){ // Sou tomador
                    if(contrato.aguardando > 0){
                        return <Dropdown.Item as="button" key={idx} onClick={()=>{
                            abreContrato({
                                filial: null,
                                empresa: notification,
                                contrato: contrato,
                            }, dispatch, history)
                        }}>
                            <FontAwesomeIcon size="2x" icon="hourglass-half" style={{color: COLOR_WARNING}}/> 
                            <div className="pl-2">
                                Há <b>{contrato.aguardando}</b> documento(s) aguardando avaliação no seu contrato <b>{contrato.nome.toUpperCase()}</b> com a empresa <b>{notification.nome.toUpperCase()}</b>
                            </div>
                        </Dropdown.Item>
                    } 
                }

                return null
            })
        })}
        {notificacoes.vencendo?.map((documento)=>{
            idx++
            let a = documento.vencimento ? moment(documento.vencimento.date).startOf('day') : moment().startOf('day')
            let b = moment().startOf('day')

            let diffDuration = moment.duration(a.diff(b))

            const diasRestantes = documento.vencimento ? diffDuration.days() : 0

            return <Dropdown.Item as="button" key={idx} onClick={()=>{
                abreDocumento(documento, documento.funcionario, dispatch, history)
            }}>
                <FontAwesomeIcon size="2x" icon="clock" style={{color: COLOR_WARNING}}/> 
                <div className="pl-2">
                    O documento <b>{documento.nome}</b> {documento.funcionario &&
                        <span>do funcionário <b>{documento.funcionario.nome.toUpperCase()}</b> </span>
                    }
                    está próximo da expiração <b>{moment(documento.vencimento.date).format('DD/MM/YYYY')}</b> ({diasRestantes} dias), considere reenvia-lo
                </div>
            </Dropdown.Item>
        })}
        {notificacoes.contratos_expirando?.map((contrato)=>{
            idx++
            let a = contrato.dataFim ? moment(contrato.dataFim.date).startOf('day') : moment().startOf('day')
            let b = moment().startOf('day')

            let diffDuration = moment.duration(a.diff(b))

            const diasRestantes = contrato.dataFim ? diffDuration.days() : 0

            return <Dropdown.Item as="button" key={idx} onClick={()=>{
                abreContratoModal(contrato.idContrato, dispatch, history)
            }}>
                <FontAwesomeIcon size="2x" icon="clock" style={{color: COLOR_INFO}}/> 
                <div className="pl-2">
                    O contrato <b>{contrato.titulo.toUpperCase()} </b> 
                    está próximo da data final <b>{moment(contrato.dataFim.date).format('DD/MM/YYYY')}</b> ({diasRestantes} dias), considere renová-lo
                </div>
            </Dropdown.Item>
        })}
        {notificacoes.contratos_pendentes?.map((contrato)=>{
            idx++
            return <Dropdown.Item as="button" key={idx} onClick={()=>{
                abreContratoModal(contrato.idContrato, dispatch, history)
            }}>
                <FontAwesomeIcon size="2x" icon="exclamation-circle" style={{color: COLOR_INFO}}/> 
                <div className="pl-2">
                    O contrato <b>{contrato.titulo.toUpperCase()} </b> 
                    criado pela empresa <b>{contrato.nomeTomador.toUpperCase()}</b>, está pendente, aprove-o para que o tomador consiga ver seus documentos
                </div>
            </Dropdown.Item>
        })}
        {notificacoes.funcionarios_troca_pendentes?.map((contrato)=>{
            idx++
            return <Dropdown.Item as="button" key={idx} onClick={()=>{
                let empresa = {}
                if(contrato.tipo === 'tomador'){
                    empresa = {
                        idFilial: contrato.idTerceiro,
                        nome: contrato.nomeTerceiro
                    }
                } else {
                    empresa = {
                        idFilial: contrato.idTomador,
                        nome: contrato.nomeTomador
                    }
                }
                abreContrato({
                    filial: null,
                    empresa,
                    contrato,
                }, dispatch, history)
            }}>
                <FontAwesomeIcon size="2x" icon="exclamation-circle" style={{color: COLOR_INFO}}/> 
                <div className="pl-2">
                    Existe uma troca de contrato pendente, do funcionário <b>{contrato.funcionario}</b>, 
                    do contrato <b>{contrato.nome.toUpperCase()}</b> para o contrato <b>{contrato.titulo.toUpperCase()} </b>,
                    aprove/rejeite a troca na tela de gestão
                </div>
            </Dropdown.Item>
        })}
        {notificacoes.recados_nao_lidos?.map((recado)=>{
            idx++
            return <Dropdown.Item as="button" key={idx} onClick={()=>{
                let empresa = {}
                let contrato = {
                    idContrato: recado.idContrato, 
                    nome: recado.contrato
                }
                if(recado.tipo === 'tomador'){
                    empresa = {
                        idFilial: recado.idTerceiro,
                        nome: recado.nomeTerceiro
                    }
                } else {
                    empresa = {
                        idFilial: recado.idTomador,
                        nome: recado.nomeTomador
                    }
                }
                abreContrato({
                    filial: null,
                    empresa,
                    contrato,
                    pesquisa: recado.nome
                }, dispatch, history)
            }}>
                <FontAwesomeIcon size="2x" icon={['far', 'comments']} style={{color: COLOR_INFO}}/> 
                <div className="pl-2">
                    Existe(m) <b>{recado.quantidade}</b> mensagem(ns) não lidas, 
                    no contrato <b>{recado.contrato.toUpperCase()} </b> 
                    {recado.idDocumento &&
                        <>
                            e documento <b>{recado.nome}</b>
                        </>
                    }
                </div>
            </Dropdown.Item>
        })}
    </Dropdown.Menu>)
}

