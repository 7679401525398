export default function defaultReducer(model, begin, success, failure, initialDataState, state, action) {
    if(!state){
        state = {
            [model]: initialDataState,
            loading: false,
            error: null
        };
    }
    switch(action.type) {
        case begin:
            return {
                ...state,
                loading: true,
                error: null,
                [model]: initialDataState
            };

        case success:
            return {
                ...state,
                loading: false,
                [model]: action.payload[model]
            };

        case failure:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                [model]: initialDataState
            };

        default:
            return state;
    }
}
