import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { requiredField } from '../../validation/formValidation'
import ButtonPrimary from '../Buttons/ButtonPrimary'

const ResendEmailForm = ({ defaultValue, onSubmit }) => {
    return (
        <>
            Favor confirmar o e-mail de cadastro do responsável na filial
            <br /> 
            <Formik
                initialValues={defaultValue}
                onSubmit={onSubmit}
                render={({ isSubmitting, handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="mx-auto">
                        <div className="my-3">
                            <div className="d-flex align-items-center justify-content-center gap-2 my-2">
                                <Field type="email" id="email" name="email" className="form-control" validate={requiredField} />
                            </div>
                        <ErrorMessage name="email" component="small" className="form-text text-danger" />
                        </div>
                        <div className='d-flex justify-content-center gap-1 w-full mt-4'>
                            <ButtonPrimary 
                                className="btn-confirm swal2-styled"
                                type="submit"
                                disabled={isSubmitting}
                            >Enviar Link Cadastro</ButtonPrimary>
                        </div>
                    </Form>
                )}
            />
        </>
    )
}

export default ResendEmailForm