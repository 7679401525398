import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { GET_ERRORS } from "./types";
import { URL_API } from "./urls";
import { setModalOpen } from "./modal";
import { getCancelToken } from "../helpers/axiosCancel";

const MySwal = withReactContent(Swal);

export const defaultClearData = (success, model, initialState) => ({
    type: success,
    payload: {
        [model]: initialState,
        error: null,
    },
});

export function defaultFetchList(
    model_list,
    reqData,
    resolve,
    reject,
    dispatch
) {
    axios
        .post(URL_API + model_list, reqData, {
            cancelToken: getCancelToken(model_list + "/defaultFetchList"),
        })
        .then((res) => {
            resolve(res.data);
        })
        .catch((err) => {
            if (axios.isCancel(err)) return;

            let erro = { message: "Não foi possível obter os dados" };
            if (err.response) {
                erro = err.response.data;
            }
            dispatch({
                type: GET_ERRORS,
                payload: { message: erro },
            });
            reject(erro.message);
        });
}

export const defaultFetchBegin = (begin) => ({
    type: begin,
});

export const defaultFetchSuccess = (success, model, data) => ({
    type: success,
    payload: { [model]: data },
});

export const defaultFetchFailure = (failure, error) => ({
    type: failure,
    payload: { error },
});

export function defaultFetch(model, begin, success, failure, id = "") {
    return (dispatch) => {
        dispatch(begin());

        axios
            .get(URL_API + model + "/" + id)
            .then((res) => {
                const { data } = res.data;
                dispatch(success(data));
                return data;
            })
            .catch((err) => {
                let erro = { message: "Não foi possível obter os dados" };
                if (err.response) {
                    erro = err.response.data;
                }
                dispatch(failure(erro));
            });
    };
}

export const defaultChangeStatus =
    (model, id, status = 0, message, afterChange, afterError) =>
    (dispatch) => {
        axios
            .put(URL_API + model + "/" + id + "/" + status)
            .then((res) => {
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: message(status),
                });
                dispatch(setModalOpen(""));
                if (afterChange) afterChange();
            })
            .catch((err) => {
                if (err.response) {
                    MySwal.fire({
                        type: "error",
                        title: err.response.data,
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    if (afterError) afterError();
                }
            });
    };

export const defaultDelete =
    (model, id, message, afterDelete, afterError, showError) => (dispatch) => {
        showError = showError || ((err) => MySwal.fire({
            type: "error",
            title: err.response.data,
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 3000,
        }));

        axios
            .delete(URL_API + model + "/" + id)
            .then((res) => {
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: message,
                });

                dispatch(setModalOpen(""));
                if (afterDelete) afterDelete();
            })
            .catch((err) => {
                if (err.response) {
                    showError(err);
                    if (afterError) afterError();
                }
            });
    };
