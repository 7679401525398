/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import ReactTable from "react-table"
import "react-table/react-table.css"

/* Métodos / Ações */
import { fetchFuncionariosContrato, fetch, associarFuncionarioContrato, associarFuncionarioContratoDocumento, fetchFuncionariosContratoSuccess, removerFuncionarioContrato, removerEDemitirFuncionarioContrato, afastarFuncionarioContrato, fetchTiposAfastamento } from '../../actions/contratos'
import { clearErrors } from '../../actions/errors'

/* Componentes */
import Error from '../Error'
import LoadingImage from '../LoadingImage'

/* Botões */
import ButtonDark from '../Buttons/ButtonDark'
import ButtonSuccess from '../Buttons/ButtonSuccess'

/* Campos */

/* Validação */

/* Cores */
import { COLOR_DARK2, COLOR_DARK, COLOR_WARNING } from '../../Colors'
import { setModalOpen } from '../../actions/modal';
import ContratoModalForm from './ContratoModalForm'
import { TIPOS_AFASTAMENTO } from '../Funcionario/FuncionarioAfastamentoModalForm'
import { setFuncionarioId } from '../../actions/scroll'
import { setFilialFuncionarioId } from '../../actions/filialFuncionario'
import { REFRESH_KEY, setRefresh } from '../../actions/refresh'

const MySwal = withReactContent(Swal)

class ContratoFuncionarios extends Component {
    constructor() {
        super()

        this._isMounted = false
        this.state = {
            tela: 'lista',
            checked: 'soft',
            removalDate: '',
            tiposAfastamento: [],
        }

        this.adicionarFuncionarioAoContrato = this.adicionarFuncionarioAoContrato.bind(this)
        this.removerFuncionarioDoContrato = this.removerFuncionarioDoContrato.bind(this)
    }

    handleSubmit() {
    }

    handleRemoveEmployee = (value) => {
        this.setState({ removalDate: value })
        this.props.dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
        Swal.clickConfirm()
    }

    handleCheckedOption = (e) => this.setState({ checked: e.target.id });

    requestData() {
        fetchTiposAfastamento()
          .then((tiposAfastamento) => this.setState({ tiposAfastamento: tiposAfastamento?.filter(tipo => tipo.id !== TIPOS_AFASTAMENTO.DEMISSAO) }));
    }

    componentDidMount() {
        this._isMounted = true
        if (this.props.id) {
            this.props.dispatch(fetch(this.props.id))
            this.props.dispatch(fetchFuncionariosContrato(this.props.id))
        }

        this.requestData()
    }

    componentWillUnmount() {
        this._isMounted = false

        if (this.state.checked !== 'soft') {
            this.setState({ checked: 'soft', })
        }
    }

    componentDidUpdate(nextProps) {
        if(nextProps.errors) {
            let show = false;
            if(Object.keys(nextProps.errors)?.length){
                show = true
            }
            if(show){
                MySwal.fire({
                    type: 'error',
                    title: nextProps.errors.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                this.props.dispatch(clearErrors())
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            let show = false;
            if (Object.keys(nextProps.errors)?.length) {
                show = true
            }
            if (show) {
                MySwal.fire({
                    type: 'error',
                    title: nextProps.errors.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                this.props.dispatch(clearErrors())
            }
        }
    }

    adicionarFuncionarioAoContrato(props) {
        MySwal.fire({
            title: '',
            type: 'question',
            html: 'Deseja realmente adicionar o funcionário <b>'+props.nome.toUpperCase()+'</b> ao contrato <b>'+this.props.contrato.titulo+'</b>?',
            showCancelButton: true,
            confirmButtonText: 'Sim, adicionar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if(result.value){
                this.props.dispatch(associarFuncionarioContrato(props, this.props.id, () => {
                    this.props.dispatch(fetchFuncionariosContrato(this.props.id))
                    this.props.dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
                    this.setState({
                        tela: 'lista'
                    })
                }))
            }
        })
    }

    removerFuncionarioDoContrato(props) {
        MySwal.fire({
            title: '',
            type: 'question',
            html: <ContratoModalForm
                    name={props.nome.toUpperCase()}
                    contract={this.props.contrato.titulo}
                    handleCheckedOption={this.handleCheckedOption}
                    checked={this.state.checked}
                    onSubmit={this.handleRemoveEmployee}
                    onCancel={Swal.clickCancel}
                    tiposAfastamento={this.state.tiposAfastamento}
                    defaultValue={{
                      dataDemissao: this.props.funcionario.dataDemissao,
                      tipoAfastamento: undefined,
                      inicioAfastamento: undefined,
                      fimAfastamento: undefined,
                    }}
                  />,
            showCancelButton: false,
            showConfirmButton: false,
        }).then((result) => {
            if (result.value) {
                this.props.dispatch(
                    setFuncionarioId({
                        id: props.idFuncionario,
                    })
                );
                this.props.dispatch(setFilialFuncionarioId(props.idFilialFuncionario));
                this.props.dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
                const afterSave = () => {
                  this.props.dispatch(fetchFuncionariosContrato(this.props.id))
                  this.setState({
                    tela: 'lista'
                  })
                }

                switch (this.state.checked) {
                  case "soft":
                    this.props.dispatch(removerFuncionarioContrato(props, this.props.id, afterSave))
                    break;
                  case "hard":
                    this.props
                      .dispatch(removerEDemitirFuncionarioContrato({
                        ...props,
                        dataAdmissao: props.dataAdmissao?.date,
                        dataNascimento: props.dataNascimento?.date,
                        dataDemissao: this.state.removalDate?.dataDemissao
                      }, afterSave))
                    break;
                  case "afastar":
                    this.props
                      .dispatch(afastarFuncionarioContrato({
                        funcionario: props.idFuncionario,
                        filial: props.idFilial,
                        afastamento: this.state.removalDate?.tipoAfastamento?.value,
                        inicio: this.state.removalDate?.inicioAfastamento,
                        termino: this.state.removalDate?.fimAfastamento,
                      }, afterSave))
                    break;
                  default: break;
                }
            }

            this.setState({ checked: 'soft', })
        })
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>
        }

        if (this.props.loading) {
            return <LoadingImage></LoadingImage>
        }

        if (this.props.contrato.tipo !== "terceiro") {
            return <h3 className="my-5 text-center px-3">Parece que você não é o terceiro, infelizmente, só o terceiro pode gerenciar funcionários do contrato</h3>
        }

        const funcionariosContrato = this.props.funcionarios.filter((val) => val.idContratoFuncionario !== null)
        const funcionariosForaContrato = this.props.funcionarios.filter((val) => (
            val.idContratoFuncionario === null && val.dataDemissao === null
        ))

        if(this.state.tela === 'lista') {
            return(
                <div className="pb-5">
                    <div className="row mx-0 d-flex align-items-center justify-content-center">
                        <div className="col-12 px-4">
                            <div className="card">
                                <div className="card-body px-2">
                                    <h5 className="font-weight-500 mb-3" style={{color: COLOR_DARK2}}>{this.props.contrato.titulo.toUpperCase()}</h5>
                                    {(funcionariosForaContrato?.length > 0) &&
                                    <ButtonDark onClick={()=>{
                                        this.setState({
                                            tela: 'add'
                                        })
                                    }} type="button" className="ml-2 mb-3 d-block mx-auto"><FontAwesomeIcon icon="user-plus"/> Adicionar Funcionário ao Contrato</ButtonDark>
                                    }
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="text-center" style={{color: COLOR_DARK}}>Funcionários já alocados no contrato:</h6>
                                            {(funcionariosContrato?.length === 0 && funcionariosForaContrato?.length > 0) &&
                                                <ButtonSuccess className="d-block mx-auto" onClick={() => {
                                                    this.setState({
                                                        tela: 'add'
                                                    })
                                                }}><FontAwesomeIcon icon="user-plus" /> Adicionar funcionários deste contrato</ButtonSuccess>
                                            }
                                            {(funcionariosContrato?.length === 0 && funcionariosForaContrato?.length === 0) &&
                                                <>
                                                <p className="text-center">Sua empresa não tem nenhum funcionário cadastrado</p>
                                                <ButtonSuccess className="d-block mx-auto" onClick={() => {
                                                    this.props.history.push('/funcionarios/')
                                                        this.props.dispatch(setModalOpen('new'))
                                                }}><FontAwesomeIcon icon="user-plus" /> Adicionar funcionários na EMPRESA</ButtonSuccess>
                                                </>
                                            }
                                            {funcionariosContrato?.length > 0 &&
                                                <Accordion defaultActiveKey="0">
                                                    {funcionariosContrato?.map((val, idx)=>{
                                                        if (this.props.contrato.tipo === "terceiro") {
                                                            return <Card key={idx}><Card.Header>{this.renderCardFuncionarioContent(val)}</Card.Header></Card>
                                                        }

                                                        return (<Card key={idx}>
                                                            <Accordion.Toggle className="cursor-pointer" as={Card.Header} eventKey={idx}>
                                                                {this.renderCardFuncionarioContent(val)}
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse eventKey={idx}>
                                                                <Card.Body>
                                                                <ReactTable
                                                                    columns={[
                                                                        {
                                                                            Header: "Nome",
                                                                            accessor: "nome",
                                                                            headerClassName: 'nome-placeholder',
                                                                            className: "font-weight-600 text-uppercase",
                                                                            Cell: (props) => <div className="d-flex align-items-center">
                                                                                <Toggle
                                                                                checked={parseInt(props.original.noContrato) !== 0}
                                                                                className="mr-3"
                                                                                onChange={(evt)=>{
                                                                                    let beforeCheck = funcionariosContrato[idx].documentos[props.index].noContrato
                                                                                    let check = evt.target.checked
                                                                                    funcionariosContrato[idx].documentos[props.index].noContrato = check ? 1 : 0
                                                                                    this.props.dispatch(fetchFuncionariosContratoSuccess(funcionariosContrato))

                                                                                    this.props.dispatch(associarFuncionarioContratoDocumento(val.idFuncionario, this.props.id, props.original.idDocumento, ()=>{
                                                                                        funcionariosContrato[idx].documentos[props.index].noContrato = beforeCheck
                                                                                        this.props.dispatch(fetchFuncionariosContratoSuccess(funcionariosContrato))
                                                                                        this.setState({ tela: 'lista' });
                                                                                    }))
                                                                                }} />{' '+props.original.nome}
                                                                            </div>,
                                                                        },
                                                                    ]}
                                                                    multiSort={false}
                                                                    data={val.documentos}
                                                                    loading={this.props.loading}
                                                                    previousText={<FontAwesomeIcon icon="chevron-left" />}
                                                                    nextText={<FontAwesomeIcon icon="chevron-right" />}
                                                                    resizable={false}
                                                                    loadingText="Carregando..."
                                                                    noDataText="Nenhum documento encontrado"
                                                                    pageText="Página"
                                                                    ofText="de"
                                                                    rowsText="linhas"
                                                                    pageJumpText="ir para a página"
                                                                    rowsSelectorText="linhas por página"
                                                                    pageSizeOptions={[5, 10, 20, 50]}
                                                                    defaultPageSize={10}
                                                                    minRows={0}
                                                                    sortable={false}
                                                                    className="-highlight"
                                                                    />
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>)
                                                    })}
                                                </Accordion>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return(
            <div className="pb-5">
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-12 px-4">
                        <div className="card">
                            <div className="card-body px-2">
                                <h5 className="font-weight-500 mb-3" style={{color: COLOR_DARK2}}>{this.props.contrato.titulo.toUpperCase()}</h5>
                                <div className="row">
                                    <div className="col-12">
                                        <h6 className="text-center" style={{color: COLOR_DARK}}>Selecione o funcionário que deseja adicionar ao contrato:</h6>
                                        {funcionariosForaContrato?.length === 0 &&
                                            <p className="text-center">Nenhum funcionário para adicionar ao contrato</p>
                                        }
                                        {funcionariosForaContrato?.length > 0 &&
                                        <ReactTable
                                            columns={[
                                                {
                                                    Header: "",
                                                    accessor: "letra",
                                                    filterable:false,
                                                    sortable:false,
                                                    className: 'option-column-5 letra-inicial',
                                                    headerClassName: 'option-column-5',
                                                    Cell: props => <div style={{
                                                        borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                        borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                        borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                        borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                        borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                                    }}>
                                                        {props.original.letra !== '' ? props.original.letra : ' '}
                                                    </div>,
                                                },
                                                {
                                                    Header: "Nome",
                                                    accessor: "nome",
                                                    filterable: true,
                                                    headerClassName: 'nome-placeholder',
                                                    className: "font-weight-600 text-uppercase",
                                                    Cell: (props) => <div onClick={()=>{this.adicionarFuncionarioAoContrato(props.original)}}>
                                                        {props.original.nome} - {props.original.cpf}
                                                    </div>
                                                },
                                            ]}
                                            multiSort={false}
                                            data={funcionariosForaContrato}
                                            previousText={<FontAwesomeIcon icon="chevron-left" />}
                                            nextText={<FontAwesomeIcon icon="chevron-right" />}
                                            resizable={false}
                                            loadingText="Carregando..."
                                            noDataText="Nenhum funcionário encontrado"
                                            pageText="Página"
                                            ofText="de"
                                            rowsText="linhas"
                                            pageJumpText="ir para a página"
                                            rowsSelectorText="linhas por página"
                                            pageSizeOptions={[5, 10, 20, 50]}
                                            defaultPageSize={10}
                                            minRows={0}
                                            sortable={false}
                                            className="-highlight"
                                            defaultFilterMethod={(filter, row, column) => {
                                                const id = filter.pivotId || filter.id
                                                return row[id] !== undefined ? String(row[id]).toUpperCase().startsWith(filter.value.toUpperCase()) : true
                                            }}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    
    renderCardFuncionarioContent = (funcionario) => (
        <>
            {funcionario.nome}<span className="small">{funcionario.cpf}</span>
            <button className="small" style={{
                color: COLOR_WARNING,
                position: 'absolute',
                right: 0,
                transform: 'translateY(25%)'
            }} onClick={()=>{
                this.removerFuncionarioDoContrato(funcionario)
            }}><FontAwesomeIcon icon="times" /> Remover</button>
        </>
    )
}
ContratoFuncionarios.propTypes = {
    funcionario: PropTypes.object.isRequired,
    contrato: PropTypes.object.isRequired,
    funcionarios: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    id: PropTypes.any,
    onAfterSave: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    permissoes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    contrato: state.contratos.contrato,
    funcionarios: state.contratos.funcionarios,
    funcionario: state.funcionarios.funcionario,
    loading: state.contratos.loadingFuncionarios,
    error: state.contratos.errorFuncionarios,
    errors: state.errors,
    id: state.modal.id,
    permissoes: state.permissions.permissoes
})

export default connect(mapStateToProps)(withRouter(ContratoFuncionarios));
