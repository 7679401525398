import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./MainLinks.css";

class MainLinks extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const defaultStyle = {
      borderBottom: "3px solid transparent",
      lineHeight: 1,
    };
    const activeStyle = { borderBottom: "3px solid #f15923" };
    const defaultClasses =
      "col-6 col-md-4 py-2 pt-lg-4 pb-lg-3 pt-xl-5 pb-xl-4 px-0 mx-lg-2 font-weight-500 col-lg text-white text-uppercase text-center cursor-pointer d-flex flex-column align-items-center justify-content-center";
    return (
      <div className="w-100" id="mainLinks">
        <div className="row mx-0 justify-content-center">
          <div className="col-auto">
            <div className="row">
              <NavLink
                to="/"
                exact={true}
                style={defaultStyle}
                activeStyle={activeStyle}
                className={defaultClasses}
              >
                <img
                  src={"/images/icon_home.png"}
                  className="img-fluid pb-1"
                  alt="Home"
                  title="Home"
                  style={{ maxHeight: "35px" }}
                />
                Home
                <br />
                &nbsp;
              </NavLink>

              {this.props.permissoes &&
                (this.props.permissoes.administradorEmpresa === 1 ||
                  this.props.permissoes.administradorFilial === 1 ||
                  this.props.permissoes.permissoesGerais.indexOf(
                    "gestao-documentos"
                  ) !== -1) && (
                  <NavLink
                    to="/gestao-documentos/"
                    style={defaultStyle}
                    activeStyle={activeStyle}
                    className={defaultClasses}
                  >
                    <img
                      src={"/images/icon_gestao_documentos.png"}
                      className="img-fluid pb-1"
                      alt="Gestão de Documentos"
                      title="Gestão de Documentos"
                      style={{ maxHeight: "35px" }}
                    />
                    Gestão
                    <br />
                    &nbsp;
                  </NavLink>
                )}

              {this.props.permissoes &&
                (this.props.permissoes.administradorEmpresa === 1 ||
                  this.props.permissoes.administradorFilial === 1 ||
                  this.props.permissoes.responsavelFuncionarios === 1) && (
                  <NavLink
                    to="/funcionarios/"
                    style={defaultStyle}
                    activeStyle={activeStyle}
                    className={defaultClasses}
                  >
                    <img
                      src={"/images/icon_funcionarios.png"}
                      className="img-fluid pb-1"
                      alt="Meus Funcionários"
                      title="Meus Funcionários"
                      style={{ maxHeight: "35px" }}
                    />
                    Meus Funcionários
                    <br />
                    &nbsp;
                  </NavLink>
                )}

              {/^\/usuarios/.test(this.props.location.pathname) && ( // Usuários
                <NavLink
                  to={this.props.location.pathname}
                  style={defaultStyle}
                  activeStyle={activeStyle}
                  className={defaultClasses}
                >
                  <img
                    src={"/images/icon_usuarios.png"}
                    className="img-fluid pb-1"
                    alt="Usuários"
                    title="Usuários"
                    style={{ maxHeight: "35px" }}
                  />
                  Usuários
                  <br />
                  &nbsp;
                </NavLink>
              )}

              {/^\/departamentos/.test(this.props.location.pathname) && ( // Departamentos
                <NavLink
                  to={this.props.location.pathname}
                  style={defaultStyle}
                  activeStyle={activeStyle}
                  className={defaultClasses}
                >
                  <img
                    src={"/images/icon_departamentos.png"}
                    className="img-fluid pb-1"
                    alt="Departamentos"
                    title="Departamentos"
                    style={{ maxHeight: "35px" }}
                  />
                  Departamentos
                  <br />
                  &nbsp;
                </NavLink>
              )}

              {/^\/contratos/.test(this.props.location.pathname) && ( // Contratos
                <NavLink
                  to={this.props.location.pathname}
                  style={defaultStyle}
                  activeStyle={activeStyle}
                  className={defaultClasses}
                >
                  <img
                    src={"/images/icon_contratos.png"}
                    className="img-fluid pb-1"
                    alt="Contratos"
                    title="Contratos"
                    style={{ maxHeight: "35px" }}
                  />
                  Contratos
                  <br />
                  &nbsp;
                </NavLink>
              )}

              {/^\/empresa/.test(this.props.location.pathname) && ( // Empresa
                <NavLink
                  to={this.props.location.pathname}
                  style={defaultStyle}
                  activeStyle={activeStyle}
                  className={defaultClasses}
                >
                  <img
                    src={"/images/icon_empresa.png"}
                    className="img-fluid pb-1"
                    alt="Minha Empresa"
                    title="Minha Empresa"
                    style={{ maxHeight: "35px" }}
                  />
                  Empresa
                  <br />
                  &nbsp;
                </NavLink>
              )}

              {/^\/filiais/.test(this.props.location.pathname) && ( // Filiais
                <NavLink
                  to={this.props.location.pathname}
                  style={defaultStyle}
                  activeStyle={activeStyle}
                  className={defaultClasses}
                >
                  <img
                    src={"/images/icon_filial.png"}
                    className="img-fluid pb-1"
                    alt="Minha Filiais"
                    title="Minha Filiais"
                    style={{ maxHeight: "35px" }}
                  />
                  Filiais
                  <br />
                  &nbsp;
                </NavLink>
              )}

              {/^\/configuracoes/.test(this.props.location.pathname) && ( // Configurações
                <NavLink
                  to={this.props.location.pathname}
                  style={defaultStyle}
                  activeStyle={activeStyle}
                  className={defaultClasses}
                >
                  <img
                    src={"/images/icon_configuracoes.png"}
                    className="img-fluid pb-1"
                    alt="Configurações"
                    title="Configurações"
                    style={{ maxHeight: "35px" }}
                  />
                  Configurações
                  <br />
                  &nbsp;
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
MainLinks.propTypes = {
  permissoes: PropTypes.object.isRequired,
  usuarioLogado: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  permissoes: state.permissions.permissoes,
  usuarioLogado: state.usuarioLogado.dados,
});

export default connect(mapStateToProps)(withRouter(MainLinks));
