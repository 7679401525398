import axios from "axios";

let m = new Map();
export const getCancelToken = (name) => {
    if (m.has(name)) {
        try {
            m.get(name)();
        } catch (err) {
            console.warn(err);
        }
    }

    m.delete(name);
    return new axios.CancelToken((c) => m.set(name, c));
};
