import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
    FETCH_CNAES_BEGIN,
    FETCH_CNAES_SUCCESS,
    FETCH_CNAES_FAILURE,
} from "./types";
import { URL_API } from "./urls";
import { getCancelToken } from "../helpers/axiosCancel";

const MySwal = withReactContent(Swal);

export const fetchCnaesBegin = () => ({
    type: FETCH_CNAES_BEGIN,
});

export const fetchCnaesSuccess = (cnaes) => ({
    type: FETCH_CNAES_SUCCESS,
    payload: { cnaes },
});

export const fetchCnaesFailure = (error) => ({
    type: FETCH_CNAES_FAILURE,
    payload: { error },
});

export function fetchCnaes() {
    return (dispatch) => {
        dispatch(fetchCnaesBegin());

        axios
            .get(URL_API + "cnae", {
                cancelToken: getCancelToken("cnaes/fetchCnaes"),
            })
            .then((res) => {
                const { data } = res.data;
                dispatch(fetchCnaesSuccess(data));
                return data;
            })
            .catch((err) => {
                if (axios.isCancel(err)) return;

                let erro = {
                    message: "Não foi possível obter a lista de CNAE's",
                };
                if (err.response) {
                    erro = err.response.data;
                }
                dispatch(fetchCnaesFailure(erro));
            });
    };
}

export const saveCnae = (cnae, after) => (dispatch) => {
    axios
        .post(URL_API + "cnae", cnae)
        .then((res) => {
            MySwal.fire({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 2000,
                type: "success",
                title: "CNAE salvo com sucesso",
            });
            dispatch(fetchCnaes());
            if (after) {
                after(cnae);
            }
        })
        .catch((err) => {
            MySwal.fire({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 2000,
                type: "error",
                title: "Não foi possível salvar o CNAE",
            });
        });
};
