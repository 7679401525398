import React, { useState, useMemo, useCallback } from "react";
import classnames from 'classnames';
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Tilt from "react-tilt";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Loading from "../../components/Loading";
import { confirmMFA, postLogin } from "../../actions/authentication";
import { requiredField } from '../../validation/formValidation';

import "../Login.css";

const MySwal = withReactContent(Swal);

const MultifactorAuth = ({ dispatch, history }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const email = useMemo(
    () => history?.location?.state?.email ?? null,
    [history],
  );

  const handleCodeChange = useCallback((e) => {
    setErrors(current => ({ ...current, code: null }));
    setCode(e.target.value);
  }, []);

  const handleCodeBlur = useCallback(() => {
    if (errors?.code) return;
    setErrors(current => ({ ...current, code: requiredField(code) }));
  }, [code, errors]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const codeError = requiredField(code);

    if (!!codeError) {
      setErrors(current => ({ ...current, code: codeError }));
      return;
    }

    try {
      setLoading(true);
      const res = await confirmMFA({
        email,
        code,
      });

      // Clear any existent data and store the token
      dispatch(postLogin({
        jwt: res.data.data,
        fingerprint: res.data.fingerprint,
        clear: true,
      }));

      // Here we need to redirect user using the window.location to reload the permissions
      window.location.href = "/";
    } catch (error) {
      const message = error?.response?.data?.message ?? "Ocorreu um erro ao tentar autorizar o dispositivo.";
      setCode("");
      if (error.response && !error.response?.data?.askMFA) {
        MySwal.fire({
          title: message,
          type: "error",
          position: "bottom-end",
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          showCancelButton: false,
        });

        // Redirect the user to the login page with the email filled and ask for the password again
        history.replace("/login", { email });
        return;
      }

      MySwal.fire({
        title: message,
        type: "error",
        position: "bottom-end",
        timer: 5000,
        toast: true,
        showConfirmButton: false,
        showCancelButton: false,
      });
    } finally {
      setLoading(false);
    }
  }, [dispatch, email, code, history]);

  return (
    <div className="limiter">
      <Loading active={loading}></Loading>
      <div className="container-login100">
        <div className="wrap-login100">
          <Tilt
            className="Tilt login100-pic js-tilt d-flex align-items-center"
            options={{ max: 25 }}
          >
            <img
              src="/images/logo.png"
              alt="Tertium Online"
              style={{ transform: "translateY(-50%)" }}
            />
          </Tilt>

          <form onSubmit={handleSubmit} className="login100-form">
            <span className="login100-form-title title-sm pb-4">Favor confirmar o acesso ao e-mail</span>

            <div className="form-description-container">
              <span className="form-description">
                O navegador/dispositivo que está usando nesse momento não está autorizado, por isso enviamos um e-mail para você com um código de acesso, favor informar o mesmo abaixo para autorizar o dispositivo.
              </span>

              <span className="form-description description-light text-center">
                Verifique sua caixa de entrega ou a pasta de spam.
              </span>
            </div>

            <div className="wrap-input100">
              <input
                className={"input100 input100-readonly"}
                disabled
                readOnly
                type="email"
                name="code"
                placeholder="E-mail"
                value={email}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <FontAwesomeIcon icon="envelope" />
              </span>
            </div>

            <div className="wrap-input100">
              <input
                className={classnames("input100", {
                  "is-invalid": errors.code
                })}
                type="text"
                name="code"
                autoComplete="one-time-code"
                placeholder="Código"
                onChange={handleCodeChange}
                value={code}
                onBlur={handleCodeBlur}
              />
              <span className="focus-input100"></span>
              <span className="symbol-input100">
                <FontAwesomeIcon icon="key" />
              </span>
            </div>
            {errors.code && (
              <div className="invalid-feedback">{errors.code}</div>
            )}

            <div className="container-login100-form-btn">
              <button className="login100-form-btn" disabled={Object.values(errors).filter(Boolean).length}>Confirmar Código de Acesso</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

MultifactorAuth.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(
  mapStateToProps,
  (dispatch) => ({ dispatch }),
)(withRouter(MultifactorAuth));
