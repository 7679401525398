import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay'
import GridLoader from 'react-spinners/GridLoader'
import './Loading.css'

export default class Home extends Component {
    render() {
        return (
            <div>
                <LoadingOverlay
                    active={this.props.active}
                    spinner={<GridLoader/>}
                    styles={{
                        spinner: (base) => ({
                            ...base,
                            width: '100px!important',
                        })
                    }}
                    >
                    {this.props.children}
                </LoadingOverlay>
            </div>
        );
    }
}