import validator from 'validator'

const isValidCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/[^0-9]/g,'')
 
    if(cnpj === '') return false
     
    if (cnpj?.length !== 14)
        return false
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" || 
        cnpj === "11111111111111" || 
        cnpj === "22222222222222" || 
        cnpj === "33333333333333" || 
        cnpj === "44444444444444" || 
        cnpj === "55555555555555" || 
        cnpj === "66666666666666" || 
        cnpj === "77777777777777" || 
        cnpj === "88888888888888" || 
        cnpj === "99999999999999")
        return false
         
    // Valida DVs
    let tamanho = cnpj?.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2)
            pos = 9
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado !== parseInt(digitos.charAt(0)))
        return false
         
    tamanho = tamanho + 1
    numeros = cnpj.substring(0,tamanho);
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--
      if (pos < 2)
            pos = 9
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1)))
          return false
           
    return true
}

const isValidCPF = (strCPF) => {
    let Soma = 0
    let Resto
    strCPF = strCPF.replace(/[^0-9]/g,'')
    if (strCPF === "00000000000") return false
     
    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
    Resto = (Soma * 10) % 11
   
    if ((Resto === 10) || (Resto === 11))  Resto = 0
    if (Resto !== parseInt(strCPF.substring(9, 10)) ) return false
   
    Soma = 0
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)
    Resto = (Soma * 10) % 11
   
    if ((Resto === 10) || (Resto === 11))  Resto = 0
    if (Resto !== parseInt(strCPF.substring(10, 11) ) ) return false
    return true
}

export const requiredField = (val) => (!val || !val?.length) ? 'Este campo é obrigatório' : null;
export const objectField = (val) => (!val) ? 'Este campo é obrigatório' : null;
export const emailField = (val) => (!val || !validator.isEmail(val)) ? 'Digite um e-mail válido' : null;
export const phoneField = (val) => (!val || !/[0-9]{10,11}/.test(val.replace(/[^0-9]/g, ''))) ? 'Digite um telefone válido' : null;
export const cnpjField = (val) => (!val || !isValidCNPJ(val)) ? 'Digite um CNPJ válido' : null;
export const cpfField = (val) => (!val || !isValidCPF(val)) ? 'Digite um CPF válido' : null;
export const cepField = (val) => (!val || !/^[0-9]{5}[-]{0,1}[0-9]{3}$/.test(val)) ? 'Digite um CEP válido' : null;
export const dateField = (val) => (!val || typeof val !== "object") ? 'Digite/Selecione uma Data válida' : null;

/* Opcionais */
export const optionalEmailField = (val) => (val !== '' && !validator.isEmail(val)) ? 'Digite um e-mail válido' : null;
export const optionalPhoneField = (val) => (val !== '' && !/(\(?\d{2}\)?) ?9?\d{4}-?\d{4}/.test(val)) ? 'Digite um telefone válido' : null;
export const optionalDateField = (val) => (val !== '' && typeof val !== "object") ? 'Digite/Selecione uma Data válida' : null;