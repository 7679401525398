import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { dateField } from '../../validation/formValidation'
import ButtonPrimary from '../Buttons/ButtonPrimary'
import Calendar from 'react-datepicker'
import MaskedTextInput from "react-text-mask";
import pt from 'date-fns/locale/pt'

const FuncionarioModalForm = ({ name, onSubmit, defaultValue, onCancel }) => {
    return (
        <>
            Informe a data de demissão de <b>{name}</b>
            <br /> 
            <Formik
                initialValues={defaultValue}
                onSubmit={onSubmit}
                render={({ isSubmitting, handleSubmit, setFieldValue, values }) => (
                    <Form onSubmit={handleSubmit} className="mx-auto">
                        <div className="form-group d-flex justify-content-center align-items-center gap-1 mx-auto mt-4">
                            <Field
                                name="dataDemissao"
                                validate={dateField}>
                                {() => <Calendar
                                            name="dataDemissao"
                                            id='dataDemissao'
                                            locale={pt}
                                            className="form-control"
                                            showLeadingZeros={true}
                                            selected={values.dataDemissao}
                                            placeholderText="-- / -- / ----"
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => setFieldValue("dataDemissao", date)}
                                            customInput={
                                                <MaskedTextInput
                                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                />
                                            }
                                        />
                                }</Field>
                        </div>
                        <ErrorMessage name="dataDemissao" component="small" className="form-text text-danger" />
                        <div className='d-flex justify-content-center gap-1 w-full mt-4'>
                            <ButtonPrimary
                                className="btn-cancel swal2-styled"
                                disabled={isSubmitting}
                                onClick={onCancel}
                            >Cancelar</ButtonPrimary>
                            <ButtonPrimary 
                                className="btn-confirm swal2-styled"
                                type="submit"
                                disabled={isSubmitting}
                            >Confirmar</ButtonPrimary>

                        </div>
                    </Form>
                )}
            />
        </>
    )
}

export default FuncionarioModalForm