import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class IE extends Component {
    render() {
        return(
            <div style={{backgroundColor: '#E5E5E5', height: '100vh'}} className="d-flex w-100 align-items-center justify-content-center">
                <img src="/images/ie.jpg" className="img-fluid d-block mx-auto" alt="IE" title="ID"/>
            </div>
        )
    }
}
IE.propTypes = {
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(withRouter(IE));