import axios from "axios";
import {
  FETCH_ENVIO_BEGIN as BEGIN,
  FETCH_ENVIO_SUCCESS as SUCCESS,
  FETCH_ENVIO_FAILURE as FAILURE,
} from "./types";
import { URL_API } from "./urls";
import { initialState } from "../reducers/enviosReducer";
import { setAppData } from "./dados";
import {
  defaultClearData,
  defaultFetchBegin,
  defaultFetchSuccess,
  defaultFetchFailure,
} from "./default";
import { getCancelToken } from "../helpers/axiosCancel";

const model = "envio";

export const clearData = () => defaultClearData(SUCCESS, model, initialState);
export const fetchBegin = () => defaultFetchBegin(BEGIN);
export const fetchSuccess = (data) => defaultFetchSuccess(SUCCESS, model, data);
export const fetchFailure = (error) => defaultFetchFailure(FAILURE, error);
export const fetch =
  (dados, idDocumentoEnvio = 0) =>
  (dispatch) => {
    dispatch(fetchBegin());

    let params = {};
    if (dados.filial) {
      params.filial = dados.filial.idFilial;
    }
    if (dados.contrato) {
      params.contrato = dados.contrato.idContrato;
    }
    if (dados.funcionario) {
      params.funcionario = dados.funcionario.idFuncionario;
      params.filialFuncionario = dados.funcionario.idFilialFuncionario;
    }
    if (idDocumentoEnvio > 0) {
      params.idDocumentoEnvio = idDocumentoEnvio;
    }

    axios
      .get(
        URL_API +
          model +
          "/" +
          dados.documento.idDocumento +
          "?" +
          new URLSearchParams(params),
        { cancelToken: getCancelToken("envios/fetch") }
      )
      .then((res) => {
        const { data } = res.data;
        dispatch(fetchSuccess(data));

        let appData = {
          filial: dados.filial,
          documento: dados.documento,
          funcionario: dados.funcionario,
          envio: data,
        };
        dispatch(setAppData(appData));
        return data;
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }

        dispatch(fetchFailure(erro));
      });
  };
