import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "react-modal";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./RightPanel.css";

class RightPanel extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <SlidingPane
          className="right-panel-modal-tertium"
          overlayClassName="right-panel-modal-tertium-overlay"
          isOpen={this.props.isPaneOpen}
          title={this.props.title}
          closeIcon={
            <img
              src={"/images/times.png"}
              alt="Fechar"
              title="Fechar"
              className="img-fluid "
              style={{
                maxWidth: "20px",
                transform: "translateY(-2px)",
              }}
            />
          }
          onRequestClose={() => {
            let scr = document.documentElement.scrollTop;
            if (this.props.onClose) this.props.onClose();

            if (document.body.classList.contains("ReactModal__Body--open"))
              document.body.classList.remove("ReactModal__Body--open");

            window.history.replaceState(null, null, " ");
            document.documentElement.scrollTop = scr;
          }}
        >
          {this.props.children}
        </SlidingPane>
      </div>
    );
  }
}
RightPanel.propTypes = {
  isPaneOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  component: PropTypes.any,
};

Modal.setAppElement(document.getElementById("root"));

export default withRouter(RightPanel);
