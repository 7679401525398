import {
  FETCH_FUNCIONARIO_BEGIN,
  FETCH_FUNCIONARIO_SUCCESS,
  FETCH_FUNCIONARIO_FAILURE
} from "../actions/types";

export const initialFuncionarioState = {
  idFilialFuncionario: "",
  idFuncionario: "",
  nome: "",
  cpf: "",
  dataNascimento: null,
  filial: "",
  dataAdmissao: null,
  dataDemissao: null,
  telefone: "",
  email: "",
  status: 1
};

const initialState = {
  funcionario: initialFuncionarioState,
  loading: false,
  error: null
};

export default function funcionariosReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FUNCIONARIO_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };

    case FETCH_FUNCIONARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        funcionario: action.payload.funcionario
      };

    case FETCH_FUNCIONARIO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        funcionario: initialFuncionarioState
      };

    default:
      return state;
  }
}
