import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonGray from "../../Buttons/ButtonGray";

const AprovarDocumentosButton = ({
  funcionario,
  documentos = [],
  onPress,
}) => {
  return (
    <ButtonGray
      onClick={() => {
        if (onPress) onPress(funcionario, documentos);
      }}
      type="button"
      className="float-right py-1"
      title="Solicitar troca deste funcionário para outro contrato desta mesma empresa"
    >
      <small>
        <FontAwesomeIcon icon="check" />
        <span className="d-none d-md-inline-block ml-2">
          {" "}
          Aprovar Documentos
        </span>
      </small>
    </ButtonGray>
  );
};

export default AprovarDocumentosButton;
