/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import ReactTable from "react-table"
import "react-table/react-table.css"

/* Métodos / Ações */
import { changeUsuarioDepartamento } from '../../actions/departamentos'
import { fetchList as fetchUsuarios } from '../../actions/usuarios'
import { clearErrors } from '../../actions/errors'

/* Componentes */
import Error from '../Error'

/* Botões */

/* Campos */

/* Validação */

/* Cores */
import { COLOR_PRIMARY } from '../../Colors'

const MySwal = withReactContent(Swal)

class DepartamentoFormUsuarios extends Component {
    constructor() {
        super()
        
        this._isMounted = false
        this.state = {
            rows: [],
            pages: null,
            loading: true,
            pageSize: null,
            page: null,
            sorted: null,
            filtered: [],
        }

        this.fetchData = this.fetchData.bind(this)
        this.requestData = this.requestData.bind(this)
        this.changeInDepartamento = this.changeInDepartamento.bind(this)
    }

    changeInDepartamento(idUsuario, inDepartamento){
        this.props.dispatch(changeUsuarioDepartamento(idUsuario, inDepartamento, this.props.id, this.requestData))
    }

    requestData() {
        let reqData = {
            pageSize: this.state.pageSize,
            page: this.state.page,
            sort: this.state.sorted,
            filter: this.state.filtered
        }
        return new Promise((resolve, reject) => {
            let filtered = reqData.filter
            if(filtered){
                let existsFilter = false
                for(let i = 0; i < filtered?.length; i ++){
                    if(filtered[i].id === 'departamento'){
                        existsFilter = true
                        filtered[i].value = this.props.id
                    }
                }

                if(!existsFilter)
                    filtered.push({
                        id: 'departamento',
                        value: this.props.id
                    })
            } else {
                filtered = [{
                    id: 'departamento',
                    value: this.props.id
                }]
            }
            reqData.filter = filtered

            fetchUsuarios(reqData, resolve, reject, this.props.dispatch)
        }).then(res => {
            if (this._isMounted) {
                this.setState({
                    rows: res.rows,
                    pages: res.pages,
                    loading: false
                })
            }
        }).catch(err => {
            if (this._isMounted) {
                this.setState({
                    rows: [],
                    pages: 0,
                    loading: false
                })
            }
        })
    }

    fetchData(state, instance) {
        this.setState({ 
            loading: true,
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
            //filtered: state.filtered,
        },()=>{
            this.requestData()
        })
    } 

    handleSubmit(values, actions) {
        //this.props.dispatch(saveFilial(values, actions, this.props.onAfterSave))
    }

    componentDidMount() {
        this._isMounted = true
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            let show = false;
            if(Object.keys(nextProps.errors)?.length){
                show = true
            }
            /*this.setState({
                errors: nextProps.errors
            })*/
            if(show){
                MySwal.fire({
                    type: 'error',
                    title: nextProps.errors.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                this.props.dispatch(clearErrors())
            }
        }
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>
        }

        return(
            <div className="pb-5">
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-12 px-4">
                        <div className="card">
                            <div className="card-body px-2">
                                <h5 style={{color: COLOR_PRIMARY}}>{this.props.nome}</h5>
                                <ReactTable
                                    defaultSorted={[{
                                        id   : 'nome',
                                        desc : false,
                                    }]}
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }} onDoubleClick={()=>this.handleOpen(props.original.idUsuario)}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "Nome",
                                            accessor: "nome",
                                            headerClassName: 'nome-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: (props) => <div className="d-flex align-items-center">
                                                <Toggle
                                                defaultChecked={parseInt(props.original.checked) === 1}
                                                className="mr-3"
                                                onChange={(evt)=>{
                                                    this.changeInDepartamento(props.original.idUsuario, evt.target.checked)
                                                }} />{' '+props.original.nome}
                                            </div>,
                                        },
                                    ]}
                                    manual
                                    multiSort={false}
                                    data={this.state.rows}
                                    pages={this.state.pages}
                                    loading={this.state.loading}
                                    onFetchData={(state, instance)=>this.fetchData(state, instance)}
                                    filterable
                                    filtered={this.state.filtered}
                                    onFilteredChange={filtered => {
                                        this.setState({ filtered })
                                    }}
                                    previousText={<FontAwesomeIcon icon="chevron-left" />}
                                    nextText={<FontAwesomeIcon icon="chevron-right" />}
                                    resizable={false}
                                    loadingText="Carregando..."
                                    noDataText="Nenhum usuário encontrado"
                                    pageText="Página"
                                    ofText="de"
                                    rowsText="linhas"
                                    pageJumpText="ir para a página"
                                    rowsSelectorText="linhas por página"
                                    pageSizeOptions={[5, 10, 20, 50]}
                                    defaultPageSize={10}
                                    minRows={0}
                                    className="-highlight"
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
DepartamentoFormUsuarios.propTypes = {
    id: PropTypes.any,
    errors: PropTypes.object.isRequired,
    nome: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    errors: state.errors,
    id: state.modal.id
})

export default connect(mapStateToProps)(withRouter(DepartamentoFormUsuarios));