import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FETCH_FILIAL_BEGIN as BEGIN, FETCH_FILIAL_SUCCESS as SUCCESS, FETCH_FILIAL_FAILURE as FAILURE, GET_ERRORS } from './types'
import { setModalOpen } from './modal'
import { URL_API } from './urls'
import { initialState } from '../reducers/filiaisReducer'
import { defaultClearData, defaultFetchList, defaultFetchBegin, defaultFetchSuccess, defaultFetchFailure, defaultFetch } from './default'

const MySwal = withReactContent(Swal)

const model_list = 'filiais'
const model = 'filial'

export const fetchList = (reqData, resolve, reject, dispatch) => defaultFetchList(model_list, reqData, resolve, reject, dispatch)
export const clearData = () => defaultClearData(SUCCESS, model, initialState)
export const fetchBegin = () => defaultFetchBegin(BEGIN)
export const fetchSuccess = data => defaultFetchSuccess(SUCCESS, model, data)
export const fetchFailure = error => defaultFetchFailure(FAILURE, error)
export const fetch = (id = '') => defaultFetch(model, fetchBegin, fetchSuccess, fetchFailure, id)

export const saveFilial = (filial, actions, afterSave) => dispatch => {
    const cidade = filial.cidade
    filial.cidade = filial.cidade.idCidade
    axios.post(URL_API + 'filial', filial)
        .then(res => {
            filial.cidade = cidade
            MySwal.fire({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Filial salva com sucesso'
            })
            actions.setSubmitting(false)

            dispatch(setModalOpen(''))
            if(afterSave)
                afterSave()
        })
        .catch(err => {
            filial.cidade = cidade
            if(err.response){
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                })
                actions.setErrors(err.response.data.errors)
            }
            actions.setSubmitting(false)
        })
}

export const deleteFilial = (idFilial, afterDelete, afterError) => dispatch => {
    axios.delete(URL_API + 'filial/' + idFilial)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Filial excluída com sucesso'
            })
            dispatch(setModalOpen(''))
            if(afterDelete)
                afterDelete()
        })
        .catch(err => {
            if(err.response){
                MySwal.fire({
                    type: 'error',
                    title: err.response.data,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                if(afterError)
                    afterError()
            }
        })
}

export const changeStatusFilial = (idFilial, status = 0, afterChange, afterError) => dispatch => {
    axios.put(URL_API + 'filial/' + idFilial + '/' + status)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Filial '+(status === 0 ? 'bloqueada' : 'ativada')+' com sucesso'
            })
            dispatch(setModalOpen(''))
            if(afterChange)
                afterChange()
        })
        .catch(err => {
            if(err.response){
                MySwal.fire({
                    type: 'error',
                    title: err.response.data,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                if(afterError)
                    afterError()
            }
        })
}

export const loadFiliaisOptions = inputValue => {
    return new Promise((resolve, reject) => {
        axios.get(URL_API+'filiais?value='+inputValue)
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
            let erro = "Não foi possível obter as filiais"
            if(err.response){
                erro = err.response
            }
            reject(erro)
        })
    })
}