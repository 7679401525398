import axios from 'axios'
import { URL_API } from './urls'

export const loadCidadesOptions = inputValue => {
    return new Promise((resolve, reject) => {
        axios.get(URL_API+'cidades?value='+inputValue)
        .then(res => {
            resolve(res.data)
        })
        .catch(err => {
            let erro = "Não foi possível obter as cidades"
            if(err.response){
                erro = err.response
            }
            reject(erro)
        })
    })
}
