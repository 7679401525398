export const COLOR_PRIMARY = "#ff6700"
export const COLOR_ORANGE = "#ff5818"
export const COLOR_WARNING = "#f19b35"
export const COLOR_INFO = "#39bbd2"
export const COLOR_DARK = "#4d4d4d"
export const COLOR_DARK2 = "#343434"
export const COLOR_GRAY = "#686868"
export const COLOR_GRAY2 = "#858585"
export const COLOR_VERY_LIGHT = "#f9f9f9"
export const COLOR_LIGHT = "#dbdbdb"
export const COLOR_LIGHT_GREEN = "#42dabf"
export const COLOR_GREEN = "#2bb442"
export const COLOR_LIGHT_BLUE = "#35bae9"
export const COLOR_VERY_LIGHT_BLUE = "#ebf8fa"
export const COLOR_DANGER = "#e54c30"