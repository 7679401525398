import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { URL_API } from "./urls";
import {
  FETCH_DOCUMENTOS_BEGIN as FETCH_BEGIN_LIST,
  FETCH_DOCUMENTOS_SUCCESS as FETCH_SUCCESS_LIST,
  FETCH_DOCUMENTOS_FAILURE as FETCH_FAILURE_LIST,
  FETCH_DOCUMENTOS_FUNCIONARIOS_BEGIN,
  FETCH_DOCUMENTOS_FUNCIONARIOS_SUCCESS,
  FETCH_DOCUMENTOS_FUNCIONARIOS_FAILURE,
  FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_BEGIN,
  FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_SUCCESS,
  FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_FAILURE,
  FETCH_DOCUMENTOS_CONTRATO_BEGIN,
  FETCH_DOCUMENTOS_CONTRATO_SUCCESS,
  FETCH_DOCUMENTOS_CONTRATO_FAILURE,
  GET_ERRORS,
  FETCH_DOCUMENTOS_FUNCIONARIOS_UPDATE,
  FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_UPDATE,
} from "./types";
import { fetch } from "./envios";
import { setModalOpen } from "./modal";
import { getCancelToken } from "../helpers/axiosCancel";
import objToQueryString from "../helpers/objToQueryString";
import { refreshExportacao } from "../helpers/exportacao";

const MySwal = withReactContent(Swal);

const model_list = "documentos";

export function fetchDocumentosDepartamento(
  reqData,
  resolve,
  reject,
  dispatch
) {
  axios
    .post(URL_API + "documentos_departamento", reqData)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      let erro = {
        message: "Não foi possível obter os dados dos documentos",
      };
      if (err.response) {
        erro = err.response.data;
      }
      dispatch({
        type: GET_ERRORS,
        payload: { message: erro },
      });
      reject(erro.message);
    });
}

export const clearList = () => ({
  type: FETCH_SUCCESS_LIST,
  payload: { [model_list]: [] },
});

export const fetchListBegin = () => ({
  type: FETCH_BEGIN_LIST,
});

export const fetchListSuccess = (data) => ({
  type: FETCH_SUCCESS_LIST,
  payload: { [model_list]: data },
});

export const fetchListFailure = (error) => ({
  type: FETCH_FAILURE_LIST,
  payload: { error },
});

export const fetchListFuncionariosBegin = () => ({
  type: FETCH_DOCUMENTOS_FUNCIONARIOS_BEGIN,
});

export const fetchListFuncionariosSuccess = (data) => ({
  type: FETCH_DOCUMENTOS_FUNCIONARIOS_SUCCESS,
  payload: { funcionarios: (data || []).map(f => ({ ...f, nome: f.nome.toUpperCase() })) },
});

export const fetchListFuncionariosUpdate = (data) => ({
  type: FETCH_DOCUMENTOS_FUNCIONARIOS_UPDATE,
  payload: { funcionarios: (data || []).map(f => ({ ...f, nome: f.nome.toUpperCase() })) },
});

export const fetchListFuncionariosFailure = (error) => ({
  type: FETCH_DOCUMENTOS_FUNCIONARIOS_FAILURE,
  payload: { error },
});

export const fetchListFuncionariosRemovidosBegin = () => ({
  type: FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_BEGIN,
});

export const fetchListFuncionariosRemovidosSuccess = (data) => ({
  type: FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_SUCCESS,
  payload: { funcionariosRemovidos: data },
});

export const fetchListFuncionariosRemovidosUpdate = (data) => ({
  type: FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_UPDATE,
  payload: { funcionariosRemovidos: (data || []).map(f => ({ ...f, nome: f.nome.toUpperCase() })) },
});

export const fetchListFuncionariosRemovidosFailure = (error) => ({
  type: FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_FAILURE,
  payload: { error },
});

export const fetchListContratoBegin = () => ({
  type: FETCH_DOCUMENTOS_CONTRATO_BEGIN,
});

export const fetchListContratoSuccess = (data) => ({
  type: FETCH_DOCUMENTOS_CONTRATO_SUCCESS,
  payload: { contrato: data },
});

export const fetchListContratoFailure = (error) => ({
  type: FETCH_DOCUMENTOS_CONTRATO_FAILURE,
  payload: { error },
});

export function fetchList(reqData, reset = true) {
  return (dispatch) => {
    if (reset) dispatch(fetchListBegin());

    axios
      .post(URL_API + "documentos", reqData, {
        cancelToken: getCancelToken("documentos/fetchList"),
      })
      .then((res) => {
        dispatch(fetchListSuccess(res.data.rows));
        return res.data.rows;
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }
        dispatch(fetchListFailure(erro));
      });
  };
}

export function fetchContratoList(reqData) {
  if (typeof reqData !== "object") {
    reqData = {};
  }
  let tmpRequest = Object.assign({}, reqData);
  tmpRequest.tipo = "CON";
  return (dispatch) => {
    dispatch(fetchListContratoBegin());

    axios
      .post(URL_API + "documentos", tmpRequest, {
        cancelToken: getCancelToken("documentos/fetchContratoList"),
      })
      .then((res) => {
        dispatch(fetchListContratoSuccess(res.data.rows));
        return res.data.rows;
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }
        dispatch(fetchListContratoFailure(erro));
      });
  };
}

export async function getContratoList(reqData = {}) {
  const tmpRequest = Object.assign({}, reqData);
  tmpRequest.tipo = "CON";

  try {
    const { data } = await axios
      .post(URL_API + "documentos", tmpRequest, {
        cancelToken: getCancelToken("documentos/getContratoList"),
      });
    return data.rows;
  } catch (err) {
    if (axios.isCancel(err)) return;

    let erro = { message: "Não foi possível obter os dados" };
    if (err.response) {
      erro = err.response.data;
    }
    MySwal.fire({
      toast: true,
      showConfirmButton: false,
      timer: 3000,
      type: "error",
      title: erro.message,
    });
  }
}

let lastQueryDocumentosFuncionarios = "";
export function fetchFuncionariosList(reqData, reset = true, callback) {
  const uri = URL_API + "documentos-funcionarios";
  const cancelToken = "documentos/fetchFuncionariosList";
  return (dispatch) => {
    const query = objToQueryString(reqData);
    if (query === lastQueryDocumentosFuncionarios && !reset) return;
    lastQueryDocumentosFuncionarios = query;

    if (reset) dispatch(fetchListFuncionariosBegin());

    axios
      .post(uri, reqData, {
        cancelToken: getCancelToken(cancelToken),
      })
      .then((res) => {
        const list = res.data.rows;
        dispatch(fetchListFuncionariosSuccess(list));
        if (callback) callback(list);
        return res.data.pages > 1
          ? nextPageList(uri, cancelToken, dispatch, fetchListFuncionariosSuccess, reqData, list, res.data.pages, 1, callback)
          : list;
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }

        dispatch(fetchListFuncionariosFailure(erro));
      });
  };
}

export function fetchDocumentosFuncionario(reqData = {}) {
  const uri = URL_API + "documentos-funcionarios";
  return (dispatch) => {
    axios
      .post(uri, reqData)
      .then((res) => {
        const list = res.data.rows;
        dispatch(fetchListFuncionariosUpdate(list));
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }

        dispatch(fetchListFuncionariosFailure(erro));
      });
  };
}

let lastQueryDocumentosFuncionariosRemovidos = "";
export function fetchFuncionariosRemovidosList(reqData, reset = true) {
  const uri = URL_API + "documentos-funcionarios-removidos";
  const cancelToken = "documentos/fetchFuncionariosRemovidosList";

  return (dispatch) => {
    const query = objToQueryString(reqData);
    if (query === lastQueryDocumentosFuncionariosRemovidos && !reset) return;
    lastQueryDocumentosFuncionariosRemovidos = query;

    if (reset) dispatch(fetchListFuncionariosRemovidosBegin());

    axios
      .post(uri, reqData, {
        cancelToken: getCancelToken(cancelToken),
      })
      .then((res) => {
        const list = res.data.rows;
        dispatch(fetchListFuncionariosRemovidosSuccess(list));
        return res.data.pages > 1
          ? nextPageList(uri, cancelToken, dispatch, fetchListFuncionariosRemovidosSuccess, reqData, list, res.data.pages, 1)
          : list;
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }

        dispatch(fetchListFuncionariosRemovidosFailure(erro));
      });
  };
}

export function fetchDocumentosFuncionarioRemovido(reqData = {}) {
  const uri = URL_API + "documentos-funcionarios-removidos";
  return (dispatch) => {
    axios
      .post(uri, reqData)
      .then((res) => {
        const list = res.data.rows;
        dispatch(fetchListFuncionariosRemovidosUpdate(list));
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;

        let erro = { message: "Não foi possível obter os dados" };
        if (err.response) {
          erro = err.response.data;
        }

        dispatch(fetchListFuncionariosRemovidosFailure(erro));
      });
  };
}

const nextPageList = (uri, cancelToken, dispatch, fetchSuccess, filter, list, totalPages, page, callback = null) => {
  const ct = getCancelToken(cancelToken);
  return axios
    .post(uri, {
      ...filter,
      page,
    })
    .then((res) => {
      const results = res.data.rows;

      if (!results?.length) {
        return list;
      }

      list = [...list, ...results];

      if (ct.reason) return list;

      dispatch(fetchSuccess(list));
      if (callback) callback(list)

      if (page === totalPages) return list;

      return nextPageList(uri, cancelToken, dispatch, fetchSuccess, filter, list, totalPages, page + 1);
    })
    .catch((err) => {
      if (ct.reason) return;
    });
};

export async function fetchDocumentosFuncionariosList(reqData) {
  try {
    return axios
      .post(URL_API + "documentos-funcionarios", reqData, {
        cancelToken: getCancelToken("documentos/fetchDocumentosFuncionariosList"),
      })
      .then((res) => {
        return res.data.rows;
      });
  } catch (err) {
    console.error(err)
    throw new Error('Não foi possível obter documentos do funcionário')
  }
}

export const loadFiliaisDocumentosOptions = (inputValue) => {
  return new Promise((resolve, reject) => {
    axios
      .get(URL_API + "filiais_documentos?value=" + inputValue, {
        cancelToken: getCancelToken("documentos/loadFiliaisDocumentosOptions"),
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        let erro = "Não foi possível obter as filiais";
        if (err.response) {
          erro = err.response;
        }
        reject(erro);
      });
  });
};

export const uploadDocumento =
  (upload, dados, actions, avaliacao = null, contrato = null, afterUpload) =>
  (dispatch) => {
    if (upload.arquivo === "") {
      MySwal.fire({
        type: "error",
        title: "Selecione um arquivo PDF",
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      actions.setSubmitting(false);
      return;
    }
    var formData = new FormData();
    Object.keys(upload).forEach(function (key) {
      formData.append(key, upload[key]);
    });

    if (dados.filial) formData.append("filial", dados.filial.idFilial);
    formData.append("documento", dados.documento.idDocumento);
    if (dados.envio.idDocumentoEnvio && dados.contrato) {
      // Por enquanto só permite reenvio para um contrato somente
      formData.append("idDocumentoEnvio", dados.envio.idDocumentoEnvio);
    }
    if (dados.funcionario) {
      formData.append("funcionario", dados.funcionario.idFuncionario);
      formData.append("filialFuncionario", dados.funcionario.idFilialFuncionario);
    }
    if (contrato || dados.contrato) {
      if (dados.contrato) {
        formData.append("contrato", dados.contrato.idContrato);
      } else if (contrato) {
        formData.append("contrato", contrato);
      }
    }
    if (avaliacao) {
      formData.append("avaliacao", true);
    }

    axios
      .post(URL_API + "upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        MySwal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: "Arquivo enviado com sucesso",
        });
        actions.setSubmitting(false);
        dispatch(setModalOpen(""));
        dispatch(fetch(dados));
        if (afterUpload) {
          afterUpload();
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch({
          type: GET_ERRORS,
          payload: { message: err.response?.data || { message: "Houve um problema no envio do documento" } },
        });
        actions.setErrors(err.response?.data?.errors || []);
        actions.setSubmitting(false);
      });
  };

export const saveDocumento = (documento, afterSave) => (dispatch) => {
  axios
    .post(URL_API + "documento", documento)
    .then((res) => {
      MySwal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        type: "success",
        title: "Documento salvo com sucesso",
      });

      if (afterSave) {
        afterSave();
      }
    })
    .catch((err) => {
      MySwal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        type: "error",
        title: "Não foi possível salvar o Documento",
      });
    });
};

export const avaliacaoDocumento =
  (dados, st = 1, justificativa = null, afterSave = () => {}) =>
  (dispatch) => {
    var formData = new FormData();

    formData.append("filial", dados.filial.idFilial);
    formData.append("documento", dados.documento.idDocumento);
    if (dados.envio.idDocumentoEnvio) {
      formData.append("idDocumentoEnvio", dados.envio.idDocumentoEnvio);
    }
    if (dados.funcionario) {
      formData.append("funcionario", dados.funcionario.idFuncionario);
    }
    if (dados.contrato) {
      formData.append("contrato", dados.contrato.idContrato);
    }
    formData.append("status", st);
    formData.append("justificativa", justificativa);

    axios
      .post(URL_API + "avaliacao", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        MySwal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: "Avaliação feita com sucesso",
        });
        afterSave();
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: { message: err.response.data },
        });
      });
  };

export const bulkDownload = ({ dispatch, idContrato, onlyApproved = false }) => {
    axios
      .post(URL_API + "documentos/lote-download/gerar", {
          contrato: idContrato,
          apenasAprovados: onlyApproved
      })
      .then(() => {
        MySwal.fire({
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: "Solicitação de download de documentos criada com sucesso.",
        });

        dispatch(refreshExportacao())
      })
      .catch((err) => {
        const message = typeof err.response.data === "string"
          ? err.response.data
          : "Houve um erro ao enviar a solicitação de download de documentos do contrato."

        MySwal.fire({
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          type: "error",
          title: message,
        });
      });
  };

export const dismissDocument = (payload, afterSave, showSucessMessage = true) => (dispatch) =>
  axios
    .post(URL_API + "documento-funcionario-dispensar", payload)
    .then((res) => {
      if (showSucessMessage) {
        MySwal.fire({
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
          type: "success",
          title: "Documento salvo com sucesso",
        });
      }

      if (afterSave) {
        afterSave();
      }
    })
    .catch((err) => {
      console.error(err);
      MySwal.fire({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        type: "error",
        title: "Não foi possível salvar o Documento",
      });
    });

export const portContractDocuments = (data = {}, afterPortDocuments) => (dispatch) => {
    axios
        .post(URL_API + "documentos/portar-documentos", data)
        .then((res) => {
            if (afterPortDocuments) afterPortDocuments();
            if (res.data.quantidade > 0) {
                MySwal.fire({
                  toast: true,
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 3000,
                  type: "success",
                  title: "Documentos portados com sucesso",
                });
                return;
            }

            MySwal.fire({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              type: "error",
              title: "Nenhum documento aprovado disponivel para portabilidade",
            });
        })
        .catch((err) => {
            MySwal.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                type: "error",
                title: err?.response ? err.response.data : "Não foi possível portar os documentos",
            });
        });
};
