import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Chart from "react-apexcharts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

/* Componentes */
import LoadingImage from "../LoadingImage";
import RightPanel from "../Sidepanel/RightPanel";
import ContratoForm from "../Contrato/ContratoForm";
import ContratoDocumentos from "../Contrato/ContratoDocumentos";
import ContratoFuncionarios from "../Contrato/ContratoFuncionarios";
import ResendEmailForm from "./ResendEmailForm";

/* Métodos / Ações */
import {
  fetchList as fetchDocumentosList,
  fetchFuncionariosList as fetchDocumentosFuncionariosList,
} from "../../actions/documentos";
import {
  fetchList as fetchContratosList,
  clearData,
  deleteData,
  changeStatus,
  fetch,
  resendEmailCadastro,
} from "../../actions/contratos";
import { fetchFuncionarios as fetchFuncionariosList } from "../../actions/funcionarios";
import { setModalOpen, setModalId } from "../../actions/modal";
import { setAppData } from "../../actions/dados";
import {
  montaGraficoDocumentos_Home,
  montaGraficoDocumentosFuncionarios_Home,
} from "../../helpers/graficos";
import { clearFuncionarioId } from "../../actions/scroll";

/* Cores */
import {
  COLOR_DARK2,
  COLOR_WARNING,
  COLOR_GREEN,
  COLOR_INFO,
  COLOR_DANGER,
  COLOR_PRIMARY,
} from "../../Colors";

/* Botões */
import ButtonPrimary from "../Buttons/ButtonPrimary";
import ButtonSuccess from "../Buttons/ButtonSuccess";

import "./PainelInicial.css";
import { EditarContratoButton } from "../Contrato/Buttons/EditarContratoButton";
import { AbreGestaoContratoButton } from "../Contrato/Buttons/AbreGestaoContratoButton";
import AlertSemPlano from "../Contrato/AlertSemPlano";
import { ApagarContratoButton } from "../Contrato/Buttons/ApagarContratoButton";
import { STATUS } from "../../types/StatusContrato";
import { setRefresh, REFRESH_KEY } from "../../actions/refresh";

const MySwal = withReactContent(Swal);

const SORT_CONTRATOS = [
  { id: "empresaStatus", desc: false },
  { id: "idContrato", desc: true },
];
class PainelInicial extends Component {
  constructor() {
    super();

    this._isMounted = false;
    this.state = {
      ultimosFilter: null,
      contrato: {
        rows: [],
        pages: null,
        loading: false,
        pageSize: 5,
        page: 1,
        sort: SORT_CONTRATOS,
        filtered: [],
        statusFilter: null,
      },
      funcionario: {
        rows: [],
        pages: null,
        loading: false,
        pageSize: 5,
        page: 1,
        sorted: null,
        filtered: null,
        statusFilter: null,
      },
      documento: {
        pageSize: 5,
      },
      contratoAguardandoCadastro: [],
    };

    this.handleCreateContract = this.handleCreateContract.bind(this);
  }

  handleUltimosFilter = (aba) => {
    this.setState({
      ultimosFilter: aba,
    });
  };

  requestContratosData = () => {
    let contrato = this.state.contrato;
    let reqData = {
      pageSize: contrato.pageSize,
      page: contrato.page,
      sort: SORT_CONTRATOS,
      filter: [],
    };

    return new Promise((resolve, reject) =>
      fetchContratosList(reqData, resolve, reject, this.props.dispatch)
    )
      .then((res) => {
        if (this._isMounted) {
          contrato.rows = res.rows;
          contrato.pages = res.pages;
          contrato.loading = false;
          this.setState({ contrato });
        }
      })
      .catch((err) => {
        if (this._isMounted) {
          contrato.rows = [];
          contrato.pages = 0;
          contrato.loading = false;
          this.setState({ contrato });
        }
      });
  };

  requestContratoData = async (id) => {
    if (
      this.state.contratoAguardandoCadastro.length === 0 ||
      this.state.contratoAguardandoCadastro.some(
        (item) => item.idContrato !== id
      )
    ) {
      const res = await this.props.dispatch(fetch(id));

      this.setState({
        contratoAguardandoCadastro: [
          ...this.state.contratoAguardandoCadastro,
          res,
        ],
      });
    }
  };

  requestFuncionariosData = () => {
    let funcionario = this.state.funcionario;
    let reqData = {
      pageSize: funcionario.pageSize,
      page: funcionario.page,
      sort: funcionario.sorted,
      filter: funcionario.filtered,
    };
    return new Promise((resolve, reject) => {
      fetchFuncionariosList(reqData, resolve, reject, this.props.dispatch);
    })
      .then((res) => {
        if (this._isMounted) {
          funcionario.rows = res.rows;
          funcionario.pages = res.pages;
          funcionario.loading = false;
          this.setState({ funcionario });
        }
      })
      .catch((err) => {
        if (this._isMounted) {
          funcionario.rows = [];
          funcionario.pages = 0;
          funcionario.loading = false;
          this.setState({ funcionario });
        }
      });
  };

  fetchContratosData = (state, instance) => {
    let contrato = this.state.contrato;
    contrato.loading = true;
    contrato.page = state.page;
    contrato.pageSize = state.pageSize;
    contrato.sorted = state.sorted;
    contrato.filtered = state.filtered;
    this.setState(
      {
        contrato,
      },
      () => {
        this.requestContratosData();
      }
    );
  };

  fetchFuncionariosData = (state, instance) => {
    let funcionario = this.state.funcionario;
    funcionario.loading = true;
    funcionario.page = state.page;
    funcionario.pageSize = state.pageSize;
    funcionario.sorted = state.sorted;
    funcionario.filtered = state.filtered;
    this.setState(
      {
        funcionario,
      },
      () => {
        this.requestFuncionariosData();
      }
    );
  };

  componentDidMount() {
    this._isMounted = true;

    this.props.dispatch(fetchDocumentosList());
    this.props.dispatch(fetchDocumentosFuncionariosList());
    this.props.dispatch(clearFuncionarioId());
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleOpenContrato = (props) => {
    this.props.dispatch(setModalOpen("edit"));
    this.props.dispatch(setModalId(props.idContrato));
  };

  handleOpenResend = (id, email) => {
    return MySwal.fire({
      title: "",
      type: "info",
      html: (
        <ResendEmailForm
          onSubmit={(val) => {
            if (val.email) {
              this.props.dispatch(resendEmailCadastro(id, val.email));
            }

            Swal.clickConfirm();
          }}
          defaultValue={{ email }}
        />
      ),
      showCancelButton: false,
      showConfirmButton: false,
    });
  };

  handleSaveLinkToClipboard = (hashCadastro) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/quero-me-cadastrar/${hashCadastro}`
    );

    MySwal.fire({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 3000,
      type: "success",
      title: "Link copiado.",
    });
  };

  abreGestao = (filter) => {
    this.props.dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
    this.props.dispatch(setAppData({ filtros: filter }));
    this.props.history.push("/gestao-documentos/");
  };

  handleContratoModalDocumentosOpen = (props) => {
    this.props.history.push("/contratos/");
    this.props.dispatch(setModalOpen("documentos"));
    this.props.dispatch(setModalId(props.idContrato));
  };

  handleContratoModalFuncionariosOpen = (props) => {
    this.props.history.push("/contratos/");
    this.props.dispatch(setModalOpen("funcionarios"));
    this.props.dispatch(setModalId(props.idContrato));
  };

  handleOpenFuncionario(id) {
    this.props.history.push("/funcionarios/");
    this.props.dispatch(setModalOpen("edit"));
    this.props.dispatch(setModalId(id));
  }

  handleOpenDocumento(documento, funcionario = null) {
    let filialFilter = this.props.usuarioLogado.filial;

    this.props.dispatch(
      setAppData({
        filial: filialFilter,
        funcionario: funcionario,
        documento: documento,
        contrato: null,
      })
    );
    this.props.history.push("/documentos/envios/");
  }

  handleDeleteContrato(contrato) {
    let titulo = contrato.nome;
    let idContrato = contrato.idContrato;
    MySwal.fire({
      title: "",
      type: "warning",
      html:
        "Deseja realmente excluir o contrato <b>" +
        titulo +
        "</b>, essa ação não pode ser revertida?",
      showCancelButton: true,
      confirmButtonText: "Sim, excluir",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.props.dispatch(
          deleteData(
            idContrato,
            () => {
              window.location.reload();
            },
            () => {}
          )
        );
      }
    });
  }

  handleChangeStatusContrato(original) {
    this.props.dispatch(
      changeStatus(
        original.idContrato,
        STATUS.ATIVO,
        () => {
          this.abreGestao({
            filial: {
              nome: original.filial,
              idFilial: original.idFilial,
            },
            empresa: {
              nome: original.empresa,
              idFilial: parseInt(original.idEmpresa),
            },
            contrato: { ...original, status: STATUS.ATIVO },
          });
        },
        () => {}
      )
    );
  }

  handleCreateContract() {
    if (!this.props?.usuarioLogado?.planoCriarContratos) {
      return MySwal.fire({
        title: "",
        type: "question",
        html: <AlertSemPlano />,
        showCancelButton: false,
      });
    }

    this.props.dispatch(clearData());
    this.props.dispatch(setModalOpen("new"));
  }

  render() {
    const graficoDocumento = montaGraficoDocumentos_Home(this.props.documentos);
    const graficoDocumentoFuncionarios =
      montaGraficoDocumentosFuncionarios_Home(
        this.props.documentosFuncionarios
      );

    let ultimosFilter = this.state.ultimosFilter;

    if (
      ultimosFilter === null &&
      this.props.permissoes &&
      (this.props.permissoes.permissoesGerais.indexOf("contratos") !== -1 ||
        this.props.permissoes.administradorEmpresa === 1 ||
        this.props.permissoes.administradorFilial === 1)
    ) {
      ultimosFilter = "contratos";
    } else if (
      ultimosFilter === null &&
      this.props.permissoes &&
      this.props.permissoes.permissoesGerais.indexOf("documentos") !== -1
    ) {
      ultimosFilter = "documentos";
    } else if (
      ultimosFilter === null &&
      this.props.permissoes &&
      this.props.permissoes.permissoesGerais.indexOf("funcionarios") !== -1
    ) {
      ultimosFilter = "funcionarios";
    }

    return (
      <>
        {(this.state.contrato.loading ||
          this.state.funcionario.loading ||
          this.props.documentosLoading) && <LoadingImage></LoadingImage>}
        <div
          className={
            this.state.contrato.loading ||
            this.state.funcionario.loading ||
            this.props.documentosLoading
              ? "d-none py-5"
              : "py-5"
          }
        >
          <h2
            className="text-center font-weight-400"
            style={{ color: COLOR_DARK2 }}
          >
            Seja bem vindo ao seu painel inicial
          </h2>

          <div className="col-12 px-2 px-lg-5">
            <div className="row mx-0 mb-4 pt-4 row-table-filters">
              <span
                className="col-auto pb-3 px-0 pr-3 mr-5"
                style={{ color: COLOR_PRIMARY }}
              >
                ÚLTIMOS ADICIONADOS
              </span>
              {this.props.permissoes &&
                (this.props.permissoes.permissoesGerais.indexOf("contratos") !==
                  -1 ||
                  this.props.permissoes.administradorEmpresa === 1 ||
                  this.props.permissoes.administradorFilial === 1) && (
                  <button
                    type="button"
                    className={
                      "col-auto pb-3 px-0 mr-5" +
                      (ultimosFilter === "contratos" ? " active" : "")
                    }
                    onClick={() => {
                      this.handleUltimosFilter("contratos");
                    }}
                  >
                    Contratos
                  </button>
                )}
              {!this.props.usuarioLogado?.sem_plano &&
                this.props.permissoes &&
                (this.props.permissoes.permissoesGerais.indexOf(
                  "documentos"
                ) !== -1 ||
                  this.props.permissoes.administradorEmpresa === 1 ||
                  this.props.permissoes.administradorFilial === 1) && (
                  <button
                    type="button"
                    className={
                      "col-auto pb-3 px-0 mr-5" +
                      (ultimosFilter === "documentos" ? " active" : "")
                    }
                    onClick={() => {
                      this.handleUltimosFilter("documentos");
                    }}
                  >
                    Documentos
                  </button>
                )}
              {this.props.permissoes &&
                (this.props.permissoes.permissoesGerais.indexOf(
                  "funcionarios"
                ) !== -1 ||
                  this.props.permissoes.administradorEmpresa === 1 ||
                  this.props.permissoes.administradorFilial === 1) && (
                  <button
                    type="button"
                    className={
                      "col-auto pb-3 px-0 mr-5" +
                      (ultimosFilter === "funcionarios" ? " active" : "")
                    }
                    onClick={() => {
                      this.handleUltimosFilter("funcionarios");
                    }}
                  >
                    Funcionários
                  </button>
                )}
            </div>
            {ultimosFilter === "contratos" &&
              this.props.permissoes &&
              (this.props.permissoes.permissoesGerais.indexOf("contratos") !==
                -1 ||
                this.props.permissoes.administradorEmpresa === 1 ||
                this.props.permissoes.administradorFilial === 1) && (
                <>
                  <ReactTable
                    defaultSorted={SORT_CONTRATOS}
                    columns={[
                      {
                        Header: "Tipo",
                        accessor: "tipo",
                        className: "font-weight-600 text-uppercase",
                        filterable: false,
                        sortable: false,
                        Cell: (props) => {
                          const cell =
                            props.value === "terceiro"
                              ? {
                                  color: "#35bae9",
                                  icon: "id-card-alt",
                                  label: "Sou terceiro",
                                }
                              : {
                                  color: "#2bb442",
                                  icon: "building",
                                  label: "Sou tomador",
                                };

                          return (
                            <span
                              onDoubleClick={() =>
                                this.handleOpenContrato(props.original)
                              }
                              style={{
                                color: cell.color,
                                fontWeight: "bold",
                              }}
                              className="small"
                            >
                              <FontAwesomeIcon
                                icon={cell.icon}
                                className="mr-2"
                              />
                              {cell.label}
                            </span>
                          );
                        },
                      },
                      {
                        Header: "Título",
                        accessor: "nome",
                        headerClassName: "titulo-placeholder",
                        className: "font-weight-600 text-uppercase",
                        Cell: (props) => {
                          return (
                            <div
                              onDoubleClick={() =>
                                this.handleOpenContrato(props.original)
                              }
                            >
                              {props.original.status ===
                                STATUS.AGUARDANDO_APROVACAO && (
                                <span
                                  className="small font-weight-bold"
                                  style={{
                                    color: COLOR_INFO,
                                  }}
                                >
                                  (Aguardando Aprovação)
                                  <br />
                                </span>
                              )}
                              {props.value}
                            </div>
                          );
                        },
                      },
                      {
                        Header: "Filial",
                        accessor: "filial",
                        sortable: false,
                        filterable: false,
                        Cell: this.renderDoubleClickable,
                      },
                      {
                        Header: "Empresa",
                        accessor: "empresa",
                        sortable: false,
                        filterable: false,
                        Cell: (props) => {
                          return (
                            <div
                              onDoubleClick={() =>
                                this.handleOpenContrato(props.original)
                              }
                            >
                              {props.original.empresaStatus === -1 && (
                                <span
                                  className="small font-weight-bold"
                                  style={{
                                    color: COLOR_INFO,
                                  }}
                                >
                                  (Aguardando Cadastro)
                                  <br />
                                </span>
                              )}
                              {props.value}
                            </div>
                          );
                        },
                      },
                      {
                        Header: "Data Inicial",
                        accessor: "dataInicio",
                        filterable: false,
                        maxWidth: 200,
                        Cell: (props) => {
                          if (props.value.date) {
                            return (
                              <div
                                onDoubleClick={() =>
                                  this.handleOpenContrato(props.original)
                                }
                              >
                                {moment(props.value.date).format("DD/MM/YYYY")}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                onDoubleClick={() =>
                                  this.handleOpenContrato(props.original)
                                }
                              >
                                --/--/---
                              </div>
                            );
                          }
                        },
                      },
                      {
                        Header: "Data Final",
                        accessor: "dataFim",
                        filterable: false,
                        maxWidth: 200,
                        Cell: (props) => {
                          if (props.value.date) {
                            return (
                              <div
                                onDoubleClick={() =>
                                  this.handleOpenContrato(props.original)
                                }
                              >
                                {moment(props.value.date).format("DD/MM/YYYY")}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                onDoubleClick={() =>
                                  this.handleOpenContrato(props.original)
                                }
                              >
                                --/--/---
                              </div>
                            );
                          }
                        },
                      },
                      {
                        Header: "",
                        filterable: false,
                        sortable: false,
                        className: "option-column-5",
                        headerClassName: "option-column-5",
                        Cell: (props) => {
                          if (
                            props.original.tipo !== "terceiro" &&
                            props.original.status ===
                              STATUS.AGUARDANDO_APROVACAO
                          )
                            return null;

                          if (
                            props.original.tipo === "terceiro" &&
                            props.original.status ===
                              STATUS.AGUARDANDO_APROVACAO
                          )
                            return (
                              <button
                                className="a"
                                onClick={() =>
                                  this.handleChangeStatusContrato(
                                    props.original
                                  )
                                }
                                title="Aprovar"
                              >
                                <FontAwesomeIcon icon="check" />
                              </button>
                            );

                          return (
                            <button
                              className="a"
                              onClick={() =>
                                this.handleOpenContrato(props.original)
                              }
                              title="Ver"
                            >
                              <FontAwesomeIcon icon="eye" />
                            </button>
                          );
                        },
                      },
                      {
                        Header: "",
                        filterable: false,
                        sortable: false,
                        className: "option-column-5",
                        headerClassName: "option-column-5",
                        Cell: (props) => (
                          <EditarContratoButton
                            contrato={props.original}
                            dispatch={this.props.dispatch}
                          />
                        ),
                      },
                      {
                        Header: "",
                        filterable: false,
                        sortable: false,
                        className: "option-column-5",
                        headerClassName: "option-column-5",
                        Cell: (props) => (
                          <>
                            <ApagarContratoButton
                              contrato={props.original}
                              dispatch={this.props.dispatch}
                              swal={MySwal}
                            />
                            <AbreGestaoContratoButton
                              appData={this.props.appData}
                              contrato={props.original}
                              dispatch={this.props.dispatch}
                              history={this.props.history}
                            />
                          </>
                        ),
                      },
                      {
                        Header: "",
                        filterable: false,
                        sortable: false,
                        className: "option-column-5",
                        headerClassName: "option-column-5",
                        Cell: (props) =>
                          props.original.empresaStatus === -1 && (
                            <button
                              className="a"
                              onClick={async () => {
                                await this.requestContratoData(
                                  props.original.idContrato
                                );

                                const { chaveCadastro } =
                                  this.state.contratoAguardandoCadastro.find(
                                    ({ idContrato }) =>
                                      idContrato === props.original.idContrato
                                  );

                                this.handleSaveLinkToClipboard(chaveCadastro);
                              }}
                              title="Copiar Link Cadastro"
                            >
                              <FontAwesomeIcon icon="copy" />
                            </button>
                          ),
                      },
                      {
                        Header: "",
                        filterable: false,
                        sortable: false,
                        className: "option-column-5",
                        headerClassName: "option-column-5",
                        Cell: (props) =>
                          props.original.empresaStatus === -1 && (
                            <button
                              className="a"
                              onClick={async () => {
                                await this.requestContratoData(
                                  props.original.idContrato
                                );

                                const contrato =
                                  this.state.contratoAguardandoCadastro.find(
                                    ({ idContrato }) =>
                                      idContrato === props.original.idContrato
                                  );

                                this.handleOpenResend(
                                  contrato.idFilial,
                                  contrato.email
                                );
                              }}
                              title="Reenviar"
                            >
                              <FontAwesomeIcon icon="redo" />
                            </button>
                          ),
                      },
                    ]}
                    onFetchData={(state, instance) => {
                      this.fetchContratosData(state, instance);
                    }}
                    defaultPageSize={this.state.contrato.pageSize}
                    pageSize={this.state.contrato.pageSize}
                    loading={this.state.contrato.loading}
                    multiSort={false}
                    data={this.state.contrato.rows}
                    filterable={false}
                    sortable={false}
                    resizable={false}
                    loadingText="Carregando..."
                    noDataText="Nenhum contrato encontrado"
                    minRows={0}
                    showPagination={false}
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    className="-highlight mb-3"
                  />
                  <div className="col-12 line-in-sides">
                    {this.state.contrato.rows?.length > 0 && (
                      <ButtonPrimary
                        style={{
                          marginTop: "10px",
                          marginRight: "10px",
                          boxShadow: "none",
                        }}
                        title="VER TODOS"
                        onClick={() => {
                          this.props.history.push("/contratos/");
                        }}
                      >
                        VER TODOS
                      </ButtonPrimary>
                    )}
                    <ButtonSuccess
                      style={{ marginTop: "10px" }}
                      title="CRIE UM CONTRATO"
                      onClick={this.handleCreateContract}
                    >
                      <FontAwesomeIcon icon="plus" /> CRIE UM CONTRATO
                    </ButtonSuccess>
                  </div>
                </>
              )}
            {ultimosFilter === "funcionarios" &&
              this.props.permissoes &&
              (this.props.permissoes.permissoesGerais.indexOf(
                "funcionarios"
              ) !== -1 ||
                this.props.permissoes.administradorEmpresa === 1 ||
                this.props.permissoes.administradorFilial === 1) && (
                <>
                  <ReactTable
                    defaultSorted={[
                      {
                        id: "idFuncionario",
                        desc: true,
                      },
                    ]}
                    columns={[
                      {
                        Header: "Nome",
                        accessor: "nome",
                        headerClassName: "nome-placeholder",
                        className: "font-weight-600 text-uppercase",
                        Cell: (props) => {
                          return (
                            <div
                              onDoubleClick={() =>
                                this.handleOpenFuncionario(
                                  props.original.idFilialFuncionario
                                )
                              }
                            >
                              {props.value}
                            </div>
                          );
                        },
                      },
                      {
                        Header: "CPF",
                        accessor: "cpf",
                        headerClassName: "cpf-placeholder",
                        maxWidth: 200,
                        Cell: (props) => {
                          return (
                            <div
                              onDoubleClick={() =>
                                this.handleOpenFuncionario(
                                  props.original.idFilialFuncionario
                                )
                              }
                            >
                              {props.value}
                            </div>
                          );
                        },
                      },
                      this.props.permissoes.administradorEmpresa === 1
                        ? {
                            Header: "Filial",
                            accessor: "filial",
                            headerClassName: "filial-placeholder",
                            Cell: (props) => {
                              return (
                                <div
                                  onDoubleClick={() =>
                                    this.handleOpenFuncionario(
                                      props.original.idFilialFuncionario
                                    )
                                  }
                                >
                                  {props.value}
                                </div>
                              );
                            },
                          }
                        : null,
                      {
                        Header: "",
                        filterable: false,
                        sortable: false,
                        className: "option-column-5",
                        headerClassName: "option-column-5",
                        Cell: (props) => {
                          return (
                            <button
                              className="a"
                              onClick={() =>
                                this.handleOpenFuncionario(
                                  props.original.idFilialFuncionario
                                )
                              }
                              title="Ver"
                            >
                              <FontAwesomeIcon icon="eye" />
                            </button>
                          );
                        },
                      },
                    ]}
                    onFetchData={(state, instance) =>
                      this.fetchFuncionariosData(state, instance)
                    }
                    defaultPageSize={this.state.funcionario.pageSize}
                    pageSize={this.state.funcionario.pageSize}
                    loading={this.state.funcionario.loading}
                    multiSort={false}
                    data={this.state.funcionario.rows}
                    filterable={false}
                    sortable={false}
                    resizable={false}
                    loadingText="Carregando..."
                    noDataText="Nenhum funcionário encontrado"
                    minRows={0}
                    showPagination={false}
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    className="-highlight mb-3"
                  />
                  <div className="col-12 line-in-sides">
                    <ButtonPrimary
                      title="VER TODOS"
                      onClick={() => {
                        this.props.history.push("/funcionarios/");
                      }}
                    >
                      VER TODOS
                    </ButtonPrimary>
                  </div>
                </>
              )}
            {ultimosFilter === "documentos" &&
              !this.props.usuarioLogado?.sem_plano &&
              this.props.permissoes &&
              (this.props.permissoes.permissoesGerais.indexOf("documentos") !==
                -1 ||
                this.props.permissoes.administradorEmpresa === 1 ||
                this.props.permissoes.administradorFilial === 1) && (
                <>
                  <ReactTable
                    defaultSorted={[
                      {
                        id: "dataInicio.date",
                        desc: true,
                      },
                    ]}
                    columns={[
                      {
                        Header: "Nome",
                        accessor: "nome",
                        headerClassName: "",
                        filterable: false,
                        className: "font-weight-600",
                        Cell: (props) => {
                          return (
                            <div
                              onDoubleClick={() =>
                                this.handleOpenDocumento(props.original)
                              }
                            >
                              {props.value}
                            </div>
                          );
                        },
                      },
                      {
                        Header: "Data Inicial",
                        accessor: "dataInicio.date",
                        headerStyle: {
                          boxShadow: "none",
                        },
                        filterable: false,
                        maxWidth: 120,
                        Cell: (props) => {
                          if (props.value) {
                            return (
                              <div
                                onDoubleClick={() =>
                                  this.handleOpenDocumento(props.original)
                                }
                              >
                                {moment(props.value).format("DD/MM/YYYY")}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                onDoubleClick={() =>
                                  this.handleOpenDocumento(props.original)
                                }
                              >
                                --/--/---
                              </div>
                            );
                          }
                        },
                      },
                      {
                        Header: "Data Final",
                        accessor: "dataFim.date",
                        filterable: false,
                        maxWidth: 120,
                        Cell: (props) => {
                          if (props.value) {
                            return (
                              <div
                                onDoubleClick={() =>
                                  this.handleOpenDocumento(props.original)
                                }
                              >
                                {moment(props.value).format("DD/MM/YYYY")}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                onDoubleClick={() =>
                                  this.handleOpenDocumento(props.original)
                                }
                              >
                                --/--/---
                              </div>
                            );
                          }
                        },
                      },
                      {
                        Header: "Situação",
                        accessor: "status",
                        filterable: false,
                        maxWidth: 150,
                        Cell: (props) => {
                          switch (props.value) {
                            case -2: // Recusado
                              return (
                                <div
                                  onDoubleClick={() =>
                                    this.handleOpenDocumento(props.original)
                                  }
                                  style={{
                                    color: COLOR_DANGER,
                                  }}
                                >
                                  <FontAwesomeIcon icon="ban" /> Recusado
                                </div>
                              );
                            case -1: // Expirado
                              return (
                                <div
                                  onDoubleClick={() =>
                                    this.handleOpenDocumento(props.original)
                                  }
                                  style={{
                                    color: COLOR_DANGER,
                                  }}
                                >
                                  <FontAwesomeIcon icon="exclamation-circle" />{" "}
                                  Expirado
                                </div>
                              );
                            case 0: // A fazer upload
                              return (
                                <div
                                  onDoubleClick={() =>
                                    this.handleOpenDocumento(props.original)
                                  }
                                  style={{
                                    color: COLOR_WARNING,
                                  }}
                                >
                                  <FontAwesomeIcon icon="cloud-upload-alt" />{" "}
                                  Pendente
                                </div>
                              );
                            case 1: // Próximo da expiração
                              return (
                                <div
                                  onDoubleClick={() =>
                                    this.handleOpenDocumento(props.original)
                                  }
                                  style={{
                                    color: COLOR_INFO,
                                  }}
                                >
                                  <FontAwesomeIcon icon="clock" /> Expirando
                                </div>
                              );
                            case 2: // Aguardando aprovação
                              return (
                                <div
                                  onDoubleClick={() =>
                                    this.handleOpenDocumento(props.original)
                                  }
                                  style={{
                                    color: COLOR_INFO,
                                  }}
                                >
                                  <FontAwesomeIcon icon="hourglass-half" /> Ag.
                                  Aprovação
                                </div>
                              );
                            case 3: // Sem pendências
                              return (
                                <div
                                  onDoubleClick={() =>
                                    this.handleOpenDocumento(props.original)
                                  }
                                  style={{
                                    color: COLOR_GREEN,
                                  }}
                                >
                                  <FontAwesomeIcon icon="check" /> Regular
                                </div>
                              );
                            default:
                              return null;
                          }
                        },
                      },
                      {
                        Header: "",
                        filterable: false,
                        sortable: false,
                        className: "option-column-5",
                        headerClassName: "option-column-5",
                        Cell: (props) => {
                          return (
                            <button
                              className="a"
                              onClick={() =>
                                this.handleOpenDocumento(props.original)
                              }
                              title="Ver"
                            >
                              <FontAwesomeIcon icon="eye" />
                            </button>
                          );
                        },
                      },
                    ]}
                    defaultPageSize={this.state.documento.pageSize}
                    pageSize={this.state.documento.pageSize}
                    multiSort={false}
                    data={this.props.documentos}
                    filterable={false}
                    sortable={false}
                    resizable={false}
                    noDataText="Nenhum documento encontrado"
                    minRows={0}
                    showPagination={false}
                    showPaginationBottom={false}
                    showPageSizeOptions={false}
                    className="-highlight mb-3"
                  />
                  <div className="col-12 line-in-sides">
                    <ButtonPrimary
                      title="VER TODOS"
                      onClick={() => {
                        this.props.history.push("/documentos/");
                      }}
                    >
                      VER TODOS
                    </ButtonPrimary>
                  </div>
                </>
              )}
            {!this.props.usuarioLogado?.sem_plano && (
              <div className="row mt-4 mx-0 justify-content-between">
                {this.props.documentos?.length > 0 && (
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                    <div className="card">
                      <div className="card-header">
                        MEUS DOCUMENTOS
                        <br />
                        <span className="small">
                          {" "}
                          Conformidade de documentos da Empresa
                        </span>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="donut">
                              <Chart
                                options={graficoDocumento.options}
                                series={graficoDocumento.series}
                                type="donut"
                                width="380"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.documentosFuncionarios?.length > 0 && (
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                    <div className="card">
                      <div className="card-header">
                        DOCUMENTOS DOS FUNCIONÁRIOS
                        <br />
                        <span className="small"> Conformidade</span>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <div className="donut">
                              <Chart
                                options={graficoDocumentoFuncionarios.options}
                                series={graficoDocumentoFuncionarios.series}
                                type="donut"
                                width="380"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Formulário de Criação de Contrato */}
          <RightPanel
            isPaneOpen={this.props.modal === "new"}
            title="Novo contrato"
            path="new"
            onClose={() => {
              this.props.dispatch(setModalOpen(""));
            }}
          >
            <ContratoForm
              onAfterSave={this.requestContratosData}
            ></ContratoForm>
          </RightPanel>

          {/* Formulário de Edição de Contrato */}
          <RightPanel
            isPaneOpen={this.props.modal === "edit" && this.props.id !== null}
            title="Edição de Contrato"
            path="edit"
            onClose={() => {
              this.props.dispatch(setModalOpen(""));
            }}
          >
            <ContratoForm
              onAfterSave={this.requestContratosData}
              id={this.props.id}
            ></ContratoForm>
          </RightPanel>

          {/* Edição de Documentos do Contrato */}
          <RightPanel
            isPaneOpen={this.props.modal === "documentos"}
            title="Documentos do Contrato"
            path="documentos"
            onClose={() => {
              this.props.dispatch(setModalOpen(""));
            }}
          >
            <ContratoDocumentos
              onAfterSave={this.requestData}
            ></ContratoDocumentos>
          </RightPanel>

          {/* Edição de Funcionários do Contrato */}
          <RightPanel
            isPaneOpen={this.props.modal === "funcionarios"}
            title="Funcionários do Contrato"
            path="funcionarios"
            onClose={() => {
              this.props.dispatch(setModalOpen(""));
            }}
          >
            <ContratoFuncionarios
              onAfterSave={this.requestData}
            ></ContratoFuncionarios>
          </RightPanel>
        </div>
      </>
    );
  }
}
PainelInicial.propTypes = {
  modal: PropTypes.string.isRequired,
  id: PropTypes.any,

  permissoes: PropTypes.object.isRequired,
  usuarioLogado: PropTypes.object.isRequired,

  documentos: PropTypes.array.isRequired,
  documentosLoading: PropTypes.bool.isRequired,

  documentosFuncionarios: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  permissoes: state.permissions.permissoes,
  usuarioLogado: state.usuarioLogado.dados,
  appData: state.dados.data,

  documentos: state.documentos.documentos,
  documentosLoading: state.documentos.loading,

  // Modal
  modal: state.modal.modalOpen,
  id: state.modal.id,

  documentosFuncionarios: state.documentosFuncionarios.funcionarios,
});

export default connect(mapStateToProps)(withRouter(PainelInicial));
