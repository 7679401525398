import React, { Component } from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";
import Toggle from "react-toggle";
import "react-toggle/style.css";

export default class CheckPermissao extends Component {
  render() {
    return (
      <div className="col-12">
        <div className="form-group d-flex align-items-center">
          <Field name={this.props.name}>
            {({ field, form }) => {
              return (
                <Toggle
                  name={this.props.name}
                  id={this.props.name + "_" + this.props.option}
                  checked={
                    field.value
                      ? field.value.includes(this.props.option)
                      : false
                  }
                  {...(this.props.forcarAtivo && {
                    checked: true,
                    readOnly: true,
                  })}
                  onChange={(evt) => {
                    if (this.props.forcarAtivo) return;

                    if (
                      field.value &&
                      field.value.includes(this.props.option)
                    ) {
                      const nextValue = field.value.filter(
                        (value) => value !== this.props.option
                      );
                      form.setFieldValue(this.props.name, nextValue);
                    } else {
                      if (!field.value) {
                        let arr = [];
                        arr.push(this.props.option);
                        form.setFieldValue(this.props.name, arr);
                      } else {
                        const nextValue = field.value.concat(this.props.option);
                        form.setFieldValue(this.props.name, nextValue);
                      }
                    }
                  }}
                />
              );
            }}
          </Field>
          <label
            htmlFor={this.props.name + "_" + this.props.option}
            className="pl-1 mb-0"
            title={
              !this.props.forcarAtivo
                ? undefined
                : "Se usuário for administrador ou responsável pelos funcionários, então precisa dessa permissão"
            }
          >
            {this.props.label}
          </label>
          <ErrorMessage
            name={this.props.name}
            component="small"
            className="form-text text-danger"
          />
        </div>
      </div>
    );
  }
}
CheckPermissao.propTypes = {
  name: PropTypes.string.isRequired,
  option: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  forcarAtivo: PropTypes.bool,
};
