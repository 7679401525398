import React, { useMemo } from "react";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContractsTable = ({ envio }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Título",
        accessor: "titulo",
        headerClassName: "",
        filterable: false,
        className: "font-weight-600",
        maxWidth: 240,
        Cell: (props) => {
          return <span>{props.value}</span>;
        },
      },
      {
        Header: "Tomador",
        accessor: "tomador",
        headerClassName: "",
        filterable: false,
        className: "font-weight-600",
        Cell: (props) => {
          return <span>{props.value}</span>;
        },
      },
    ],
    []
  );

  return (
    <div className="row pb-5 mx-0 d-flex align-items-center justify-content-center">
      <div className="col-12">
        <div className="card">
          <div className="card-body px-1">
            <ReactTable
              columns={columns}
              multiSort={false}
              data={envio.contratos}
              filterable={false}
              resizable={false}
              noDataText="Nenhum contrato encontrado"
              pageText="Página"
              ofText="de"
              rowsText="linhas"
              pageJumpText="ir para a página"
              rowsSelectorText="linhas por página"
              previousText={<FontAwesomeIcon icon="chevron-left" />}
              nextText={<FontAwesomeIcon icon="chevron-right" />}
              pageSizeOptions={[5, 10, 20, 50]}
              defaultPageSize={10}
              minRows={0}
              className="-highlight mb-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractsTable;
