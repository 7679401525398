import axios from 'axios'
import { GET_ERRORS, SET_KEY } from './types'
import { URL_API } from './urls'

export const enterKey = (chave) => dispatch => {
    axios.get(URL_API + 'filial-key/'+ chave)
        .then(res => {
            const { data } = res.data
            dispatch(setKey(data[0]));            
        })
        .catch(err => {
            let erro = {message: "Chave incorreta, verifique-a e tente novamente!"}
            if(err.response){
                erro = err.response.data
            }
            dispatch({  
                type: GET_ERRORS,
                payload: erro
            }); 
        });
}

export const setKey = chave => {
    return {
        type: SET_KEY,
        payload: chave
    }
}

