/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from "moment"
import ReactTable from "react-table"
import "react-table/react-table.css"

/* Métodos / Ações */
import { fetchList, loadFiliaisDocumentosOptions, fetchFuncionariosList } from '../../actions/documentos'
import { setAppData } from '../../actions/dados'

/* Cores */
import { COLOR_VERY_LIGHT, COLOR_DARK2, COLOR_WARNING, COLOR_GREEN, COLOR_INFO, COLOR_DANGER, COLOR_PRIMARY, COLOR_GRAY2 } from '../../Colors'

/* Componentes */
import Error from '../Error'
import LoadingImage from '../LoadingImage'

/* Botões */
import ButtonPrimary from '../Buttons/ButtonPrimary'

class Documentos extends Component {
    constructor() {
        super()

        this._isMounted = false
        this.state = {
            rows: [],
            pages: null,
            loading: false,
            pageSize: null,
            page: null,
            sorted: null,
            filtered: null,
            statusFilter: null,
            tipoFilter: 'empresa',
            listaFiliais: [],
            loadingFiliais: false,
            filialFilter: null,
            filialPesquisa: '',
            pesquisa: '',
            ultimaPesquisa: null,
            show: {
                documentos: false,
                funcionarios: {}
            }
        }

        this.renderDoubleClickable = this.renderDoubleClickable.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleFilialFilterChange = this.handleFilialFilterChange.bind(this)
        this.loadFiliais = this.loadFiliais.bind(this)
        this.handlePesquisa = this.handlePesquisa.bind(this)
        this.handleClearFilter = this.handleClearFilter.bind(this)
    }

    handleFilialFilterChange(filter) {
        this.setState({
            filialFilter: filter
        }, () => {
            this.props.dispatch(fetchList({
                filial: filter.idFilial
            }))

            this.props.dispatch(fetchFuncionariosList({
                filial: filter.idFilial
            }))
        })
    }

    handleFilialPesquisaChange(event) {
        let pesquisa = event.target.value
        this.setState({
            filialPesquisa: pesquisa,
            loadingFiliais: true
        }, () => {
            this.loadFiliais()
        })
    }

    loadFiliais() {
        loadFiliaisDocumentosOptions(this.state.filialPesquisa)
            .then((data) => {
                if (this._isMounted) {
                    this.setState({
                        listaFiliais: data,
                        loadingFiliais: false
                    })
                }
            }).catch((err) => {
                if (this._isMounted) {
                    this.setState({
                        listaFiliais: [],
                        loadingFiliais: false
                    })
                }
            })
    }

    handleClearFilter() {
        this.props.dispatch(setAppData({
            filtroDocumentos: {
                filtroUsuario: false,
                funcionario: '',
                filial: ''
            }
        }))
    }

    componentDidMount() {
        this._isMounted = true
        this.loadFiliais()
        let req = { filial: this.getFilial() }
        this.props.dispatch(fetchList(req))
        this.props.dispatch(fetchFuncionariosList(req))
    }

    getFilial () {
        return this.props.dados?.filtroDocumentos?.filtroUsuario
            ? this.props.dados.filtroDocumentos.filial
            : (
                this.state.filialFilter
                    ? this.state.filialFilter.idFilial : this.props.usuarioLogado.filial.idFilial
            );
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    handleOpen(documento, funcionario = null) {
        let filialFilter = this.state.filialFilter ? this.state.filialFilter : this.props.usuarioLogado.filial

        if (this.state.listaFiliais && this.getFilial() !== filialFilter?.idFilial) {
            const filialFiltroDocumentos = this.state.listaFiliais?.filter((f) => f.idFilial === this.getFilial());
            if (filialFiltroDocumentos.length) {
                filialFilter = filialFiltroDocumentos.pop()
            }
        }

        this.props.dispatch(setAppData({
            filial: filialFilter,
            funcionario: funcionario,
            documento: documento,
            contrato: null
        }))
        this.props.history.push('/documentos/envios/')
    }

    renderDoubleClickable(cellInfo) {
        return (
            <div onDoubleClick={() => this.handleOpen(cellInfo.original)}>{cellInfo.value}</div>
        )
    }

    handlePesquisa(evt) {
        let pesquisa = evt.target.value
        this.setState({
            pesquisa,
            ultimaPesquisa: moment()
        }, () => {
            setTimeout(() => {
                var a = this.state.ultimaPesquisa;
                var b = moment();

                if (b.diff(a, 'seconds') > 0) {
                    let req = {}
                    if (this.state.filialFilter) {
                        req.filial = this.state.filialFilter.idFilial
                    }
                    req.s = pesquisa
                    this.props.dispatch(fetchList(req))
                    this.props.dispatch(fetchFuncionariosList(req))

                    setTimeout(() => {
                        if (document.getElementById('pesquisa-documentos'))
                            document.getElementById('pesquisa-documentos').focus()
                    }, 1000)
                }
            }, 1000)
        })
    }

    sortDocumentos(documentos) {
        return !!documentos && documentos
            .slice()
            .sort((a,b) => a.status === b.status ? 0 : (a.status === 3 ? -1 : (b.status === 3 ? 0 : -1)))
    }

    render() {
        if (this.props.error && this.props.modal === '') {
            return <Error erro={this.props.error}></Error>
        }
        if (this.props.loading || this.state.loading) {
            return <LoadingImage></LoadingImage>
        }

        if (this.props.dados && this.props.dados.filtroDocumentos && this.props.dados.filtroDocumentos.filtroUsuario) {
            var filialFilter = this.props.dados.filtroDocumentos.filial ? this.props.dados.filtroDocumentos.filial : this.props.usuarioLogado.filial
        } else {
            filialFilter = this.state.filialFilter ? this.state.filialFilter : this.props.usuarioLogado.filial
        }

        const filiais = this.state.listaFiliais?.length > 0 ? this.state.listaFiliais?.map((val, idx) => {
            let active = false
            if (this.props.dados && this.props.dados.filtroDocumentos && this.props.dados.filtroDocumentos.filtroUsuario) {
                if (this.props.dados.filtroDocumentos.filial === val.idFilial) active = true
            } else {
                if (val.idFilial === filialFilter.idFilial) active = true
            }

            return <div key={idx} className="col-12 py-2" style={{
                borderBottom: '1px solid #e4e4e4',
                cursor: 'pointer',
                background: active ? '#e4e4e4' : 'transparent'
            }}
                onClick={() => {
                    this.handleFilialFilterChange(val)
                    this.props.dispatch(setAppData({
                        filtroDocumentos: {
                            filtroUsuario: false,
                            funcionario: '',
                            filial: ''
                        }
                    }))
                }}>{val.matriz === 1 ? (val.nome + ' - Matriz').toUpperCase() : val.nome.toUpperCase()}</div>
        }) : <div className="col-12">Nenhuma filial encontrada</div>

        const invalidos_len = this.props.documentos?.reduce((sum, val) => {
            if (val.status < 1 || val.status === 2) {
                return sum + 1;
            } else {
                return sum;
            }
        }, 0)

        return (
            <div>
                <div className="row justify-content-center mx-0">

                    <div className="col-12 px-0">
                        <div className="card tertium-card">
                            <div className="card-header py-0">
                                <div className="row m-0">
                                    {this.props.permissoes.administradorEmpresa === 1 &&
                                        <div className="col-12 col-lg-3 d-flex align-items-center justify-content-center py-4 py-lg-0" style={{
                                            borderRadius: '0',
                                            boxShadow: 'none',
                                            borderTopLeftRadius: 'calc(.25rem - 1px)',
                                            backgroundColor: '#f15923',
                                            borderBottom: '1px solid #cd4c1e',
                                        }}>
                                            <input
                                                className="transparent-input"
                                                type="text"
                                                title="Buscar filial..."
                                                placeholder="Buscar filial..."
                                                value={this.state.filialPesquisa}
                                                onChange={this.handleFilialPesquisaChange.bind(this)}
                                            />
                                            <img src="/images/search.png" alt="Pesquisar" title="Pesquisar" style={{
                                                maxWidth: '18px'
                                            }} />
                                        </div>
                                    }
                                    <div className="col-12 col-lg-9 pb-md-3 py-3 px-2 px-lg-5  d-flex justify-content-between align-items-center">
                                        <div className="d-inline-block w-100">
                                            {filialFilter.nome ? filialFilter.nome : 'Documentos'}
                                        </div>

                                        {this.props.dados && this.props.dados.filtroDocumentos && this.props.dados.filtroDocumentos.filtroUsuario &&
                                            <div style={{
                                                backgroundColor: COLOR_GRAY2,
                                                right: 0,
                                                position: 'absolute',
                                                color: 'white',
                                                height: '100%',
                                                top: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '10px'
                                            }} className="d-flex justify-content-center align-items-center">
                                                <button type="button" style={{
                                                    color: 'white',
                                                    boxShadow: 'none',
                                                    lineHeight: 1
                                                }} title="Limpar filtros" onClick={() => {
                                                    this.handleClearFilter()
                                                }}><FontAwesomeIcon icon="times" /> <br />Limpar filtros</button>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card-body py-0">
                                <div className="row mx-0 justify-content-center">
                                    {this.props.permissoes.administradorEmpresa === 1 &&
                                        <div className="col-12 col-lg-3 py-3 custom-list overflow-on-md" style={{
                                            backgroundColor: COLOR_VERY_LIGHT,
                                            fontSize: '14px'
                                        }}>
                                            {this.state.loadingFiliais &&
                                                <div className="col-12">Carregando...</div>
                                            }
                                            {!this.state.loadingFiliais &&
                                                filiais
                                            }
                                        </div>
                                    }

                                    <div className="col-12 col-lg-9 px-2 px-lg-5 py-3 pb-5">
                                        <div className="input-group mb-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" style={{ height: '38px', backgroundColor: COLOR_PRIMARY, borderColor: COLOR_PRIMARY, color: 'white' }} id="pesquisa-documentos-addon"><FontAwesomeIcon icon="search" /></span>
                                            </div>
                                            <input tabIndex={-1} id="pesquisa-documentos" className="form-control" placeholder={"Pesquisa por documentos"} value={this.state.pesquisa} onChange={this.handlePesquisa}></input>
                                        </div>
                                        {(!this.props.dados || !this.props.dados.filtroDocumentos || !this.props.dados.filtroDocumentos.filtroUsuario) &&
                                            <>
                                                <h3 className="font-weight-400" style={{ color: COLOR_DARK2 }}>Documentos</h3>
                                                <ReactTable
                                                    columns={[
                                                        {
                                                            Header: "Nome",
                                                            accessor: "nome",
                                                            headerClassName: '',
                                                            filterable: false,
                                                            className: "font-weight-600",
                                                            Cell: this.renderDoubleClickable,
                                                        },
                                                        {
                                                            Header: "Data Inicial",
                                                            accessor: "dataInicio.date",
                                                            filterable: false,
                                                            maxWidth: 120,
                                                            Cell: props => {
                                                                if (props.value) {
                                                                    return <div onDoubleClick={() => this.handleOpen(props.original)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                                } else {
                                                                    return <div onDoubleClick={() => this.handleOpen(props.original)}>--/--/---</div>
                                                                }
                                                            }
                                                        },
                                                        {
                                                            Header: "Data Final",
                                                            accessor: "dataFim.date",
                                                            filterable: false,
                                                            maxWidth: 120,
                                                            Cell: props => {
                                                                if (props.value) {
                                                                    return <div onDoubleClick={() => this.handleOpen(props.original)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                                } else {
                                                                    return <div onDoubleClick={() => this.handleOpen(props.original)}>--/--/---</div>
                                                                }
                                                            }
                                                        },
                                                        {
                                                            Header: "Situação",
                                                            accessor: "status",
                                                            filterable: false,
                                                            maxWidth: 150,
                                                            Cell: props => {
                                                                switch (props.value) {
                                                                    case -2: // Recusado
                                                                        return <div onDoubleClick={() => this.handleOpen(props.original)} style={{
                                                                            color: COLOR_DANGER
                                                                        }}><FontAwesomeIcon icon="ban" /> Recusado</div>
                                                                    case -1: // Expirado
                                                                        return <div onDoubleClick={() => this.handleOpen(props.original)} style={{
                                                                            color: COLOR_DANGER
                                                                        }}><FontAwesomeIcon icon="exclamation-circle" /> Expirado</div>
                                                                    case 0: // A fazer upload
                                                                        return <div onDoubleClick={() => this.handleOpen(props.original)} style={{
                                                                            color: COLOR_WARNING
                                                                        }}><FontAwesomeIcon icon="cloud-upload-alt" /> Pendente</div>
                                                                    case 1: // Próximo da expiração
                                                                        return <div onDoubleClick={() => this.handleOpen(props.original)} style={{
                                                                            color: COLOR_INFO
                                                                        }}><FontAwesomeIcon icon="clock" /> Expirando</div>
                                                                    case 2: // Aguardando aprovação
                                                                        return <div onDoubleClick={() => this.handleOpen(props.original)} style={{
                                                                            color: COLOR_INFO
                                                                        }}><FontAwesomeIcon icon="hourglass-half" /> Ag. Aprovação</div>
                                                                    case 3: // Sem pendências
                                                                        return <div onDoubleClick={() => this.handleOpen(props.original)} style={{
                                                                            color: COLOR_GREEN
                                                                        }}><FontAwesomeIcon icon="check" /> Regular</div>
                                                                    default:
                                                                        return null
                                                                }
                                                            }
                                                        },
                                                        {
                                                            Header: '',
                                                            filterable: false,
                                                            sortable: false,
                                                            className: 'option-column-5',
                                                            headerClassName: 'option-column-5',
                                                            Cell: props => {
                                                                return <button className="a" onClick={() => this.handleOpen(props.original)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                                            }
                                                        }
                                                    ]}
                                                    defaultPageSize={this.state.show.documentos ? this.props.documentos?.length : 5}
                                                    pageSize={this.state.show.documentos ? this.props.documentos?.length : 5}
                                                    multiSort={false}
                                                    data={this.sortDocumentos(this.props.documentos)}
                                                    filterable={false}
                                                    resizable={false}
                                                    noDataText="Nenhum documento encontrado"
                                                    minRows={0}
                                                    showPagination={false}
                                                    showPaginationBottom={false}
                                                    showPageSizeOptions={false}
                                                    className="-highlight mb-3"
                                                />
                                                {(this.props.documentos?.length > 5 && !this.state.show.documentos) &&
                                                    <div className="w-100">
                                                        <ButtonPrimary title="Ver mais documentos" onClick={() => {
                                                            let show = this.state.show
                                                            show.documentos = true
                                                            this.setState({
                                                                show: show
                                                            })
                                                        }}>VER + DOCUMENTOS</ButtonPrimary>
                                                        {(invalidos_len - 5) > 0 &&
                                                            <span className="ml-2 font-weight-500" style={{ color: COLOR_WARNING, fontSize: '14px' }}>
                                                                <FontAwesomeIcon icon="exclamation-triangle" /> + {(invalidos_len - 5)} irregulares
                                                            </span>
                                                        }
                                                    </div>

                                                }
                                                {(this.props.documentos?.length > 5 && this.state.show.documentos) &&
                                                    <ButtonPrimary title="Ver menos documentos" onClick={() => {
                                                        let show = this.state.show
                                                        show.documentos = false
                                                        this.setState({
                                                            show: show
                                                        })
                                                    }}>VER - DOCUMENTOS</ButtonPrimary>
                                                }
                                            </>
                                        }

                                        <h3 className="font-weight-400 mt-5 mb-3 py-2" style={{
                                            color: COLOR_DARK2,
                                            borderBottom: '2px solid #c8c8c8'
                                        }}>Funcionários<span onClick={() => { this.props.history.push('/funcionarios/') }} className="small float-right mt-1"><FontAwesomeIcon icon="pencil-alt" /></span></h3>
                                        {this.props.funcionarios?.length > 0 &&
                                            this.props.funcionarios?.map((funcionario, idx) => {
                                                if (this.props.dados && this.props.dados.filtroDocumentos && this.props.dados.filtroDocumentos.filtroUsuario) {
                                                    if (this.props.dados.filtroDocumentos.funcionario === funcionario.idFuncionario) {
                                                        let func_invalidos_len = funcionario.documentos.filter((val) => {
                                                            return val.status < 1 || val.status === 2
                                                        })?.length

                                                        return (<div className="row mb-4 mx-0" key={idx}>
                                                            <div className="col-12 mb-2 px-0">
                                                                <b>{funcionario.nome}</b> ({funcionario.email})
                                                            </div>
                                                            {(func_invalidos_len === 0 && !this.state.show.funcionarios[funcionario.idFuncionario]) &&
                                                                <div className="w-100">
                                                                    <div className="col-12 py-2" style={{
                                                                        backgroundColor: '#d5f0d9',
                                                                        color: '#2bb442',
                                                                        fontWeight: 500,
                                                                        borderRadius: '4px'
                                                                    }}>
                                                                        NÃO CONSTA NENHUMA IRREGULARIDADE
                                                                    </div>
                                                                    <div className="w-100 pt-3">
                                                                        <ButtonPrimary title="Ver mais documentos" onClick={() => {
                                                                            let show = this.state.show
                                                                            show.funcionarios[funcionario.idFuncionario] = true
                                                                            this.setState({
                                                                                show: show
                                                                            })
                                                                        }}>VER DOCUMENTOS</ButtonPrimary>
                                                                        {(func_invalidos_len - 3) > 0 &&
                                                                            <span className="ml-2 font-weight-500" style={{ color: COLOR_WARNING, fontSize: '14px' }}>
                                                                                <FontAwesomeIcon icon="exclamation-triangle" /> + {(func_invalidos_len - 3)} irregulares
                                                                            </span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            }
                                                            {(func_invalidos_len > 0 || this.state.show.funcionarios[funcionario.idFuncionario]) &&
                                                                <div className="col-12">
                                                                    <ReactTable
                                                                        columns={[
                                                                            {
                                                                                Header: "Nome",
                                                                                accessor: "nome",
                                                                                headerClassName: '',
                                                                                filterable: false,
                                                                                className: "font-weight-600",
                                                                                Cell: props => {
                                                                                    return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>{props.value}</div>
                                                                                }
                                                                            },
                                                                            {
                                                                                Header: "Data Inicial",
                                                                                accessor: "dataInicio.date",
                                                                                filterable: false,
                                                                                maxWidth: 120,
                                                                                Cell: props => {
                                                                                    if (props.value) {
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                                                    } else {
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>--/--/---</div>
                                                                                    }
                                                                                }
                                                                            },
                                                                            {
                                                                                Header: "Data Final",
                                                                                accessor: "dataFim.date",
                                                                                filterable: false,
                                                                                maxWidth: 120,
                                                                                Cell: props => {
                                                                                    if (props.value) {
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                                                    } else {
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>--/--/---</div>
                                                                                    }
                                                                                }
                                                                            },
                                                                            {
                                                                                Header: "Situação",
                                                                                accessor: "status",
                                                                                filterable: false,
                                                                                maxWidth: 150,
                                                                                Cell: props => {
                                                                                    switch (props.value) {
                                                                                        case -2: // Recusado
                                                                                            return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                                color: COLOR_DANGER
                                                                                            }}><FontAwesomeIcon icon="ban" /> Recusado</div>
                                                                                        case -1: // Expirado
                                                                                            return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                                color: COLOR_DANGER
                                                                                            }}><FontAwesomeIcon icon="exclamation-circle" /> Expirado</div>
                                                                                        case 0: // A fazer upload
                                                                                            return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                                color: COLOR_WARNING
                                                                                            }}><FontAwesomeIcon icon="cloud-upload-alt" /> Pendente</div>
                                                                                        case 1: // Próximo da expiração
                                                                                            return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                                color: COLOR_INFO
                                                                                            }}><FontAwesomeIcon icon="clock" /> Expirando</div>
                                                                                        case 2: // Aguardando aprovação
                                                                                            return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                                color: COLOR_INFO
                                                                                            }}><FontAwesomeIcon icon="hourglass-half" /> Ag. Aprovação</div>
                                                                                        case 3: // Sem pendências
                                                                                            return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                                color: COLOR_GREEN
                                                                                            }}><FontAwesomeIcon icon="check" /> Regular</div>
                                                                                        default:
                                                                                            return null
                                                                                    }
                                                                                }
                                                                            },
                                                                            {
                                                                                Header: '',
                                                                                filterable: false,
                                                                                sortable: false,
                                                                                className: 'option-column-5',
                                                                                headerClassName: 'option-column-5',
                                                                                Cell: props => {
                                                                                    return <button className="a" onClick={() => this.handleOpen(props.original, funcionario)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                                                                }
                                                                            }
                                                                        ]}
                                                                        defaultPageSize={this.state.show.funcionarios[funcionario.idFuncionario] ? funcionario.documentos?.length : 3}
                                                                        pageSize={this.state.show.funcionarios[funcionario.idFuncionario] ? funcionario.documentos?.length : 3}
                                                                        multiSort={false}
                                                                        data={this.sortDocumentos(funcionario.documentos)}
                                                                        filterable={false}
                                                                        resizable={false}
                                                                        noDataText="Nenhum documento encontrado"
                                                                        minRows={0}
                                                                        showPagination={false}
                                                                        showPaginationBottom={false}
                                                                        showPageSizeOptions={false}
                                                                        className="-highlight mb-3"
                                                                    />
                                                                    {(funcionario.documentos?.length > 3 && !this.state.show.funcionarios[funcionario.idFuncionario]) &&
                                                                        <div className="w-100">
                                                                            <ButtonPrimary title="Ver mais documentos" onClick={() => {
                                                                                let show = this.state.show
                                                                                show.funcionarios[funcionario.idFuncionario] = true
                                                                                this.setState({
                                                                                    show: show
                                                                                })
                                                                            }}>VER + DOCUMENTOS</ButtonPrimary>
                                                                            {(func_invalidos_len - 3) > 0 &&
                                                                                <span className="ml-2 font-weight-500" style={{ color: COLOR_WARNING, fontSize: '14px' }}>
                                                                                    <FontAwesomeIcon icon="exclamation-triangle" /> + {(func_invalidos_len - 3)} irregulares
                                                                                </span>
                                                                            }
                                                                        </div>

                                                                    }
                                                                    {(funcionario.documentos?.length > 3 && this.state.show.funcionarios[funcionario.idFuncionario]) &&
                                                                        <ButtonPrimary title="Ver menos documentos" onClick={() => {
                                                                            let show = this.state.show
                                                                            show.funcionarios[funcionario.idFuncionario] = false
                                                                            this.setState({
                                                                                show: show
                                                                            })
                                                                        }}>VER - DOCUMENTOS</ButtonPrimary>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>)
                                                    }
                                                } else {
                                                    let func_invalidos_len = funcionario.documentos.filter((val) => {
                                                        return val.status < 1 || val.status === 2
                                                    })?.length

                                                    return (<div className="row mb-4 mx-0" key={idx}>
                                                        <div className="col-12 mb-2 px-0">
                                                            <b>{funcionario.nome}</b> ({funcionario.email})
                                                            </div>
                                                        {(func_invalidos_len === 0 && !this.state.show.funcionarios[funcionario.idFuncionario]) &&
                                                            <div className="w-100">
                                                                <div className="col-12 py-2" style={{
                                                                    backgroundColor: '#d5f0d9',
                                                                    color: '#2bb442',
                                                                    fontWeight: 500,
                                                                    borderRadius: '4px'
                                                                }}>
                                                                    NÃO CONSTA NENHUMA IRREGULARIDADE
                                                                    </div>
                                                                <div className="w-100 pt-3">
                                                                    <ButtonPrimary title="Ver mais documentos" onClick={() => {
                                                                        let show = this.state.show
                                                                        show.funcionarios[funcionario.idFuncionario] = true
                                                                        this.setState({
                                                                            show: show
                                                                        })
                                                                    }}>VER DOCUMENTOS</ButtonPrimary>
                                                                    {(func_invalidos_len - 3) > 0 &&
                                                                        <span className="ml-2 font-weight-500" style={{ color: COLOR_WARNING, fontSize: '14px' }}>
                                                                            <FontAwesomeIcon icon="exclamation-triangle" /> + {(func_invalidos_len - 3)} irregulares
                                                                            </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                        {(func_invalidos_len > 0 || this.state.show.funcionarios[funcionario.idFuncionario]) &&
                                                            <div className="col-12">
                                                                <ReactTable
                                                                    columns={[
                                                                        {
                                                                            Header: "Nome",
                                                                            accessor: "nome",
                                                                            headerClassName: '',
                                                                            filterable: false,
                                                                            className: "font-weight-600",
                                                                            Cell: props => {
                                                                                return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>{props.value}</div>
                                                                            }
                                                                        },
                                                                        {
                                                                            Header: "Data Inicial",
                                                                            accessor: "dataInicio.date",
                                                                            filterable: false,
                                                                            maxWidth: 120,
                                                                            Cell: props => {
                                                                                if (props.value) {
                                                                                    return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                                                } else {
                                                                                    return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>--/--/---</div>
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            Header: "Data Final",
                                                                            accessor: "dataFim.date",
                                                                            filterable: false,
                                                                            maxWidth: 120,
                                                                            Cell: props => {
                                                                                if (props.value) {
                                                                                    return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                                                } else {
                                                                                    return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)}>--/--/---</div>
                                                                                }
                                                                            }
                                                                        },
                                                                        {
                                                                            Header: "Situação",
                                                                            accessor: "status",
                                                                            filterable: false,
                                                                            maxWidth: 150,
                                                                            Cell: props => {
                                                                                switch (props.value) {
                                                                                    case -2: // Recusado
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                            color: COLOR_DANGER
                                                                                        }}><FontAwesomeIcon icon="ban" /> Recusado</div>
                                                                                    case -1: // Expirado
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                            color: COLOR_DANGER
                                                                                        }}><FontAwesomeIcon icon="exclamation-circle" /> Expirado</div>
                                                                                    case 0: // A fazer upload
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                            color: COLOR_WARNING
                                                                                        }}><FontAwesomeIcon icon="cloud-upload-alt" /> Pendente</div>
                                                                                    case 1: // Próximo da expiração
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                            color: COLOR_INFO
                                                                                        }}><FontAwesomeIcon icon="clock" /> Expirando</div>
                                                                                    case 2: // Aguardando aprovação
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                            color: COLOR_INFO
                                                                                        }}><FontAwesomeIcon icon="hourglass-half" /> Ag. Aprovação</div>
                                                                                    case 3: // Sem pendências
                                                                                        return <div onDoubleClick={() => this.handleOpen(props.original, funcionario)} style={{
                                                                                            color: COLOR_GREEN
                                                                                        }}><FontAwesomeIcon icon="check" /> Regular</div>
                                                                                    default:
                                                                                        return null
                                                                                }
                                                                            }
                                                                        },
                                                                        /*{
                                                                            Header: "Comentários",
                                                                            filterable:false,
                                                                            maxWidth: 120
                                                                        },*/
                                                                        {
                                                                            Header: '',
                                                                            filterable: false,
                                                                            sortable: false,
                                                                            className: 'option-column-5',
                                                                            headerClassName: 'option-column-5',
                                                                            Cell: props => {
                                                                                return <button className="a" onClick={() => this.handleOpen(props.original, funcionario)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                                                            }
                                                                        }
                                                                    ]}
                                                                    defaultPageSize={this.state.show.funcionarios[funcionario.idFuncionario] ? funcionario.documentos?.length : 3}
                                                                    pageSize={this.state.show.funcionarios[funcionario.idFuncionario] ? funcionario.documentos?.length : 3}
                                                                    multiSort={false}
                                                                    data={this.sortDocumentos(funcionario.documentos)}
                                                                    filterable={false}
                                                                    resizable={false}
                                                                    noDataText="Nenhum documento encontrado"
                                                                    minRows={0}
                                                                    showPagination={false}
                                                                    showPaginationBottom={false}
                                                                    showPageSizeOptions={false}
                                                                    className="-highlight mb-3"
                                                                />
                                                                {(funcionario.documentos?.length > 3 && !this.state.show.funcionarios[funcionario.idFuncionario]) &&
                                                                    <div className="w-100">
                                                                        <ButtonPrimary title="Ver mais documentos" onClick={() => {
                                                                            let show = this.state.show
                                                                            show.funcionarios[funcionario.idFuncionario] = true
                                                                            this.setState({
                                                                                show: show
                                                                            })
                                                                        }}>VER + DOCUMENTOS</ButtonPrimary>
                                                                        {(func_invalidos_len - 3) > 0 &&
                                                                            <span className="ml-2 font-weight-500" style={{ color: COLOR_WARNING, fontSize: '14px' }}>
                                                                                <FontAwesomeIcon icon="exclamation-triangle" /> + {(func_invalidos_len - 3)} irregulares
                                                                                </span>
                                                                        }
                                                                    </div>

                                                                }
                                                                {(funcionario.documentos?.length > 3 && this.state.show.funcionarios[funcionario.idFuncionario]) &&
                                                                    <ButtonPrimary title="Ver menos documentos" onClick={() => {
                                                                        let show = this.state.show
                                                                        show.funcionarios[funcionario.idFuncionario] = false
                                                                        this.setState({
                                                                            show: show
                                                                        })
                                                                    }}>VER - DOCUMENTOS</ButtonPrimary>
                                                                }
                                                            </div>
                                                        }
                                                    </div>)
                                                }
                                                return null
                                            })
                                        }
                                        {this.props.funcionarios?.length === 0 &&
                                            <p>Nenhum funcionário encontrado</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
Documentos.propTypes = {
    permissoes: PropTypes.object.isRequired,
    usuarioLogado: PropTypes.object.isRequired,
    documentos: PropTypes.array.isRequired,
    funcionarios: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
    permissoes: state.permissions.permissoes,
    usuarioLogado: state.usuarioLogado.dados,
    documentos: state.documentos.documentos,
    loading: state.documentos.loading,
    error: state.documentos.error,
    funcionarios: state.documentosFuncionarios.funcionarios,
    dados: state.dados.data
})

export default connect(mapStateToProps)(withRouter(Documentos));
