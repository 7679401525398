import React from 'react';

const PHONE = process.env.REACT_APP_PHONE ?? '';
const UNMASKED_PHONE = PHONE.replace(/\D/g, '');

const AlertSemPlano = () => {
  return (
    <div className="mt-3">
      <p>
        Deseja utilizar a plataforma Tertium para solicitar documentos de empresas que prestam serviços para sua empresa?
      </p>
      <p>
        Entre em contato através do nosso WhatsApp <a href={`http://wa.me/55${UNMASKED_PHONE}`} target="_blank" rel="noopener noreferrer"><b>{PHONE}</b></a>
      </p>
    </div>
  )
}

export default AlertSemPlano
