import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
    FETCH_MODELOS_BEGIN as BEGIN,
    FETCH_MODELOS_SUCCESS as SUCCESS,
    FETCH_MODELOS_FAILURE as FAILURE,
    GET_ERRORS
} from './types'
import { URL_API } from './urls'
import { initialState } from '../reducers/contratosReducer'
import { defaultClearData, defaultFetchBegin, defaultFetchSuccess, defaultFetchFailure, defaultDelete } from './default'
import { fetchDocumentosContratoSuccess } from './contratos'

const MySwal = withReactContent(Swal)

const model = 'modelo'

export const clearData = () => defaultClearData(SUCCESS, model, initialState)
export const fetchBegin = () => defaultFetchBegin(BEGIN)
export const fetchSuccess = data => defaultFetchSuccess(SUCCESS, model, data)
export const fetchFailure = error => defaultFetchFailure(FAILURE, error)
export const deleteData = (id, afterDelete, afterError) => defaultDelete(model, id, 'Modelo excluído com sucesso', afterDelete, afterError)

export const fetch = () => dispatch => {
    dispatch(fetchBegin())

    axios.get(URL_API + 'modelos/')
        .then(res => {
            const { rows } = res.data
            dispatch(fetchSuccess(rows))
            return rows
        })
        .catch(err => {
            let erro = { message: "Não foi possível obter os dados" }
            if (err.response) {
                erro = err.response.data
            }
            dispatch(fetchFailure(erro))
        })
}

export const saveModelo = (dados, afterSave) => dispatch => {
    axios.post(URL_API + model, dados)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000,
                type: 'success',
                title: 'Modelo criado com sucesso'
            })

            if (afterSave)
                afterSave()
        })
        .catch(err => {
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                })
            }
        })
}

export const selectModelo = (id = 0, modelos, documentos) => dispatch => {
    let modelo = {}
    for (let i = 0; i < modelos?.length; i++) {
        if (parseInt(modelos[i].id) === parseInt(id)) {
            modelo = modelos[i]
            break
        }
    }

    let tipos = ['EMP', 'FUN', 'CON']
    for (let i = 0; i < tipos?.length; i++) {
        let tipo = tipos[i]
        let qtd = 0
        for (let j = 0; j < documentos[tipo]?.length; j++) {
            if (modelo.documentos.indexOf(documentos[tipo][j].idDocumento) !== -1) {
                qtd++
                documentos[tipo][j].noContrato = 1
            } else {
                documentos[tipo][j].noContrato = 0
            }
        }
        documentos.qtd[tipo] = qtd
    }

    dispatch(fetchDocumentosContratoSuccess({}))
    dispatch(fetchDocumentosContratoSuccess(documentos))
}
