import { SET_PERMISSIONS, GET_PERMISSIONS } from '../actions/types';

const initialState = {
    permissoes: {
        departamentos: [],
        administradorEmpresa: 0,
        administradorFilial: 0,
        permissoesGerais: []
    }
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_PERMISSIONS:
            return {
                ...state,
                permissoes: action.payload
            }
        case GET_PERMISSIONS:
            return action.payload;
        default: 
            return state;
    }
};