import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { dateField, objectField } from "../../validation/formValidation";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import MaskedTextInput from "react-text-mask";
import pt from "date-fns/locale/pt";
import Select from "react-select";
import BetterCalendar from "./BetterCalendar";

const BUTTON_LABEL = {
    soft: "SIM, REMOVER",
    afastar: "SIM, AFASTAR",
    hard: "SIM, DEMITIR",
};

const DATE_MASK = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];

const ContratoModalForm = ({
    name,
    handleCheckedOption,
    checked,
    tiposAfastamento,
    contract,
    onSubmit,
    defaultValue,
    onCancel,
}) => {
    const [checkedRadio, setCheckedRadio] = useState("soft");
    const tiposAfastamentoOptions = tiposAfastamento.map((tipo) => ({
        label: tipo.nome,
        value: tipo.id,
    }));

    return (
        <>
            Deseja realmente remover o funcionário <b>{name}</b> do contrato{" "}
            <b>{contract}</b>
            <br />
            <Formik
                initialValues={defaultValue}
                onSubmit={onSubmit}
                render={({
                    isSubmitting,
                    handleSubmit,
                    setFieldValue,
                    values,
                }) => (
                    <Form onSubmit={handleSubmit} className="mx-auto">
                        <div className="my-3">
                            <div className="d-flex align-items-center justify-content-center gap-2 my-2">
                                <input
                                    className="mr-1 cursor-pointer"
                                    type="radio"
                                    id="soft"
                                    name="isSoftRemove"
                                    defaultChecked={checked === "soft"}
                                    onChange={(e) => {
                                        setCheckedRadio("soft");
                                        handleCheckedOption(e);
                                    }}
                                />
                                <label
                                    htmlFor="soft"
                                    className="h6 d-flex align-items-center cursor-pointer"
                                >
                                    Remover apenas do contrato
                                </label>
                            </div>
                            <div className="d-flex align-items-center justify-content-center gap-2 my-2 pointer">
                                <input
                                    className="mr-1 cursor-pointer"
                                    type="radio"
                                    id="hard"
                                    name="isSoftRemove"
                                    defaultChecked={checked === "hard"}
                                    onChange={(e) => {
                                        setCheckedRadio("hard");
                                        handleCheckedOption(e);
                                    }}
                                />
                                <label
                                    htmlFor="hard"
                                    className="h6 d-flex align-items-center cursor-pointer"
                                >
                                    Demitir funcionário também
                                </label>
                            </div>
                            <div className="d-flex align-items-center justify-content-center gap-2 my-2 pointer">
                                <input
                                    className="mr-1 cursor-pointer"
                                    type="radio"
                                    id="afastar"
                                    name="isSoftRemove"
                                    defaultChecked={checked === "afastar"}
                                    onChange={(e) => {
                                        setCheckedRadio("afastar");
                                        handleCheckedOption(e);
                                    }}
                                />
                                <label
                                    htmlFor="afastar"
                                    className="h6 d-flex align-items-center cursor-pointer"
                                >
                                    Afastar funcionário temporariamente
                                </label>
                            </div>
                        </div>
                        {checkedRadio === "hard" && (
                            <>
                                <div className="form-group d-flex justify-content-center align-items-center gap-1 mx-auto">
                                    <label htmlFor="dataDemissao">
                                        Data de Demissão*
                                    </label>
                                    <Field
                                        name="dataDemissao"
                                        validate={dateField}
                                    >
                                        {() => (
                                            <BetterCalendar
                                                name="dataDemissao"
                                                id="dataDemissao"
                                                locale={pt}
                                                className="form-control"
                                                showLeadingZeros={true}
                                                selected={values.dataDemissao}
                                                placeholderText="-- / -- / ----"
                                                dateFormat="dd/MM/yyyy"
                                                onChange={(date) =>
                                                    setFieldValue(
                                                        "dataDemissao",
                                                        date
                                                    )
                                                }
                                                customInput={
                                                    <MaskedTextInput
                                                        mask={DATE_MASK}
                                                    />
                                                }
                                            />
                                        )}
                                    </Field>
                                </div>
                                <div
                                    className="form-group alert alert-warning"
                                    style={{
                                        fontSize: "80%",
                                        textAlign: "justify",
                                    }}
                                >
                                    <b>ATENÇÃO: </b> a demissão do funcionário
                                    terá efeito <b>IMEDIATO</b> e afetará
                                    todos os Contratos que o mesmo estiver ativo.
                                </div>
                            </>
                        )}
                        {checkedRadio === "afastar" && (
                            <>
                                <div className="form-group d-flex flex-column justify-content-center align-items-start gap-1">
                                    <label htmlFor="tipoAfastamento">
                                        Tipo de Afastamento*
                                    </label>
                                    <Field
                                        name="tipoAfastamento"
                                        validate={objectField}
                                    >
                                        {({ field }) => (
                                            <Select
                                                {...field}
                                                name="tipoAfastamento"
                                                id="tipoAfastamento"
                                                className="w-100"
                                                placeholder="Selecione o tipo de afastamento"
                                                options={
                                                    tiposAfastamentoOptions
                                                }
                                                defaultValue={
                                                    values.tipoAfastamento
                                                }
                                                value={values.tipoAfastamento}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        "tipoAfastamento",
                                                        value
                                                    )
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary50: "#ebebeb",
                                                        primary25: "#ebebeb",
                                                        primary: "#ff5818",
                                                    },
                                                })}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage
                                        name="tipoAfastamento"
                                        component="small"
                                        className="form-text text-danger"
                                    />
                                </div>
                                <div className="d-flex gap-1">
                                    <div className="form-group d-flex flex-column justify-content-center align-items-start gap-1">
                                        <label htmlFor="inicioAfastamento">
                                            Início Afastamento*
                                        </label>
                                        <Field
                                            name="inicioAfastamento"
                                            validate={dateField}
                                        >
                                            {() => (
                                                <BetterCalendar
                                                    name="inicioAfastamento"
                                                    id="inicioAfastamento"
                                                    locale={pt}
                                                    className="form-control"
                                                    showLeadingZeros={true}
                                                    selected={
                                                        values.inicioAfastamento
                                                    }
                                                    placeholderText="-- / -- / ----"
                                                    dateFormat="dd/MM/yyyy"
                                                    maxDate={
                                                        values.fimAfastamento
                                                    }
                                                    onChange={(date) =>
                                                        setFieldValue(
                                                            "inicioAfastamento",
                                                            date
                                                        )
                                                    }
                                                    customInput={
                                                        <MaskedTextInput
                                                            mask={DATE_MASK}
                                                        />
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="inicioAfastamento"
                                            component="small"
                                            className="form-text text-danger"
                                        />
                                    </div>
                                    <div className="form-group d-flex flex-column justify-content-center align-items-start gap-1">
                                        <label htmlFor="fimAfastamento">
                                            Fim Afastamento*
                                        </label>
                                        <Field
                                            name="fimAfastamento"
                                            validate={dateField}
                                        >
                                            {() => (
                                                <BetterCalendar
                                                    name="fimAfastamento"
                                                    id="fimAfastamento"
                                                    locale={pt}
                                                    className="form-control"
                                                    showLeadingZeros={true}
                                                    selected={
                                                        values.fimAfastamento
                                                    }
                                                    placeholderText="-- / -- / ----"
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={
                                                        values.inicioAfastamento
                                                    }
                                                    onChange={(date) =>
                                                        setFieldValue(
                                                            "fimAfastamento",
                                                            date
                                                        )
                                                    }
                                                    customInput={
                                                        <MaskedTextInput
                                                            mask={DATE_MASK}
                                                        />
                                                    }
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="fimAfastamento"
                                            component="small"
                                            className="form-text text-danger"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                        <ErrorMessage
                            name="dataDemissao"
                            component="small"
                            className="form-text text-danger"
                        />
                        <div className="d-flex justify-content-center gap-1 w-full mt-4">
                            <ButtonPrimary
                                className="btn-cancel swal2-styled"
                                disabled={isSubmitting}
                                onClick={onCancel}
                            >
                                Cancelar
                            </ButtonPrimary>
                            <ButtonPrimary
                                className="btn-confirm swal2-styled"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {BUTTON_LABEL[checkedRadio] ||
                                    BUTTON_LABEL["soft"]}
                            </ButtonPrimary>
                        </div>
                    </Form>
                )}
            />
        </>
    );
};

export default ContratoModalForm;
