import {
  FETCH_DOCUMENTOS_BEGIN as BEGIN_LIST,
  FETCH_DOCUMENTOS_SUCCESS as SUCCESS_LIST,
  FETCH_DOCUMENTOS_FAILURE as FAILURE_LIST,
  FETCH_DOCUMENTO_BEGIN as BEGIN,
  FETCH_DOCUMENTO_SUCCESS as SUCCESS,
  FETCH_DOCUMENTO_FAILURE as FAILURE,
  FETCH_DOCUMENTOS_FUNCIONARIOS_BEGIN,
  FETCH_DOCUMENTOS_FUNCIONARIOS_SUCCESS,
  FETCH_DOCUMENTOS_FUNCIONARIOS_UPDATE,
  FETCH_DOCUMENTOS_FUNCIONARIOS_FAILURE,
  FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_BEGIN,
  FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_SUCCESS,
  FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_FAILURE,
  FETCH_DOCUMENTOS_CONTRATO_BEGIN,
  FETCH_DOCUMENTOS_CONTRATO_SUCCESS,
  FETCH_DOCUMENTOS_CONTRATO_FAILURE,
  FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_UPDATE,
} from "../actions/types";
import defaultReducer from "./defaultReducer";

const model = "documento";
const model_funcionarios = "funcionarios";
const model_funcionarios_removidos = "funcionariosRemovidos";
const model_contrato = "contrato";
const list_model = "documentos";

export const initialListState = [];
export const initialState = {};
export const initialFuncionariosState = [];
export const initialFuncionariosRemovidosState = [];
export const initialContratoState = [];

export const documentosReducer = (state, action) => {
  return defaultReducer(
    list_model,
    BEGIN_LIST,
    SUCCESS_LIST,
    FAILURE_LIST,
    initialListState,
    state,
    action
  );
};

export const documentosFuncionariosReducer = (
  state = {
    [model_funcionarios]: initialFuncionariosState,
    loading: false,
    error: null
  },
  action
) => {
  switch(action.type) {
    case FETCH_DOCUMENTOS_FUNCIONARIOS_SUCCESS:
      const selected = state[model_funcionarios][0];
      const hasDuplicated = action.payload[model_funcionarios].find(item => selected?.idFilialFuncionario === item.idFilialFuncionario);
      return {
        ...state,
        loading: false,
        [model_funcionarios]: !hasDuplicated && selected ? [selected, ...action.payload[model_funcionarios]] : action.payload[model_funcionarios]
      };

    case FETCH_DOCUMENTOS_FUNCIONARIOS_UPDATE:
      return {
        ...state,
        loading: false,
        [model_funcionarios]: state[model_funcionarios].length
          ? state[model_funcionarios].map(stateItem => {
              const updatedItem = action.payload[model_funcionarios].find(newItem => newItem.idFilialFuncionario === stateItem.idFilialFuncionario);
              return updatedItem || stateItem;
            })
          : action.payload[model_funcionarios]
      }

    default:
      return defaultReducer(
        model_funcionarios,
        FETCH_DOCUMENTOS_FUNCIONARIOS_BEGIN,
        FETCH_DOCUMENTOS_FUNCIONARIOS_SUCCESS,
        FETCH_DOCUMENTOS_FUNCIONARIOS_FAILURE,
        initialFuncionariosState,
        state,
        action
      );
  }
};

export const documentosFuncionariosRemovidosReducer = (
  state = {
    [model_funcionarios_removidos]: initialFuncionariosRemovidosState,
    loading: false,
    error: null
  },
  action
) => {
  switch(action.type) {
    case FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_SUCCESS:
      const selected = state[model_funcionarios_removidos][0];
      const hasDuplicated = action.payload[model_funcionarios_removidos].find(item => selected?.idFilialFuncionario === item.idFilialFuncionario);
      return {
        ...state,
        loading: false,
        [model_funcionarios_removidos]: !hasDuplicated && selected
          ? [selected, ...action.payload[model_funcionarios_removidos]]
          : action.payload[model_funcionarios_removidos]
      };

    case FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_UPDATE:
      return {
        ...state,
        loading: false,
        [model_funcionarios_removidos]: state[model_funcionarios_removidos].length
          ? state[model_funcionarios_removidos].map(stateItem => {
              const updatedItem = action.payload[model_funcionarios_removidos].find(newItem => newItem.idFilialFuncionario === stateItem.idFilialFuncionario);
              return updatedItem || stateItem;
            })
          : action.payload[model_funcionarios_removidos]
      }

    default:
      return defaultReducer(
        model_funcionarios_removidos,
        FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_BEGIN,
        FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_SUCCESS,
        FETCH_DOCUMENTOS_FUNCIONARIOS_REMOVIDOS_FAILURE,
        initialFuncionariosRemovidosState,
        state,
        action
      );
  }
};

export const documentosContratoReducer = (state, action) => {
  return defaultReducer(
    model_contrato,
    FETCH_DOCUMENTOS_CONTRATO_BEGIN,
    FETCH_DOCUMENTOS_CONTRATO_SUCCESS,
    FETCH_DOCUMENTOS_CONTRATO_FAILURE,
    initialContratoState,
    state,
    action
  );
};

export const documentoReducer = (state, action) => {
  return defaultReducer(
    model,
    BEGIN,
    SUCCESS,
    FAILURE,
    initialState,
    state,
    action
  );
};
