import {
  CLEAR_FUNCIONARIO_ID,
  SET_FUNCIONARIO_ID,
} from '../actions/types';

export const initialState = {};

export default (state = initialState, action ) => {
  switch(action.type) {
    case CLEAR_FUNCIONARIO_ID:
      return {
        ...state,
        idFuncionario: undefined,
        fired: undefined,
      };
    case SET_FUNCIONARIO_ID:
      const isObject = typeof action.payload === 'object';

      return {
        ...state,
        idFuncionario: isObject ? action.payload?.id : action.payload,
        fired: isObject ? action.payload?.fired ?? false : false,
      };
    default: 
      return state;
  }
};
