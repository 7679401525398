import { SET_MODAL_OPEN, SET_MODAL_ID, SET_MODAL_EXTRA } from '../actions/types';

const initialState = {
    modalOpen: window.location.hash.replace('#', ''),
    id: localStorage.getItem('modal_id'),
    extraData: JSON.parse(localStorage.getItem('modal_extra'))
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_MODAL_OPEN:
            return {
                ...state,
                modalOpen: action.payload
            }
        case SET_MODAL_ID:
            return {
                ...state,
                id: action.payload
            }
        case SET_MODAL_EXTRA:
            return {
                ...state,
                extraData: action.payload
            }
        default: 
            return state;
    }
};