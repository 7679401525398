import {
  CLEAR_REFRESH,
  SET_REFRESH,
} from '../actions/types';

export const initialState = {};

export default (state = initialState, action ) => {
  switch(action.type) {
    case CLEAR_REFRESH:
      return {
        ...state,
        [action.payload]: false
      };
    case SET_REFRESH:
      return {
        ...state,
        [action.payload]: true
      };
    default: 
      return state;
  }
};