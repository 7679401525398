import {
    FETCH_CONTRATO_BEGIN as BEGIN,
    FETCH_CONTRATO_SUCCESS as SUCCESS,
    FETCH_CONTRATO_FAILURE as FAILURE,
    FETCH_CONTRATO_DOCUMENTOS_BEGIN,
    FETCH_CONTRATO_DOCUMENTOS_SUCCESS,
    FETCH_CONTRATO_DOCUMENTOS_FAILURE,
    FETCH_FUNCIONARIOS_CONTRATO_BEGIN,
    FETCH_FUNCIONARIOS_CONTRATO_SUCCESS,
    FETCH_FUNCIONARIOS_CONTRATO_FAILURE
} from '../actions/types'

const model = 'contrato'

export const initialState = {
    idContrato: '',
    titulo: '',
    descricao: '',
    terceiro: '',
    tomador: '',
    dataInicio: null,
    dataFim: null,
    status: 1,
    cnae: [],
    tipo: null,

    // empresa contratada
    idFilial: '',
    email: '',
    cnpj: '',
    nome: '',
    telefone: '',
    endereco: '',
    numero: '',
    cep: '',
    bairro: '',
    cidade: ''
}

export default function contratoReducer(state = {
        [model]: initialState,
        loading: false,
        error: null,
        documentos: {},
        loadingDocumentos: false,
        errorDocumentos: null,
        funcionarios: [],
        loadingFuncionarios: false,
        errorFuncionarios: null
    }, action) {
    switch(action.type) {
        case BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case SUCCESS:
            return {
                ...state,
                loading: false,
                [model]: action.payload[model],
                error: action.payload.error ? action.payload.error : null
            };

        case FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                [model]: initialState
            };

        case FETCH_CONTRATO_DOCUMENTOS_BEGIN:
            return {
                ...state,
                loadingDocumentos: true,
                errorDocumentos: null
            };

        case FETCH_CONTRATO_DOCUMENTOS_SUCCESS:
            return {
                ...state,
                loadingDocumentos: false,
                documentos: action.payload.documentos
            };

        case FETCH_CONTRATO_DOCUMENTOS_FAILURE:
            return {
                ...state,
                loadingDocumentos: false,
                errorDocumentos: action.payload.error,
                documentos: {}
            };

        case FETCH_FUNCIONARIOS_CONTRATO_BEGIN:
            return {
                ...state,
                loadingFuncionarios: true,
                errorFuncionarios: null
            };

        case FETCH_FUNCIONARIOS_CONTRATO_SUCCESS:
            return {
                ...state,
                loadingFuncionarios: false,
                funcionarios: action.payload.funcionarios
            };

        case FETCH_FUNCIONARIOS_CONTRATO_FAILURE:
            return {
                ...state,
                loadingFuncionarios: false,
                errorFuncionarios: action.payload.error,
                funcionarios: []
            };

        default:
            return state;
    }
}