import {
    FETCH_ENVIO_BEGIN as BEGIN,
    FETCH_ENVIO_SUCCESS as SUCCESS,
    FETCH_ENVIO_FAILURE as FAILURE
} from '../actions/types'
import defaultReducer from './defaultReducer'

const model = 'envio'

export const initialState = {
    idDocumentoEnvio: null,
    dataEmissao: null,
    validade: null,
    contratos: [],
    versoes: [],
    status: 0,
    porcentagem: 0,
    aguardando: 0,
    validos: 0,
    invalidos: 0
}

export default function enviosReducer(state, action){ return defaultReducer(model, BEGIN, SUCCESS, FAILURE, initialState, state, action) }