import { SET_VISIBILITY_FILTER } from '../actions/types';

const initialState = {
    controleAccordion: {
        Contratos: false,
        Documentos: false,
        Funcionarios: false,
        HomeInfo: true,
    }
};

export default function(state = initialState, action ) {
    switch(action.type) {
        case SET_VISIBILITY_FILTER:
            return {
                ...state,
                controleAccordion: action.payload
            }
        default: 
            return state;
    }
};