/* Geral / Bibliotecas */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-toggle/style.css";
import Select from "react-select";

/* Cores */
import { COLOR_DARK2 } from "../../Colors";

/* Métodos / Ações */
import { saveConfiguracoes } from "../../actions/usuarios";
import { clearErrors } from "../../actions/errors";
import { clearFuncionarioId } from "../../actions/scroll";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";

/* Botões */
import ButtonSuccess from "../Buttons/ButtonSuccess";

/* Campos */
import Thumb from "../CustomFields/Thumb";

/* Validação */
import { requiredField, objectField } from "../../validation/formValidation";

const MySwal = withReactContent(Swal);

const emailOptions = [
  { value: "D", label: "Diariamente" },
  { value: "S", label: "Semanalmente" },
  { value: "N", label: "Nunca" },
];

class Configuracoes extends Component {
  constructor() {
    super();

    this._isMounted = false;
    this.state = {
      loading: true,
    };
  }

  handleSubmit(values, actions) {
    if (this._isMounted)
      this.props.dispatch(saveConfiguracoes(values, actions));
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
    this._isMounted = true;

    this.props.dispatch(clearFuncionarioId());
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      let show = false;
      if (Object.keys(nextProps.errors)?.length) {
        show = true;
      }
      if (show) {
        MySwal.fire({
          type: "error",
          title: nextProps.errors.message,
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 3000,
        });
        this.props.dispatch(clearErrors());
      }
    }
  }

  render() {
    if (this.props.error) {
      return <Error erro={this.props.error}></Error>;
    }

    if (this.state.loading) {
      return <LoadingImage></LoadingImage>;
    }

    const getValue = (opts, val) => opts.find((o) => o.value === val);

    return (
      <div className="py-5">
        <h4
          className="text-center font-weight-400"
          style={{ color: COLOR_DARK2 }}
        >
          Seu perfil
        </h4>
        <div className="row px-0 px-lg-5 mx-0 justify-content-center">
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="card-header">
                Informações
                <span className="small">Seus dados</span>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={this.props.dados}
                  enableReinitialize={true}
                  onSubmit={this.handleSubmit.bind(this)}
                  render={({
                    isSubmitting,
                    setFieldValue,
                    handleSubmit,
                    validateField,
                    values,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="nome">Nome *</label>
                            <Field
                              type="text"
                              autoComplete="username"
                              id="nome"
                              name="nome"
                              className="form-control"
                              validate={requiredField}
                            />
                            <ErrorMessage
                              name="nome"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="nome">Email</label>
                            <div className="w-100">
                              {this.props.dados.email}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="senha">
                              Senha
                              <span className="small">
                                {" "}
                                Digite para alterar
                              </span>
                            </label>
                            <Field
                              autoComplete="new-password"
                              type="password"
                              id="senha"
                              name="senha"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="senha"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="confirmaSenha">
                              Confirme a senha
                            </label>
                            <Field
                              autoComplete="new-password"
                              type="password"
                              id="confirmaSenha"
                              name="confirmaSenha"
                              className="form-control"
                            />
                            <ErrorMessage
                              name="confirmaSenha"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group form-check">
                            <Field
                              type="checkbox"
                              name="receberNotificacoesImediatas"
                              className="form-check-input"
                              id="receberNotificacoesImediatas"
                              checked={values.receberNotificacoesImediatas}
                            />
                            <label htmlFor="receberNotificacoesImediatas">
                              Receber notificações sobre movimentações de
                              contrato imediatamente?
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group form-check">
                            <Field
                              type="checkbox"
                              name="apenasNotificacoesResponsavel"
                              className="form-check-input"
                              id="apenasNotificacoesResponsavel"
                              checked={values.apenasNotificacoesResponsavel}
                            />
                            <label htmlFor="apenasNotificacoesResponsavel">
                              Receber notificações apenas de Contratos que sou
                              responsável
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group form-check">
                            <Field
                                type="checkbox"
                                name="mostrarApenasSouResponsavel"
                                className="form-check-input"
                                id="mostrarApenasSouResponsavel"
                                checked={values.mostrarApenasSouResponsavel}
                            />
                            <label htmlFor="mostrarApenasSouResponsavel">
                              Ver apenas Contratos que sou Responsável
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="emailResumo">
                              Email de Resumo *
                            </label>
                            <Field name="emailResumo" validate={objectField}>
                              {({ field }) => {
                                return (
                                  <Select
                                    {...field}
                                    placeholder="Digite para localizar..."
                                    id="emailResumo"
                                    options={emailOptions}
                                    defaultValue={getValue(
                                      emailOptions,
                                      this.props.dados.emailResumo
                                    )}
                                    value={getValue(
                                      emailOptions,
                                      values.emailResumo
                                    )}
                                    onChange={(value) => {
                                      setFieldValue("emailResumo", value.value);
                                    }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary50: "#ebebeb",
                                        primary25: "#ebebeb",
                                        primary: "#ff5818",
                                      },
                                    })}
                                  />
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name="emailResumo"
                              component="small"
                              className="form-text text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Foto atual</label>
                            <img
                              style={{
                                maxWidth: "200px",
                              }}
                              className="img-fluid rounded-circle d-block mx-auto"
                              src={
                                this.props.dados.foto
                                  ? this.props.dados.foto
                                  : "/images/user.png"
                              }
                              alt={this.props.dados.nome}
                              title={this.props.dados.nome}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text"
                                  id="inputGroupFileAddon01"
                                >
                                  Logo
                                </span>
                              </div>
                              <div className="custom-file">
                                <input
                                  type="file"
                                  className="custom-file-input"
                                  id="foto"
                                  name="foto"
                                  aria-describedby="inputGroupFileAddon01"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "foto",
                                      event.target.files[0]
                                    );
                                  }}
                                />
                                <label
                                  className="custom-file-label"
                                  htmlFor="foto"
                                >
                                  Escolher arquivo
                                </label>
                              </div>
                            </div>
                            <ErrorMessage
                              name="foto"
                              component="small"
                              className="form-text text-danger"
                            />
                            <Thumb file={values.foto} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                          <ButtonSuccess type="submit" disabled={isSubmitting}>
                            <FontAwesomeIcon icon="save" /> Salvar
                          </ButtonSuccess>
                        </div>
                      </div>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Configuracoes.propTypes = {
  dados: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  dados: state.usuarioLogado.dados,
});

export default connect(mapStateToProps)(withRouter(Configuracoes));
