import {
    FETCH_MODELOS_BEGIN as BEGIN,
    FETCH_MODELOS_SUCCESS as SUCCESS,
    FETCH_MODELOS_FAILURE as FAILURE
} from '../actions/types'
import defaultReducer from './defaultReducer'

const model = 'modelo'

export const initialState = []

export default function modelosReducer(state, action){ return defaultReducer(model, BEGIN, SUCCESS, FAILURE, initialState, state, action) }