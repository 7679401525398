import React from "react";

import { DEMITIDO } from "../DocumentosFuncionarioTable";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import ButtonGray from "../../Buttons/ButtonGray";

const TrocaContratoButton = ({
  contratoFilter,
  filialFilter,
  funcionario,
  handleAprovaReprovaTrocaContrato,
  handleLoadContratos,
}) => {
  if (!contratoFilter) return null;

  if (funcionario.alterarContrato) {
    return (
      <>
        {funcionario.solicitanteAlteracao === filialFilter.idFilial ? (
          <small className="float-right py-1 text-danger">
            <FontAwesomeIcon icon="exchange-alt" /> Troca solicitada para{" "}
            <b>{funcionario.alterarContratoTitulo}</b>
          </small>
        ) : (
          <ButtonPrimary
            className="float-right py-1"
            onClick={() => {
              handleAprovaReprovaTrocaContrato(funcionario);
            }}
          >
            <FontAwesomeIcon icon="exchange-alt" /> Troca solicitada
          </ButtonPrimary>
        )}
      </>
    );
  }

  if (funcionario.statusContratoFuncionario !== DEMITIDO) {
    return (
      <ButtonGray
        onClick={() => {
          handleLoadContratos(funcionario.idFuncionario);
        }}
        type="button"
        className="float-right py-1 ml-2"
        title="Solicitar troca deste funcionário para outro contrato desta mesma empresa"
      >
        <small>
          <FontAwesomeIcon icon="exchange-alt" />
          <span className="d-none d-md-inline-block ml-2">
            {" "}
            Troca de contrato
          </span>
        </small>
      </ButtonGray>
    );
  }

  return null;
};

export default TrocaContratoButton;
