import React, { memo, useMemo } from "react";

import { parseISO, isFuture } from "date-fns";
import { formatDate } from "../../../helpers/formatDate";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const DoubleClickableCell = ({
  view = "funcionarios",
  cellInfo,
  handleOpen,
}) => {
  const currentColumn = useMemo(() => cellInfo?.column.Header, [cellInfo]);
  const afastamento = useMemo(() => cellInfo?.original.afastamento, [cellInfo]);
  const afastamentoDataInicio = useMemo(() => parseISO(afastamento?.dataInicio), [afastamento]);
  const afastamentoDataFim = useMemo(() => parseISO(afastamento?.dataFim), [afastamento]);

  return (
    <div
      onDoubleClick={() =>
        handleOpen(
          view === "contratos"
            ? cellInfo?.original
            : cellInfo?.original.idFilialFuncionario
        )
      }
    >
      {cellInfo?.value}
      {currentColumn === "Nome" &&
        afastamento &&
        isFuture(afastamentoDataFim) && (
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover className="p-1" id="afastamento-tooltip">
                {formatDate(afastamentoDataInicio)} até{" "}
                {formatDate(afastamentoDataFim)}
              </Popover>
            }
          >
            <small
              className="badge-pill badge-danger py-1 px-2 mx-1 font-weight-bold text-uppercase"
              style={{ background: "#f15923" }}
            >
              {afastamento?.tipoAfastamentoNome}
            </small>
          </OverlayTrigger>
        )}
    </div>
  );
};

export default memo(DoubleClickableCell);
