import { combineReducers } from 'redux'

import errorReducer from './errorReducer'
import modalReducer from './modalReducer'
import authReducer from './authReducer'
import visibilityReducer from './visibilityReducer'
import permissionsReducer from './permissionsReducer'
import notificacoesReducer from './notificacoesReducer'
import empresasReducer from './empresasReducer'
import filiaisReducer from './filiaisReducer'
import funcionariosReducer from './funcionariosReducer'
import usuariosReducer from './usuariosReducer'
import cnaesReducer from './cnaesReducer'
import departamentosReducer from './departamentosReducer'
import usuarioLogadoReducer from './usuarioLogadoReducer'
import pesquisaReducer from './pesquisaReducer'
import enviosReducer from './enviosReducer'
import dadosReducer from './dadosReducer'
import contratosReducer from './contratosReducer'
import modelosReducer from './modelosReducer'
import gestaoDocumentosReducer from './gestaoDocumentosReducer'
import { documentosReducer, documentoReducer, documentosFuncionariosReducer, documentosFuncionariosRemovidosReducer, documentosContratoReducer } from './documentosReducer'
import cadastroEmpresaReducer from './cadastroEmpresaReducer'
import comentariosReducer from './comentariosReducer'
import planosReducer from './planosReducer'
import scrollReducer from './scrollReducer'
import exportacaoReducer from './exportacaoReducer'
import filialFuncionarioReducer from './filialFuncionarioReducer'
import refreshReducer from './refreshReducer'

export default combineReducers({
    errors: errorReducer,
    permissions: permissionsReducer,
    auth: authReducer,
    visibility: visibilityReducer,
    modal: modalReducer,
    empresas: empresasReducer,
    filiais: filiaisReducer,
    funcionarios: funcionariosReducer,
    usuarios: usuariosReducer,
    contratos: contratosReducer,
    cnaes: cnaesReducer,
    departamentos: departamentosReducer,
    usuarioLogado: usuarioLogadoReducer,
    pesquisa: pesquisaReducer,
    documentos: documentosReducer,
    documentosFuncionarios: documentosFuncionariosReducer,
    documentosFuncionariosRemovidos: documentosFuncionariosRemovidosReducer,
    documentosContrato: documentosContratoReducer,
    documento: documentoReducer,
    envios: enviosReducer,
    dados: dadosReducer,
    modelos: modelosReducer,
    gestaoDocumentos: gestaoDocumentosReducer,
    cadastroEmpresa: cadastroEmpresaReducer,
    notificacoes: notificacoesReducer,
    exportacao: exportacaoReducer,
    comentarios: comentariosReducer,
    planos: planosReducer,
    scroll: scrollReducer,
    filialFuncionario: filialFuncionarioReducer,
    refresh: refreshReducer,
});
