/* Geral / Bibliotecas */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTable from "react-table";
import "react-table/react-table.css";

/* Botões */
import ButtonSuccess from "../Buttons/ButtonSuccess";

/* Cores */
import { COLOR_DARK2 } from "../../Colors";

import { getContratoList } from "../../actions/documentos";
import { portContractDocuments } from "../../actions/documentos";
import { setModalOpen } from "../../actions/modal";

const DocumentsPortability = ({
  dispatch,
  afterSave,
  appData,
  selectedContract,
  dados,
  documentosContrato,
  isVisible,
}) => {
  const [selected, setSelected] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);

  const contrato = useMemo(() => appData?.filtros?.contrato ?? null, [appData]);

  const isSubmitDisabled = useMemo(
    () => !documents.length || !selected.length || loading,
    [documents.length, loading, selected.length]
  );

  const handleSubmit = useCallback(() => {
    dispatch(
      portContractDocuments(
        {
          contratoOrigem: selectedContract.idContrato,
          contratoDestino: contrato?.idContrato,
          documentos: selected,
        },
        afterSave
      )
    );
    dispatch(setModalOpen(""));
  }, [dispatch, selectedContract, contrato, selected, afterSave]);

  const toggleDocument = useCallback(
    (docId) =>
      setSelected((prev) =>
        prev.includes(docId)
          ? prev.filter((documentId) => documentId !== docId)
          : [...prev, docId]
      ),
    []
  );

  const toggleAllDocuments = useCallback(
    () =>
      setSelected((prev) =>
        prev.length === documents.length
          ? []
          : documents.map((document) => document.idDocumento)
      ),
    [documents]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "letra",
        filterable: false,
        sortable: false,
        className: "option-column-5 letra-inicial",
        headerClassName: "option-column-5",
        Cell: (props) => (
          <div
            style={{
              borderTop:
                props.original.letra !== "" ? "2px solid white" : "none",
              borderTopRightRadius:
                props.original.letra !== "" ? "calc(.25rem - 1px)" : "0",
              borderTopLeftRadius:
                props.original.letra !== "" ? "calc(.25rem - 1px)" : "0",
              borderBottomRightRadius:
                props.original.letra_len > 1 && props.original.letra !== ""
                  ? "0"
                  : "calc(.25rem - 1px)",
              borderBottomLeftRadius:
                props.original.letra_len > 1 && props.original.letra !== ""
                  ? "0"
                  : "calc(.25rem - 1px)",
            }}
          >
            {props.original.letra !== "" ? props.original.letra : " "}
          </div>
        ),
      },
      {
        Header: "Nome",
        accessor: "nome",
        headerClassName: "nome-placeholder documentos-filtro",
        className: "font-weight-600 text-uppercase",
        filterable: true,
        Cell: (props) => (
          <div className="d-flex align-items-center">
            <Toggle
              checked={selected.includes(props.original.idDocumento)}
              className="mr-3"
              onChange={() => toggleDocument(props.original.idDocumento)}
            />
            {" " + props.original.nome}
          </div>
        ),
      },
    ],
    [selected, toggleDocument]
  );

  const handleClose = useCallback(() => {
    setLoading(true);
    setSelected([]);
    setDocuments([]);
  }, []);

  const handleLetter = useCallback((document, _, arr) => {
    const letra =
      arr.find((d) => d.nome.startsWith(document.nome[0])).idDocumento ===
      document.idDocumento
        ? document.nome?.[0]?.toUpperCase()
        : "";

    return {
      ...document,
      letra,
    };
  }, []);

  useEffect(() => {
    if (!isVisible || !selectedContract?.idContrato) {
      handleClose();
      return;
    }

    const loadData = async () => {
      const data = await getContratoList({
        contrato: selectedContract.idContrato,
        empresa: appData.filtros?.empresa ?? dados?.[0] ?? null,
      });
      setDocuments(
        (
          data?.filter(
            (document) =>
              !!documentosContrato?.find(
                (d) => d.idDocumento === document.idDocumento
              )
          ) ?? []
        )
          .sort((a, b) => a.nome.localeCompare(b.nome))
          .map(handleLetter)
      );
    };

    loadData().finally(() => setLoading(false));
  }, [
    selectedContract,
    appData,
    dados,
    documentosContrato,
    isVisible,
    handleClose,
    handleLetter,
  ]);

  return (
    <div className="pb-5">
      <div className="row mx-0 d-flex align-items-center justify-content-center">
        <div className="col-12 px-4">
          <div className="card">
            <div className="card-body px-2">
              <h5
                className="font-weight-500 mb-3"
                style={{ color: COLOR_DARK2 }}
              >
                <FontAwesomeIcon icon="signature" /> {selectedContract?.nome}
              </h5>
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={toggleAllDocuments}
                    className="small position-absolute"
                    style={{
                      right: "24px",
                      top: "8px",
                      zIndex: "999",
                      color: COLOR_DARK2,
                    }}
                  >
                    {selected.length === documents.length
                      ? "Remover seleção"
                      : "Selecionar todos"}
                  </button>
                  <ReactTable
                    columns={columns}
                    multiSort={false}
                    data={documents}
                    loading={loading}
                    previousText={<FontAwesomeIcon icon="chevron-left" />}
                    nextText={<FontAwesomeIcon icon="chevron-right" />}
                    resizable={false}
                    loadingText="Carregando..."
                    noDataText="Nenhum documento encontrado"
                    pageText="Página"
                    ofText="de"
                    rowsText="linhas"
                    pageJumpText="ir para a página"
                    rowsSelectorText="linhas por página"
                    pageSizeOptions={[5, 10, 20, 50]}
                    defaultPageSize={10}
                    minRows={0}
                    sortable={false}
                    className="-highlight"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                  <ButtonSuccess
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled}
                    type="submit"
                  >
                    <FontAwesomeIcon icon="copy" /> Portar documentos
                  </ButtonSuccess>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentsPortability.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  selectedContract: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  selectedContract: state.modal.extraData,
  appData: state.dados.data,
  dados: state.gestaoDocumentos.gestaoDocumentos || [],
  documentosContrato: state.documentosContrato.contrato,
});

export default connect(mapStateToProps)(withRouter(DocumentsPortability));
