import { SET_APP_DATA } from "../actions/types";

const initialState = {
  data: JSON.parse(localStorage.getItem("tertium_app_data")),
};

export default function (state = initialState, action) {
  if (state.data === null) {
    state.data = {};
  }

  switch (action.type) {
    case SET_APP_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}
