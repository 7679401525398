/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import AsyncSelect from 'react-select/lib/Async'

/* Métodos / Ações */
import { fetch, saveFilial, deleteFilial, changeStatusFilial } from '../../actions/filiais'
import { loadCidadesOptions } from '../../actions/cidades'
import { clearErrors } from '../../actions/errors'

/* Componentes */
import Error from '../Error'
import LoadingImage from '../LoadingImage'

/* Botões */
import ButtonSuccess from '../Buttons/ButtonSuccess'
import ButtonGray from '../Buttons/ButtonGray'
import ButtonWarning from '../Buttons/ButtonWarning'

/* Campos */
import Telefone from '../CustomFields/Telefone'
import Cnpj from '../CustomFields/Cnpj'
import Cep from '../CustomFields/Cep'

/* Validação */
import { requiredField, emailField, phoneField, cnpjField, objectField, cepField } from '../../validation/formValidation'

const MySwal = withReactContent(Swal)

class FilialForm extends Component {
    constructor() {
        super()
        
        this._isMounted = false
        this.state = {}
    }

    handleSubmit(values, actions) {
        this.props.dispatch(saveFilial(values, actions, this.props.onAfterSave))
    }

    componentDidMount() {
        this._isMounted = true
        if(this.props.id)
            this.props.dispatch(fetch(this.props.id))
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            let show = false;
            if(Object.keys(nextProps.errors)?.length){
                show = true
            }
            /*this.setState({
                errors: nextProps.errors
            })*/
            if(show){
                MySwal.fire({
                    type: 'error',
                    title: nextProps.errors.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                this.props.dispatch(clearErrors())
            }
        }
    }

    handleDelete(){
        MySwal.fire({
            title: '',
            type: 'warning',
            html: 'Deseja realmente excluir a filial <b>'+this.props.filial.nome+'</b>, essa ação não pode ser revertida?',
            showCancelButton: true,
            confirmButtonText: 'Sim, excluir',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if(result.value){
                this.setState({
                    loading: true
                })
                this.props.dispatch(deleteFilial(this.props.filial.idFilial, this.props.onAfterSave, ()=>{
                    this.setState({loading: false})
                }))
            }
        })
    }

    handleChangeStatus(){
        var st = this.props.filial.status === 1 ? 0 : 1;
        this.setState({
            loading: true
        })
        this.props.dispatch(changeStatusFilial(this.props.filial.idFilial, st, this.props.onAfterSave, ()=>{
            this.setState({loading: false})
        }))
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>
        }

        if (this.props.loading) {
            return <LoadingImage></LoadingImage>
        }

        return(
            <div className="pb-5">
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-12 px-4">
                        <div className="card">
                            <div className="card-body px-2">
                            <Formik
                                initialValues={this.props.filial}
                                onSubmit={this.handleSubmit.bind(this)}
                                render={({ isSubmitting, setFieldValue, values, handleSubmit, validateField }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="cnpj">CNPJ *</label>
                                                <Field name="cnpj" validate={cnpjField}>{({ field }) => {
                                                    return <Cnpj mounted={this._isMounted} callback={(data)=>{
                                                        if(typeof data.nome === 'undefined') return
                                                        setFieldValue('nome', data.nome)
                                                        setFieldValue('telefone', data.telefone)
                                                        setFieldValue('endereco', data.logradouro)
                                                        setFieldValue('numero', data.numero)
                                                        setFieldValue('cep', data.cep.replace('.', ''))
                                                        setFieldValue('bairro', data.bairro)
                                                        setFieldValue('cidade', data.cidade)
                                                        if(data.tipo === 'MATRIZ'){
                                                            setFieldValue('matriz', 1)
                                                        } else {
                                                            setFieldValue('matriz', 0)
                                                        }
                                                        }} {...field} id="cnpj"></Cnpj>
                                                }}</Field>
                                                <ErrorMessage name="cnpj" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="nome">Nome *</label>
                                                <Field type="text" id="nome" name="nome" className="form-control" validate={requiredField} />
                                                <ErrorMessage name="nome" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="email">E-mail *</label>
                                                <Field type="email" id="email" name="email" className="form-control" validate={emailField} />
                                                <ErrorMessage name="email" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="telefone">Telefone *</label>
                                                <Field name="telefone" validate={phoneField}>{({ field }) => {
                                                    return <Telefone {...field} id="telefone"></Telefone>
                                                }}</Field>
                                                <ErrorMessage name="telefone" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="inscricaoMunicipal">Inscrição Municipal</label>
                                                <Field type="text" id="inscricaoMunicipal" name="inscricaoMunicipal" className="form-control"/>
                                                <ErrorMessage name="inscricaoMunicipal" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="inscricaoEstadual">Inscrição Estadual</label>
                                                <Field type="text" id="inscricaoEstadual" name="inscricaoEstadual" className="form-control"/>
                                                <ErrorMessage name="inscricaoEstadual" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="cep">CEP *</label>
                                                <Field name="cep" validate={cepField}>{({ field }) => {
                                                    return <Cep onBlur={() => validateField('cep')} mounted={this._isMounted} callback={(data)=>{
                                                        if(data.erro !== true){
                                                            setFieldValue('endereco', data.logradouro)
                                                            setFieldValue('bairro', data.bairro)
                                                            setFieldValue('pontoReferencia', data.complemento)
                                                            setFieldValue('cidade', data.cidade)
                                                        }
                                                        }} {...field} id="cep"></Cep>
                                                }}</Field>
                                                <ErrorMessage name="cep" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="endereco">Endereço *</label>
                                                <Field type="text" id="endereco" name="endereco" className="form-control" validate={requiredField} />
                                                <ErrorMessage name="endereco" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="numero">Número</label>
                                                <Field type="text" id="numero" name="numero" className="form-control" />
                                                <ErrorMessage name="numero" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="numero">Bairro *</label>
                                                <Field type="text" id="bairro" name="bairro" className="form-control" validate={requiredField}/>
                                                <ErrorMessage name="bairro" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="pontoReferencia">Ponto de Referência</label>
                                                <Field type="text" id="pontoReferencia" name="pontoReferencia" className="form-control" />
                                                <ErrorMessage name="pontoReferencia" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="cidade" className="w-100">Cidade *</label>
                                                <Field name="cidade" validate={objectField}>{({ field }) => {
                                                    return <AsyncSelect {...field} 
                                                        placeholder="Digite para localizar..." 
                                                        id="cidade" 
                                                        isSearchable
                                                        defaultValue={this.props.filial.cidade}
                                                        getOptionValue={(option) => (option['idCidade'])}
                                                        getOptionLabel={(option) => (option['nome'] + ' - '  + option['estado']['uf'])}
                                                        loadOptions={loadCidadesOptions}
                                                        onChange={(value)=>{
                                                            setFieldValue("cidade", value)
                                                        }}
                                                        noOptionsMessage={(input)=>{
                                                            return input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado"
                                                        }}
                                                        loadingMessage={()=>"Carregando..."}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                            ...theme.colors,
                                                                primary50: '#ebebeb',
                                                                primary25: '#ebebeb',
                                                                primary: '#ff5818',
                                                            },
                                                        })} />
                                                }}</Field>
                                                <ErrorMessage name="cidade" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className="col-12">
                                            <div className="form-group d-flex align-items-center">
                                                <Field name="matriz">{({ field }) => {
                                                    return <Toggle
                                                        name="matriz"
                                                        id='matriz'
                                                        checked={field.value === 1}
                                                        onChange={(evt)=>{
                                                            setFieldValue("matriz", evt.target.checked ? 1 : 0)
                                                        }} />
                                                }}</Field>
                                                <label htmlFor="matriz" className="pl-1 mb-0">Matriz</label>
                                                <ErrorMessage name="matriz" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                                            {this.props.filial.status === 1 &&
                                                <ButtonSuccess type="submit" disabled={isSubmitting}><FontAwesomeIcon icon="save"/> Salvar</ButtonSuccess>
                                            }
                                            {(this.props.filial.idFilial !== '' && this.props.filial.status === 0) &&
                                                <ButtonSuccess type="button" onClick={this.handleChangeStatus.bind(this)} disabled={isSubmitting}><FontAwesomeIcon icon="check"/> Ativar</ButtonSuccess>
                                            }
                                            {(this.props.filial.idFilial !== '' && this.props.filial.status === 1 && this.props.filial.matriz !== 1) &&
                                                <ButtonWarning type="button" onClick={this.handleChangeStatus.bind(this)} className="ml-3" disabled={isSubmitting}><FontAwesomeIcon icon="times"/> Bloquear</ButtonWarning>
                                            }
                                            {(this.props.filial.idFilial !== '' && this.props.filial.matriz !== 1) &&
                                                <ButtonGray type="button" onClick={this.handleDelete.bind(this)} className="ml-3" disabled={isSubmitting}><FontAwesomeIcon icon="trash"/> Excluir</ButtonGray>
                                            }
                                        </div>
                                    </div>
                                </Form>
                                )}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
FilialForm.propTypes = {
    filial: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    id: PropTypes.any,
    onAfterSave: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    filial: state.filiais.filial,
    loading: state.filiais.loading,
    error: state.filiais.error,
    errors: state.errors,
    id: state.modal.id
})

export default connect(mapStateToProps)(withRouter(FilialForm));