import axios from 'axios'
import { FETCH_PESQUISA_BEGIN, FETCH_PESQUISA_SUCCESS, FETCH_PESQUISA_FAILURE } from './types'
import { URL_API } from './urls'
import { initialPesquisaState } from '../reducers/pesquisaReducer'

export const clearPesquisa = () => ({
    type: FETCH_PESQUISA_SUCCESS,
    payload: { dados: initialPesquisaState }
})

export const fetchPesquisaBegin = () => ({
  type: FETCH_PESQUISA_BEGIN
});

export const fetchPesquisaSuccess = dados => ({
  type: FETCH_PESQUISA_SUCCESS,
  payload: { dados }
});

export const fetchPesquisaFailure = error => ({
  type: FETCH_PESQUISA_FAILURE,
  payload: { error }
});

export function fetchPesquisa(search = '') {
    return dispatch => {
        dispatch(fetchPesquisaBegin());

        axios.post(URL_API + 'pesquisa/', {s: search})
        .then(res => {
            dispatch(fetchPesquisaSuccess(res.data))
            return res.data
        })
        .catch(err => {
            let erro = {message: "Não foi possível obter os dados do funcionário"}
            if(err.response){
                erro = err.response.data
            }
            dispatch(fetchPesquisaFailure(erro))
        });
    };
}