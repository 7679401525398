import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import posed from "react-pose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logoutUser } from "../actions/authentication";
import {
    COLOR_PRIMARY,
    COLOR_DARK,
    COLOR_WARNING,
    COLOR_DANGER,
} from "../Colors";
import "./TopNavbar.css";
import { setModalOpen } from "../actions/modal";
import { montaNotificacoes } from "../actions/notificacoes";
import { montaCentralExportacao } from "../actions/exportacao";
import { refreshNotificacoes } from "../helpers/notificacoes";
import { refreshExportacao } from "../helpers/exportacao";
import ButtonSuccess from "../components/Buttons/ButtonSuccess";
import { URL_API } from "../actions/urls";

const REFRESH_CENTRAL_EXPORTACAO_INTERVAL = 15000;

const MySwal = withReactContent(Swal);

const Pesquisa = posed.input({
    visible: {
        width: "auto",
        opacity: 1,
        transition: {
            width: { ease: "linear", duration: 250 },
            opacity: { ease: "linear", duration: 0 },
        },
    },
    hidden: {
        width: 0,
        opacity: 0,
        transition: {
            width: { ease: "linear", duration: 500 },
            opacity: { ease: "linear", duration: 450 },
        },
    },
});

class TopNavbar extends Component {
    constructor() {
        super();
        this.state = {
            pesquisa: "",
            pesquisaAberta: false,
            pesquisaFocused: false,
            qt_notificacoes: 0,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    refreshCentralExportacaoInterval = null;

    componentDidMount() {
        this.props.refreshNotificacoes();
        if (this.props.exportacao.length === 0) this.props.refreshExportacao();

        this.refreshCentralExportacaoInterval = setInterval(
            () => this.props.refreshExportacao(),
            REFRESH_CENTRAL_EXPORTACAO_INTERVAL
        );

        axios.interceptors.response.use(
            function (response) {
                if (response.data.update_notifications)
                    this.props.refreshNotificacoes();

                return response;
            }.bind(this),
            function (error) {
                return Promise.reject(error);
            }
        );
    }

    componentWillUnmount() {
        clearInterval(this.refreshCentralExportacaoInterval);
    }

    onLogout(e) {
        e.preventDefault();
        this.props.logoutUser(this.props.history);
    }

    showPesquisa() {
        if (this.state.pesquisaAberta === false) {
            this.setState(
                {
                    pesquisaAberta: true,
                },
                () => {
                    document.querySelectorAll('input[name="s"]')[0].focus();
                    if (!this.state.pesquisaFocused)
                        setTimeout(() => {
                            if (!this.state.pesquisaFocused)
                                this.setState({ pesquisaAberta: false });
                        }, 5000);
                }
            );
        } else {
            this.onSubmit();
        }
    }

    onSubmit(evt) {
        if (evt) evt.preventDefault();
        if (this.state.pesquisa !== "") {
            this.props.history.push(
                "/pesquisa/" + encodeURIComponent(this.state.pesquisa)
            );
        }
    }

    focusPesquisa() {
        this.setState({
            pesquisaFocused: true,
        });
    }

    blurPesquisa() {
        this.setState({
            pesquisaFocused: false,
        });
        if (this.state.pesquisa === "") {
            this.setState({ pesquisaAberta: false });
        }
    }

    changePesquisa(evt) {
        this.setState({
            pesquisa: evt.target.value,
        });
    }

    solicitarUpgrade = () => {
        MySwal.fire({
            type: "question",
            confirmButtonText: "Solicitar",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            title: "Upgrade",
            html: (
                <p className="h5">
                    Deseja solicitar upgrade do seu plano atual?
                    <br />
                    <span className="small">
                        Algum de nossos atendentes entrará em contato para
                        acertar os detalhes.
                    </span>
                </p>
            ),
        }).then((result) => {
            if (result.value) {
                axios
                    .post(URL_API + "upgrade-plano")
                    .then((res) => {
                        MySwal.fire({
                            toast: true,
                            position: "center",
                            showConfirmButton: false,
                            timer: 3000,
                            type: "success",
                            title: "Solicitação enviada com sucesso",
                        });
                    })
                    .catch((err) => {
                        MySwal.fire({
                            toast: true,
                            position: "center",
                            showConfirmButton: false,
                            timer: 3000,
                            type: "error",
                            title: "Não foi possível enviar sua solicitação",
                        });
                    });
            }
        });
    };

    render() {
        const { isAuthenticated } = this.props.auth;
        if (!isAuthenticated) return null;

        const notificacoes = montaNotificacoes(
            this.props.notificacoes,
            this.props.dispatch,
            this.props.history
        );

        const exportacao = montaCentralExportacao(this.props.exportacao);

        return (
            <div
                className="w-100"
                style={{
                    backgroundImage:
                        "linear-gradient(to right, #1a1a18, #20201e, #2c2b29, #33322f, #33322f)",
                }}
            >
                {this.props.usuarioLogado?.sem_cnaes &&
                    this.props.permissoes.administradorEmpresa === 1 && (
                        <div
                            style={{
                                backgroundColor: COLOR_WARNING,
                                padding: "15px",
                                fontWeight: 500,
                                color: COLOR_DARK,
                            }}
                            onClick={() => {
                                this.props.history.push("/empresa");
                                this.props.modalOpen("edit");
                            }}
                            className="w-100 text-center cursor-pointer"
                        >
                            <FontAwesomeIcon icon="exclamation-triangle" /> Sua
                            empresa não tem nenhum <b>CNAE</b> selecionado,
                            portanto nenhum documento será exibido, clique aqui
                            para selecionar.
                        </div>
                    )}
                {this.props.usuarioLogado?.sem_plano &&
                    this.props.permissoes.administradorEmpresa === 1 && (
                        <div
                            style={{
                                backgroundColor: COLOR_PRIMARY,
                                padding: "15px",
                                fontWeight: 500,
                                color: "white",
                            }}
                            className="w-100 text-center"
                        >
                            <FontAwesomeIcon icon="exclamation-triangle" /> Sua
                            empresa não tem nenhum <b>PLANO</b> selecionado,
                            para ter acesso a todas as funcionalidades, entre em
                            contato para contratar um plano.
                        </div>
                    )}
                {this.props.pendenciasPlanos?.length > 0 && (
                    <>
                        <div
                            style={{
                                backgroundColor: COLOR_DANGER,
                                paddingLeft: "15px",
                                paddingRight: "15px",
                                paddingBottom: "0px",
                                paddingTop: "10px",
                                fontWeight: 500,
                                color: "white",
                            }}
                            className="w-100 text-center"
                        >
                            <div className="row">
                                <div className="col d-flex align-items-center justify-content-center justify-content-sm-end">
                                    <h4 className="mb-0">
                                        <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                                        ATENÇÃO!
                                    </h4>
                                </div>
                                <div className="col d-flex align-items-center">
                                    <ButtonSuccess
                                        onClick={this.solicitarUpgrade}
                                    >
                                        <FontAwesomeIcon icon="angle-double-up" />
                                        &nbsp;SOLICITAR UPGRADE DE PLANO
                                    </ButtonSuccess>
                                </div>
                            </div>
                        </div>
                        {this.props.pendenciasPlanos?.map((val, idx) => (
                            <div
                                key={idx}
                                style={{
                                    backgroundColor: COLOR_DANGER,
                                    padding: "15px",
                                    fontWeight: 500,
                                    color: "white",
                                }}
                                className="w-100 text-center"
                            >
                                {val.motivo === "usuarios" && (
                                    <FontAwesomeIcon icon="user" />
                                )}
                                {val.motivo === "funcionarios" && (
                                    <FontAwesomeIcon icon="user-friends" />
                                )}
                                {val.motivo === "armazenamento" && (
                                    <FontAwesomeIcon icon="hdd" />
                                )}
                                &nbsp;{val.mensagem}
                            </div>
                        ))}
                    </>
                )}
                <img
                    src="/images/fundo_top.jpeg"
                    className="img-fluid w-100"
                    alt="Tertium Online"
                    title="Tertium Online"
                    style={{ position: "absolute" }}
                />
                <Navbar
                    expand="lg"
                    style={{ borderBottom: "1px solid rgba(255,255,255,0.15)" }}
                >
                    <Link className="navbar-brand" to="/">
                        <img
                            src="/images/logo2.png"
                            className="img-fluid"
                            alt="Tertium Online"
                            title="Tertium Online"
                            style={{ maxHeight: "30px" }}
                        />
                    </Link>
                    <Navbar.Toggle aria-controls="navbarTertium" />
                    <Navbar.Collapse id="navbarTertium">
                        <Nav className="ml-auto">
                            <form
                                onSubmit={this.onSubmit}
                                id="form_pesquisa"
                                action="/pesquisa"
                                className="form-inline my-2 my-lg-0 mr-4"
                            >
                                <Pesquisa
                                    name="s"
                                    pose={
                                        this.state.pesquisaAberta
                                            ? "visible"
                                            : "hidden"
                                    }
                                    onFocus={this.focusPesquisa.bind(this)}
                                    onBlur={this.blurPesquisa.bind(this)}
                                    onChange={this.changePesquisa.bind(this)}
                                    className="form-control mr-sm-2"
                                    type="search"
                                    placeholder="Pesquisa"
                                    aria-label="Pesquisa"
                                />
                                <img
                                    src="/images/search.png"
                                    style={{ cursor: "pointer" }}
                                    alt="Pesquisa"
                                    title="Pesquisa"
                                    onClick={this.showPesquisa.bind(this)}
                                />
                            </form>
                            <Dropdown as={Navbar.NavItem} id="drop_exportacao">
                                <Dropdown.Toggle
                                    as={Navbar.NavLink}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        boxShadow: "none",
                                        fontSize: "14px",
                                    }}
                                >
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon="download"
                                    />
                                    <span
                                        className="badge badge-primary"
                                        style={{
                                            backgroundColor:
                                                this.props.exportacao.length > 0
                                                    ? COLOR_PRIMARY
                                                    : COLOR_DARK,
                                        }}
                                    >
                                        {this.props.exportacao.length}
                                    </span>
                                </Dropdown.Toggle>
                                {exportacao}
                            </Dropdown>
                            {this.props.permissoes &&
                                (this.props.permissoes.permissoesGerais.indexOf(
                                    "notificacoes"
                                ) !== -1 ||
                                    this.props.permissoes
                                        .administradorEmpresa === 1 ||
                                    this.props.permissoes
                                        .administradorFilial === 1) && (
                                    <Dropdown
                                        as={Navbar.NavItem}
                                        id="drop_notificacoes"
                                    >
                                        <Dropdown.Toggle
                                            as={Navbar.NavLink}
                                            style={{
                                                backgroundColor: "transparent",
                                                border: "none",
                                                boxShadow: "none",
                                                fontSize: "14px",
                                            }}
                                        >
                                            <img
                                                src="/images/notification.png"
                                                alt="Notificações"
                                                title="Notificações"
                                            />
                                            <span
                                                className="badge badge-primary"
                                                style={{
                                                    backgroundColor:
                                                        this.props.notificacoes
                                                            .quantidade > 0
                                                            ? COLOR_PRIMARY
                                                            : COLOR_DARK,
                                                }}
                                            >
                                                {
                                                    this.props.notificacoes
                                                        .quantidade
                                                }
                                            </span>
                                        </Dropdown.Toggle>
                                        {notificacoes}
                                    </Dropdown>
                                )}
                            <Dropdown as={Navbar.NavItem}>
                                <Dropdown.Toggle
                                    as={Navbar.NavLink}
                                    style={{
                                        backgroundColor: "transparent",
                                        border: "none",
                                        boxShadow: "none",
                                        fontSize: "14px",
                                    }}
                                >
                                    {this.props.usuarioLogado.nome.toUpperCase()}
                                    <img
                                        src={
                                            this.props.usuarioLogado.foto
                                                ? this.props.usuarioLogado.foto
                                                : "/images/user.png"
                                        }
                                        alt="Você"
                                        className="img-fluid rounded-circle ml-2"
                                        style={{ height: "40px" }}
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu-right">
                                    {this.props.permissoes &&
                                        (this.props.permissoes
                                            .administradorEmpresa === 1 ||
                                            this.props.permissoes
                                                .administradorFilial === 1 ||
                                            this.props.permissoes.permissoesGerais.indexOf(
                                                "documentos"
                                            ) !== -1) && (
                                            <NavLink
                                                activeStyle={{
                                                    fontWeight: "bold",
                                                }}
                                                className="dropdown-item"
                                                role="button"
                                                to="/contratos"
                                            >
                                                Todos os contratos
                                            </NavLink>
                                        )}
                                    {(this.props.permissoes
                                        .administradorEmpresa === 1 ||
                                        this.props.permissoes
                                            .administradorFilial === 1) && (
                                        <NavLink
                                            activeStyle={{ fontWeight: "bold" }}
                                            className="dropdown-item"
                                            role="button"
                                            to="/usuarios"
                                        >
                                            Usuários
                                        </NavLink>
                                    )}
                                    {this.props.permissoes
                                        .administradorEmpresa === 1 && (
                                        <NavLink
                                            activeStyle={{ fontWeight: "bold" }}
                                            className="dropdown-item"
                                            role="button"
                                            to="/departamentos"
                                        >
                                            Departamentos
                                        </NavLink>
                                    )}
                                    {this.props.permissoes
                                        .administradorEmpresa === 1 && (
                                        <NavLink
                                            activeStyle={{ fontWeight: "bold" }}
                                            className="dropdown-item"
                                            role="button"
                                            to="/empresa"
                                        >
                                            Minha Empresa
                                        </NavLink>
                                    )}
                                    {this.props.permissoes
                                        .administradorEmpresa === 1 && (
                                        <NavLink
                                            activeStyle={{ fontWeight: "bold" }}
                                            className="dropdown-item"
                                            role="button"
                                            to="/filiais"
                                        >
                                            Minhas Filiais
                                        </NavLink>
                                    )}
                                    <NavLink
                                        activeStyle={{ fontWeight: "bold" }}
                                        className="dropdown-item"
                                        role="button"
                                        to="/configuracoes"
                                    >
                                        Configurações
                                    </NavLink>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                        onClick={this.onLogout.bind(this)}
                                    >
                                        Logout
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}
TopNavbar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    refreshNotificacoes: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    permissoes: PropTypes.object.isRequired,
    notificacoes: PropTypes.object.isRequired,
    usuarioLogado: PropTypes.object.isRequired,
    pendenciasPlanos: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    permissoes: state.permissions.permissoes,
    pendenciasPlanos: state.planos.pendencias,
    notificacoes: state.notificacoes.notificacoes,
    exportacao: state.exportacao,
    usuarioLogado: state.usuarioLogado.dados,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    logoutUser: (history) => {
        dispatch(logoutUser(history));
    },
    modalOpen: (modal) => {
        dispatch(setModalOpen(modal));
    },
    refreshNotificacoes: () => {
        dispatch(refreshNotificacoes());
    },
    refreshExportacao: () => {
        dispatch(refreshExportacao());
    },
    dispatch: (method) => {
        dispatch(method);
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TopNavbar));
