/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from "react-table"
import "react-table/react-table.css"

/* Ações */
import { fetchList, fetchFuncionariosList } from '../../actions/documentos'
import { fetchPesquisa } from '../../actions/pesquisa'
import { setAppData } from '../../actions/dados'
import { setModalOpen, setModalId } from '../../actions/modal'
import { setRefresh, REFRESH_KEY } from '../../actions/refresh'

/* Componentes */
import LoadingImage from '../LoadingImage'
import Error from '../Error'

/* Cores */
import { COLOR_DARK2, COLOR_WARNING, COLOR_GREEN, COLOR_INFO, COLOR_DANGER, COLOR_LIGHT_GREEN, COLOR_LIGHT_BLUE } from '../../Colors'


class Pesquisa extends Component {
    constructor() {
        super()

        this._isMounted = false
        this.state = {}
    }

    componentDidMount() {
        this._isMounted = true

        let pesquisa = this.props.match.params.s
        this.props.dispatch(fetchPesquisa(pesquisa))

        let req = {}
        req.s = pesquisa
        this.props.dispatch(fetchList(req))
        this.props.dispatch(fetchFuncionariosList(req))

        setTimeout(()=>{
            if(document.getElementById('pesquisa-documentos'))
            document.getElementById('pesquisa-documentos').focus()
        },1000)
    }
    componentWillUnmount() {
        this._isMounted = false
    }

    handleOpenDocumento(documento, funcionario = null){
        let filialFilter = this.props.usuarioLogado.filial

        this.props.dispatch(setAppData({
            filial: filialFilter,
            funcionario: funcionario,
            documento: documento,
            contrato: null
        }))
        this.props.history.push('/documentos/envios/')
    }

    handleOpenContrato(props){
        this.props.history.push('/contratos/')
        this.props.dispatch(setModalOpen('edit'))
        this.props.dispatch(setModalId(props.idContrato))
    }

    handleOpenFuncionario(id){
        this.props.history.push('/funcionarios/')
        this.props.dispatch(setModalOpen('edit'))
        this.props.dispatch(setModalId(id))
    }

    handleOpenFilial(id){
        this.props.history.push('/filiais/')
        this.props.dispatch(setModalOpen('edit'))
        this.props.dispatch(setModalId(id))
    }

    handleOpenUsuario(id){
        this.props.history.push('/usuarios/')
        this.props.dispatch(setModalOpen('edit'))
        this.props.dispatch(setModalId(id))
    }

    abreGestao = (filter) => {
        if (this.props.appData?.filtros?.contrato?.idContrato !== filter?.contrato?.idContrato) {
            this.props.dispatch(setRefresh(REFRESH_KEY.DOCUMENTOS_FUNCIONARIOS));
        }

        this.props.dispatch(setAppData({filtros: filter}))
        this.props.history.push('/gestao-documentos/')
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>
        }

        if (this.props.loading || this.props.documentosLoading) {
            return <LoadingImage></LoadingImage>
        }

        return(
            <div className="py-5">
                <h4 className="text-center font-weight-400" style={{color: COLOR_DARK2}}>Pesquisa</h4>
                <div className="row px-0 px-lg-5 mx-0 justify-content-center">

                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                Dados encontrados <span className="small">Termo pesquisado: <b>{this.props.match.params.s.toUpperCase()}</b></span>
                            </div>
                            <div className="card-body">

                                {this.props.documentos?.length > 0 &&
                                <div>
                                    <h3 className="font-weight-400" style={{color: COLOR_DARK2}}>Documentos</h3>
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "Nome",
                                                accessor: "nome",
                                                headerClassName: '',
                                                filterable:false,
                                                className: "font-weight-600",
                                                Cell: props => {
                                                    return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)}>{props.value}</div>
                                                },
                                            },
                                            {
                                                Header: "Data Inicial",
                                                accessor: "dataInicio.date",
                                                filterable:false,
                                                maxWidth: 120,
                                                Cell: props => {
                                                    if(props.value){
                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                    } else {
                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)}>--/--/---</div>
                                                    }
                                                }
                                            },
                                            {
                                                Header: "Data Final",
                                                accessor: "dataFim.date",
                                                filterable:false,
                                                maxWidth: 120,
                                                Cell: props => {
                                                    if(props.value){
                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                    } else {
                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)}>--/--/---</div>
                                                    }
                                                }
                                            },
                                            {
                                                Header: "Situação",
                                                accessor: "status",
                                                filterable:false,
                                                maxWidth: 150,
                                                Cell: props => {
                                                    switch(props.value){
                                                        case -2: // Recusado
                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)} style={{
                                                                color: COLOR_DANGER
                                                            }}><FontAwesomeIcon icon="ban" /> Recusado</div>
                                                        case -1: // Expirado
                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)} style={{
                                                                color: COLOR_DANGER
                                                            }}><FontAwesomeIcon icon="exclamation-circle" /> Expirado</div>
                                                        case 0: // A fazer upload
                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)} style={{
                                                                color: COLOR_WARNING
                                                            }}><FontAwesomeIcon icon="cloud-upload-alt" /> Pendente</div>
                                                        case 1: // Próximo da expiração
                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)} style={{
                                                                color: COLOR_INFO
                                                            }}><FontAwesomeIcon icon="clock" /> Expirando</div>
                                                        case 2: // Aguardando aprovação
                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)} style={{
                                                                color: COLOR_INFO
                                                            }}><FontAwesomeIcon icon="hourglass-half" /> Ag. Aprovação</div>
                                                        case 3: // Sem pendências
                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original)} style={{
                                                                color: COLOR_GREEN
                                                            }}><FontAwesomeIcon icon="check" /> Regular</div>
                                                        default:
                                                            return null
                                                    }
                                                }
                                            },
                                            /*{
                                                Header: "Comentários",
                                                filterable:false,
                                                maxWidth: 120
                                            },*/
                                            {
                                                Header: '',
                                                filterable:false,
                                                sortable:false,
                                                className: 'option-column-5',
                                                headerClassName: 'option-column-5',
                                                Cell: props => {
                                                    return <button className="a" onClick={()=>this.handleOpenDocumento(props.original)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                                }
                                            }
                                        ]}
                                        defaultPageSize={this.props.documentos?.length}
                                        pageSize={this.props.documentos?.length}
                                        multiSort={false}
                                        data={this.props.documentos}
                                        filterable={false}
                                        resizable={false}
                                        noDataText="Nenhum documento encontrado"
                                        minRows={0}
                                        showPagination={false}
                                        showPaginationBottom={false}
                                        showPageSizeOptions={false}
                                        className="-highlight mb-3"
                                        />
                                </div>
                                }

                                {this.props.documentosFuncionarios?.length > 0 &&
                                <div>
                                    <h3 className="font-weight-400 mt-5 mb-3 py-2" style={{
                                        color: COLOR_DARK2,
                                        borderBottom: '2px solid #c8c8c8'
                                    }}>Documentos dos Funcionários</h3>
                                    {this.props.documentosFuncionarios?.length > 0 &&
                                        this.props.documentosFuncionarios?.map((funcionario, idx)=>{
                                        return (<div className="row mb-4 mx-0" key={idx}>
                                                <div className="col-12 mb-2 px-0">
                                                    <b>{funcionario.nome}</b> ({funcionario.email})
                                                </div>
                                                <div className="col-12">
                                                    <ReactTable
                                                        columns={[
                                                            {
                                                                Header: "Nome",
                                                                accessor: "nome",
                                                                headerClassName: '',
                                                                filterable:false,
                                                                className: "font-weight-600",
                                                                Cell: props => {
                                                                    return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)}>{props.value}</div>
                                                                }
                                                            },
                                                            {
                                                                Header: "Data Inicial",
                                                                accessor: "dataInicio.date",
                                                                filterable:false,
                                                                maxWidth: 120,
                                                                Cell: props => {
                                                                    if(props.value){
                                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                                    } else {
                                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)}>--/--/---</div>
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                Header: "Data Final",
                                                                accessor: "dataFim.date",
                                                                filterable:false,
                                                                maxWidth: 120,
                                                                Cell: props => {
                                                                    if(props.value){
                                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)}>{moment(props.value).format('DD/MM/YYYY')}</div>
                                                                    } else {
                                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)}>--/--/---</div>
                                                                    }
                                                                }
                                                            },
                                                            {
                                                                Header: "Situação",
                                                                accessor: "status",
                                                                filterable:false,
                                                                maxWidth: 150,
                                                                Cell: props => {
                                                                    switch(props.value){
                                                                        case -2: // Recusado
                                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)} style={{
                                                                                color: COLOR_DANGER
                                                                            }}><FontAwesomeIcon icon="ban" /> Recusado</div>
                                                                        case -1: // Expirado
                                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)} style={{
                                                                                color: COLOR_DANGER
                                                                            }}><FontAwesomeIcon icon="exclamation-circle" /> Expirado</div>
                                                                        case 0: // A fazer upload
                                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)} style={{
                                                                                color: COLOR_WARNING
                                                                            }}><FontAwesomeIcon icon="cloud-upload-alt" /> Pendente</div>
                                                                        case 1: // Próximo da expiração
                                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)} style={{
                                                                                color: COLOR_INFO
                                                                            }}><FontAwesomeIcon icon="clock" /> Expirando</div>
                                                                        case 2: // Aguardando aprovação
                                                                            return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)} style={{
                                                                                color: COLOR_INFO
                                                                            }}><FontAwesomeIcon icon="hourglass-half" /> Ag. Aprovação</div>
                                                                        case 3: // Sem pendências
                                                                        return <div onDoubleClick={()=>this.handleOpenDocumento(props.original, funcionario)} style={{
                                                                                color: COLOR_GREEN
                                                                            }}><FontAwesomeIcon icon="check" /> Regular</div>
                                                                        default:
                                                                            return null
                                                                    }
                                                                }
                                                            },
                                                            /*{
                                                                Header: "Comentários",
                                                                filterable:false,
                                                                maxWidth: 120
                                                            },*/
                                                            {
                                                                Header: '',
                                                                filterable:false,
                                                                sortable:false,
                                                                className: 'option-column-5',
                                                                headerClassName: 'option-column-5',
                                                                Cell: props => {
                                                                    return <button className="a" onClick={()=>this.handleOpenDocumento(props.original, funcionario)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                                                }
                                                            }
                                                        ]}
                                                        defaultPageSize={funcionario.documentos?.length}
                                                        pageSize={funcionario.documentos?.length}
                                                        multiSort={false}
                                                        data={funcionario.documentos}
                                                        filterable={false}
                                                        resizable={false}
                                                        noDataText="Nenhum documento encontrado"
                                                        minRows={0}
                                                        showPagination={false}
                                                        showPaginationBottom={false}
                                                        showPageSizeOptions={false}
                                                        className="-highlight mb-3"
                                                        />
                                                    </div>
                                            </div>)
                                        })
                                    }
                                </div>
                                }

                                {this.props.pesquisa.contratos?.length > 0 &&
                                <div>
                                    <h3 className="font-weight-400 mt-5 mb-3 py-2" style={{
                                        color: COLOR_DARK2,
                                        borderBottom: '2px solid #c8c8c8'
                                    }}>Contratos</h3>
                                    <ReactTable
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }} onDoubleClick={()=>this.handleOpenContrato(props.original)}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "Título",
                                            accessor: "nome",
                                            headerClassName: 'titulo-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenContrato(props.original)}>{props.value}</div>
                                            }
                                        },
                                        {
                                            Header: "Empresa",
                                            accessor: "empresa",
                                            headerClassName: 'empresa-placeholder',
                                            sortable:false,
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenContrato(props.original)}>{props.value}</div>
                                            }
                                        },
                                        {
                                            Header: "Data Inicial",
                                            accessor: "dataInicio",
                                            filterable:false,
                                            maxWidth: 200,
                                            Cell: props => {
                                                if(props.value.date){
                                                    return <div onDoubleClick={()=>this.handleOpenContrato(props.original)}>{moment(props.value.date).format('DD/MM/YYYY')}</div>
                                                } else {
                                                    return <div onDoubleClick={()=>this.handleOpenContrato(props.original)}>--/--/---</div>
                                                }
                                            }
                                        },
                                        {
                                            Header: "Data Final",
                                            accessor: "dataFim",
                                            filterable:false,
                                            maxWidth: 200,
                                            Cell: props => {
                                                if(props.value.date){
                                                    return <div onDoubleClick={()=>this.handleOpenContrato(props.original)}>{moment(props.value.date).format('DD/MM/YYYY')}</div>
                                                } else {
                                                    return <div onDoubleClick={()=>this.handleOpenContrato(props.original)}>--/--/---</div>
                                                }
                                            }
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => {
                                                return <button className="a" onClick={()=>this.handleOpenContrato(props.original)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                            }
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => {
                                                return <button className="a" onClick={()=>this.abreGestao({
                                                    filial: null,
                                                    empresa: {
                                                        nome: props.original.empresa,
                                                        idFilial: parseInt(props.original.idEmpresa)
                                                    },
                                                    contrato: props.original,
                                                })} title="Gestão de Documentos"><FontAwesomeIcon icon="cog" /></button>
                                            }
                                        },
                                    ]}
                                    defaultPageSize={this.props.pesquisa.contratos?.length}
                                    pageSize={this.props.pesquisa.contratos?.length}
                                    multiSort={false}
                                    data={this.props.pesquisa.contratos}
                                    filterable={false}
                                    sortable={false}
                                    resizable={false}
                                    noDataText="Nenhum contrato encontrado"
                                    minRows={0}
                                    showPagination={false}
                                    showPaginationBottom={false}
                                    showPageSizeOptions={false}
                                    className="-highlight mb-3"
                                    />
                            </div>
                            }

                            {this.props.pesquisa.funcionarios?.length > 0 &&
                                <div>
                                    <h3 className="font-weight-400 mt-5 mb-3 py-2" style={{
                                        color: COLOR_DARK2,
                                        borderBottom: '2px solid #c8c8c8'
                                    }}>Funcionários</h3>
                                    <ReactTable
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }} onDoubleClick={()=>this.handleOpenFuncionario(props.original.idFilialFuncionario)}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "Nome",
                                            accessor: "nome",
                                            headerClassName: 'nome-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenFuncionario(props.original.idFilialFuncionario)}>{props.value}</div>
                                            }
                                        },
                                        {
                                            Header: "CPF",
                                            accessor: "cpf",
                                            headerClassName: 'cpf-placeholder',
                                            maxWidth: 200,
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenFuncionario(props.original.idFilialFuncionario)}>{props.value}</div>
                                            }
                                        },
                                        (this.props.permissoes.administradorEmpresa === 1 ?
                                        {
                                            Header: "Filial",
                                            accessor: "filial",
                                            headerClassName: 'filial-placeholder',
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenFuncionario(props.original.idFilialFuncionario)}>{props.value}</div>
                                            }
                                        } : null),
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => {
                                                return <button className="a" onClick={()=>this.handleOpenFuncionario(props.original.idFilialFuncionario)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                            }
                                        }
                                    ]}
                                    defaultPageSize={this.props.pesquisa.funcionarios?.length}
                                    pageSize={this.props.pesquisa.funcionarios?.length}
                                    multiSort={false}
                                    data={this.props.pesquisa.funcionarios}
                                    filterable={false}
                                    sortable={false}
                                    resizable={false}
                                    noDataText="Nenhum funcionário encontrado"
                                    minRows={0}
                                    showPagination={false}
                                    showPaginationBottom={false}
                                    showPageSizeOptions={false}
                                    className="-highlight mb-3"
                                    />
                            </div>
                            }

                            {this.props.pesquisa.filiais?.length > 0 &&
                                <div>
                                    <h3 className="font-weight-400 mt-5 mb-3 py-2" style={{
                                        color: COLOR_DARK2,
                                        borderBottom: '2px solid #c8c8c8'
                                    }}>Filiais</h3>
                                    <ReactTable
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }} onDoubleClick={()=>this.handleOpenFilial(props.original.idFilial)}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "Nome",
                                            accessor: "nome",
                                            headerClassName: 'nome-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenFilial(props.original.idFilial)}>{props.value}</div>
                                            }
                                        },
                                        {
                                            Header: "CNPJ",
                                            accessor: "cnpj",
                                            headerClassName: 'cnpj-placeholder',
                                            maxWidth: 200,
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenFilial(props.original.idFilial)}>{props.value}</div>
                                            }
                                        },
                                        {
                                            Header: "Tipo",
                                            accessor: "matriz",
                                            maxWidth: 200,
                                            Cell: props => <span onDoubleClick={()=>this.handleOpenFilial(props.original.idFilial)} className='table-badge' style={{backgroundColor: parseInt(props.value) === 1 ? COLOR_LIGHT_GREEN : COLOR_LIGHT_BLUE}}>{parseInt(props.value) === 1 ? 'MATRIZ' : 'FILIAL'}</span>,
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => {
                                                return <button className="a" onClick={()=>this.handleOpenFilial(props.original.idFilial)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                            }
                                        }
                                    ]}
                                    defaultPageSize={this.props.pesquisa.filiais?.length}
                                    pageSize={this.props.pesquisa.filiais?.length}
                                    multiSort={false}
                                    data={this.props.pesquisa.filiais}
                                    filterable={false}
                                    sortable={false}
                                    resizable={false}
                                    noDataText="Nenhuma filial encontrada"
                                    minRows={0}
                                    showPagination={false}
                                    showPaginationBottom={false}
                                    showPageSizeOptions={false}
                                    className="-highlight mb-3"
                                    />
                            </div>
                            }

                            {this.props.pesquisa.usuarios?.length > 0 &&
                                <div>
                                    <h3 className="font-weight-400 mt-5 mb-3 py-2" style={{
                                        color: COLOR_DARK2,
                                        borderBottom: '2px solid #c8c8c8'
                                    }}>Usuários</h3>
                                    <ReactTable
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }} onDoubleClick={()=>this.handleOpenUsuario(props.original.idUsuario)}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "Nome",
                                            accessor: "nome",
                                            headerClassName: 'nome-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenUsuario(props.original.idUsuario)}>{props.value}</div>
                                            }
                                        },
                                        {
                                            Header: "CPF",
                                            accessor: "cpf",
                                            headerClassName: 'cpf-placeholder',
                                            maxWidth: 200,
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenUsuario(props.original.idUsuario)}>{props.value}</div>
                                            }
                                        },
                                        {
                                            Header: "Filial",
                                            accessor: "filial",
                                            headerClassName: 'filial-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: props => {
                                                return <div onDoubleClick={()=>this.handleOpenUsuario(props.original.idUsuario)}>{props.value}</div>
                                            }
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => {
                                                return <button className="a" onClick={()=>this.handleOpenUsuario(props.original.idUsuario)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                            }
                                        }
                                    ]}
                                    defaultPageSize={this.props.pesquisa.usuarios?.length}
                                    pageSize={this.props.pesquisa.usuarios?.length}
                                    multiSort={false}
                                    data={this.props.pesquisa.usuarios}
                                    filterable={false}
                                    sortable={false}
                                    resizable={false}
                                    noDataText="Nenhum usuário encontrado"
                                    minRows={0}
                                    showPagination={false}
                                    showPaginationBottom={false}
                                    showPageSizeOptions={false}
                                    className="-highlight mb-3"
                                    />
                            </div>
                            }

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
Pesquisa.propTypes = {
    usuarioLogado: PropTypes.object.isRequired,
    pesquisa: PropTypes.object.isRequired,
    modal: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    permissoes: PropTypes.object.isRequired,

    documentos: PropTypes.array.isRequired,
    documentosLoading: PropTypes.bool.isRequired,
    documentosFuncionarios: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => ({
    pesquisa: state.pesquisa.dados,
    usuarioLogado: state.usuarioLogado.dados,
    loading: state.pesquisa.loading,
    error: state.pesquisa.error,
    modal: state.modal.modalOpen,
    permissoes: state.permissions.permissoes,
    appData: state.dados.data,

    documentos: state.documentos.documentos,
    documentosLoading: state.documentos.loading,
    documentosFuncionarios: state.documentosFuncionarios.funcionarios
})

export default connect(mapStateToProps)(withRouter(Pesquisa));
