import React, { memo, useCallback, useMemo } from "react";
import { parseISO } from "date-fns";

import {
  COLOR_DANGER,
  COLOR_DARK2,
  COLOR_GRAY2,
  COLOR_GREEN,
  COLOR_WARNING,
} from "../../Colors";
import EmployeeListItem from "./EmployeeListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion } from "react-bootstrap";
import LoadingImage from "../LoadingImage";

const EmployeesList = ({
  employees,
  title = "",
  onEdit,
  employeesDocuments,
  disabledStyle = false,
  employeeRef,
  onDocumentOpen,
  onUploadOpen,
  scroll,
  collapse,
  loading,
  onIrregularDocumentsDismiss,
  className = "",
  showingDocumentsFor = [],
  expandedDocumentsFor = null,
  isOpen = false,
  onToggle,
  onDocumentsAccordionToggle,
  onDocumentsExpand,
  onDocumentDismiss,
  onShowContracts,
}) => {
  const list = useMemo(
    () => employees.sort((a, b) => (a.nome < b.nome ? -1 : 1)),
    [employees]
  );

  const sortByStatus = useCallback((a, b) => {
    if (a.status === b.status && !a.dispensado && !b.dispensado) return 0;

    if (a.status > b.status && !a.dispensado) return -1;
    if (a.status < b.status && !b.dispensado) return 1;

    if (a.status > b.status && a.dispensado && b.dispensado) return -1;
    if (a.status < b.status && a.dispensado && b.dispensado) return 1;

    if (a.dispensado) return 1;
    if (b.dispensado) return -1;

    return 0;
  }, []);

  const List = () => {
    if (list.length === 0)
      return "Nenhum funcionário encontrado para esta filial.";

    return list.map((employee, idx) => {
      const percentage = employee.percentagem * 100;
      const absenceInitialDate = parseISO(employee.afastamento?.dataInicio);
      const absenceFinalDate = parseISO(employee.afastamento?.dataFim);
      const documents =
        employeesDocuments?.[employee.funcionario]?.documentos ?? [];
      const sortedDocuments = documents.sort(sortByStatus);
      const color =
        percentage < 100
          ? employee.invalidos
            ? COLOR_DANGER
            : COLOR_WARNING
          : COLOR_GREEN;

      return (
        <EmployeeListItem
          key={employee.idFilialFuncionario}
          employeeRef={employeeRef}
          index={idx}
          employee={employee}
          absenceFinalDate={absenceFinalDate}
          absenceInitialDate={absenceInitialDate}
          color={color}
          percentage={percentage}
          onEdit={onEdit}
          documents={sortedDocuments}
          showingDocumentsFor={showingDocumentsFor}
          expandedDocumentsFor={expandedDocumentsFor}
          onDocumentsExpand={onDocumentsExpand}
          onDocumentOpen={onDocumentOpen}
          onUploadOpen={onUploadOpen}
          onDocumentDismiss={onDocumentDismiss}
          onIrregularDocumentsDismiss={onIrregularDocumentsDismiss}
          scroll={scroll}
          onDocumentsAccordionToggle={onDocumentsAccordionToggle}
          onShowContracts={onShowContracts}
        />
      );
    });
  };

  return (
    <Accordion
      defaultActiveKey="0"
      activeKey={isOpen ? "1" : "0"}
      className={className}
    >
      {collapse ? (
        <Accordion.Toggle
          className="w-100 text-left"
          style={{
            cursor: collapse ? "cursor-pointer" : "cursor-text",
          }}
          onClick={onToggle}
          eventKey="1"
        >
          <h3
            className="font-weight-400 py-2 mb-3"
            style={{
              color: disabledStyle ? COLOR_GRAY2 : COLOR_DARK2,
              borderBottom: "2px solid #c8c8c8",
            }}
          >
            {isOpen ? (
              <FontAwesomeIcon icon="caret-down" />
            ) : (
              <FontAwesomeIcon icon="caret-up" />
            )}{" "}
            {title}
          </h3>
        </Accordion.Toggle>
      ) : (
        <h3
          className="font-weight-400 py-2 mb-3"
          style={{
            color: disabledStyle ? COLOR_GRAY2 : COLOR_DARK2,
            borderBottom: "2px solid #c8c8c8",
          }}
        >
          {title}
        </h3>
      )}
      {collapse && (
        <Accordion.Collapse eventKey="1">
          {loading ? <LoadingImage /> : <List />}
        </Accordion.Collapse>
      )}
      {!collapse && <List />}
    </Accordion>
  );
};

export default memo(EmployeesList);
