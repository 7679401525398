import { SET_MODAL_OPEN, SET_MODAL_ID, SET_MODAL_EXTRA } from "./types";
import { clearErrors } from "./errors";

export const setModalOpen = (modalOpen) => (dispatch) => {
  dispatch(clearErrors());

  if (modalOpen === "" || modalOpen === "new") {
    localStorage.removeItem("modal_id");
    dispatch({
      type: SET_MODAL_ID,
      payload: null,
    });
    localStorage.removeItem("modal_extra");
    dispatch({
      type: SET_MODAL_EXTRA,
      payload: null,
    });
  }

  window.location.hash = modalOpen;
  dispatch({
    type: SET_MODAL_OPEN,
    payload: modalOpen,
  });
};

export const setModalId = (id) => (dispatch) => {
  localStorage.setItem("modal_id", id);
  dispatch({
    type: SET_MODAL_ID,
    payload: id,
  });
};

export const setModalExtraData = (data) => (dispatch) => {
  localStorage.setItem("modal_extra", JSON.stringify(data));
  dispatch({
    type: SET_MODAL_EXTRA,
    payload: data,
  });
};
