import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
    FETCH_CONTRATO_BEGIN as BEGIN,
    FETCH_CONTRATO_SUCCESS as SUCCESS,
    FETCH_CONTRATO_FAILURE as FAILURE,
    GET_ERRORS,
    FETCH_CONTRATO_DOCUMENTOS_BEGIN,
    FETCH_CONTRATO_DOCUMENTOS_SUCCESS,
    FETCH_CONTRATO_DOCUMENTOS_FAILURE,
    FETCH_FUNCIONARIOS_CONTRATO_BEGIN,
    FETCH_FUNCIONARIOS_CONTRATO_SUCCESS,
    FETCH_FUNCIONARIOS_CONTRATO_FAILURE,
} from "./types";
import { setModalOpen, setModalId } from "./modal";
import { URL_API } from "./urls";
import { initialState } from "../reducers/contratosReducer";
import {
    defaultClearData,
    defaultFetchList,
    defaultFetchBegin,
    defaultFetchSuccess,
    defaultFetchFailure,
    defaultDelete,
    defaultChangeStatus,
} from "./default";

const MySwal = withReactContent(Swal);

const model_list = "contratos";
const model = "contrato";

export const fetchList = (reqData, resolve, reject, dispatch) =>
    defaultFetchList(model_list, reqData, resolve, reject, dispatch);
export const clearData = () => defaultClearData(SUCCESS, model, initialState);
export const fetchBegin = () => defaultFetchBegin(BEGIN);
export const fetchSuccess = (data) => defaultFetchSuccess(SUCCESS, model, data);
export const fetchFailure = (error) => defaultFetchFailure(FAILURE, error);
export const deleteData = (id, afterDelete, afterError) =>
    defaultDelete(
        model,
        id,
        "Contrato excluído com sucesso",
        afterDelete,
        afterError,
        (err) => MySwal.fire({
            title: "",
            type: "error",
            html: err.response.data,
            position: "center",
        })
    );
const msgStatus = (status) =>
    "Contrato " + (status === 0 ? "suspenso" : "ativado") + " com sucesso";
export const changeStatus = (id, status = 0, afterChange, afterError) =>
    defaultChangeStatus(model, id, status, msgStatus, afterChange, afterError);

export const saveContrato =
    (contrato, tipo, actions, afterSave) => (dispatch) => {
        let tmpContrato = Object.assign({}, contrato);
        tmpContrato.tipo = tipo;
        tmpContrato[tipo] = tmpContrato.filial;
        axios
            .post(URL_API + "contrato", tmpContrato)
            .then((res) => {
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 2000,
                    type: "success",
                    title: "Contrato criado/alterado com sucesso",
                });
                actions.setSubmitting(false);

                if (res.data.aguardando) {
                    setTimeout(() => {
                        MySwal.fire({
                            toast: false,
                            showConfirmButton: true,
                            type: "info",
                            title: "Você pode selecionar quais documentos deseja, mas só poderá visualizá-los após a aprovação do terceiro",
                        });
                    }, 2500);
                }

                dispatch(setModalId(res.data.id));
                dispatch(setModalOpen("documentos"));
                if (afterSave) afterSave();
            })
            .catch((err) => {
                if (err.response) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: { message: err.response.data },
                    });
                    actions.setErrors(err.response.data.errors);
                }
                actions.setSubmitting(false);
            });
    };

export const fetchDocumentosContratoBegin = () => ({
    type: FETCH_CONTRATO_DOCUMENTOS_BEGIN,
});

export const fetchDocumentosContratoSuccess = (data) => ({
    type: FETCH_CONTRATO_DOCUMENTOS_SUCCESS,
    payload: { documentos: data },
});

export const fetchDocumentosContratoFailure = (error) => ({
    type: FETCH_CONTRATO_DOCUMENTOS_FAILURE,
    payload: { error },
});

export const fetchDocumentosContrato = (id) => (dispatch) => {
    dispatch(fetchDocumentosContratoBegin());

    axios
        .get(URL_API + model + "-documentos/" + id)
        .then((res) => {
            const { rows } = res.data;
            dispatch(fetchDocumentosContratoSuccess(rows));
            return rows;
        })
        .catch((err) => {
            let erro = { message: "Não foi possível obter os dados" };
            if (err.response) {
                erro = err.response.data;
            }
            dispatch(fetchDocumentosContratoFailure(erro));
        });
};

export const fetch = (id) => (dispatch) => {
    dispatch(fetchBegin());

    return axios
        .get(URL_API + model + "/" + id)
        .then((res) => {
            const { data } = res.data;
            data.dataInicio = new Date(data.dataInicio.date);
            data.dataFim = new Date(data.dataFim.date);
            dispatch(fetchSuccess(data));
            return data;
        })
        .catch((err) => {
            let erro = { message: "Não foi possível obter os dados" };
            if (err.response) {
                erro = err.response.data;
            }
            dispatch(fetchFailure(erro));
        });
};

export const resendEmailCadastro = (idFilial, email) => (dispatch) => {
    dispatch(fetchBegin());

    return axios
        .post(URL_API + "filial/reenviar-email-cadastro", { idFilial, email })
        .then((res) => {
            MySwal.fire({
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 3000,
                type: "success",
                title: "Email enviado com sucesso",
            }).then((res) => dispatch(fetchSuccess(res)));
        })
        .catch((err) => {
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data,
                });
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "error",
                    title: "Não foi possível reenviar o email",
                }).then((res) => dispatch(fetchSuccess(res)));
            }
        });
};

export const changeDocumentoContrato =
    (idx = 0, tipo = "EMP", checked = true, documentos) =>
    (dispatch) => {
        let qtd = documentos.qtd[tipo];
        if (checked) {
            qtd = qtd + 1;
        } else {
            qtd = qtd - 1;
        }
        documentos.qtd[tipo] = qtd;

        documentos[tipo][idx].noContrato = checked ? 1 : 0;

        dispatch(fetchDocumentosContratoSuccess({}));
        dispatch(fetchDocumentosContratoSuccess(documentos));
    };

export const changeAllDocumentosContrato =
    (tipo = "EMP", checked = true, documentos) =>
    (dispatch) => {
        if (checked) {
            documentos.qtd[tipo] = documentos[tipo]?.length;
        } else {
            documentos.qtd[tipo] = 0;
        }

        for (let i = 0; i < documentos[tipo]?.length; i++) {
            documentos[tipo][i].noContrato = checked ? 1 : 0;
        }

        dispatch(fetchDocumentosContratoSuccess({}));
        dispatch(fetchDocumentosContratoSuccess(documentos));
    };

export const saveDocumentosContrato =
    (documentos, idContrato, afterSave, dontClose = false) =>
    (dispatch) => {
        let tmpDocumentos = Object.assign({}, documentos);
        tmpDocumentos.contrato = idContrato;

        axios
            .post(URL_API + "contrato-documentos", tmpDocumentos)
            .then((res) => {
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: "Documentos do contrato alterados com sucesso",
                });

                if (!dontClose) dispatch(setModalOpen(""));

                if (afterSave) afterSave();
            })
            .catch((err) => {
                if (err.response) {
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data,
                    });
                }
            });
    };

export const associarFuncionarioContrato =
    (funcionario, idContrato, afterSave) => (dispatch) => {
        let tmpDados = Object.assign({}, funcionario);
        tmpDados.contrato = idContrato;

        axios
            .post(URL_API + "contrato-funcionario", tmpDados)
            .then((res) => {
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: "Funcionário associado ao contrato com sucesso",
                });

                if (afterSave) afterSave();
            })
            .catch((err) => {
                if (err.response) {
                    MySwal.fire({
                        type: "error",
                        title: err.response.data,
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            });
    };

export const removerFuncionarioContrato =
    (funcionario, idContrato, afterSave) => (dispatch) => {
        let tmpDados = Object.assign({}, funcionario);
        tmpDados.contrato = idContrato;

        axios
            .delete(URL_API + "contrato-funcionario", { data: tmpDados })
            .then((res) => {
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: "Funcionário removido do contrato com sucesso",
                });

                if (afterSave) afterSave();
            })
            .catch((err) => {
                if (err.response) {
                    MySwal.fire({
                        type: "error",
                        title: err.response.data,
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            });
    };

export const removerEDemitirFuncionarioContrato =
    (funcionario, afterSave) => (dispatch) => {
        const tmpFuncionario = { ...funcionario, filial: funcionario.idFilial };

        axios
            .post(URL_API + "funcionario", tmpFuncionario)
            .then((res) => {
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: "Funcionário removido do contrato e demitido com sucesso",
                });

                if (afterSave) afterSave();
            })
            .catch((err) => {
                if (err.response) {
                    MySwal.fire({
                        type: "error",
                        title: err.response.data,
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            });
    };

export const fetchTiposAfastamento = (dispatch) => {
    return new Promise((resolve, reject) => {
        axios
            .get(URL_API + "tipos-afastamento")
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const afastarFuncionarioContrato =
    ({ funcionario, filial, afastamento, inicio, termino }, afterSave) =>
    (dispatch) => {
        const data = {
            funcionario,
            filial,
            afastamento,
            inicio,
            termino,
        };

        axios
            .post(URL_API + "funcionario-afastar", data)
            .then((res) => {
                MySwal.fire({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000,
                    type: "success",
                    title: "Funcionário afastado com sucesso",
                });

                if (afterSave) afterSave();
            })
            .catch((err) => {
                if (err.response) {
                    MySwal.fire({
                        type: "error",
                        title: err.response.data,
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                }
            });
    };

export const associarFuncionarioContratoDocumento =
    (funcionario, contrato, documento, afterError) => (dispatch) => {
        let dados = {
            contrato,
            funcionario,
            documento,
        };

        axios
            .post(URL_API + "contrato-funcionario-documento", dados)
            .then((res) => {})
            .catch((err) => {
                if (err.response) {
                    MySwal.fire({
                        type: "error",
                        title: err.response.data,
                        toast: true,
                        position: "center",
                        showConfirmButton: false,
                        timer: 3000,
                    });
                    if (afterError) afterError();
                }
            });
    };

export const fetchFuncionariosContratoBegin = () => ({
    type: FETCH_FUNCIONARIOS_CONTRATO_BEGIN,
});

export const fetchFuncionariosContratoSuccess = (data) => ({
    type: FETCH_FUNCIONARIOS_CONTRATO_SUCCESS,
    payload: { funcionarios: data },
});

export const fetchFuncionariosContratoFailure = (error) => ({
    type: FETCH_FUNCIONARIOS_CONTRATO_FAILURE,
    payload: { error },
});

export const fetchFuncionariosContrato = (id) => (dispatch) => {
    dispatch(fetchFuncionariosContratoBegin());

    axios
        .get(URL_API + model + "-funcionarios/" + id)
        .then((res) => {
            const { rows } = res.data;
            dispatch(fetchFuncionariosContratoSuccess(rows));
            return rows;
        })
        .catch((err) => {
            let erro = { message: "Não foi possível obter os dados" };
            if (err.response) {
                erro = err.response.data;
            }
            dispatch(fetchFuncionariosContratoFailure(erro));
        });
};
