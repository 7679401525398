import React, { useMemo } from "react";
import LoadingImage from "../LoadingImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* Cores */
import { COLOR_WARNING, COLOR_INFO, COLOR_DANGER } from "../../Colors";

const destaqueFn = (pesquisa) => {
  if (!pesquisa) return (nome) => nome;

  const regex = new RegExp(pesquisa, "gi");
  const subst = "\u003C\u0062\u003E" + pesquisa + "\u003C\u002F\u0062\u003E";

  return (nome) => nome.replace(regex, subst);
};

const ListaFiliais = ({
  handleFilialFilterChange,
  handleEmpresaFilterChange,

  idFilialAtiva,
  filialPesquisa,
  filiais,
  loadingEmpresas,
  idFilialEmpresa,
  empresaPesquisa,
  empresas,
}) => {
  filialPesquisa = filialPesquisa && filialPesquisa.toUpperCase();
  const destaqueFilial = destaqueFn(filialPesquisa);
  const destaqueEmpresa = destaqueFn(empresaPesquisa);

  filiais = useMemo(
    () =>
      (filiais || [])?.map((filial) =>
        Object.assign(filial, {
          selecionada: filial.idFilial === idFilialAtiva,
        })
      ),
    [filiais, idFilialAtiva]
  );

  empresas = useMemo(
    () =>
      (empresas || [])?.map((empresa) =>
        Object.assign(empresa, {
          selecionada: empresa.idFilial === idFilialEmpresa,
        })
      ),
    [empresas, idFilialEmpresa]
  );

  if (filiais.length === 0)
    return <div className="col-12">Nenhuma filial encontrada</div>;

  return (
    <>
      {filiais.map((val, idx) => (
        <div key={idx}>
          <div
            className="col-12 py-2"
            style={{
              borderBottom: "1px solid #e4e4e4",
              cursor: "pointer",
              background: val.selecionada ? "#e4e4e4" : "transparent",
            }}
            onClick={() => handleFilialFilterChange(val)}
            dangerouslySetInnerHTML={{ __html: destaqueFilial(val.nome) }}
          ></div>
          {val.selecionada && (
            <>
              {loadingEmpresas ? (
                <LoadingImage />
              ) : !empresas?.length ? (
                <div className="col-12">
                  Esta filial ainda não possui contratos (aprovados)
                </div>
              ) : (
                empresas.map((val) => (
                  <div
                    key={val.idFilial + "|" + (val.selecionada ? 1 : 0)}
                    style={{
                      position: "relative",
                      display: "flex",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      className="col-12 py-2"
                      style={{
                        cursor: "pointer",
                        fontWeight: val.selecionada ? "bold" : "light",
                      }}
                      onClick={() => handleEmpresaFilterChange(val)}
                      dangerouslySetInnerHTML={{
                        __html: destaqueEmpresa(val.nome),
                      }}
                    ></div>
                    {val.comentarios > 0 && (
                      <div
                        className="position-absolute"
                        style={{
                          right: "25px",
                          display: "flex",
                          alignSelf: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: COLOR_INFO }}
                          icon="comment"
                        />
                      </div>
                    )}
                    {(val.invalidos > 0 ||
                      val.pendentes > 0 ||
                      val.aguardando > 0) && (
                      <div
                        className="position-absolute"
                        style={{
                          right: "5px",
                          display: "flex",
                          alignSelf: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            color:
                              val.invalidos > 0 ? COLOR_DANGER : COLOR_WARNING,
                          }}
                          icon="exclamation-triangle"
                        />
                      </div>
                    )}
                  </div>
                ))
              )}
              <div style={{ marginBottom: "35px" }}></div>
            </>
          )}
        </div>
      ))}
    </>
  );
};

export default ListaFiliais;
