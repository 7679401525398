import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteData } from "../../../actions/contratos";
import { STATUS } from "../../../types/StatusContrato";

export const ApagarContratoButton = ({ contrato, swal, dispatch }) => {
  if (
    contrato.tipo !== "terceiro" ||
    contrato.status !== STATUS.AGUARDANDO_APROVACAO
  )
    return null;

  const handle = () => {
    let titulo = contrato.nome;
    let idContrato = contrato.idContrato;

    swal
      .fire({
        title: "",
        type: "warning",
        html:
          "Deseja realmente excluir o contrato <b>" +
          titulo +
          "</b>, essa ação não pode ser revertida?",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir",
        cancelButtonText: "Cancelar",
        reverseButtons: true,
      })
      .then((result) => {
        if (!result.value) return;
        dispatch(
          deleteData(
            idContrato,
            () => {
              window.location.reload();
            },
            () => {}
          )
        );
      });
  };

  return (
    <button className="a" onClick={() => handle()} title="Excluir">
      <FontAwesomeIcon icon="trash" />
    </button>
  );
};
