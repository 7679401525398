import axios from "axios";
import { URL_API } from "../actions/urls";
import { setExportacao } from "../actions/exportacao";
import { initialState } from "../reducers/exportacaoReducer";
import { getCancelToken } from "./axiosCancel";

const uriExportacao = URL_API + "documentos/lote-download/acompanhar";

const cancelToken = () => getCancelToken("exportacao/refreshExportacao");
export const refreshExportacao = () => (dispatch) => {
  axios
    .get(uriExportacao)
    .then((res) => {
      const data = res.data;
      dispatch(setExportacao(data.rows));

      return data.pages > 1
        ? nextPage(dispatch, data.rows, data.pages, 1)
        : data.rows;
    })
    .catch((err) => {
      dispatch(setExportacao(initialState));
      console.error(
        "Ocorreu um erro ao obter os dados da central de exportação. " + err.toString()
      );
    });
};

const nextPage = (dispatch, exportacao, totalPages, page) => {
  const ct = cancelToken();
  axios
    .get(uriExportacao, {
      cancelToken: ct,
      params: { page },
    })
    .then((res) => {
      const pageExportacao = res.data.rows;
      if (pageExportacao.length) {
        exportacao.push(...pageExportacao);

        if (ct.reason) {
          return exportacao;
        }

        dispatch(setExportacao(exportacao));
      }

      if (page === totalPages) return exportacao;

      return nextPage(dispatch, exportacao, totalPages, page + 1);
    });
};
