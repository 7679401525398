/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from "react-table"
import "react-table/react-table.css"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios'

/* Métodos / Ações */
import { fetchList, clearData } from '../../actions/usuarios'
import { setModalOpen, setModalId } from '../../actions/modal'
import { refreshPendenciasPlano } from '../../helpers/refresh'
import { clearFuncionarioId } from '../../actions/scroll'

/* Componentes */
import Error from '../Error'
import RightPanel from '../Sidepanel/RightPanel'
import UsuarioForm from './UsuarioForm'

/* Botões */
import ButtonPrimary from '../Buttons/ButtonPrimary'
import ButtonSuccess from '../Buttons/ButtonSuccess'

import { URL_API } from '../../actions/urls'

const MySwal = withReactContent(Swal)

class Usuarios extends Component {
    constructor() {
        super()
        
        this._isMounted = false
        this.state = {
            rows: [],
            pages: null,
            loading: true,
            pageSize: null,
            page: null,
            sorted: null,
            filtered: null,
            statusFilter: null,
        }

        this.fetchData = this.fetchData.bind(this)
        this.renderDoubleClickable = this.renderDoubleClickable.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.requestData = this.requestData.bind(this)
        this.handleStatusFilter = this.handleStatusFilter.bind(this)
    }

    requestData() {
        refreshPendenciasPlano();

        let reqData = {
            pageSize: this.state.pageSize,
            page: this.state.page,
            sort: this.state.sorted,
            filter: this.state.filtered
        }
        return new Promise((resolve, reject) => {
            fetchList(reqData, resolve, reject, this.props.dispatch)
        }).then(res => {
            if (this._isMounted) {
                this.setState({
                    rows: res.rows,
                    pages: res.pages,
                    loading: false
                })
            }
        }).catch(err => {
            if (this._isMounted) {
                this.setState({
                    rows: [],
                    pages: 0,
                    loading: false
                })
            }
        })
    }

    fetchData(state, instance) {
        this.setState({ 
            loading: true,
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
            filtered: state.filtered,
        },()=>{
            this.requestData()
        })
        
    } 

    handleStatusFilter(st = null){
        let filtered = this.state.filtered
        if(filtered){
            let existsFilter = false
            for(let i = 0; i < filtered?.length; i ++){
                if(filtered[i].id === 'status'){
                    existsFilter = true
                    if(st !== null)
                        filtered[i].value = st
                    else
                        filtered.splice(i, 1)
                }
            }

            if(!existsFilter)
                filtered.push({
                    id: 'status',
                    value: st
                })
        } else if(st !== null) {
            filtered = [{
                id: 'status',
                value: st
            }]
        }
        this.setState({
            statusFilter: st,
            loading: true,
            filtered: filtered
        },()=>{
            this.requestData()
        })
    }

    componentDidMount() {
        this._isMounted = true

        this.props.dispatch(clearFuncionarioId())
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    handleOpen(id){
        this.props.dispatch(setModalOpen('edit'))
        this.props.dispatch(setModalId(id))
    }

    renderDoubleClickable(cellInfo) {
        return (
            <div onDoubleClick={()=>this.handleOpen(cellInfo.original.idUsuario)}>{cellInfo.value}</div>
        )
    }

    solicitarUpgrade = () => {
        MySwal.fire({
            type: 'question',
            confirmButtonText: 'Solicitar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            title: 'Upgrade',
            html: <p className="h5">Deseja solicitar upgrade do seu plano atual?<br/><span className="small">Algum de nossos atendentes entrará em contato para acertar os detalhes.</span></p>,
        }).then((result) => {
            if (result.value) {
                axios.post(URL_API + 'upgrade-plano')
                .then(res => {
                    MySwal.fire({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'success',
                        title: 'Solicitação enviada com sucesso'
                    })
                })
                .catch(err => {
                    MySwal.fire({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 3000,
                        type: 'error',
                        title: 'Não foi possível enviar sua solicitação'
                    })
                })
            }
        })
    }

    render() {
        if (this.props.error && this.props.modal === '') {
            return <Error erro={this.props.error}></Error>
        }

        return(
            <div className="pb-3">
                <div className="row justify-content-center mx-0">

                    <div className="col-12 px-0">
                        <div className="card tertium-card">
                            <div className="card-header py-3 px-2 px-lg-5">
                                Usuários
                                {this.props.permissoes.administradorEmpresa === 1 &&
                                    <span className="ml-3">({this.props.cotasPlano.usado} / {this.props.cotasPlano.permitido})</span>
                                }
                                {(this.props.cotasPlano.upgrade && this.props.permissoes.administradorEmpresa === 1 && this.props.cotasPlano.permitido > 0) &&
                                    <ButtonSuccess title="Solicitar upgrade de plano" onClick={()=>{
                                        this.solicitarUpgrade()
                                    }}
                                    style={{
                                        position: 'absolute',
                                        right: '0',
                                        top: '0',
                                        height: '100%',
                                        borderRadius: '0',
                                        boxShadow: 'none',
                                        borderTopRightRadius: 'calc(.25rem - 1px)',
                                        backgroundColor: '#2bb442',
                                        borderBottom: '1px solid #196c27',
                                        marginBottom: '-1px'
                                    }}><span className="d-none d-md-inline-block px-5 mx-3">Upgrade de Plano</span>
                                        <img src={"/images/plus.png"} alt="Solicitar upgrade de plano" title="Solicitar upgrade de plano" className="img-fluid ml-0 plus-img" style={{ 
                                            maxWidth: '25px',
                                            transform: 'translateY(-3px)',
                                            position: 'absolute',
                                            right: '20px'
                                        }}/>
                                    </ButtonSuccess>
                                }
                                {!this.props.cotasPlano.upgrade &&
                                    <ButtonPrimary title="Cadastrar novo usuário" onClick={()=>{
                                        this.props.dispatch(clearData())
                                        this.props.dispatch(setModalOpen('new'))
                                    }}
                                    style={{
                                        position: 'absolute',
                                        right: '0',
                                        top: '0',
                                        height: '100%',
                                        borderRadius: '0',
                                        boxShadow: 'none',
                                        borderTopRightRadius: 'calc(.25rem - 1px)',
                                        backgroundColor: '#f15923',
                                        borderBottom: '1px solid #cd4c1e',
                                        marginBottom: '-1px'
                                    }}><span className="d-none d-md-inline-block px-5 mx-3">Novo usuário</span>
                                        <img src={"/images/plus.png"} alt="Cadastrar novo usuário" title="Cadastrar novo usuário" className="img-fluid ml-0 plus-img" style={{ 
                                            maxWidth: '25px',
                                            transform: 'translateY(-3px)',
                                            position: 'absolute',
                                            right: '20px'
                                        }}/>
                                    </ButtonPrimary>
                                }
                            </div>
                            <div className="card-body px-2 px-lg-5">
                                <div className="row mx-0 mb-4 pt-4 row-table-filters">
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === null ? ' active' : '')} onClick={()=>{this.handleStatusFilter(null)}}>
                                        Todos
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === 1 ? ' active' : '')} onClick={()=>{this.handleStatusFilter(1)}}>
                                        Ativos
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-5"+(this.state.statusFilter === 0 ? ' active' : '')} onClick={()=>{this.handleStatusFilter(0)}}>
                                        Bloqueados
                                    </button>
                                </div>
                                <ReactTable
                                    defaultSorted={[{
                                        id   : 'nome',
                                        desc : false,
                                    }]}
                                    columns={[
                                        {
                                            Header: "",
                                            accessor: "letra",
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5 letra-inicial',
                                            headerClassName: 'option-column-5',
                                            Cell: props => <div style={{
                                                borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
                                                borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                            }} onDoubleClick={()=>this.handleOpen(props.original.idUsuario)}>
                                                {props.original.letra !== '' ? props.original.letra : ' '}
                                            </div>,
                                        },
                                        {
                                            Header: "Nome",
                                            accessor: "nome",
                                            headerClassName: 'nome-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: this.renderDoubleClickable,
                                        },
                                        {
                                            Header: "CPF",
                                            accessor: "cpf",
                                            headerClassName: 'cpf-placeholder',
                                            maxWidth: 200,
                                            Cell: this.renderDoubleClickable
                                        },
                                        {
                                            Header: "Filial",
                                            accessor: "filial",
                                            headerClassName: 'filial-placeholder',
                                            className: "font-weight-600 text-uppercase",
                                            Cell: this.renderDoubleClickable,
                                        },
                                        {
                                            Header: '',
                                            filterable:false,
                                            sortable:false,
                                            className: 'option-column-5',
                                            headerClassName: 'option-column-5',
                                            Cell: props => {
                                                return <button className="a" onClick={()=>this.handleOpen(props.original.idUsuario)} title="Ver"><FontAwesomeIcon icon="eye" /></button>
                                            }
                                        }
                                    ]}
                                    manual
                                    multiSort={false}
                                    data={this.state.rows}
                                    pages={this.state.pages}
                                    loading={this.state.loading}
                                    onFetchData={(state, instance)=>this.fetchData(state, instance)}
                                    filterable
                                    previousText={<FontAwesomeIcon icon="chevron-left" />}
                                    nextText={<FontAwesomeIcon icon="chevron-right" />}
                                    resizable={false}
                                    loadingText="Carregando..."
                                    noDataText="Nenhum usuário encontrado"
                                    pageText="Página"
                                    ofText="de"
                                    rowsText="linhas"
                                    pageJumpText="ir para a página"
                                    rowsSelectorText="linhas por página"
                                    pageSizeOptions={[5, 10, 20, 50]}
                                    defaultPageSize={10}
                                    minRows={0}
                                    className="-highlight"
                                    />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Formulário de Criação */}
                <RightPanel isPaneOpen={this.props.modal === 'new'} title="Cadastro de Usuário" path="new" onClose={()=>{
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <UsuarioForm onAfterSave={this.requestData}></UsuarioForm>
                </RightPanel>

                
                <RightPanel isPaneOpen={(this.props.modal === 'edit' && this.props.id !== null)} title="Edição de Usuário" path="edit" onClose={()=>{
                    this.props.dispatch(setModalOpen(''))
                }}>
                    <UsuarioForm onAfterSave={this.requestData} id={this.props.id}></UsuarioForm>
                </RightPanel>
            </div>
        )
    }
}
Usuarios.propTypes = {
    modal: PropTypes.string.isRequired,
    id: PropTypes.any,
    permissoes: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
    modal: state.modal.modalOpen,
    id: state.modal.id,
    permissoes: state.permissions.permissoes,
    error: state.errors.message,
    cotasPlano: state.planos.cotas.usuarios,
})

export default connect(mapStateToProps)(withRouter(Usuarios));
