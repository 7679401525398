import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { requiredField, emailField } from '../../../validation/formValidation';
import { changePassword } from '../../../actions/authentication';

const MySwal = withReactContent(Swal);

const SecondStep = ({ email, previousStep, setLoading }) => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});

  const handleCodeChange = useCallback((e) => {
    setErrors(current => ({ ...current, code: null }));
    setCode(e.target.value);
  }, []);

  const handlePasswordChange = useCallback((e) => {
    setErrors(current => ({ ...current, password: null }));
    setPassword(e.target.value);
  }, []);

  const handleConfirmPasswordChange = useCallback((e) => {
    setErrors(current => ({ ...current, confirmPassword: null }));
    setConfirmPassword(e.target.value);
  }, []);

  const handleCodeBlur = useCallback(() => {
    if (errors?.code) return;
    setErrors(current => ({ ...current, code: requiredField(code) }));
  }, [code, errors]);

  const handlePasswordBlur = useCallback(() => {
    if (errors?.password) return;
    setErrors(current => ({ ...current, password: requiredField(password) }));
  }, [password, errors]);

  const handleConfirmPasswordBlur = useCallback(() => {
    if (errors?.confirmPassword) return;
    setErrors(current => ({ ...current, confirmPassword: requiredField(confirmPassword) }));
  }, [confirmPassword, errors]);

  const handleGoBack = useCallback(() => previousStep(), [previousStep]);

  const getPasswordsError = useCallback(
    () => password !== confirmPassword ? 'As senhas devem ser iguais' : null,
    [confirmPassword, password],
  );

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const fieldErrors = {
      email: emailField(email),
      code: requiredField(code),
      password: requiredField(password),
      confirmPassword: requiredField(confirmPassword) || getPasswordsError(password, confirmPassword),
    }

    if (Object.values(fieldErrors).filter(Boolean).length) {
      setErrors(current => ({ ...current, ...fieldErrors }));
      return;
    }

    try {
      setLoading(true);
      const res = await changePassword({
        email,
        codigoAcesso: code,
        novaSenha: password,
        confirmacaoSenha: confirmPassword,
      });

      if (!res?.data?.status) return;

      MySwal.fire({
        title: "Senha alterada com sucesso",
        type: "success",
        showCancelButton: false,
        confirmButtonText: "OK",
        onClose: () => window.location.href = '/login',
      });
    } finally {
      setLoading(false);
    }
  }, [code, confirmPassword, email, getPasswordsError, password, setLoading]);

  useEffect(() => {
    const error = getPasswordsError(password, confirmPassword);
    if (password.length && confirmPassword.length && error) {
      setErrors(current => ({ ...current, confirmPassword: error }));
    }
  }, [confirmPassword, getPasswordsError, password, setErrors])

  return (
    <form onSubmit={handleSubmit} className="login100-form">
      <span className="login100-form-title pb-4">Redefinir senha</span>

      <span className="login100-form-description">
        Você receberá um e-mail com um código de verificação para redefinir sua senha. Verifique sua caixa de entrada ou a pasta de spam
      </span>


      <div className="wrap-input100">
        <input
          className={classnames("input100", {
            "is-invalid": errors.code
          })}
          type="text"
          name="code"
          autoComplete="one-time-code"
          placeholder="Código"
          onChange={handleCodeChange}
          value={code}
          onBlur={handleCodeBlur}
        />
        <span className="focus-input100"></span>
        <span className="symbol-input100">
          <FontAwesomeIcon icon="key" />
        </span>
      </div>
      {errors.code && (
        <div className="invalid-feedback">{errors.code}</div>
      )}

      <div className="wrap-input100">
        <input
          className={classnames("input100", {
            "is-invalid": errors.password
          })}
          type="password"
          name="password"
          autoComplete="new-password"
          placeholder="Senha"
          onChange={handlePasswordChange}
          value={password}
          onBlur={handlePasswordBlur}
        />
        <span className="focus-input100"></span>
        <span className="symbol-input100">
          <FontAwesomeIcon icon="lock" />
        </span>
      </div>
      {errors.password && (
        <div className="invalid-feedback">{errors.password}</div>
      )}

      <div className="wrap-input100">
        <input
          className={classnames("input100", {
            "is-invalid": errors.confirmPassword
          })}
          type="password"
          name="confirmPassword"
          autoComplete="new-password"
          placeholder="Confirmar senha"
          onChange={handleConfirmPasswordChange}
          value={confirmPassword}
          onBlur={handleConfirmPasswordBlur}
        />
        <span className="focus-input100"></span>
        <span className="symbol-input100">
          <FontAwesomeIcon icon="lock" />
        </span>
      </div>
      {errors.confirmPassword && (
        <div className="invalid-feedback">{errors.confirmPassword}</div>
      )}

      <div className="container-login100-form-btn">
        <button className="login100-form-btn" disabled={Object.values(errors).filter(Boolean).length}>Salvar</button>
      </div>

      <div className="container-login100-form-btn">
        <button type="button" onClick={handleGoBack}>Voltar</button>
      </div>
    </form>
  )
};

export default SecondStep;
