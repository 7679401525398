import React, { Component } from "react";
import PdfView from "../CustomFields/PdfView";
import { COLOR_WARNING } from "../../Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EnvioArquivo extends Component {
  render() {
    if (this.props.envio.tipo === "Dispensado") {
      return (
        <div
          style={{
            fontSize: "15px",
            color: COLOR_WARNING,
            textAlign: "center",
          }}
          className="font-weight-500 cursor-pointer py-5"
        >
          <FontAwesomeIcon icon="exclamation-triangle" /> Envio do documento{" "}
          {this.props.documento.nome} foi dispensado
        </div>
      );
    }

    return (
      <PdfView
        name={this.props.documento.nome}
        filial={this.props.filial}
        contrato={this.props.contrato}
        funcionario={this.props.funcionario}
        idDocumentoEnvio={this.props.envio.idDocumentoEnvio}
        documento={this.props.documento.idDocumento}
      />
    );
  }
}

export default EnvioArquivo;
