import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/*import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)*/

export default class ButtonBack extends Component {
  onBack() {
    if (this.props.modal > 1) {
      var cont = this.props.modal * 2;
      for (var i = 1; i <= cont - 1; i++) {
        window.history.back();
      }
    } else {
      window.history.back();
    }
  }

  render() {
    let props = {};
    Object.keys(this.props).map((idx) => {
      if (idx !== "modal") props[idx] = this.props[idx];

      return null;
    });
    return (
      <div>
        <button
          style={{
            color: "#343434",
            position: "absolute",
            top: "0px",
            left: "10px",
            fontSize: "2em",
            height: "100%",
          }}
          onClick={this.onBack.bind(this)}
          {...props}
        >
          <FontAwesomeIcon icon="chevron-circle-left" />
        </button>
      </div>
    );
  }
}
