import React from "react";

export default function LoadingImage(props) {
  if (props.absolute === "true")
    return (
      <img
        src="/images/loading.gif"
        alt="Carregando..."
        title="Carregando..."
        className="img-fluid"
        style={{ maxWidth: "130px" }}
        {...props}
      />
    );

  return (
    <div className="d-flex align-items-center justify-content-center w-100 p-4">
      <img
        src="/images/loading.gif"
        alt="Carregando..."
        title="Carregando..."
        className="img-fluid"
        style={{ maxWidth: "130px" }}
        {...props}
      />
    </div>
  );
}
