import {
  CLEAR_FILIAL_FUNCIONARIO_ID,
  SET_FILIAL_FUNCIONARIO_ID,
} from '../actions/types';

export const initialState = {};

export default (state = initialState, action ) => {
  switch(action.type) {
    case CLEAR_FILIAL_FUNCIONARIO_ID:
      return {
        ...state,
        idFilialFuncionario: undefined
      };
    case SET_FILIAL_FUNCIONARIO_ID:
      return {
        ...state,
        idFilialFuncionario: action.payload
      };
    default: 
      return state;
  }
};
