import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import AsyncSelect from 'react-select/lib/Async'
import VMasker from 'vanilla-masker'

import { COLOR_PRIMARY } from '../../Colors'
import LoadingImage from '../LoadingImage'
import { saveEmpresa } from '../../actions/empresas'
import { fetchCnaes, saveCnae } from '../../actions/cnaes'
import Error from '../Error'
import ButtonSuccess from '../Buttons/ButtonSuccess'
import Telefone from '../CustomFields/Telefone'
import Thumb from '../CustomFields/Thumb'
import { requiredField, emailField, phoneField } from '../../validation/formValidation'

const MySwal = withReactContent(Swal)

class EmpresaEdit extends Component {
    handleSubmit(values, actions) {
        this.props.dispatch(saveEmpresa(values, this.props.history, actions))
    }

    handleCnaeCreation = (done) => {
        MySwal.mixin({
            input: 'text',
            confirmButtonText: 'Continuar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            progressSteps: ['1', '2']
          }).queue([
            {
              title: 'Código',
              text: 'Digite o código do CNAE',
              customClass: {
                input: 'maskCNAE',
              },
              onOpen: () => {
                VMasker(document.querySelector(".maskCNAE")).maskPattern("9999-9/99");
              },
              preConfirm: (value) => {
                return new Promise((resolve, reject) => {
                    if (value && value?.length === 9) {
                        resolve()
                    } else {
                        reject()
                    }
                }).catch(() => {
                    Swal.showValidationMessage(
                        `Digite um CNAE válido (7 números)`
                      )
                  });
              }
            },
            {
              title: 'Descrição',
              confirmButtonText: 'Salvar',
              text: 'Digite a descrição',
              preConfirm: (value) => {
                return new Promise((resolve, reject) => {
                    if (value && value?.length >= 10) {
                        resolve()
                    } else {
                        reject()
                    }
                }).catch(() => {
                    Swal.showValidationMessage(
                        `Digite uma descrição válida (>= 10 caracteres)`
                      )
                  });
              }
            },
          ]).then((result) => {
            if (result.value) {
                let cnae = {
                    codigo: result.value[0],
                    descricao: result.value[1],
                }
                this.props.dispatch(saveCnae(cnae, done))
            }
          })
    }

    componentDidMount() {
        this.props.dispatch(fetchCnaes())
        //this.props.dispatch(fetchEmpresa())
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.errors) {
            let show = false;
            if(nextProps.errors.message){
                show = true
            }
            this.setState({
                errors: nextProps.errors
            })
            if(show){
                MySwal.fire({
                    type: 'error',
                    title: nextProps.errors.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
            }
        }
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>
        }

        if (this.props.loading) {
            return <LoadingImage></LoadingImage>
        }

        const loadCnaesOptions = inputValue => {
            return new Promise(resolve => {
                let dados = this.props.cnaes.dados
                if(inputValue !== ''){
                    dados = dados.filter(i =>
                        i.codigo.toLowerCase().includes(inputValue.toLowerCase()) || i.descricao.toLowerCase().includes(inputValue.toLowerCase())
                    )
                }
                dados = dados.slice(0, 30)
                resolve(dados)
            });
        }

        return(
            <div className="pb-5">
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-12 col-md-12">
                        <div className="card">
                            <div className="card-body">
                            <Formik
                                initialValues={this.props.empresa}
                                onSubmit={this.handleSubmit.bind(this)}
                                render={({ isSubmitting, setFieldValue, values, handleSubmit }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="nome">Nome *</label>
                                                <Field type="text" id="nome" name="nome" className="form-control" validate={requiredField} />
                                                <ErrorMessage name="nome" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="razaoSocial">Razão Social *</label>
                                                <Field type="text" id="razaoSocial" name="razaoSocial" className="form-control" validate={requiredField} />
                                                <ErrorMessage name="razaoSocial" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="email">E-mail *</label>
                                                <Field type="email" id="email" name="email" className="form-control" validate={emailField} />
                                                <ErrorMessage name="email" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="telefone">Telefone *</label>
                                                <Field name="telefone" validate={phoneField}>{({ field }) => {
                                                    return <Telefone {...field} id="telefone"></Telefone>
                                                }}</Field>
                                                <ErrorMessage name="telefone" component="small" className="form-text text-danger"/>  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="cnae" className="w-100">CNAE's *</label>
                                                <Field name="cnae[]">{({ field }) => {
                                                    return <AsyncSelect {...field} 
                                                        isMulti 
                                                        placeholder="Digite para localizar..." 
                                                        id="cnae" 
                                                        isSearchable
                                                        defaultValue={this.props.empresa.cnae}
                                                        getOptionValue={(option) => (option['codigo'])}
                                                        getOptionLabel={(option) => (option['codigo'] + ' - '  + option['descricao'])}
                                                        loadOptions={loadCnaesOptions}
                                                        onChange={(value)=>{
                                                            setFieldValue("cnae", value)
                                                        }}
                                                        noOptionsMessage={(input)=>{
                                                            return input.inputValue === '' ? "Digite para localizar..." : "Nenhum resultado encontrado, por favor adicione o seu CNAE"
                                                        }}
                                                        loadingMessage={()=>"Carregando..."}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            colors: {
                                                            ...theme.colors,
                                                                primary50: '#ebebeb',
                                                                primary25: '#ebebeb',
                                                                primary: '#ff5818',
                                                            },
                                                        })} />
                                                }}</Field>
                                                <ErrorMessage name="cnae" component="small" className="form-text text-danger"/>  
                                                <button type="button" onClick={()=>{
                                                    this.handleCnaeCreation((dt)=>{
                                                        let cnae = values.cnae;
                                                        cnae.push({
                                                            codigo: dt.codigo,
                                                            descricao: dt.descricao,
                                                            empresaCriadora: null,
                                                            status: 1
                                                        });
                                                        setFieldValue('cnae', cnae);
                                                    })
                                                }} className="small w-100 text-right" style={{ color: COLOR_PRIMARY, top: 0 }}>Não encontrou na lista? Sugerir CNAE</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Logo atual</label>
                                                <img style={{maxWidth: '200px'}} className="img-fluid rounded-circle d-block mx-auto" src={this.props.empresa.logo} alt={ this.props.empresa.nome } title={ this.props.empresa.nome }/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text" id="inputGroupFileAddon01">
                                                        Logo
                                                        </span>
                                                    </div>
                                                    <div className="custom-file">
                                                        <input
                                                        type="file"
                                                        className="custom-file-input"
                                                        id="logo"
                                                        name="logo" 
                                                        aria-describedby="inputGroupFileAddon01"
                                                        onChange={(event) => {
                                                            setFieldValue("logo", event.target.files[0]);
                                                        }}
                                                        />
                                                        <label className="custom-file-label" htmlFor="logo">
                                                            Escolher arquivo
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorMessage name="logo" component="small" className="form-text text-danger"/> 
                                                <Thumb file={values.logo} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                                            <ButtonSuccess type="submit" disabled={isSubmitting}>Salvar</ButtonSuccess>
                                        </div>
                                    </div>
                                </Form>
                                )}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
EmpresaEdit.propTypes = {
    empresa: PropTypes.object.isRequired,
    cnaes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    empresa: state.empresas.empresa,
    cnaes: state.cnaes,
    loading: state.empresas.loading,
    error: state.empresas.error,
    errors: state.errors
})

export default connect(mapStateToProps)(withRouter(EmpresaEdit));