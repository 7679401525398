import {
    FETCH_DEPARTAMENTOS_BEGIN,
    FETCH_DEPARTAMENTOS_SUCCESS,
    FETCH_DEPARTAMENTOS_FAILURE
} from '../actions/types';

const initialDepartamentosState = []

const initialState = {
    dados: initialDepartamentosState,
    loading: false,
    error: null
};
  
export default function departamentosReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_DEPARTAMENTOS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_DEPARTAMENTOS_SUCCESS:
            return {
                ...state,
                loading: false,
                dados: action.payload.departamentos
            };

        case FETCH_DEPARTAMENTOS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                dados: initialDepartamentosState
            };

        default:
            return state;
    }
}