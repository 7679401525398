import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { FETCH_DEPARTAMENTOS_BEGIN, FETCH_DEPARTAMENTOS_SUCCESS, FETCH_DEPARTAMENTOS_FAILURE, GET_ERRORS } from './types'
import { URL_API } from './urls'

const MySwal = withReactContent(Swal)

export function fetchDepartamentosList(reqData, resolve, reject, dispatch) {
    axios.post(URL_API + 'departamentos', reqData)
    .then(res => {
        resolve(res.data)
    })
    .catch(err => {
        let erro = {message: "Não foi possível obter os dados dos departamentos"}
        if(err.response){
            erro = err.response.data
        }
        dispatch({
            type: GET_ERRORS,
            payload: {message: erro}
        })
        reject(erro.message)
    })
}

export const fetchDepartamentosBegin = () => ({
  type: FETCH_DEPARTAMENTOS_BEGIN
})

export const fetchDepartamentosSuccess = departamentos => ({
  type: FETCH_DEPARTAMENTOS_SUCCESS,
  payload: { departamentos }
})

export const fetchDepartamentosFailure = error => ({
  type: FETCH_DEPARTAMENTOS_FAILURE,
  payload: { error }
})

export function fetchDepartamentos() {
    return dispatch => {
        dispatch(fetchDepartamentosBegin());

        axios.get(URL_API + 'departamento')
        .then(res => {
            const { data } = res.data;
            dispatch(fetchDepartamentosSuccess(data));
            return data;
        })
        .catch(err => {
            let erro = {message: "Não foi possível obter a lista de departamentos"}
            if(err.response){
                erro = err.response.data
            }
            dispatch(fetchDepartamentosFailure(erro))
        });
    };
}

export const saveDepartamento = (departamento) => dispatch => {
    axios.post(URL_API + 'departamento', departamento)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 2000,
                type: 'success',
                title: 'Departamento salvo com sucesso'
            })
            dispatch(fetchDepartamentos())
        })
        .catch(err => {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 2000,
                type: 'error',
                title: 'Não foi possível salvar o departamento'
            })
        })
}

export const changeUsuarioDepartamento = (idUsuario, st, departamento, afterSave = null) => dispatch => {
    axios.post(URL_API + 'departamento/'+departamento+'/'+idUsuario+'/'+st)
        .then(res => {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 2000,
                type: 'success',
                title: 'Usuário '+(st ? 'inserido no' : 'removido do')+' departamento com sucesso'
            })
            if(afterSave !== null)
                afterSave()
        })
        .catch(err => {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 2000,
                type: 'error',
                title: 'Não foi possível alterar o usuário'
            })
        })
}

export const changeDocumentoDepartamento = (idDocumento, st, departamento, afterSave = null) => dispatch => {
    axios.put(URL_API + 'departamento/'+departamento+'/'+idDocumento+'/'+st)
        .then(res => {
            if(window.intervalSwal)
                clearInterval(window.intervalSwal);
            window.intervalSwal = setInterval(()=>{
                MySwal.fire({
                    toast: true,
                    position: 'bottom',
                    showConfirmButton: false,
                    timer: 2000,
                    type: 'success',
                    title: 'Documentos alterados com sucesso'
                })
                clearInterval(window.intervalSwal);
            }, 3000)
            
            if(afterSave !== null)
                afterSave()
        })
        .catch(err => {
            MySwal.fire({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 2000,
                type: 'error',
                title: 'Não foi possível alterar o documento'
            })
        })
}