import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'

export default class Telefone extends Component {
    constructor() {
        super();
        this.state = { 
            value: null,
            mask: ['(',/[1-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
        }
    }
    onChange = (event) => {
        var value = event.target.value;
        var newState = {
            mask: ['(',/[1-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
            value: value
        };
        if (value.replace(/[^0-9]/g, '')?.length === 10) {
            newState.mask = ['(',/[1-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
            //newState.mask = ['(',/[1-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
        }
        if (value.replace(/[^0-9]/g, '')?.length > 10) {
            newState.mask = ['(',/[1-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
        }
        this.setState(newState)
        this.props.onChange(event)
    }
    componentDidMount(){
        if(this.props.value){
            if(this.props.value.replace(/[^0-9]/g, '')?.length >= 10){
                this.setState({
                    mask: ['(',/[1-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]
                });
            }
        }
    }      
    render() {
        const value = this.state.value === null ? this.props.value : this.state.value
        return (
            <MaskedInput guide={false} type="tel" mask={this.state.mask} {...this.props} value={value} onKeyUp={this.onChange} className="form-control" />
        )
    }
}