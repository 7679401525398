import { SET_EXPORTACAO } from "../actions/types";

export const initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_EXPORTACAO:
            return action.payload;
        default:
            return state;
    }
}
