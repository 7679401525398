import {
  CLEAR_REFRESH,
  SET_REFRESH
} from './types'

export const REFRESH_KEY = {
  DOCUMENTOS_FUNCIONARIOS: 'DOCUMENTOS_FUNCIONARIOS',
}

export const clearRefresh = key => dispatch => dispatch({ type: CLEAR_REFRESH, payload: key });

export const setRefresh = key => dispatch => dispatch({ type: SET_REFRESH, payload: key });
