import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { URL_API } from "./urls";

const MySwal = withReactContent(Swal);

export const avaliacaoEmLote = (data = {}, afterSave) => (dispatch) => {
  axios
    .post(URL_API + "avaliacao-em-lote", data)
    .then(() => {
      if (afterSave) afterSave();
      MySwal.fire({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        type: "success",
        title: "Documentos aprovados com sucesso",
      });
    })
    .catch((err) => {
      MySwal.fire({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        type: "error",
        title: err?.response ? err.response.data : "Não foi possível aprovar os documentos",
      });
    });
};
