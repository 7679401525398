import React, { useRef, useEffect } from "react";
import Calendar from "react-datepicker";

const BetterCalendar = (props) => {
  const calendarRef = useRef(props.ref);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current || !calendarRef.current) return;

    const KEYCODE_TAB = 9;
    let timeout = null;
    const keyDown = (e) => {
      if (e.key !== "Tab" && e.keyCode === KEYCODE_TAB) return;

      timeout = setTimeout(() => {
        clearTimeout(timeout);
        if (containerRef.current.contains(document.activeElement)) {
          return;
        }
        calendarRef.current.setOpen(false);
      }, 100);
    };

    containerRef.current.addEventListener("keydown", keyDown);

    return () =>
      containerRef?.current.removeEventListener("keydown", keyDown) &&
      clearTimeout(timeout);
  }, []);

  return (
    <div ref={containerRef}>
      <Calendar ref={calendarRef} {...props} />
    </div>
  );
};

export default BetterCalendar;
