import axios from "axios";
import { URL_API } from "../actions/urls";
import { setNotificacoes } from "../actions/notificacoes";
import { initialState } from "../reducers/notificacoesReducer";
import { getCancelToken } from "./axiosCancel";

const uriNotificacoes = URL_API + "app_notificacoes";

const cancelToken = () => getCancelToken("notificacoes/refreshNotificacoes");
export const refreshNotificacoes = () => (dispatch) => {
    axios
        .get(uriNotificacoes, { cancelToken: cancelToken() })
        .then((res) => {
            const notificacoes = res.data;
            dispatch(setNotificacoes(notificacoes));

            return notificacoes.pages > 1
                ? nextPage(dispatch, notificacoes, notificacoes.pages, 1)
                : notificacoes;
        })
        .catch((err) => {
            if (axios.isCancel(err)) return;
            dispatch(setNotificacoes(initialState.notificacoes));
            console.error(
                "Ocorreu um erro ao obter as notificações. " + err.toString()
            );
        });
};

const nextPage = (dispatch, notificacoes, totalPages, page) => {
    const ct = cancelToken();
    return axios
        .get(uriNotificacoes, {
            cancelToken: ct,
            params: { page },
        })
        .then((res) => {
            const pageNotificacoes = res.data;
            if (pageNotificacoes.quantidade) {
                notificacoes = {
                    ...notificacoes,
                    contratos: [
                        ...notificacoes.contratos,
                        ...pageNotificacoes.contratos,
                    ].sort((a, b) => {
                        if (a.porcentagem === b.porcentagem)
                            return a.nome < b.nome ? -1 : 1;

                        return a.porcentagem < b.porcentagem ? -1 : 1;
                    }),
                    contratos_expirando: [
                        ...notificacoes.contratos_expirando,
                        ...pageNotificacoes.contratos_expirando,
                    ],
                    contratos_pendentes: [
                        ...notificacoes.contratos_pendentes,
                        ...pageNotificacoes.contratos_pendentes,
                    ],
                    funcionarios_troca_pendentes: [
                        ...notificacoes.funcionarios_troca_pendentes,
                        ...pageNotificacoes.funcionarios_troca_pendentes,
                    ],
                    quantidade:
                        notificacoes.quantidade + pageNotificacoes.quantidade,
                };

                if (ct.reason) {
                    return notificacoes;
                }

                dispatch(setNotificacoes(notificacoes));
            }

            if (page === totalPages) return notificacoes;

            return nextPage(dispatch, notificacoes, totalPages, page + 1);
        })
        .catch((err) => {
            if (axios.isCancel(err)) throw err;

            if (page === totalPages) return notificacoes;

            return nextPage(dispatch, notificacoes, totalPages, page + 1);
        });
};
