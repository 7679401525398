import React from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import posed, { PoseGroup } from "react-pose";
import NotFound from "./404/404";
import PainelInicial from "./Inicio/PainelInicial";
import Empresa from "./Empresa/Empresa";
import Filiais from "./Filial/Filiais";
import Usuarios from "./Usuario/Usuarios";
import Configuracoes from "./Configuracoes/Configuracoes";
import Departamentos from "./Departamento/Departamentos";
import Documentos from "./Documento/Documentos";
import Envios from "./Documento/Envios";
import Avaliacao from "./Documento/Avaliacao";
import VisaoTomadorFuncionarios from "./Funcionario/VisaoTomadorFuncionarios";
import Pesquisa from "./Pesquisa/Pesquisa";
import Contratos from "./Contrato/Contratos";
import GestaoDocumentos from "./GestaoDocumentos/GestaoDocumentos";
import Error from "./Error";
import "./MainBlock.css";

const RoutesContainer = posed.div({
  enter: { opacity: 1, transform: "scale(1)", transition: { duration: 100 } },
  exit: { opacity: 0, delay: 0, transition: { duration: 0 } },
  pre: {
    opacity: 0,
    transform: "scale(0)",
    delay: 0,
    transition: { duration: 0 },
  },
});

const MainBlock = ({ location }) => (
  <div id="MainBlock" className="mt-3 mt-lg-0">
    <PoseGroup animateOnMount={true} preEnterPose="pre">
      <RoutesContainer key={location.pathname}>
        <Switch>
          {/* Home */}
          <Route exact path="/" component={PainelInicial} />

          {/* Empresa */}
          <Route path="/empresa" component={Empresa} />

          {/* Filial */}
          <Route path="/filiais" component={Filiais} />

          {/* Usuário */}
          <Route path="/usuarios" component={Usuarios} />

          {/* Contratos */}
          <Route path="/contratos" component={Contratos} />

          {/* Configurações */}
          <Route path="/configuracoes" component={Configuracoes} />

          {/* Departamentos */}
          <Route path="/departamentos" component={Departamentos} />

          {/* Funcionários */}
          <Route path="/funcionarios" component={VisaoTomadorFuncionarios} />

          {/* 401 */}
          <Route path="/401" component={Error} />

          {/* Documentos */}
          <Route path="/documentos/envios/" component={Envios} />
          <Route path="/documentos" component={Documentos} />

          {/* Gestão de Documentos */}
          <Route path="/gestao-documentos/avaliacao/" component={Avaliacao} />
          <Route path="/gestao-documentos" component={GestaoDocumentos} />

          {/* Pesquisa */}
          <Route path="/pesquisa/:s" component={Pesquisa} />

          {/* 404 */}
          <Route component={NotFound} />
        </Switch>
      </RoutesContainer>
    </PoseGroup>
  </div>
);

export default withRouter(MainBlock);
