import React, { useCallback, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, OverlayTrigger, Popover } from "react-bootstrap";
import { isFuture } from "date-fns";
import moment from "moment";

import DocumentsTable from "./DocumentsTable";

import {
  COLOR_DANGER,
  COLOR_GRAY2,
  COLOR_GREEN,
  COLOR_WARNING,
} from "../../Colors";
import { formatDate } from "../../helpers/formatDate";
import { isIrregularDocument } from "../../helpers/isIrregularDocument";

export default function EmployeeListItem({
  index,
  employee,
  employeeRef,
  absenceInitialDate,
  absenceFinalDate,
  documents = [],
  showingDocumentsFor = [],
  expandedDocumentsFor = [],
  percentage,
  color,
  scroll,
  onIrregularDocumentsDismiss,
  onDocumentOpen,
  onUploadOpen,
  onDocumentDismiss,
  onEdit,
  onDocumentsAccordionToggle,
  onDocumentsExpand,
  onShowContracts,
}) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(
    showingDocumentsFor?.includes(employee.funcionario)
  );

  const hasIrregularDocuments = useMemo(() => !!employee.invalidos, [employee]);

  const irregularDocuments = useMemo(
    () => documents.filter(isIrregularDocument),
    [documents]
  );

  const handleAccordionOnSelect = useCallback(
    (key) => {
      if (onDocumentsAccordionToggle) onDocumentsAccordionToggle(key, employee);
      setIsAccordionOpen(key !== null);
    },
    [onDocumentsAccordionToggle, employee]
  );

  const handleOpen = useCallback(
    (document, employee, isExpanded) =>
      onDocumentOpen(employee, document, isExpanded),
    [onDocumentOpen]
  );

  const handleUpload = useCallback(
    (document, employee, isExpanded) =>
      onUploadOpen(employee, document, isExpanded),
    [onUploadOpen]
  );

  return (
    <Accordion
      key={employee.funcionario}
      activeKey={isAccordionOpen ? "1" : "0"}
      className="d-flex flex-column"
      onSelect={handleAccordionOnSelect}
      style={{
        borderBottom: "2px solid #c8c8c8",
      }}
    >
      <div
        className={`row m-0 justify-content-center pb-3 ${
          employee.invalidos === 0 ? "success" : "warning"
        } ${!index ? "mt-0" : "mt-3"}`}
      >
        <div
          className="col-auto p-0 d-flex justify-content-center"
          style={{ minWidth: 80 }}
        >
          {employee.invalidos > 0 && (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                color: employee.invalidos > 0 ? COLOR_DANGER : COLOR_WARNING,
              }}
            >
              <FontAwesomeIcon icon="exclamation-triangle" />
              Irregular
              <br />
            </div>
          )}
          {employee.invalidos === 0 && (
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{ color: COLOR_GREEN }}
            >
              <FontAwesomeIcon icon="check" />
              Regular
              <br />
            </div>
          )}
        </div>
        <div className="d-flex align-items-center row col">
          <div className="col-12 col-xl-7">
            <div className="col-auto d-flex align-items-center">
              <h3
                ref={
                  employee.funcionario === scroll?.idFuncionario
                    ? employeeRef
                    : null
                }
                className="font-weight-600 mr-2 mb-0 text-uppercase"
                style={{ fontSize: "14px" }}
              >
                {employee.nome}
              </h3>
              {employee.afastamento && isFuture(absenceFinalDate) && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Popover className="p-1" id="afastamento-tooltip">
                      {formatDate(absenceInitialDate)} até{" "}
                      {formatDate(absenceFinalDate)}
                    </Popover>
                  }
                >
                  <small
                    className="badge-pill badge-danger py-1 px-2 mx-1 font-weight-bold text-uppercase"
                    style={{ background: "#f15923" }}
                  >
                    {employee.afastamento?.tipoAfastamentoNome}
                  </small>
                </OverlayTrigger>
              )}
            </div>
            <div className="col-auto d-flex flex-column mt-2">
              <div className="col-12 d-flex p-0">
                <div className="col d-flex flex-column text-center text-md-left p-0">
                  <div className="row text-left">
                    <div className="col-5">
                      <span
                        style={{
                          color: COLOR_GRAY2,
                          fontSize: "14px",
                        }}
                      >
                        Cargo:
                      </span>
                    </div>
                    <div className="col-7">
                      <span
                        className="text-uppercase"
                        style={{
                          color: COLOR_GRAY2,
                          fontSize: "14px",
                        }}
                      >
                        <b>{employee.cargo}</b>
                      </span>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-5">
                      <span
                        style={{
                          color: COLOR_GRAY2,
                          fontSize: "14px",
                        }}
                      >
                        CPF:
                      </span>
                    </div>
                    <div className="col-7">
                      <span
                        style={{
                          color: COLOR_GRAY2,
                          fontSize: "14px",
                        }}
                      >
                        <b>{employee.cpf}</b>
                      </span>
                    </div>
                  </div>
                  <div className="row text-left">
                    <div className="col-5">
                      <span
                        style={{
                          color: COLOR_GRAY2,
                          fontSize: "14px",
                        }}
                      >
                        Data Admissão:
                      </span>
                    </div>
                    <div className="col-7">
                      <span
                        style={{
                          color: COLOR_GRAY2,
                          fontSize: "14px",
                        }}
                      >
                        <b>
                          {moment(employee.dataAdmissao.date).format(
                            "DD/MM/YYYY"
                          )}
                        </b>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <button
                  type="button"
                  onClick={() => onEdit(employee.idFilialFuncionario)}
                  style={{
                    textDecoration: "underline",
                    fontSize: "12px",
                  }}
                  className="mt-2 mr-3 font-weight-600"
                >
                  EDITAR FUNCIONÁRIO
                </button>
                <Accordion.Toggle
                  type="button"
                  eventKey="1"
                  style={{
                    textDecoration: "underline",
                    fontSize: "12px",
                  }}
                  className="mt-2 mr-3 font-weight-600"
                >
                  {isAccordionOpen ? "OCULTAR" : `ABRIR`} DOCUMENTOS
                </Accordion.Toggle>
                {hasIrregularDocuments && (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Popover className="p-1">
                        Marcar todos os Documentos Irregulares do Funcionário
                        (expirado, expirando e pendentes) como Dispensados
                      </Popover>
                    }
                  >
                    <button
                      type="button"
                      onClick={() =>
                        onIrregularDocumentsDismiss(
                          irregularDocuments,
                          employee,
                          !irregularDocuments.length && hasIrregularDocuments
                        )
                      }
                      style={{
                        textDecoration: "underline",
                        fontSize: "12px",
                      }}
                      className="mt-2 font-weight-600"
                    >
                      DISPENSAR DOCUMENTOS PENDENTES
                    </button>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          </div>
          <div className="col-10 offset-1 col-xl p-0 ml-xl-4 mr-xl-4 mt-2 mt-xl-0">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover
                  placement="bottom"
                  id="popover-basic"
                  title={
                    parseInt(percentage) < 100 ? "Como melhorar?" : "Tudo certo"
                  }
                >
                  {parseInt(percentage) < 100 && (
                    <div className="p-2">
                      {employee.invalidos > 0 && (
                        <div
                          style={{
                            color: COLOR_DANGER,
                          }}
                        >
                          <FontAwesomeIcon icon="ban" />{" "}
                          <b>Documento(s) Inválido(s)</b>: Caso o documento
                          tenha sido recusado, é possível reenviá-lo ao contrato
                          que o fez, ou para todos os contratos, caso o
                          documento tenha expirado deve-se ser enviado a todos
                          os contratos
                        </div>
                      )}
                    </div>
                  )}
                  {parseInt(percentage) >= 100 && (
                    <div className="p-2" style={{ color: COLOR_GREEN }}>
                      <FontAwesomeIcon icon="check" /> Nenhuma pendência
                    </div>
                  )}
                </Popover>
              }
            >
              <div className="progress mt-4">
                <div
                  className="progress-bar"
                  style={{
                    background: color,
                    width: parseInt(employee.percentagem * 100) + "%",
                  }}
                  role="progressbar"
                  aria-valuenow={parseInt(employee.percentagem * 100)}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  id="progressbar"
                >
                  <span
                    className="progress-indicator"
                    style={{
                      backgroundColor: color,
                      borderColor: color,
                      left: parseInt(percentage) + "%",
                    }}
                  >
                    {parseInt(employee.percentagem * 100)}%
                  </span>
                </div>
              </div>
            </OverlayTrigger>
            <div
              className="w-100 text-center font-weight-500 pt-2"
              style={{ fontSize: "15px" }}
            >
              {employee.aguardando + employee.invalidos > 0 && (
                <span className="d-block w-100">
                  {employee.invalidos > 0 && (
                    <>
                      HÁ <b>{employee.invalidos}</b> PENDÊNCIA(S)
                    </>
                  )}
                  {employee.aguardando > 0 && (
                    <>
                      {employee.invalidos > 0 ? " E" : "HÁ"}{" "}
                      <b>{employee.aguardando}</b> AGUARDANDO APROVAÇÃO
                    </>
                  )}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <DocumentsTable
        employee={employee}
        documents={isAccordionOpen ? documents : []}
        handleEyeClick={handleOpen}
        handleFinalDateDbClick={handleOpen}
        handleInitialDateDbClick={handleOpen}
        handleNameDbClick={handleOpen}
        handleStatusDbClick={handleOpen}
        onUploadOpen={handleUpload}
        handleEmployeeDocumentDismissal={onDocumentDismiss}
        scroll={scroll}
        showingAllDocumentsDefault={expandedDocumentsFor?.includes(
          employee.funcionario
        )}
        onDocumentsExpand={onDocumentsExpand}
        onShowContracts={onShowContracts}
      />
    </Accordion>
  );
}
