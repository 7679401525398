import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import axios from 'axios'

import { URL_API } from '../../actions/urls'

export default class Cpf extends Component {
    constructor() {
        super();
        
        let anyNum = /[0-9]/;
        this.state = { 
            value: null,
            mask: [anyNum, anyNum, anyNum, '.', anyNum, anyNum, anyNum, '.', anyNum, anyNum, anyNum, '-', anyNum, anyNum]
        }

        this.buscaCpf = this.buscaCpf.bind(this)
    }

    buscaCpf = (cpf) => {
        cpf = cpf.replace(/[^0-9]/g, '')
        if(cpf?.length !== 11 || !this.props.mounted) return
        this.setState({
            loading: true
        })
        axios.get(URL_API+'cpf?cpf='+cpf)
        .then(res => {
            if(this.props.mounted){
                this.setState({
                    loading: false
                })
                if(this.props.callback)
                    this.props.callback(res.data)
            }
        })
        .catch(err => {
            if(this.props.mounted){
                this.setState({
                    loading: false
                })
                let erro = "Não foi possível obter os dados do CPF"
                if(err.response){
                    erro = err.response
                }
                console.error(erro)
            }
        });
    }

    onChange = (event) => {
        var value = event.target.value
        this.setState({
            value: value
        })
        this.buscaCpf(value)
        this.props.onChange(event)
    }
      
    render() {
        const value = this.state.value === null ? this.props.value : this.state.value
        let props = {}
        Object.keys(this.props).map((idx)=>{ // Não pode colocar a função customizada callback no input
            if(idx !== 'callback' && idx !== 'mounted') 
                props[idx] = this.props[idx]
            
            return null
        })
        return (
            <MaskedInput guide={true} type="tel" mask={this.state.mask} {...props} value={value} onKeyUp={this.onChange} className="form-control" />
        )
    }
}
Cpf.propTypes = {
    mounted: PropTypes.bool.isRequired,
}