/* Geral / Bibliotecas */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "react-table/react-table.css";

/* Métodos / Ações */
import { fetch } from "../../actions/envios";
import { setModalOpen } from "../../actions/modal";
import { avaliacaoDocumento } from "../../actions/documentos";
import { clearComentario } from "../../actions/comentarios";
import { setFuncionarioId } from "../../actions/scroll";

/* Cores */
import {
  COLOR_DARK2,
  COLOR_GREEN,
  COLOR_WARNING,
  COLOR_INFO,
  COLOR_DANGER,
} from "../../Colors";

/* Componentes */
import Error from "../Error";
import LoadingImage from "../LoadingImage";
import RightPanel from "../Sidepanel/RightPanel";
import Upload from "./Upload";
import ComentarioForm from "../Comentario/ComentarioForm";

/* Botões */
import ButtonSuccess from "../Buttons/ButtonSuccess";
import ButtonWarning from "../Buttons/ButtonWarning";
import ButtonDark from "../Buttons/ButtonDark";
import ButtonBack from "../Buttons/ButtonBack";
import { setFilialFuncionarioId } from "../../actions/filialFuncionario";
import EnvioArquivo from "./EnvioArquivo";

const MySwal = withReactContent(Swal);

class Avaliacao extends Component {
  constructor() {
    super();

    this._isMounted = false;
    this.state = {
      expand: false,
      qtdModalOpen: 1,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState(() => ({
      loadingTimeout: setTimeout(() => {
        if (!this.props.dados?.documento) {
          this.props.history.push("/gestao-documentos/");
        }
      }, 1000),
    }));

    if (this.props.dados?.documento)
      this.props.dispatch(fetch(this.props.dados));

    // Se vier pelo envelope abrir a modal
    if (window.location.hash === "#comments") {
      this.props.dispatch(setModalOpen("comments"));
    }

    if (this.props.dados.funcionario?.idFuncionario) {
      this.props.dispatch(
        setFuncionarioId({
          id: this.props.dados.funcionario.idFuncionario,
          fired: !!this.props.dados.funcionario?.dataDemissao,
        })
      );
    }

    if (this.props.dados.funcionario?.idFilialFuncionario) {
      this.props.dispatch(
        setFilialFuncionarioId(this.props.dados.funcionario.idFilialFuncionario)
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.state.loadingTimeout && clearTimeout(this.state.loadingTimeout);
  }

  aprovarDocumento = () => {
    MySwal.fire({
      title: "",
      type: "warning",
      html:
        "Deseja realmente aprovar o documento <b>" +
        this.props.dados.documento.nome +
        "</b>,<br> enviado pela empresa <b>" +
        this.props.dados.filtros.empresa.nome +
        "</b>?",
      showCancelButton: true,
      confirmButtonText: "Sim, aprovar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.props.dispatch(
          avaliacaoDocumento(this.props.dados, 1, null, () => {
            this.props.history.push("/gestao-documentos/");
          })
        );
      }
    });
  };

  rejeitarDocumento = () => {
    MySwal.fire({
      title: "",
      type: "warning",
      input: "text",
      inputPlaceholder: "Justificativa",
      inputValidator: (value) => {
        if (!value) {
          return "Por favor informe uma justifitiva, lembre-se: isso deve ajudar o terceiro a adequar o documento";
        }
      },
      html:
        "Deseja realmente rejeitar o documento <b>" +
        this.props.dados.documento.nome +
        "</b>,<br> enviado pela empresa <b>" +
        this.props.dados.filtros.empresa.nome +
        '</b>?<br><span class="small">Por favor informe a justificativa da rejeição abaixo:</span>',
      showCancelButton: true,
      confirmButtonText: "Sim, rejeitar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.props.dispatch(
          avaliacaoDocumento(this.props.dados, -1, result.value, () => {
            this.props.history.push("/gestao-documentos/");
          })
        );
      }
    });
  };

  render() {
    if (this.props.error && this.props.modal === "") {
      return <Error erro={this.props.error}></Error>;
    }

    if (!this.props.dados?.documento || this.props.loading) {
      return <LoadingImage />;
    }

    if (
      this.props.dados.contrato &&
      this.props.dados.contrato.tipo !== "tomador"
    ) {
      return (
        <Error
          erro={
            "Parece que você não é tomador deste contrato, você não deveria estar nesta tela, " +
            "caso queira aprovar documentos vá para a aba Gestão, caso o problema persista entre em contato."
          }
        ></Error>
      );
    }

    let a = this.props.envio.validade
      ? moment(this.props.envio.validade.date).startOf("day")
      : moment().startOf("day");
    let b = moment().startOf("day");

    let diffDuration = moment.duration(a.diff(b));

    const anosRestantes = this.props.envio.validade ? diffDuration.years() : 0;
    const mesesRestantes = this.props.envio.validade
      ? diffDuration.months()
      : 0;
    const diasRestantes = this.props.envio.validade ? diffDuration.days() : 0;

    const status =
      this.props.envio.ultimo || this.props.envio.versoes?.length === 0
        ? () => {
            switch (this.props.envio.status) {
              case -2: // Recusado
                return (
                  <div
                    style={{
                      color: COLOR_DANGER,
                    }}
                  >
                    <FontAwesomeIcon icon="ban" /> Recusado
                  </div>
                );
              case -1: // Expirado
                return (
                  <div
                    style={{
                      color: COLOR_DANGER,
                    }}
                  >
                    <FontAwesomeIcon icon="exclamation-circle" /> Expirado
                  </div>
                );
              case 0: // A fazer upload
                return (
                  <div
                    style={{
                      color: COLOR_WARNING,
                    }}
                  >
                    <FontAwesomeIcon icon="cloud-upload-alt" /> Pendente
                  </div>
                );
              case 1: // Próximo da expiração
                return (
                  <div
                    style={{
                      color: COLOR_INFO,
                    }}
                  >
                    <FontAwesomeIcon icon="clock" /> Expirando
                  </div>
                );
              case 2: // Aguardando aprovação
                return (
                  <div
                    style={{
                      color: COLOR_INFO,
                    }}
                  >
                    <FontAwesomeIcon icon="hourglass-half" /> Aguardando
                    Aprovação
                  </div>
                );
              case 3: // Sem pendências
                return (
                  <div
                    style={{
                      color: COLOR_GREEN,
                    }}
                  >
                    <FontAwesomeIcon icon="check" /> Regular
                  </div>
                );
              default:
                return null;
            }
          }
        : () => {
            return null;
          };

    const filial = this.props.dados.filial
      ? this.props.dados.filial
      : this.props.usuarioLogado.filial;

    return (
      <div>
        <div className="row justify-content-center mx-0">
          <div className="col-12 px-0">
            <div className="card tertium-card">
              <div className="card-header py-3 px-2 px-lg-5">
                <ButtonBack modal={this.state.qtdModalOpen}></ButtonBack>
                {(!this.props.dados.filtros ||
                  !this.props.dados.filtros.empresa) && (
                  <div>Avaliação de Documento</div>
                )}
                {this.props.dados.filtros && this.props.dados.filtros.empresa && (
                  <div>
                    <img
                      src={
                        this.props.dados.filtros.empresa.logo
                          ? this.props.dados.filtros.empresa.logo
                          : "/images/filial.png"
                      }
                      alt={this.props.dados.filtros.empresa.nome}
                      className="img-fluid rounded-circle ml-2"
                      style={{ height: "40px" }}
                    />
                    &nbsp;
                    {this.props.dados.filtros.empresa.nome}
                  </div>
                )}
              </div>
              <div className="card-body pb-0">
                <div
                  className="row mx-0 py-4 px-2 px-lg-5"
                  style={{
                    borderBottom: !this.state.expand
                      ? "none"
                      : "1px solid #cccccc",
                  }}
                >
                  <div className="col-12 col-lg-7 d-flex flex-column">
                    <h4
                      className="font-weight-400"
                      style={{ color: COLOR_DARK2 }}
                    >
                      {this.props.dados.documento.nome}
                    </h4>
                    {this.props.envio.status !== 0 && (
                      <div
                        style={{
                          fontSize: "15px",
                          color:
                            anosRestantes >= 1 ||
                            mesesRestantes >= 1 ||
                            diasRestantes >= 1
                              ? COLOR_GREEN
                              : COLOR_WARNING,
                        }}
                        className="font-weight-500"
                      >
                        <FontAwesomeIcon icon="calendar-alt" /> VENCIMENTO:{" "}
                        {moment(this.props.envio.validade.date).format(
                          "DD/MM/YYYY"
                        )}
                        &nbsp;
                        {(anosRestantes >= 1 ||
                          diasRestantes >= 1 ||
                          mesesRestantes >= 1) && (
                          <span
                            style={{
                              color: "#666666",
                              fontWeight: "400",
                            }}
                          >
                            ({anosRestantes > 1 && anosRestantes + " anos, "}
                            {anosRestantes === 1 && "1 ano, "}
                            {mesesRestantes > 1 && mesesRestantes + " meses e "}
                            {mesesRestantes === 1 && "1 mês e "}
                            {diasRestantes > 1 &&
                              diasRestantes + " dias restantes"}
                            {diasRestantes === 1 && "1 dia restante "})
                          </span>
                        )}
                      </div>
                    )}
                    {this.props.dados.funcionario && (
                      <div
                        style={{
                          fontSize: "15px",
                        }}
                        className="font-weight-500"
                      >
                        <FontAwesomeIcon icon="user-friends" /> FUNCIONÁRIO:{" "}
                        <b>{this.props.dados.funcionario.nome}</b>
                      </div>
                    )}
                    {this.props.dados.filtros &&
                      this.props.dados.filtros.contrato && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          className="font-weight-500"
                        >
                          <FontAwesomeIcon icon="signature" /> CONTRATO:{" "}
                          <b>{this.props.dados.filtros.contrato.nome}</b>
                        </div>
                      )}
                    {this.props.envio.idDocumentoEnvio &&
                      !this.props.envio.ultimo && (
                        <div
                          style={{
                            fontSize: "15px",
                            color: COLOR_WARNING,
                          }}
                          className="font-weight-500 cursor-pointer"
                          onClick={() => {
                            this.setState({
                              expand: true,
                            });
                          }}
                        >
                          <FontAwesomeIcon icon="exclamation-triangle" />{" "}
                          <b>ATENÇÃO</b>: Você não está vendo a última versão
                          deste documento, clique aqui para ver todas as
                          emissões
                        </div>
                      )}
                    {status()}
                  </div>
                  <div className="col-12 col-lg-4 d-flex align-items-center">
                    <div
                      className="w-100 text-center font-weight-500 pt-2"
                      style={{ fontSize: "15px" }}
                    >
                      {(this.props.envio.ultimo ||
                        this.props.envio.versoes?.length === 0) && (
                        <div className="d-flex justify-content-around w-100">
                          {(this.props.envio.status === 2 ||
                            this.props.envio.status === -2) && (
                            <>
                              <ButtonSuccess
                                onClick={() => {
                                  this.aprovarDocumento();
                                }}
                                style={{
                                  fontSize: "14px",
                                }}
                                className="px-4 py-2 my-2"
                              >
                                <FontAwesomeIcon icon="check" /> APROVAR
                              </ButtonSuccess>

                              {this.props.envio.contratos?.length === 1 &&
                                this.props.envio.status === -2 && (
                                  <ButtonWarning
                                    onClick={() => {
                                      MySwal.fire({
                                        title: "",
                                        type: "warning",
                                        html: `<h5 style="color:${COLOR_DANGER}">Recusado</h5>
                                                                        <b>Justificativa:</b><br>
                                                                        ${this.props.envio.contratos[0].justificativa}`,
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        cancelButtonText: "Fechar",
                                      });
                                    }}
                                    style={{
                                      fontSize: "14px",
                                    }}
                                    className="px-4 py-2 my-2"
                                  >
                                    <FontAwesomeIcon icon="question-circle" />
                                  </ButtonWarning>
                                )}
                            </>
                          )}
                          {(this.props.envio.status === 2 ||
                            this.props.envio.status === 3) && (
                            <ButtonWarning
                              onClick={() => {
                                this.rejeitarDocumento();
                              }}
                              style={{
                                fontSize: "14px",
                              }}
                              className="px-4 py-2 my-2"
                            >
                              <FontAwesomeIcon icon="times" /> REJEITAR
                            </ButtonWarning>
                          )}
                          {this.props.envio.status === 0 && (
                            <ButtonDark
                              onClick={() => {
                                this.props.dispatch(setModalOpen("upload"));
                                this.setState({
                                  qtdModalOpen: this.state.qtdModalOpen + 1,
                                });
                              }}
                              style={{
                                fontSize: "14px",
                              }}
                              className="px-4 py-2 my-2"
                            >
                              <FontAwesomeIcon icon="file-export" /> ENVIAR
                              DOCUMENTO
                            </ButtonDark>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-1 d-flex align-items-center justify-content-center">
                    <button
                      style={{
                        backgroundColor: "#696969",
                        color: "#dcdcdc",
                        fontSize: "2.5em",
                        borderRadius: "50%",
                        display: "flex",
                      }}
                      onClick={() => {
                        if (this.state.expand) {
                          this.setState({
                            expand: false,
                          });
                        } else {
                          this.setState({
                            expand: true,
                          });
                        }
                      }}
                    >
                      {this.state.expand && (
                        <FontAwesomeIcon
                          style={{ margin: "-1px" }}
                          icon="chevron-circle-up"
                        />
                      )}
                      {!this.state.expand && (
                        <FontAwesomeIcon
                          style={{ margin: "-1px" }}
                          icon="chevron-circle-down"
                        />
                      )}
                    </button>
                  </div>
                </div>
                {this.state.expand && (
                  <div className="row mx-0 py-4 px-2 px-lg-5">
                    <div className="col-12">
                      <h5
                        className="font-weight-400"
                        style={{ color: COLOR_DARK2 }}
                      >
                        Emissões:
                      </h5>
                      {this.props.envio.versoes?.length === 0 &&
                        this.props.envio.status !== 0 && (
                          <p>Este documento só tem uma emissão (Em exibição)</p>
                        )}
                      {this.props.envio.versoes?.length === 0 &&
                        this.props.envio.status === 0 && (
                          <p>Este documento nunca foi enviado</p>
                        )}
                      {this.props.envio.versoes?.length > 0 && (
                        <div className="w-100">
                          {this.props.envio.versoes?.map((val, idx) => {
                            return (
                              <div
                                key={idx}
                                className="cursor-pointer mr-2"
                                style={{
                                  backgroundColor:
                                    parseInt(
                                      this.props.envio.idDocumentoEnvio
                                    ) === parseInt(val.idDocumentoEnvio) ||
                                    (this.props.envio.idDocumentoEnvio ===
                                      null &&
                                      idx === 0)
                                      ? COLOR_INFO
                                      : COLOR_DARK2,
                                  color: "white",
                                  fontWeight: 500,
                                  borderRadius: "20px",
                                  padding: "5px 25px",
                                  display: "inline-block",
                                }}
                                onClick={() => {
                                  this.props.dispatch(
                                    fetch(
                                      this.props.dados,
                                      val.idDocumentoEnvio
                                    )
                                  );
                                }}
                              >
                                {moment(val.data.date).format("DD/MM/YYYY")}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.props.envio.idDocumentoEnvio && this._isMounted && (
                  <div className="row mx-0">
                    <div className="col-12 px-0">
                      <EnvioArquivo
                        envio={this.props.envio}
                        filial={filial.idFilial}
                        documento={this.props.dados.documento}
                        funcionario={this.props.dados.funcionario?.idFuncionario}
                        contrato={this.props.dados.filtros.contrato?.idContrato}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          style={{
            backgroundColor: "#f15923",
            color: "#ffffff",
            fontSize: "2em",
            borderRadius: "50%",
            position: "fixed",
            top: "50%",
            right: "15px",
            padding: "8px 14px",
          }}
          onClick={() => {
            this.props.dispatch(setModalOpen("comments"));
          }}
        >
          <FontAwesomeIcon icon={["far", "comments"]} />
        </button>

        {/* Formulário de Upload */}
        <RightPanel
          isPaneOpen={this.props.modal === "upload"}
          title="Upload de Arquivo"
          path="upload"
          onClose={() => {
            this.props.dispatch(setModalOpen(""));
          }}
        >
          <Upload
            modal={this.state.qtdModalOpen}
            avaliacao={true}
            onAfterSave={() => {
              if (this.props.dados && this.props.dados.documento)
                this.props.dispatch(fetch(this.props.dados));
            }}
          ></Upload>
        </RightPanel>
        <RightPanel
          isPaneOpen={this.props.modal === "comments"}
          title="Comentários"
          path="comments"
          onClose={() => {
            this.setState({
              qtdModalOpen: this.state.qtdModalOpen + 1,
            });
            this.props.dispatch(setModalOpen(""));
            this.props.dispatch(clearComentario());
          }}
        >
          <ComentarioForm
            tipoContrato="terceiro"
            contrato={this.props.dados.contrato?.idContrato}
            onAfterSave={this.requestData}
          ></ComentarioForm>
        </RightPanel>
      </div>
    );
  }
}
Avaliacao.propTypes = {
  modal: PropTypes.string.isRequired,
  envio: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  dados: PropTypes.any,
};

const mapStateToProps = (state) => ({
  modal: state.modal.modalOpen,
  dados: state.dados.data,
  error: state.errors.message ? state.errors.message : state.envios.error,
  envio: state.envios.envio,
  usuarioLogado: state.usuarioLogado.dados,
  loading: state.envios.loading,
});

export default connect(mapStateToProps)(withRouter(Avaliacao));
