import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tilt from "react-tilt";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from 'react-router-dom'
import { loginUser, resetPassword } from "../actions/authentication";
import Loading from "../components/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { clearErrors } from "../actions/errors";
import "./Login.css";

const MySwal = withReactContent(Swal);

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      loading: true,
      errors: {}
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.passwordRef = createRef(null);
  }

  handleInputChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const user = {
      email: this.state.email,
      password: this.state.password
    };
    this.setState({
      loading: true
    });
    this.props.loginUser(user);
  }

  handleChangePassword(e) {
    e.preventDefault();

    if (this.state.email === "") return;

    this.setState(
      {
        loading: true
      },
      () => {
        this.props.resetPassword(this.state.email, () => {
          this.setState(
            {
              loading: false
            },
            () => {
              MySwal.fire({
                title: "Senha enviada",
                type: "success",
                text:
                  "Sua nova senha foi enviada para seu e-mail, por favor verifique-o para prosseguir.",
                showCancelButton: false,
                confirmButtonText: "OK"
              });
            }
          );
        });
      }
    );
  }

  componentDidMount() {
    this.props.clearErrors();
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
      return;
    }
    this.setState({
      loading: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      //this.props.history.push('/')
      window.location.href = "/";
    }

    const email = nextProps.history?.location?.state?.email ?? "";

    if (!!email) {
      // Set email used for MFA
      this.setState({ email });
      // Auto focus password input
      this.passwordRef.current.focus();
    }

    if (nextProps.errors) {
      let show = false;
      if (nextProps.errors.message) {
        show = true;
      }
      this.setState({
        errors: nextProps.errors,
        loading: false
      });
      if (show) {
        MySwal.fire({
          title: "Oops!",
          type: "error",
          text: nextProps.errors.message,
          showCancelButton: false,
          confirmButtonText: "OK"
        });

        this.props.clearErrors();
      }
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="limiter">
        <Loading active={this.state.loading}></Loading>
        <div className="container-login100">
          <div className="wrap-login100">
            <Tilt
              className="Tilt login100-pic js-tilt d-flex align-items-center"
              options={{ max: 25 }}
            >
              <img
                src="/images/logo.png"
                alt="Tertium Online"
                style={{ transform: "translateY(-50%)" }}
              />
            </Tilt>

            <form onSubmit={this.handleSubmit} className="login100-form">
              <span className="login100-form-title">Login</span>

              <div className="wrap-input100">
                <input
                  className={classnames("input100", {
                    "is-invalid": errors.email
                  })}
                  type="text"
                  name="email"
                  autoComplete="username"
                  placeholder="E-mail"
                  onChange={this.handleInputChange}
                  value={this.state.email}
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <FontAwesomeIcon icon="envelope" />
                </span>
              </div>
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}

              <div className="wrap-input100">
                <input
                  ref={this.passwordRef}
                  className={classnames("input100", {
                    "is-invalid": errors.password
                  })}
                  type="password"
                  name="password"
                  autoFocus={this.state.autoFocus}
                  autoComplete="current-password"
                  placeholder="Senha"
                  onChange={this.handleInputChange}
                  value={this.state.password}
                />
                <span className="focus-input100"></span>
                <span className="symbol-input100">
                  <FontAwesomeIcon icon="lock" />
                </span>
              </div>
              {errors.password && (
                <div className="invalid-feedback">{errors.password}</div>
              )}

              <div className="container-login100-form-btn">
                <button className="login100-form-btn">Entrar</button>
              </div>

              <div className="text-center pt-3">
                <Link
                  as="button"
                  type="button"
                  className="txt2"
                  to="/esqueci-minha-senha"
                >
                  <FontAwesomeIcon icon="sad-tear" />
                  &nbsp; Esqueci minha senha!
                </Link>
              </div>

              <div className="text-center pt-4">
                <a
                  href="https://tertium.online#contato"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="txt2"
                >
                  <FontAwesomeIcon icon="life-ring" />
                  &nbsp; Preciso de Ajuda
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser, clearErrors, resetPassword }
)(withRouter(Login));
