import {
    FETCH_PESQUISA_BEGIN,
    FETCH_PESQUISA_SUCCESS,
    FETCH_PESQUISA_FAILURE
} from '../actions/types';

export const initialPesquisaState = {
    contratos: [],
    usuarios: [],
    funcionarios: [],
    filiais: [],
}

const initialState = {
    dados: initialPesquisaState,
    loading: false,
    error: null
};
  
export default function pesquisaReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_PESQUISA_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_PESQUISA_SUCCESS:
            return {
                ...state,
                loading: false,
                dados: action.payload.dados
            };

        case FETCH_PESQUISA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                dados: initialPesquisaState
            };

        default:
            return state;
    }
}