/* Geral / Bibliotecas */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from "react-table"
import "react-table/react-table.css"

/* Métodos / Ações */
import { fetchDocumentosContrato, fetch, changeDocumentoContrato, changeAllDocumentosContrato, saveDocumentosContrato } from '../../actions/contratos'
import { clearErrors } from '../../actions/errors'
import { fetch as fetchModelos, saveModelo, selectModelo } from '../../actions/modelos'
import { saveDocumento } from '../../actions/documentos'

/* Componentes */
import Error from '../Error'
import LoadingImage from '../LoadingImage'

/* Botões */
import ButtonSuccess from '../Buttons/ButtonSuccess'
import ButtonDark from '../Buttons/ButtonDark'

/* Campos */

/* Validação */

/* Cores */
import { COLOR_DARK2, COLOR_INFO } from '../../Colors'

const MySwal = withReactContent(Swal)

class ContratoDocumentos extends Component {
    constructor() {
        super()

        this._isMounted = false
        this.state = {
            aba: 'EMP',
            descModelo: ''
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleSubmit() {
        this.props.dispatch(saveDocumentosContrato(this.props.documentos, this.props.id, this.props.onAfterSave))
    }

    handleChange(event) {
        //console.log('value' + event.target.value)
        this.setState({ descModelo: event.target.value });
    }

    handleLoadModelo() {
        MySwal.fire({
            onOpen: () => {
                document.getElementById('modelo-doc').onchange = function (evt) {
                    (document.querySelector('.swal2-content p')).innerHTML = this.options[this.selectedIndex].getAttribute('data-descricao');
                }
            },
            showConfirmButton: true,
            confirmButtonText: 'Carregar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            title: 'Selecione um modelo:',
            html: `<p class="h6 pt-4 pb-2">${this.state.descModelo}</p>
                    <select class="form-control" id="modelo-doc">
                        <option value="0">Selecione um modelo...</option>
                        ${this.props.modelos?.map(function (option) {
                return `<option value="${option.id}" data-descricao="${option.descricao}">${option.titulo}</option>`
            })}
                    </select>`
        }).then((result) => {
            let sel = document.getElementById('modelo-doc')
            let value = sel.options[sel.selectedIndex].value
            if (result && result.value && value > 0) {
                this.props.dispatch(selectModelo(value, this.props.modelos, this.props.documentos))
            }
        })
    }

    handleCreateModelo() {
        MySwal.mixin({
            input: 'text',
            confirmButtonText: 'Continuar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            progressSteps: ['1', '2']
        }).queue([
            {
                title: 'Criar um modelo',
                text: 'Tranforme esta seleção de documentos em um modelo para utilizar nos próximos contratos! Primeiro, digite o nome do modelo...',
                preConfirm: (value) => {
                    return new Promise((resolve, reject) => {
                        if (value && value?.length >= 5) {
                            resolve()
                        } else {
                            reject()
                        }
                    }).catch(() => {
                        Swal.showValidationMessage(
                            `Digite um título válido (>= 5 números)`
                        )
                    });
                }
            },
            {
                title: 'Criar um modelo',
                confirmButtonText: 'Salvar',
                text: 'Agora uma descrição para lembretes futuros',
                preConfirm: (value) => {
                    return new Promise((resolve, reject) => {
                        resolve()
                    }).catch(() => {
                        Swal.showValidationMessage(
                            `Digite uma descrição válida`
                        )
                    });
                }
            },
        ]).then((result) => {
            if (result.value) {
                let dados = Object.assign({}, this.props.documentos)
                dados.titulo = result.value[0]
                dados.descricao = result.value[1]
                dados.contrato = this.props.id

                this.props.dispatch(saveModelo(dados, () => {
                    this.props.dispatch(fetchModelos())
                }))
            }
        })
    }

    handleCreateDocumento() {
        MySwal.mixin({
            confirmButtonText: 'Continuar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            progressSteps: ['1', '2'],
            customClass: {
                input: 'form-control',
            },
        }).queue([
            {
                title: 'Nome',
                text: 'Digite o nome do documento',
                input: 'text',
                preConfirm: (value) => {
                    return new Promise((resolve, reject) => {
                        if (value && value?.length >= 5) {
                            resolve()
                        } else {
                            reject()
                        }
                    }).catch(() => {
                        Swal.showValidationMessage(
                            `Digite um nome válido (>= 5 números)`
                        )
                    });
                }
            },
            {
                title: 'Tipo',
                confirmButtonText: 'Salvar',
                text: 'Selecione o tipo',
                input: 'select',
                inputOptions: {
                    EMP: 'Empresa',
                    FUN: 'Funcionário',
                    CON: 'Contrato',
                },
                preConfirm: (value) => {
                    return new Promise((resolve, reject) => {
                        resolve()
                    }).catch(() => {
                        Swal.showValidationMessage(
                            `Selecione um tipo válido`
                        )
                    });
                }
            },
        ]).then((result) => {
            if (result.value) {
                let dados = {
                    nome: result.value[0],
                    tipo: result.value[1]
                }

                this.props.dispatch(saveDocumentosContrato(this.props.documentos, this.props.id, () => { }, true))

                this.props.dispatch(saveDocumento(dados, () => {
                    if (this.props.id) {
                        this.props.dispatch(fetchDocumentosContrato(this.props.id))
                    }
                }))
            }
        })
    }

    componentDidMount() {
        this._isMounted = true
        if (this.props.id) {
            this.props.dispatch(fetch(this.props.id))
            this.props.dispatch(fetchDocumentosContrato(this.props.id))
            this.props.dispatch(fetchModelos())
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentDidUpdate(nextProps) {
        if (nextProps.errors) {
            let show = false;
            if (Object.keys(nextProps.errors)?.length) {
                show = true
            }
            /*this.setState({
                errors: nextProps.errors
            })*/
            if (show) {
                MySwal.fire({
                    type: 'error',
                    title: nextProps.errors.message,
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000,
                })
                this.props.dispatch(clearErrors())
            }
        }
    }

    render() {
        if (this.props.error) {
            return <Error erro={this.props.error}></Error>
        }

        if (this.props.loading || !this.props.documentos) {
            return <LoadingImage></LoadingImage>
        }

        if (document.querySelector(".documentos-filtro > input")) {
            document.querySelector(".documentos-filtro > input").placeholder = "Que documentos você deseja?";
        }

        return (
            <div className="pb-5">
                {(this.props.permissoes.administradorEmpresa === 1 || this.props.permissoes.administradorFilial === 1) &&
                    <ButtonDark style={{
                        backgroundColor: COLOR_INFO,
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        borderRadius: 0,
                        fontSize: '12px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '64px'
                    }} onClick={this.handleCreateDocumento.bind(this)} type="button"><div><FontAwesomeIcon icon="clipboard-list" /> <span className="d-none d-md-inline-flex">Sugerir</span></div><span className="d-none d-md-inline-flex"> documento</span></ButtonDark>
                }
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-12 px-4">
                        <div className="card">
                            <div className="card-body px-2">
                                <h5 className="font-weight-500 mb-3" style={{ color: COLOR_DARK2 }}><FontAwesomeIcon icon="signature" /> {this.props.contrato.titulo}</h5>
                                <div className="row mx-0 mb-4 pt-4 row-table-filters">
                                    <button type="button" className={"col-auto pb-3 px-0 mr-4" + (this.state.aba === 'EMP' ? ' active' : '')} onClick={() => { this.setState({ aba: 'EMP' }) }}>
                                        Empresa ({this.props.documentos?.qtd ? this.props.documentos?.qtd.EMP : 0}/{this.props.documentos?.EMP ? this.props.documentos?.EMP?.length : 0})
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-4" + (this.state.aba === 'FUN' ? ' active' : '')} onClick={() => { this.setState({ aba: 'FUN' }) }}>
                                        Funcionários ({this.props.documentos?.qtd ? this.props.documentos?.qtd.FUN : 0}/{this.props.documentos?.FUN ? this.props.documentos?.FUN?.length : 0})
                                    </button>
                                    <button type="button" className={"col-auto pb-3 px-0 mr-4" + (this.state.aba === 'CON' ? ' active' : '')} onClick={() => { this.setState({ aba: 'CON' }) }}>
                                        Contrato ({this.props.documentos?.qtd ? this.props.documentos?.qtd.CON : 0}/{this.props.documentos?.CON ? this.props.documentos?.CON?.length : 0})
                                    </button>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <button type="button" onClick={() => {
                                            if (this.props.documentos?.qtd[this.state.aba] === this.props.documentos[this.state.aba]?.length) {
                                                this.props.dispatch(changeAllDocumentosContrato(this.state.aba, false, this.props.documentos))
                                            } else {
                                                this.props.dispatch(changeAllDocumentosContrato(this.state.aba, true, this.props.documentos))
                                            }
                                        }} className="small position-absolute" style={{
                                            right: '24px',
                                            top: '8px',
                                            zIndex: '999',
                                            color: COLOR_DARK2
                                        }}>
                                            {(this.props.documentos?.qtd && (this.props.documentos?.qtd[this.state.aba] === this.props.documentos[this.state.aba]?.length)) &&
                                                'Remover seleção'
                                            }
                                            {(this.props.documentos?.qtd && (this.props.documentos?.qtd[this.state.aba] !== this.props.documentos[this.state.aba]?.length)) &&
                                                'Selecionar todos'
                                            }
                                        </button>
                                        <ReactTable
                                            columns={[
                                                {
                                                    Header: "",
                                                    accessor: "letra",
                                                    filterable: false,
                                                    sortable: false,
                                                    className: 'option-column-5 letra-inicial',
                                                    headerClassName: 'option-column-5',
                                                    Cell: props => <div style={{
                                                        borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
                                                        borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                        borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
                                                        borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                                        borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
                                                    }}>
                                                        {props.original.letra !== '' ? props.original.letra : ' '}
                                                    </div>,
                                                },
                                                {
                                                    Header: "Nome",
                                                    accessor: "nome",
                                                    headerClassName: 'nome-placeholder documentos-filtro',
                                                    className: "font-weight-600 text-uppercase",
                                                    filterable: true,
                                                    Cell: (props) => <div className="d-flex align-items-center">
                                                        <Toggle
                                                            checked={parseInt(props.original.noContrato) !== 0}
                                                            className="mr-3"
                                                            onChange={(evt) => {
                                                                return this.props.dispatch(changeDocumentoContrato(props.index, this.state.aba, evt.target.checked, this.props.documentos))
                                                            }} />{' ' + props.original.nome}
                                                    </div>,
                                                },
                                            ]}
                                            multiSort={false}
                                            data={this.props.documentos[this.state.aba]}
                                            loading={this.props.loading}
                                            previousText={<FontAwesomeIcon icon="chevron-left" />}
                                            nextText={<FontAwesomeIcon icon="chevron-right" />}
                                            resizable={false}
                                            loadingText="Carregando..."
                                            noDataText="Nenhum documento encontrado"
                                            pageText="Página"
                                            ofText="de"
                                            rowsText="linhas"
                                            pageJumpText="ir para a página"
                                            rowsSelectorText="linhas por página"
                                            pageSizeOptions={[5, 10, 20, 50]}
                                            defaultPageSize={10}
                                            minRows={0}
                                            sortable={false}
                                            className="-highlight"
                                            defaultFilterMethod={(filter, row, column) => {
                                                const id = filter.pivotId || filter.id
                                                return row[id] !== undefined ? String(row[id]).toUpperCase().startsWith(filter.value.toUpperCase()) : true
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                                        {this.state.aba === 'EMP' &&
                                            <ButtonSuccess onClick={() => this.setState({ aba: 'FUN' })} type="submit"><FontAwesomeIcon icon="arrow-right" /> Próximo</ButtonSuccess>
                                        }
                                        {this.state.aba === 'FUN' &&
                                            <ButtonSuccess onClick={() => this.setState({ aba: 'CON' })} type="submit"><FontAwesomeIcon icon="arrow-right" /> Próximo</ButtonSuccess>
                                        }
                                        {this.state.aba === 'CON' &&
                                            <ButtonSuccess onClick={this.handleSubmit.bind(this)} type="submit"><FontAwesomeIcon icon="save" /> Salvar</ButtonSuccess>
                                        }
                                    </div>
                                    {(this.props.permissoes && (this.props.permissoes.administradorEmpresa || this.props.permissoes.administradorFilial || this.props.permissoes.permissoesGerais.indexOf('contratos_modelo') !== -1)) &&
                                        <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                                            {this.props.modelos?.length > 0 &&
                                                <ButtonDark onClick={this.handleLoadModelo.bind(this)} type="button"><FontAwesomeIcon icon="file-export" /> Carregar modelo</ButtonDark>
                                            }
                                            <ButtonDark onClick={this.handleCreateModelo.bind(this)} type="button" className="ml-2"><FontAwesomeIcon icon="file-import" /> Criar modelo</ButtonDark>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
ContratoDocumentos.propTypes = {
    contrato: PropTypes.object.isRequired,
    documentos: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    id: PropTypes.any,
    onAfterSave: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    permissoes: PropTypes.object.isRequired,
    modelos: PropTypes.array.isRequired
}

const mapStateToProps = (state) => ({
    contrato: state.contratos.contrato,
    documentos: state.contratos.documentos,
    loading: state.contratos.loadingDocumentos,
    error: state.contratos.errorDocumentos,
    errors: state.errors,
    id: state.modal.id,
    modelos: state.modelos.modelo,
    permissoes: state.permissions.permissoes
})

export default connect(mapStateToProps)(withRouter(ContratoDocumentos));