import React, { useRef, useEffect, useState } from "react";
import "./MaxViewHeightContainer.css";

const MaxViewHeightContainer = ({ children, className, style, ...props }) => {
  const ref = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    if (!ref.current) return;

    const onResize = () =>
      setOffsetTop(ref.current?.getBoundingClientRect().top);

    onResize()

    const ro = new ResizeObserver(() => onResize());
    ro.observe(ref.current);

    return () => {
      ro.disconnect();
    };
  });

  return (
    <div
      style={{
        ...style,
        "--tertium-offset-top": `${offsetTop}px`,
      }}
      className={`${className} scroll-fit`}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
};

export default MaxViewHeightContainer;
