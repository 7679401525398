import { Form, Formik } from "formik";
import React, { useState } from "react";
import ButtonPrimary from "../Buttons/ButtonPrimary";

const BaixarDocumentosModal = ({ onSubmit, onCancel }) => {
  const [selected, setSelected] = useState("all-documents");

  return (
    <>
      <div className="d-flex flex-column my-2">
        <p>Quais arquivos deseja exportar em um arquivo Zip?</p>
        <p>
          <b>Atenção:</b> a geração pode levar alguns segundos, acompanhe a
          mesma na Central de Exportação.
        </p>
      </div>
      <Formik
        onSubmit={() => onSubmit(selected)}
        render={({ isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="mx-auto">
            <div className="my-3">
              <div className="d-flex align-items-center justify-content-center gap-2 my-2">
                <input
                  className="mr-1 cursor-pointer"
                  type="radio"
                  id="all-documents"
                  value="all-documents"
                  name="bulkDownload"
                  defaultChecked={selected === "all-documents"}
                  onChange={() => setSelected("all-documents")}
                />
                <label
                  htmlFor="all-documents"
                  className="h6 d-flex align-items-center cursor-pointer"
                >
                  Exportar Todos os Documentos
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-center gap-2 my-2 pointer">
                <input
                  className="mr-1 cursor-pointer"
                  type="radio"
                  id="approved-documents"
                  value="approved-documents"
                  name="bulkDownload"
                  defaultChecked={selected === "approved-documents"}
                  onChange={() => setSelected("approved-documents")}
                />
                <label
                  htmlFor="approved-documents"
                  className="h6 d-flex align-items-center cursor-pointer"
                >
                  Exportar Apenas Aprovados
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-center gap-1 w-full mt-4">
              <ButtonPrimary
                className="btn-cancel swal2-styled"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancelar
              </ButtonPrimary>
              <ButtonPrimary
                className="btn-confirm swal2-styled"
                type="submit"
                disabled={isSubmitting}
              >
                Confirmar
              </ButtonPrimary>
            </div>
          </Form>
        )}
      />
    </>
  );
};

export default BaixarDocumentosModal;
