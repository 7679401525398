import { SET_NOTIFICACOES } from "../actions/types";

export const initialState = {
    notificacoes: {
        contratos: [],
        vencendo: [],
        contratos_expirando: [],
        contratos_pendentes: [],
        funcionarios_troca_pendentes: [],
        recados_nao_lidos: [],
        quantidade: 0,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_NOTIFICACOES:
            return {
                ...state,
                notificacoes: action.payload,
            };
        default:
            return state;
    }
}
