import React, { Component } from 'react'
import PDFViewer from 'pdf-viewer-reactjs'
import LoadingImage from '../LoadingImage'

export default class PdfPreview extends Component {
    state = {
      loading: false,
      pdf: undefined,
      name: null,
    };
  
    componentWillReceiveProps(nextProps) {
      if (!nextProps.file || typeof nextProps.file !== 'object' || nextProps.file.name === this.state.name) { return; }
  
      this.setState({ loading: true }, () => {
        let reader = new FileReader();
  
        reader.onloadend = () => {

          this.setState({ loading: false, pdf: reader.result, name: nextProps.file.name });
        };
  
        reader.readAsArrayBuffer(nextProps.file);
      });
    }
  
    render() {
        const { file } = this.props;
        const { loading, pdf } = this.state;
    
        if (!file || typeof file !== 'object') { return null; }
    
        if (loading) { return <LoadingImage></LoadingImage>; }
    
        if(!pdf) return null;
        return (
            <PDFViewer
                canvasCss={"height:auto;"}
                navbarOnTop={true}
                scale={0.8}
                scaleStep={0.2}
                maxScale={1.6}
                minScale={0.6}
                hideRotation={true}
                document={{
                    binary: pdf
                }}
            />
        );
    }
  }