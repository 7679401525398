import React from 'react'
import ButtonPrimary from '../Buttons/ButtonPrimary'

const DismissDocumentModal = ({ onCancel, onConfirm }) => {
    return (
        <div className='mx-auto'>
            <div className="form-group d-flex flex-column justify-content-center align-items-center mx-auto mt-4">
                <p style={{ fontWeight: 'bold'}}>Deseja dispensar este documento para este funcionário?</p>
                <p>Atenção: o funcionário não poderá realizar atividades que exijam este documento, sob pena de responsabilidade solidária entre tomador e terceiro!</p>
            </div>
            <div className='d-flex justify-content-center gap-1 w-full mt-4'>
                <ButtonPrimary className="btn-cancel swal2-styled" onClick={onCancel}>
                    Cancelar
                </ButtonPrimary>
                <ButtonPrimary className="btn-confirm swal2-styled" type="submit" onClick={onConfirm}>
                    Confirmar
                </ButtonPrimary>
            </div>
        </div>
    )
}

export default DismissDocumentModal
