/* Geral / Bibliotecas */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactTable from "react-table"
import "react-table/react-table.css"

/* Botões */
import ButtonSuccess from '../Buttons/ButtonSuccess'

/* Cores */
import { COLOR_DARK2 } from '../../Colors'

import { setModalOpen } from '../../actions/modal'
import { SITUACAO } from '../../types/SituacaoDocumento';
import { avaliacaoEmLote } from '../../actions/avaliacao'
import { setFuncionarioId } from '../../actions/scroll'

const ApproveDocuments = ({ isVisible, dispatch, appData, afterSave, data }) => {
  const [selected, setSelected] = useState([]);

  const handleLetter = useCallback((document, _, arr) => {
    const letra = arr.find(d => d.nome.startsWith(document.nome[0])).idDocumento === document.idDocumento
      ? document.nome?.[0]?.toUpperCase()
      : '';

    return {
      ...document,
      letra 
    }
  }, []);

  const formattedDocuments = useMemo(() => {
    const documentos = data?.documentos ?? []
    return documentos
      .filter(document => document.status === SITUACAO.AGUARDANDO_APROVACAO)
      .sort((a, b) => a.nome.localeCompare(b.nome))
      .map(handleLetter);
  }, [data, handleLetter]);

  const isSubmitDisabled = useMemo(
    () => !formattedDocuments.length || !selected.length,
    [formattedDocuments.length, selected.length]
  );

  const handleAfterSave = useCallback(() => {
    if (afterSave) afterSave();
    dispatch(
      setFuncionarioId({
        id: data?.funcionario?.idFuncionario,
        fired: !!data.funcionario?.dataDemissao,
      })
    );
  }, [dispatch, afterSave, data]);

  const handleSubmit = useCallback(
    () => {
      dispatch(avaliacaoEmLote({
        funcionario: data?.funcionario?.idFuncionario,
        contrato: appData?.filtros?.contrato?.idContrato,
        documentos: selected,
      }, handleAfterSave))
      dispatch(setModalOpen(""));
    },
    [handleAfterSave, appData, data, dispatch, selected],
  );

  const toggleDocument = useCallback(
    (docId) => setSelected(
      prev => prev.includes(docId)
        ? prev.filter(documentId => documentId !== docId)
        : [...prev, docId]
    ),
    [],
  );

  const toggleAllDocuments = useCallback(
    () => setSelected(prev => prev.length === formattedDocuments.length ? [] : formattedDocuments.map(document => document.idDocumento)),
    [formattedDocuments],
  );

  const columns = useMemo(() => [
    {
      Header: "",
      accessor: "letra",
      filterable: false,
      sortable: false,
      className: 'option-column-5 letra-inicial',
      headerClassName: 'option-column-5',
      Cell: props => <div style={{
        borderTop: props.original.letra !== '' ? '2px solid white' : 'none',
        borderTopRightRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
        borderTopLeftRadius: props.original.letra !== '' ? 'calc(.25rem - 1px)' : '0',
        borderBottomRightRadius: (props.original.letra_len > 1 && props.original.letra !== '')  ? '0' : 'calc(.25rem - 1px)',
        borderBottomLeftRadius: (props.original.letra_len > 1 && props.original.letra !== '') ? '0' : 'calc(.25rem - 1px)',
      }}>
        {props.original.letra !== '' ? props.original.letra : ' '}
      </div>,
    },
    {
      Header: "Nome",
      accessor: "nome",
      headerClassName: 'nome-placeholder documentos-filtro',
      className: "font-weight-600 text-uppercase",
      filterable: true,
      Cell: (props) => (
        <div className="d-flex align-items-center">
          <Toggle
            checked={selected.includes(props.original.idDocumento)}
            className="mr-3"
            onChange={() => toggleDocument(props.original.idDocumento)}
          />
          {' ' + props.original.nome}
        </div>
      )
    },
  ], [selected, toggleDocument])

  useEffect(() => {
    if (!isVisible) setSelected([]);
  }, [isVisible])

  return (
    <div className="pb-5">
      <div className="row mx-0 d-flex align-items-center justify-content-center">
        <div className="col-12 px-4">
          <div className="card">
            <div className="card-body px-2">
              <h5 className="font-weight-500 mb-3" style={{ color: COLOR_DARK2 }}><FontAwesomeIcon icon="signature" /> {data?.funcionario?.nome}</h5>
              <p className="mb-3" style={{ color: COLOR_DARK2 }}>Selecione os Documentos que deseja aprovar.</p>
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    onClick={toggleAllDocuments}
                    className="small position-absolute"
                    style={{
                      right: '24px',
                      top: '8px',
                      zIndex: '999',
                      color: COLOR_DARK2
                    }}
                  >
                    {selected.length === formattedDocuments.length ? 'Remover seleção' : 'Selecionar todos'}
                  </button>
                  <ReactTable
                    columns={columns}
                    multiSort={false}
                    data={formattedDocuments}
                    previousText={<FontAwesomeIcon icon="chevron-left" />}
                    nextText={<FontAwesomeIcon icon="chevron-right" />}
                    resizable={false}
                    loadingText="Carregando..."
                    noDataText="Nenhum documento encontrado"
                    pageText="Página"
                    searchText="Página"
                    ofText="de"
                    rowsText="linhas"
                    pageJumpText="ir para a página"
                    rowsSelectorText="linhas por página"
                    pageSizeOptions={[5, 10, 20, 50]}
                    defaultPageSize={10}
                    minRows={0}
                    sortable={false}
                    className="-highlight"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-center pt-4">
                  <ButtonSuccess onClick={handleSubmit} disabled={isSubmitDisabled} type="submit"><FontAwesomeIcon icon="check" /> Aprovar documentos</ButtonSuccess>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ApproveDocuments.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
  data: PropTypes.object.isRequired,
  appData: PropTypes.object.isRequired,
  afterSave: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  data: state.modal.extraData,
  appData: state.dados.data,
})

export default connect(mapStateToProps)(withRouter(ApproveDocuments));
