import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../actions/authentication";
import { withRouter } from "react-router-dom";
import { COLOR_DARK2 } from "../Colors";

import ButtonBack from "./Buttons/ButtonBack";

class Error extends Component {
  render() {
    if (this.props.erro === "Token expirado ou expurgado, tente fazer o logout e login novamente") {
      setTimeout(() => {
        this.props.logoutUser(this.props.history);
      }, 3000);
    }
    return (
      <>
        <ButtonBack style={{ height: "20px" }}></ButtonBack>
        <div className="py-5">
          <img src="/images/error.png" className="img-fluid d-block mx-auto" alt="Erro" title="Erro" style={{ maxWidth: "200px" }} />
          <h2 className="text-center font-weight-400 pt-3" style={{ color: COLOR_DARK2 }}>
            Oops! {this.props.erro && this.props.erro.message ? this.props.erro.message : this.props.erro}
          </h2>
        </div>
      </>
    );
  }
}

Error.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logoutUser: (history) => {
    dispatch(logoutUser(history));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Error));
