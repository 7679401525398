import { SET_KEY } from '../actions/types';

const initialState = {
    filial: {
        filial: '',
        chaveCadastro: '',
        nome: '',
        razaoSocial: '',
        cnpj: '',
    }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_KEY:
            return {
                ...state,
                filial: action.payload,
            }
        default:
            return state;
    }
}