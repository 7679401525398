import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "./setAuthToken";
import store from "./store";
import { URL_API } from "../actions/urls";
import { setPermissions } from "../actions/permissions";
import { setPendencias, setCotas } from "../actions/planos";

const refreshPermissions = () => {
  axios
    .get(URL_API + "permissions")
    .then(res => {
      const { data } = res.data;
      store.dispatch(setPermissions(data));
    })
    .catch(err => {
      store.dispatch(
        setPermissions({
          departamentos: [],
          administradorEmpresa: 0,
          administradorFilial: 0,
          permissoesGerais: []
        })
      );
      console.error(
        "Ocorreu um erro ao obter as permissões de usuário. " + err
      );
    });
};

export const refreshPendenciasPlano = () => {
  axios
    .get(URL_API + "problemas-plano")
    .then(res => {
      const { pendencias, cotas } = res.data;
      store.dispatch(setPendencias(pendencias));
      store.dispatch(setCotas(cotas));
    })
    .catch(err => {
      store.dispatch(setPendencias([]));
      console.error("Ocorreu um erro ao obter as pendências do plano. " + err);
    });
};

export const refreshToken = () => {
  if (localStorage.jwtToken) {
    // Atualiza as permissões de usuário também
    refreshPermissions();
    // Atualiza as pendências do plano
    refreshPendenciasPlano();

    const decoded = jwt_decode(localStorage.jwtToken);

    const currentTime = Date.now() / 1000;
    if (decoded.exp - 1800 < currentTime) {
      // Meia hora antes para não perder um formulário por exemplo
      axios
        .post(URL_API + "refresh")
        .then(res => {
          const { data } = res.data;
          localStorage.setItem("jwtToken", data);
          setAuthToken(data);
        })
        .catch(err => {
          localStorage.removeItem("jwtToken");
          setAuthToken(false);
          window.location.reload();
        });
    }
  } else {
    store.dispatch(
      setPermissions({
        departamentos: [],
        administradorEmpresa: 0,
        administradorFilial: 0,
        permissoesGerais: []
      })
    );
    store.dispatch(setPendencias([]));
  }
};
