import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { sendAccessCode } from '../../../actions/authentication';
import { emailField } from '../../../validation/formValidation';

const FirstStep = ({
  email,
  setEmail,
  setLoading,
  nextStep,
}) => {
  const [errors, setErrors] = useState({});

  const handleInputChange = useCallback((e) => {
    setErrors(current => ({ ...current, email: null }));
    setEmail(e.target.value);
  }, [setErrors, setEmail]);

  const validateField = useCallback(() => {
    setErrors(current => ({
      ...current,
      email: emailField(email),
    }));
  }, [email, setErrors])

  const handleGoBack = useCallback(() => window.history.back(), []);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const fieldErrors = {
      email: emailField(email),
    }

    if (fieldErrors.email) {
      setErrors(current => ({ ...current, ...fieldErrors }));
      return;
    }

    try {
      setLoading(true);
      const res = await sendAccessCode(email);

      if (!res?.data?.status) return;

      nextStep();
    } finally {
      setLoading(false);
    }
  }, [email, setErrors, setLoading, nextStep]);

  return (
    <form onSubmit={handleSubmit} className="login100-form">
      <span className="login100-form-title">Esqueci minha senha</span>

      <div className="wrap-input100">
        <input
          className={classnames("input100", {
            "is-invalid": errors.email
          })}
          type="text"
          name="email"
          autoComplete="username"
          placeholder="E-mail"
          onChange={handleInputChange}
          onBlur={validateField}
          value={email}
        />
        <span className="focus-input100"></span>
        <span className="symbol-input100">
          <FontAwesomeIcon icon="envelope" />
        </span>
      </div>
      {errors.email && (
        <div className="invalid-feedback">{errors.email}</div>
      )}

      <div className="container-login100-form-btn">
        <button className="login100-form-btn" disabled={!!errors?.email}>Receber código</button>
      </div>

      <div className="container-login100-form-btn">
        <button type="button" onClick={handleGoBack}>Voltar</button>
      </div>
    </form>
  )
};

export default FirstStep;
